import { Component, OnInit } from '@angular/core';
import { ROUTE_ANIMATIONS_ELEMENTS } from '@app/core';
import { UserService } from '../../core/services/UserService';
import { IUser } from '../../core/interfaces/user/IUser';


@Component({
  selector: 'anms-about',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS;
  releaseButler = require('../../../assets/release-butler.png');
  public user: IUser;
  
  constructor(
      private userService: UserService
  ) { }

  ngOnInit() {
      this.loadUser();
  }

  loadUser() {

      this.userService.getCurrentUser()
          .subscribe(value => this.userLoaded(value),
          error => console.log(error)
          );
  }

  userLoaded(user: IUser) {
      this.user = user;
  }
}
