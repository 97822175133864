
<style>
  .mat-cell, .mat-header-cell {
    padding: 10px;
  }

  .nomarginbutton {
    margin: 0 !important;
  }

  .fix-static-bug {
    width: 100% !important;
    position: static !important;
  }
</style>


<div class="fix-static-bug">
  <div class="container">

    <div class="row">
      <div class="offset-lg-2 col-lg-8 entry">
        <mat-card>
          <h3>Kontoinställningar företag</h3>
          <br />
          <form name="form" *ngIf="!isLoading" (ngSubmit)="onSubmit()" #f="ngForm" novalidate>
            <div class="row" style="margin-bottom: 15px;">

              <div class="col-lg-12">
                <mat-form-field style="width: 100%" floatPlaceholder="never">
                  <input required matInput [ngModel]="user?.AccountSettings.CompanyName" (ngModelChange)="user.AccountSettings.CompanyName = $event" placeholder="Företagets namn" id="companyname" name="companyname">
                </mat-form-field>
              </div>
              <div class="col-lg-6">
                <mat-form-field style="width: 100%" floatPlaceholder="never">
                  <input matInput [ngModel]="user?.AccountSettings.Phone" (ngModelChange)="user.AccountSettings.Phone = $event" placeholder="Telefon" id="telephoneftg" name="telephone">
                </mat-form-field>
              </div>
              <div class="col-lg-6">
                <mat-form-field style="width: 100%" floatPlaceholder="never">
                  <input matInput [ngModel]="user?.AccountSettings.Email" (ngModelChange)="user.AccountSettings.Email = $event" placeholder="E-post" id="emailftg" name="email">
                </mat-form-field>
              </div>
              <div class="col-lg-6" *ngIf="user.AccountSettings.Country.Id == null || user.AccountSettings.Country.Name == 'Sverige'">
                <mat-form-field style="width: 100%" floatPlaceholder="never">
                  <input matInput [ngModel]="user?.AccountSettings.OrgNumber" (ngModelChange)="user.AccountSettings.OrgNumber = $event" ngModel placeholder="Organisationsnr" id="organisationsnrftg" name="Organisationsnr">
                </mat-form-field>
              </div>
              <div class="col-lg-6" *ngIf="user.AccountSettings.Country.Id != null && user.AccountSettings.Country.Name != 'Sverige'">
                <mat-form-field style="width: 100%" floatPlaceholder="never">
                  <input matInput [ngModel]="user?.AccountSettings.Vat" (ngModelChange)="user.AccountSettings.Vat = $event && user.AccountSettings.OrgNumber = $event" ngModel placeholder="VAT" id="VATftg" name="VAT">
                </mat-form-field>
              </div>
              <div class="col-lg-6">
                <mat-select name="country" placeholder="Land" id="countryftg" [(ngModel)]="user.AccountSettings.Country.Id" (ngModelChange)="user.AccountSettings.Country.Id = $event; getCountryName(user.AccountSettings.Country.Id);" [ngModelOptions]="{standalone: true}" required>
                  <mat-option *ngFor="let c of countries" [value]="c.Id">
                    {{ c.Name }}
                  </mat-option>
                </mat-select>
              </div>

              <!-- Kontaktperson -->
              <div class="col-lg-12" style="margin: 20px 0">
                <h4>Kontaktperson</h4>
              </div>

              <div class="col-sm-6">
                <mat-form-field style="width: 100%" floatPlaceholder="never">
                  <input matInput required [ngModel]="user?.AccountSettings.FirstName" (ngModelChange)="user.AccountSettings.FirstName = $event" placeholder="Förnamn" id="firstname" name="firstname">
                </mat-form-field>
              </div>
              <div class="col-sm-6">
                <mat-form-field style="width: 100%" floatPlaceholder="never">
                  <input matInput required [ngModel]="user?.AccountSettings.LastName" (ngModelChange)="user.AccountSettings.LastName = $event" placeholder="Efternamn" id="lastname" name="lastname">
                </mat-form-field>
              </div>
              <div class="col-lg-6">
                <mat-form-field style="width: 100%" floatPlaceholder="never">
                  <input matInput [ngModel]="user?.AccountSettings.ContactPhone" (ngModelChange)="user.AccountSettings.ContactPhone = $event" placeholder="Telefon till kontaktperson" id="telephoneftgcontact" name="telephonecontact">
                </mat-form-field>
              </div>
              <div class="col-lg-6">
                <mat-form-field style="width: 100%" floatPlaceholder="never">
                  <input matInput [ngModel]="user?.AccountSettings.ContactEmail" (ngModelChange)="user.AccountSettings.ContactEmail = $event" placeholder="E-post till kontaktperson" id="emailftgcontact" name="emailcontact">
                </mat-form-field>
              </div>

              <!-- Fakturadress -->
              <div class="col-lg-12" style="margin: 20px 0">
                <h4>Företagsadress</h4>
              </div>

              <div class="col-lg-12">
                <mat-form-field style="width: 100%" floatPlaceholder="never">
                  <input matInput [ngModel]="user?.AccountSettings.Address" (ngModelChange)="user.AccountSettings.Address = $event" placeholder="Företagsadress (gatuadress)" id="addressftg" name="address">
                </mat-form-field>
              </div>
              <div class="col-lg-12">
                <mat-form-field style="width: 100%" floatPlaceholder="never">
                  <input matInput [ngModel]="user?.AccountSettings.Co" (ngModelChange)="user.AccountSettings.Co = $event" placeholder="Företagsadress 2 (t ex c/o)" id="coaddressftg" name="coaddress">
                </mat-form-field>
              </div>
              <div class="col-lg-6">
                <mat-form-field style="width: 100%" floatPlaceholder="never">
                  <input matInput [ngModel]="user?.AccountSettings.Zip" (ngModelChange)="user.AccountSettings.Zip = $event" placeholder="Postnummer" id="zipftg" name="zip">
                </mat-form-field>
              </div>
              <div class="col-lg-6">
                <mat-form-field style="width: 100%" floatPlaceholder="never">
                  <input matInput [ngModel]="user?.AccountSettings.City" (ngModelChange)="user.AccountSettings.City = $event" placeholder="Ort" id="cityftg" name="city">
                </mat-form-field>
              </div>

              <!-- Leveransadress -->

              <div class="col-lg-12" style="margin: 20px 0">
                <h4>Särskild fakturaadress, om annan än ovan</h4>
              </div>

              <div class="col-lg-12">
                <mat-form-field style="width: 100%" floatPlaceholder="never">
                  <input matInput [ngModel]="user?.AccountSettings.DeliveryAddress" (ngModelChange)="user.AccountSettings.DeliveryAddress = $event" placeholder="Fakturaadress (gatuadress)" id="levaddressftg" name="levaddress">
                </mat-form-field>
              </div>
              <div class="col-lg-12">
                <mat-form-field style="width: 100%" floatPlaceholder="never">
                  <input matInput [ngModel]="user?.AccountSettings.DeliveryCo" (ngModelChange)="user.AccountSettings.DeliveryCo = $event" placeholder="Fakturaadress 2 (t ex c/o)" id="levaddressftg2" name="levaddress2">
                </mat-form-field>
              </div>
              <div class="col-lg-6">
                <mat-form-field style="width: 100%" floatPlaceholder="never">
                  <input matInput [ngModel]="user?.AccountSettings.DeliveryZip" (ngModelChange)="user.AccountSettings.DeliveryZip = $event" placeholder="Postnummer" id="levzipftg" name="levzip">
                </mat-form-field>
              </div>
              <div class="col-lg-6">
                <mat-form-field style="width: 100%" floatPlaceholder="never">
                  <input matInput [ngModel]="user?.AccountSettings.DeliveryCity" (ngModelChange)="user.AccountSettings.DeliveryCity = $event" placeholder="Ort" id="levcityftg" name="levcity" #levcity="ngModel">
                </mat-form-field>
              </div>



              <!-- Bankuppgifter -->
              <div class="col-lg-12" style="margin: 20px 0">
                <h4>Bankuppgifter</h4>
              </div>

              <div class="row col-lg-12" *ngIf="user.AccountSettings.Country.Id == null || user.AccountSettings.Country.Name == 'Sverige'">
                <div class="col-lg-6">
                  <mat-form-field style="width: 100%" floatPlaceholder="never">
                    <input matInput [ngModel]="user?.AccountSettings.BankName" (ngModelChange)="user.AccountSettings.BankName = $event" placeholder="Banknamn" id="BankNameftg" name="BankName">
                  </mat-form-field>
                </div>
                <div class="col-lg-3">
                  <mat-form-field style="width: 100%" floatPlaceholder="never">
                    <input matInput [ngModel]="user?.AccountSettings.ClearingNumber" (ngModelChange)="user.AccountSettings.ClearingNumber = $event" placeholder="Clearingnummer" id="ClearingNumberftg" name="ClearingNumber">
                  </mat-form-field>
                </div>
                <div class="col-lg-3">
                  <mat-form-field style="width: 100%" floatPlaceholder="never">
                    <input matInput [ngModel]="user?.AccountSettings.AccountNumber" (ngModelChange)="user.AccountSettings.AccountNumber = $event" placeholder="Kontonummer" id="AccountNumberftg" name="AccountNumber">
                  </mat-form-field>
                </div>
                <div class="col-lg-6">
                  <mat-form-field style="width: 100%" floatPlaceholder="never">
                    <input matInput [ngModel]="user?.AccountSettings.Bankgiro" (ngModelChange)="user.AccountSettings.Bankgiro = $event" placeholder="Bankgiro" id="AccountNumberftg" name="Bankgiro">
                  </mat-form-field>
                </div>

                <div class="col-lg-6">
                  <mat-form-field style="width: 100%" floatPlaceholder="never">
                    <input matInput [ngModel]="user?.AccountSettings.Plusgiro" (ngModelChange)="user.AccountSettings.Plusgiro = $event" placeholder="Plusgiro" id="AccountNumberftg" name="Plusgiro">
                  </mat-form-field>
                </div>
              </div>

              <div class="row col-lg-12" *ngIf="user.AccountSettings.Country.Id == null || user.AccountSettings.Country.Name != 'Sverige'">
                <div class="col-lg-6">
                  <mat-form-field style="width: 100%" floatPlaceholder="never">
                    <input matInput [ngModel]="user?.AccountSettings.Iban" (ngModelChange)="user.AccountSettings.Iban = $event" placeholder="IBAN" id="ibanftg" name="Iban">
                  </mat-form-field>
                </div>

                <div class="col-lg-6">
                  <mat-form-field style="width: 100%" floatPlaceholder="never">
                    <input matInput [ngModel]="user?.AccountSettings.Bic" (ngModelChange)="user.AccountSettings.Bic = $event" placeholder="BIC" id="bicftg" name="Bic">
                  </mat-form-field>
                </div>
              </div>

              <div class="col-sm-12">
                <button color="accent" mat-flat-button [disabled]="!saveValidates() || isLoading" style="float:right; margin-top: 20px;">Spara</button>
              </div>
              <div>{{validationErrorMessage}}</div>
            </div>

          </form>
        </mat-card>
      </div>
    </div>
  </div>
</div>
