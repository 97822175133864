
<style>

  .main-heading
  {
    margin-left: 10px;
    margin-bottom: 20px;
  }

  .sp-form-field
  {
    margin-bottom: 30px;
  }
  .sp-form-field-label {
    margin-bottom: 15px;
    font-weight: 600;
  }
  
  .right {
    float: right;
  }
  .description
  {
    margin-bottom: 20px;
  }
  .warning
  {
    color: red;
  }

  mat-form-field
  {
    width: 100%;
  }

  .row
  {
      margin-bottom: 15px;
  }

  .radiobuttonmargin
  {
      margin-right:5px;
  }

  .description   {
      font-size: 90%;
      font-style: italic;
  }


  .search-input-wrapper {
    padding: 8px 16px;
    display: flex;
    align-items: center;
  }

  .search-input {
    width: 100%;
    border: none;
    outline: none;
    font-size: 16px;
  }

  .search-input-wrapper:hover {
    background-color: rgba(0, 0, 0, 0.04); /* Similar to hover effect for mat-option */
  }
</style>

<form name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>

  <div *ngIf="paymentRecievers != null" class="col-12" style="padding:0px;">
    <h3>Betalmottagare</h3>
    <div class="description">Välj vilka som ska få lön/betalning i fakturaunderlaget</div>
    <div class="d-flex align-items-center">
      <mat-select placeholder="Välj betalmottagare ({{invoiceGroupTemplate?.SelectedPaymentRecievers.length}})"
                  class="select-paymentreciever flex-grow-1"
                  style="color: black;"
                  [(ngModel)]="invoiceGroupTemplate.SelectedPaymentRecievers"
                  name="paymentrecievers"
                  multiple
                  (openedChange)="resetSearchTerm()">

        <!-- Search Input Styled Like Options -->
        <div class="search-input-wrapper">
          <input matInput
                 placeholder="Sök..."
                 [(ngModel)]="searchTerm"
                 (ngModelChange)="filterPaymentReceivers()"
                 autocomplete="off"
                 name="searchterm"
                 class="search-input">
        </div>

        <!-- If no users found, show message -->
        <div class="search-input-wrapper" *ngIf="filteredPaymentRecievers.length === 0" disabled>
          Inga användare hittades
        </div>

        <!-- Filtered Payment Receivers with Checkbox -->
        <ng-container *ngFor="let item of filteredPaymentRecievers">
          <mat-option [value]="item.Id" [selected]="invoiceGroupTemplate.SelectedPaymentRecievers.includes(item.Id)">

            {{item.FirstName}} {{item.LastName}} ({{item.PersonalNumber}})

          </mat-option>
        </ng-container>

      </mat-select>


      <button mat-menu-item (click)="createSalaryReciever($event,invoiceGroupTemplate.SelectedPaymentRecievers)" style="width: 60px; margin-left: 10px;">
        <mat-icon color="primary" matTooltip="Lägg till ny ersättningsmottagare">person_add</mat-icon>
      </button>
    </div>
  </div>

  <hr />
  <h3>Information</h3>
  <div class="col-12 row">
    <div class="description">
      Lägg till information som gäller för alla betalningsmottagare. Individuell information kan läggas till och redigeras i grundvyn.
    </div> 
  </div>


  <div class="col-12 row">
    <h4>Beskrivning</h4>
    <mat-form-field appearance="outline" style="width:100%;">
      <mat-label>Beskrivning av utfört arbete.</mat-label>
      <textarea matInput rows="3"
                [(ngModel)]="invoiceGroupTemplate.Description"
                placeholder="Beskrivning av utfört arbete"
                id="message" name="description-quick-add"></textarea>
    </mat-form-field>

  </div>

  <!--*ngIf="invoiceGroupTemplate?.PaymentReciever?.IsCompany == false && invoiceGroupTemplate?.PaymentReciever?.PersonalNumber != null"-->

  <div class="col-12 row">
    <h4>Anställningsform</h4>
    <mat-radio-group name="employmentType" [(ngModel)]="invoiceGroupTemplate.EmploymentTypeId">
      <mat-radio-button class="ml-2 radiobuttonmargin" [value]="2">Egenanställd <mat-icon color="accent" class="info" matTooltipPosition="after" area-label="Hover to display info" matTooltip="Personen är egenanställd via SAMpoolen. Den egenanställda betalar själv serviceavgiften.">info</mat-icon></mat-radio-button>
      <mat-radio-button class="ml-2 radiobuttonmargin" [value]="1">Bemanning <mat-icon color="accent" class="info" matTooltipPosition="after" area-label="Hover to display info" matTooltip="Personen är anställd av SAMgruppen på uppdrag av dig. Uppdragsgivaren betalar vår serviceavgift.">info</mat-icon></mat-radio-button>
      <mat-radio-button class="ml-2 radiobuttonmargin" [value]="3">Konsult/företagare/enskild firma <mat-icon color="accent" class="info" matTooltipPosition="after" area-label="Hover to display info" matTooltip="Uppdragstagaren är egen företagare. Ingen serviceavgift.">info</mat-icon></mat-radio-button>
    </mat-radio-group>

  </div>

  <div class="col-12 row">
    <h4>Belopp</h4>
    <mat-form-field appearance="outline" style="width: 100%" floatPlaceholder="never">
      <input type="number" matInput [(ngModel)]="invoiceGroupTemplate.Amount" placeholder="Belopp (ex moms)" id="commission" name="commission">
    </mat-form-field>
  </div>

  <div class="col-12 row">

    <h4>Moms *</h4>
    <!--<span style="font-size: 14px;">
      0% moms?
      <a class="accent" style="font-weight: bolder;" href="https://www.sampoolen.se/invoicetool-support/" target="_blank">Klicka här</a>
    </span>-->

    <mat-select required appearance="outline" class="col-12" placeholder="Välj moms" [(ngModel)]="invoiceGroupTemplate.Vat" name="rowvatselected">
      <ng-container *ngFor="let item of vatList | slice:0:3">
        <mat-option value="{{item.Value}}">{{item.Value}} %</mat-option>
      </ng-container>
    </mat-select>

  </div>

  <div class="col-sm-12 sp-form-field" style="margin-top: 40px;">
    <button mat-raised-button [disabled]="!f.form.valid" color="accent" class="right">Spara</button>&nbsp;
    <a mat-stroked-button (click)="cancel()" color="secondary" class="right" style="margin-right:10px;">Avbryt</a>
  </div>

</form>

