var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LocalStorageService, AnimationsService } from '@app/core';
import { SETTINGS_KEY, SettingsActionTypes } from './settings.reducer';
var SettingsEffects = /** @class */ (function () {
    function SettingsEffects(actions$, localStorageService, animationsService) {
        this.actions$ = actions$;
        this.localStorageService = localStorageService;
        this.animationsService = animationsService;
    }
    SettingsEffects.prototype.persistSettings = function () {
        var _this = this;
        return this.actions$.ofType(SettingsActionTypes.PERSIST).pipe(tap(function (action) {
            var settings = action.payload.settings;
            var pageAnimations = settings.pageAnimations, elementsAnimations = settings.elementsAnimations;
            _this.localStorageService.setItem(SETTINGS_KEY, settings);
            _this.animationsService.updateRouteAnimationType(pageAnimations, elementsAnimations);
        }));
    };
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Observable)
    ], SettingsEffects.prototype, "persistSettings", null);
    return SettingsEffects;
}());
export { SettingsEffects };
