<div style='overflow:auto;'>
  <form *ngIf="!useYesNo" #confirmForm="ngForm" novalidate>

    <div class="headline" *ngIf="headline">{{headline}}</div>
    <div class="text" *ngIf="!errorText" [innerHtml]="text"></div>
    <div class="text error" *ngIf="errorText" [innerHtml]="errorText"></div>

    <mat-form-field *ngIf="!hideInput" style="width:100%;margin-top:25px;">
      <input matInput type="text" [(ngModel)]='response.ResponseText' placeholder="{{inputTitle}}" required name="message">
    </mat-form-field>

    <div style="margin-top:30px;">
      <button mat-flat-button [disabled]="isLoading || (requireInput && !confirmForm.valid)" (click)="save()" [color]="confirmButtonColor" style="margin-bottom: 5px; float: right;">
        <mat-icon>{{confirmButtonIcon}}</mat-icon>
        &nbsp;{{confirmButtonText}}&nbsp;
      </button>

      <button *ngIf="!hideCancelButton" [disabled]="isLoading" mat-flat-button (click)="cancel()" color="primary" style="margin-bottom: 5px; margin-right:10px; float: left;">
        <mat-icon>cancel</mat-icon>
        &nbsp;Avbryt&nbsp;
      </button>
    </div>
  </form>
  <form *ngIf="useYesNo" #confirmForm="ngForm" novalidate>

    <div class="headline" *ngIf="headline">{{headline}}</div>
    <div class="text" *ngIf="!errorText" [innerHtml]="text"></div>
    <div class="text error" *ngIf="errorText" [innerHtml]="errorText"></div>

    <!--<mat-form-field *ngIf="!hideInput" style="width:100%;margin-top:25px;">
      <input matInput type="text" [(ngModel)]='response.ResponseText' placeholder="{{inputTitle}}" required name="message">
    </mat-form-field>-->

  <div style="margin-top:30px;">

    <button mat-flat-button [disabled]="isLoading || !confirmForm.valid" (click)="saveYes()" [color]="confirmButtonColor" style="margin-bottom: 5px; float: left;">
      <mat-icon>{{confirmButtonIcon}}</mat-icon>
      &nbsp;ja&nbsp;
    </button>

    <button mat-flat-button [disabled]="isLoading || !confirmForm.valid" (click)="saveNo()" [color]="confirmButtonColor" style="margin-bottom: 5px; float: right;">
      <mat-icon>{{confirmButtonIcon}}</mat-icon>
      &nbsp;nej&nbsp;
    </button>

    <button *ngIf="!hideCancelButton" [disabled]="isLoading" mat-flat-button (click)="cancel()" color="primary" style="margin-bottom: 5px; margin-right:10px; float: left;">
      <mat-icon>cancel</mat-icon>
      &nbsp;Avbryt&nbsp;
    </button>
  </div>
  </form>
</div>
