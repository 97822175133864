
<style>
  .time-row, .text-input, textarea {
    font-size: 16px;
    margin-left: 0px !important;
    margin-bottom: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 8px;
    border: 1px solid gray;
  }

  mat-select.select-hours, mat-select.select-vat, textarea {
    padding: 9px !important;
    border: 1px solid gray !important;
  }

  .mat-form-field-label {
    margin-left: 10px !important;
  }

  textarea:focus, input:focus {
    caret-color: black;
  }

  .segment
  {
    margin-bottom:20px;
  }

  .invoicegroup
  {
    margin-bottom: 20px;
  }
</style>


<div style='height:100%;'>

    <!--<div class="row">
      <mat-form-field class="col-sm-12">
        <textarea matInput placeholder="Beskriv ditt arbete, denna information inkluderas på fakturan" [(ngModel)]="data.invoiceItemGroup.Description" ngDefaultControl [ngModelOptions]="{standalone: true}"></textarea>
      </mat-form-field>
    </div>-->
    <div class="row">
      
      <!--<div class="col-12">
        <img src="../../../assets/logo.png" />
      </div>-->
  
      <div class="row segment" *ngIf="data.Customer != undefined">
        <div class="col-12">
          <h3>1. Kund</h3>
        </div>
        <div class="col-12">
          Orgnr: {{data.Customer.OrgNumber}} 
        </div>
        <div class="col-12">
          {{data.Customer.Name}}
        </div>
        <div class="col-12">
          {{data.Customer.Address}}
        </div>
        <div class="col-12">
          {{data.Customer.Zip}} {{data.Customer.City}} 
        </div>
        <div class="col-12">
          Referens: {{data.Reference.Name}}
        </div>
      </div>

      <div class="row segment">
        <div class="col-12">
          <h3>2. Vad ska faktureras:</h3>
        </div>
        <div *ngFor="let g of data.InvoiceGroups" class="col-12 invoicegroup">
            <b> - {{g.Type.Name}}: {{g.Description}}</b>
            <div *ngFor="let r of g.InvoiceRows">
              - {{r.StartDate | date: 'yyyy-MM-dd'}}, {{r.HoursWorked}} timmar
            </div>
          <div>
            Belopp: {{g.Amount}} {{data.Currency.Name}} ({{g.Vat}} % moms)
          </div>
        </div>
        <div class="col-12">
          - Totalt belopp som faktureras: {{data.TotalInvoiceAmount}} {{data.Currency.Name}} exkl moms
        </div>
        <div class="col-12">
          - Fakturaavgift: {{data.FeePaidByReciever ? data.InvoiceFee : '0'}} {{data.Currency.Name}} exkl moms
        </div>
        <div class="col-12">
          - ROT/RUT?: {{data.IsRotRut ? 'ja' : 'nej'}}
        </div>
      </div>

      <div class="row segment">
        <div class="col-12">
          <h3>3. Fakturering</h3>
        </div>
        <div class="col-12">
          <div>
            - Fakturan skickas via e-post:
            <div style="padding-left:12px;"> &#9702; {{data.RecieverEmail}}</div>
          </div>
        </div>
        <div class="col-12">
          <div>
            - Fakturakopia:
            <div *ngIf="data.RecieverEmailCopy == undefined || data.RecieverEmailCopy == ''" style="padding-left:12px;"> &#9702; Ingen fakturakopia</div>
            <div *ngIf="data.RecieverEmailCopy != undefined && data.RecieverEmailCopy != ''" style="padding-left:12px;"> &#9702; {{data.RecieverEmailCopy}}</div>
          </div>
        </div>
        <div class="col-12">
          <div>
            - Förfallodatum / betalvillkor:
            <div style="padding-left:12px;"> &#9702; {{data.PaymentTerm}} dagar</div>
          </div>
        </div>
      </div>

      <div class="row segment">
        <div class="col-12">
          <h3>4. Lönepåverkande underlag:</h3>
        </div>

        <div class="col-12">
          - Avdrag
        </div>
        <div *ngIf="data.Deductions.length == 0">
          <div style="padding-left:12px;"> &#9702; Inga avdrag</div>
        </div>
        <div *ngFor="let d of data.Deductions" class="col-12">
            <div style="padding-left:12px;"> &#9702; {{d.Amount}} SEK ({{d.VatPercentage}} % moms)</div>
        </div>
        <div class="col-12">
          - Resekostnader
        </div>
        <div class="col-12">
          <div *ngIf="data.TravelExpenses == undefined || data.TravelExpenses.TravelReportTotalAmount == undefined || data.TravelExpenses.TravelReportTotalAmount == '' || data.TravelExpenses.TravelReportTotalAmount == 0" style="padding-left:12px;"> &#9702; Inga resekostnader</div>
          <div *ngIf="data.TravelExpenses.TravelReportTotalAmount != undefined && data.TravelExpenses.TravelReportTotalAmount != '' && data.TravelExpenses.TravelReportTotalAmount != 0" style="padding-left:12px;"> &#9702; {{data.TravelExpenses.TravelReportTotalAmount}} {{data.Currency.Name}}</div>
        </div>
        <div class="col-12">
          - Dela lön
        </div>
        <div *ngIf="data.SalaryShares.length == 0" style="padding-left:12px;"> &#9702; Inga delning</div>
        <div *ngFor="let d of data.SalaryShares" class="col-12">
          <div *ngIf="d.Salary > 0" style="padding-left:12px;"> &#9702; Bruttolön: {{d.Salary}} {{data.Currency.Name}} (delas med: {{d.PersonalNumber}})</div>
          <div *ngIf="d.Share > 0" style="padding-left:12px;"> &#9702; Procentandel: {{d.Share}} % (delas med: {{d.PersonalNumber}})</div>
        </div>
        <div class="col-12">
          - Fakturaavgift
        </div>
        <div class="col-12">
          <div *ngIf="data.FeePaidByReciever" style="padding-left:12px;"> &#9702; Betalas av kunden på fakturan</div>
          <div *ngIf="!data.FeePaidByReciever" style="padding-left:12px;"> &#9702; Betalas av mig</div>
        </div>
        <div class="col-12">
          - Expressutbetalning
        </div>
        <div class="col-12">
          <div *ngIf="data.ExpressPayOut" style="padding-left:12px;"> &#9702; ja</div>
          <div *ngIf="!data.ExpressPayOut" style="padding-left:12px;"> &#9702; nej</div>
        </div>

        <div class="col-12">
          - Valutaavgift
        </div>
        <div class="col-12">
          <div *ngIf="data.Currency.Name != 'SEK'" style="padding-left:12px;"> &#9702; ja</div>
          <div *ngIf="data.Currency.Name == 'SEK'" style="padding-left:12px;"> &#9702; nej</div>
        </div>

     </div>
    
     
      
      
      <!--<div class="row time-row" *ngFor="let i of data.invoiceItemGroup.InvoiceRows" style="width: 100%; margin-top: 20px;">
      <div class="col-sm-6">
        <mat-icon (click)="deleteListItem(i)">delete</mat-icon> {{i.StartDate | date: 'yyyy-MM-dd'}}
      </div>
      <div class="col-sm-3">
        <mat-select placeholder="Timmar" [(ngModel)]="i.HoursWorked" [ngModelOptions]="{standalone: true}">
          <mat-option *ngFor="let item of hoursList" value="{{item.Value}}">{{item.Value}} h</mat-option>
        </mat-select>
      </div>
      <div class="col-sm-3">
        <mat-select placeholder="Moms" [(ngModel)]="i.Vat" [ngModelOptions]="{standalone: true}">
          <mat-option *ngFor="let item of vatList" value="{{item.Value}}">{{item.Value}} %</mat-option>
        </mat-select>
      </div>

    </div>-->

    <!--<div class="row">
      <div class="col-12">
        <button mat-stroked-button style="float: right; margin-right:5px;" (click)="cancel()">stäng</button>&nbsp;
      </div>
    </div>-->
  </div>

</div>