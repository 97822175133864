import { Component, Inject, ViewChild, TemplateRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDatepicker, MatDatepickerToggle, NativeDateAdapter } from '@angular/material';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Http, RequestOptions, Headers, Response, ResponseType } from '@angular/http';
import { Observable, from } from 'rxjs';
import { AuthenticationService } from '../../core/services/AuthenticationService'
import { AppConfig } from '../../config/app.config';
import { IFile } from '../../../core/interfaces/IFile';
import { ITimeReportStatus } from '../../core/interfaces/invoice/TimeReportStatus';
import { InvoiceService } from '../../../core/services/InvoiceService';
import { CompanyService } from '../../core/services/CompanyService';
import { AllowanceService } from '../../../core/services/AllowanceService';
import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { start } from 'repl';
import { ITaxAdjustment } from '../../../core/interfaces/user/ITaxAdjustment';
import { UserService } from '../../../core/services/UserService';
import { debounce } from 'rxjs/operators';
import { IAttachmentOfSalary } from '../../../core/interfaces/user/IAttachmentOfSalary';

@Component({
  selector: 'attachment-of-salary-dialog',
  templateUrl: 'attachment-of-salary-Dialog.Component.html'
})

export class AttachmentOfSalaryDialogComponent {
    private _dimesionToggle = false;
    private _formValid = false;
    public _imageList: any[] = [];
    public _hasImages = false;
    private _showImageList = false;
    private _showAttachments = false;
    private _hasAttachments = false;
    private _showComments = false;
    public isNewCustomer: boolean;
    @ViewChild('myInput', { static: false })
    myInputVariable: any;
    private _apiBaseUrl: string;
    //@ViewChild("pickerStartDate", { static: false }) pickerStartDate: MatDatepicker<Date>;
    selectedDate: Date = new Date();
    public rowTypeList = [];
    public taxAdjustmentTypes: any;
    public isAdmin: boolean = false;
    public sum: number;
    public addButtonValid: boolean = false;
    public errorMessage: string = "";
    public belowTresholdAmount: number = 20435;
    
     
    attachmentOfSalary: IAttachmentOfSalary;

    constructor(
      public dialogRef: MatDialogRef<AttachmentOfSalaryDialogComponent>,
        //private http: Http,
        //private authenticationService: AuthenticationService,
        private invoiceService: InvoiceService,
        private userService: UserService,
      @Inject(MAT_DIALOG_DATA) public data: any
          ) 
    { 
        
    }

  ngOnInit()
  {
      //Create new attachment of salary
      if (this.data.attachmentOfSalary == null) {
        this.attachmentOfSalary = <IAttachmentOfSalary>{
          Id: 0,
          Guid: this.guid(),
          Document: {
            FileType: {
              Id: 0,
              TechnicalName: 'AttachmentOfSalary'
            }
          },
          AttachmentAmount: 0,
          ReservedAmount: 0,
          DateStart: null,
          DateEnd: null
        };
      }
      //Edit attachment of salary
      else {
        JSON.parse(JSON.stringify(this.data.attachmentOfSalary));
        this.attachmentOfSalary = this.data.attachmentOfSalary;
      }

    this.isAdmin = this.userService.userHasRole("admin");        

    this.attachmentOfSalary.Document.Id = 0;
    console.log(this.attachmentOfSalary);
  }

    
  onSubmit()
  {
      this.save();
  }
    
  calculateAddButtonValid()
  {
    if (this.isAdmin)
    {
      return true;
    }
    else {
        
    }
  }
    

  deleteFile(file: IFile)
  {
      file.Guid = null;
      file.Id = 0;
  }

  showFile(file: IFile) {
      window.open(file.Url);
  }
      
  save()
  {debugger
    this.dialogRef.close(this.attachmentOfSalary);
  }
    
  cancel()
  {
      this.dialogRef.close(null);
  }
        
  guid() {  
      return this.s4() + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' + this.s4() + this.s4() + this.s4();
  }

  s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
          .toString(16)
          .substring(1);
  }
    
    
  onError(error: any)
  {
      alert('An error occured');
      console.log(error);
  }

  fileChange(event: any, file: IFile) {

    file.Guid = this.newGuid();      

      let fileList: FileList = event.target.files;
      if (fileList.length > 0) {
          let f: File = fileList[0];

          this.invoiceService.uploadDocument(f, file.Guid)
              .subscribe(
              response => this.onDocumentSaved(response, file),
              error => console.log(error));

      }
  }

  newGuid() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
          var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
          return v.toString(16);
      });
  }

    

  toFormattedDate(iso: string, hours: number = null, minutes: number = null) {
        
    const date = new Date(iso);

    var d = null;

    if (hours != null || minutes != null) {

        if (hours == null)
            hours = 0;
        if (minutes == null)
            minutes = 0;


        d = `${date.getFullYear()}-${(date.getMonth() + 1).toString().length == 1 ? ('0' + (date.getMonth() + 1)) : date.getMonth() + 1}-${date.getDate().toString().length == 1 ? ("0" + date.getDate().toString()) : date.getDate().toString()} ${hours.toString()}:${minutes.toString()}:00`;
    }
    else
    {
        d = `${date.getFullYear()}-${(date.getMonth() + 1).toString().length == 1 ? ('0' + (date.getMonth() + 1)) : date.getMonth() + 1}-${date.getDate().toString().length == 1 ? ("0" + date.getDate().toString()) : date.getDate().toString()}`;
    }

    if (d == '1970-01-01')
        return undefined;
    console.log("Datum");
    console.log(d);
    return d;
  }

  onDocumentSaved(response: any, file: IFile)
  {
      file.Id = response.Id;
    file.Url = response.Url;
    file.FileType = response.FileType;
  }

}
