import { Component, Inject, ViewChild, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { UserService } from '../../core/services/UserService';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService, User } from "../../core/services/AuthenticationService"
import { environment as env } from '@env/environment';
import { LocalStorageService } from '../../core/local-storage/local-storage.service';

@Component({
  selector: 'login-dialog',
  templateUrl: './LoginDialog.Component.html',
  styleUrls: ['./LoginDialog.Component.scss'],
})

export class LoginDialogComponent {

  public user = {} as User;
  public loading: boolean = false;
  public appUrl = env.hostBase;
  public invoiceId: number = 0;

  constructor(
    public dialogRef: MatDialogRef<LoginDialogComponent>,
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    public snackBar: MatSnackBar,
    private userService: UserService,
    private localStorageService: LocalStorageService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit() {
    this.invoiceId = this.data.invoiceId;
    //alert(this.invoiceId);
  }

  onCloseClick(): void {
    this.dialogRef.close();
  }

  loginBankId(openOnAnotherUnit: boolean) {


    // Clear login
    this.localStorageService.removeItem("AUTH");
    this.localStorageService.setItem("editInvoice", this.invoiceId);

    if (openOnAnotherUnit) {
      // Starta BankId på annan enhet
      this.loading = true;
      this.authenticationService.login(null, null, null, this.user.PersonalNumber)
        .subscribe(
          data => {
            this.loading = false;
            // In the current use case we never get here because
            // bankid redirects to another page and component unloads
          },
          error => {
            this.loading = false;
          });
    }
    else {
      // Starta bankID på samma enhet kräver att sessionen lagras för att läsas upp när man kommer tillbaka till sidan
      this.loading = true;

      this.authenticationService.loginBankIdPassive(this.user.PersonalNumber)
        .subscribe(
          data => {

            //setInterval(() => {
            //  alert('intervall');
            //}, 3000);

            // User logged in att BankID, just verify and finish
            // login successful so redirect to return url
            var returnUrl = this.appUrl + "/#/login?bid=" + data
            //var returnUrl = this.appUrl + "/#/login/" + this.invoiceId;
            var bankIdAuthUrl = "https://app.bankid.com/?autostarttoken=" + data + "&redirect=" + returnUrl;
            window.location.href = bankIdAuthUrl;



          },
          error => {
            this.loading = false;
          });
    }
  }

}
