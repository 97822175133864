const packageJson = require('../../package.json');

export const environment = {
  appId: 2,
  appName: 'SAMekonomi',
  envName: 'PROD SAMekonomi',
  themeName: 'samfinans-theme',
  hostBase: "https://app.samekonomi.se",
  hostApi: "https://api.sampoolen.se/api/",
  hostAuth: "https://api.sampoolen.se/oauth2/token",
  bankIdUrl: "https://login.sampoolen.se/Account/Login?caller=5&appId=2",
    production: true,
    versions: {
        app: packageJson.version,
        angular: packageJson.dependencies['@angular/core'],
        ngrx: packageJson.dependencies['@ngrx/store'],
        material: packageJson.dependencies['@angular/material'],
        bootstrap: packageJson.dependencies.bootstrap,
        rxjs: packageJson.dependencies.rxjs,
        angularCli: packageJson.devDependencies['@angular/cli'],
        typescript: packageJson.devDependencies['typescript']
    }
};
