import { Component, Inject, ViewChild, TemplateRef, Input } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { MatDialog, MatRadioChange, MatDialogRef, MAT_DIALOG_DATA, MatDatepicker, MatDatepickerToggle, MatTableDataSource } from '@angular/material';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Http, RequestOptions, Headers, Response } from '@angular/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../../core/services/AuthenticationService'
import { AppConfig } from '../../config/app.config';
import { ICompany } from '../../core/interfaces/ICompany';
import { IFile } from '../../core/interfaces/IFile';
import { CompanyService } from '../../core/services/CompanyService';
import { InvoiceService } from '../../core/services/InvoiceService';
import { UserService } from '../../core/services/UserService';
import { FileService } from '../../core/services/FileService';
import { EditCustomerDialogComponent } from '../../shared/customer/EditCustomerDialog.Component';
import { IFileType } from '../../core/interfaces/IFileType';
import { IDeduction } from '../../core/interfaces/invoice/IDeduction';



@Component({
    selector: 'invoice-admin-dialog',
    templateUrl: 'invoice-admin.Dialog.Component.html'
})


export class InvoiceAdminDialogComponent {
  public isLoading: boolean = true;
    private _dimesionToggle = false;
    private _formValid = false;
    public _imageList: any[] = [];
    public _hasImages = false;
    private _showImageList = false;
    private _showAttachments = false;
    private _hasAttachments = false;
    private _showComments = false;
    public customer: ICompany;
    public isNewCustomer: boolean;
    @ViewChild('myInput', { static: false })
    myInputVariable: any;
    private _apiBaseUrl: string;
    private hoursList = [];
    private vatList = [];
    private rowTypeList = [];
    private hoursSelected: string = "8";
    private vatSelected: string = "25";
    private rowTypeSelected: string = "0"
    private row: any = {};
    public sendTypes: any = [{ Id: 1, Name: "E-post" }, { Id: 2, Name: "Post" }, { Id: 3, Name: "E-faktura" }];
    EnrollmentDate = null;
    downloading = false;
    ExpressPayout: boolean;
    file: IFile = { Id: 0, FileType: { Id: 0, Name: null, TechnicalName: null, PublicToMembers: false }, FileSize: 0, Name: null, Guid: null, Url: null, DisplayDate: null };
    filter: string;
    search: '';
    asc: boolean = false;
    orderBy: string = 'Created';
    documents: any;
    fileTypes = [] as Array<IFileType>;
    dataSource: MatTableDataSource<Array<IFile>>;
    sumDomesticAllowance: number = 0;
    sumInternationalAllowance: number = 0;
    sumMileage: number = 0;
    mileageDistance: number = 0;
    sendDocumentByEmailValid: boolean = false;
    sendDocumentByEmailErrorMessage: string = '';
    DeductionsTotal: number = 0;
    UnhandledDeductions: boolean = false;
    isInvoiceSent = false;
    
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        public dialogRef: MatDialogRef<InvoiceAdminDialogComponent>,
        private http: Http,
        private authenticationService: AuthenticationService,
        private invoiceService: InvoiceService,
        private companyService: CompanyService,
        public dialog: MatDialog,
        private userService: UserService,
        private fileService: FileService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) 
    { 
        
    }

  ngOnInit() {
    this.dataLoaded();
    this.calculateSumAllowances();

  }

  dataLoaded() {

    this.setInitialDeductionStatus();

    if (this.data.InvoiceDocument == null) {
      this.data.InvoiceDocument = { Guid: null, Url: null };
    }

    this.loadFileTypes();
    this.isLoading = false;
  }


  addLinebreaks(maxLineLength: number, text: string)
  {

    if (text != null) {
      var lines = text.split('\n');
      var buffer = "";
      
      for (var i = 0; i < lines.length; i++) {
        // If current word makes the line line longer than x, add new line
        if (lines[i].length > maxLineLength) {
          buffer += this.addLinebreak(maxLineLength, lines[i]);
        }
        else {
          buffer += lines[i] + "\n";
        }
      }
    }
        
    return buffer;
  }

  addLinebreak(maxLineLength: number, text: string) {
        
      var wordList = text.split(' ');
      var currentLine = "";
      var buffer = "";

      for (var i = 0; i < wordList.length; i++) {
          // If current word makes the line line longer than x, add new line
          if ((wordList[i].length + currentLine.length + 1) > maxLineLength) {
              buffer += currentLine;
              buffer += "\n";

              currentLine = wordList[i] + " ";
          }
          else {
              currentLine += wordList[i] + " ";
          }
      }

      // Flush the current line if not empty
      if (currentLine != "")
          buffer += currentLine + "\n";
        
      return buffer;
}

  sendNotificationToUser(file: IFile, invoiceId: number) {
    var question = "";
    
    //if (file.FileType.TechnicalName == "CashInvoice" || file.FileType.TechnicalName == "ExpressInquiry") {
    if (file.FileType != null && (file.FileType.Id == 807 || file.FileType.Id == 304)) {
      question = 'Vill du skicka epost till fakturaavsändaren med filen ';
    }
    else {
      question = 'Vill du skicka epost till fakturamottagaren med filen ';
    }

  if(confirm( question + file.Name + '?'))
  {
    this.isLoading = true;
    this.invoiceService.sendFileUploadedNotification(invoiceId, file.Guid)
      .subscribe(
        response => this.notificationSent(response),
        error => this.isLoading = false);
  }
}

  documentTypeOnChange() {
    
  this.save(this.data);
}

  notificationSent(response: any) {
  //this.data.Log.push(response);
    this.isLoading = false;
    this.isInvoiceSent = true;
}

  toFormattedDate(iso: string) {
        
    const date = new Date(iso);
        
    var d = `${date.getFullYear()}-${(date.getMonth() + 1).toString().length == 1 ? ('0' + (date.getMonth() + 1)) : date.getMonth() + 1}-${date.getDate().toString().length == 1 ? ("0" + date.getDate().toString()) : date.getDate().toString()}`;

    if (d == '1970-01-01')
        return undefined;

    return d;
}

  showFile(file: IFile) { 
      window.open(file.Url);
  }

  onDataChange(newdate) {
           
      //const _ = moment();
      //const date = moment(newdate).add({ hours: _.hour(), minutes: _.minute(), seconds: _.second() })
      //this.date = date.toDate();
      //console.log({ hours: _.hour(), minutes: _.minute(), seconds: _.second() })
  }
    
  onDateChange(evt: any)
  {
      console.log(evt);

      //var d = this.datePipe.transform(evt.value, "yyyy-MM-dd");
        
  }
    
  setDate(date: any)
  {
      this.data.InvoiceSentByUser = date;
  }

  rowTypeChange(event: any)
  {
      this.data.invoiceItemGroup.Type = this.getRowType(event.value);
        
      if (event.value == "Övrigt")
      {
          // No rows allowed in the misc category
          this.data.invoiceItemGroup.InvoiceRows = [];
      }
  }
    
  save(invoice: any) {
    this.isLoading = true;
        this.invoiceService.updateInvoiceSummary(invoice, false, false)
            .subscribe(
            response => this.onSaved(response),
            error => console.log(error));
  }

  saveAndClose(invoice: any): void {
    // Check if the invoice is under review and hasn't been sent
    if (invoice.Status.TechnicalName === 'UnderReview' && !this.isInvoiceSent) {
      const confirmSave = window.confirm('Du har inte skickat fakturadokumentet, vill du spara ändå?');

      if (!confirmSave) {
        this.isLoading = false;  // Reset loading if user cancels
        return;  // Exit the function if the user chooses "Nej"
      }
    }

    // If the user confirmed or the conditions aren't met, proceed with the save
    this.performSaveAndClose(invoice);
  }

  performSaveAndClose(invoice: any): void {
    this.isLoading = true;
    this.invoiceService.updateInvoiceSummary(invoice)
      .subscribe(
        response => this.onSavedClosed(response),
        error => {
          console.log(error);
          this.isLoading = false;  // Reset loading on error
        });
  }

  onSavedClosed(response: any): void {
    this.dialogRef.close(this.data);
  }

  close(invoice: any) {
    this.dialogRef.close(this.data);
  }

  onSaved(response: any) {
    this.data = response;
    this.dataLoaded();

  }

  calculateSumAllowances() {

    //Sum mileage
    for (var i = 0; i < this.data.Allowances.length; i++) {
      if (this.data.Allowances[i].AllowanceType.Id == 1) {
        
        this.sumMileage += Math.round(this.data.Allowances[i].Sum * 100) / 100;
        this.mileageDistance += this.data.Allowances[i].Mileage;
      }
      
      this.sumMileage = Math.round(this.sumMileage * 100) / 100;
    }

    // Sum allowance domestic travel
    for (var i = 0; i < this.data.Allowances.length; i++) {
      if (this.data.Allowances[i].AllowanceType.Id == 2) {

        this.sumDomesticAllowance += Math.round(this.data.Allowances[i].Sum * 100) / 100;
      }

      this.sumDomesticAllowance = Math.round(this.sumDomesticAllowance * 100) / 100;
    }

    // Sum allowance international travel
    for (var i = 0; i < this.data.Allowances.length; i++) {
      if (this.data.Allowances[i].AllowanceType.Id == 3) {

        this.sumInternationalAllowance += Math.round(this.data.Allowances[i].Sum * 100) / 100;
      }

      this.sumInternationalAllowance = Math.round(this.sumInternationalAllowance * 100) / 100;
    }

  }

  changeValueInactive(value) {
    this.data.Inactive = value.checked;
  }

  setInitialDeductionStatus() {
    this.data.DeductionsTotalAmount = 0;

    if (!this.data.ParentInvoice && this.data.Deductions !== undefined) {

      this.data.Deductions.forEach(deduction => {
        // Accumulate total amount
        this.calculateDeductionTotalAmount(); 

        // Set deduction status based on existing properties
        if (deduction.DeniedByAdmin) {
          deduction.status = 'denied';
        }
        else if (deduction.ApprovedByAdmin) {
          deduction.status = 'approved';
        }
        else {
          // Assuming both false represents 'Under Review'
          deduction.status = 'underReview';
          this.UnhandledDeductions = true;
        }
      });
    }
    
    // Set deduction status for subinvoices/invoice groups
    if (this.data.ParentInvoice) { 
      this.data.InvoiceGroups.forEach(group => {

        if (group.Deductions !== undefined) { 
          group.Deductions.forEach(deduction => {
            // Accumulate total amount
            //this.calculateDeductionTotalAmount();

            // Set deduction status based on existing properties
            if (deduction.DeniedByAdmin) {
              deduction.status = 'denied';
            }
            else if (deduction.ApprovedByAdmin) {
              deduction.status = 'approved';
            }
            else {
              // Assuming both false represents 'Under Review'
              deduction.status = 'underReview';
              this.UnhandledDeductions = true;
            }
          });
        }
      });
    }
    
  }

  calculateDeductionTotalAmount() {
    this.data.DeductionsTotalAmount = 0;
    this.DeductionsTotal = 0;

    if (this.data.Deductions != undefined) {
      for (var i = 0; i < this.data.Deductions.length; i++) {

        var deduction = this.data.Deductions[i]

        this.data.DeductionsTotalAmount += deduction.Amount;
        
        if (deduction.ApprovedByAdmin == true) {
          this.DeductionsTotal += deduction.Amount;
        }
      }
    }
  }

  changeDeductionStatus(deduction: any, status: string) {
    // Reset flags
    deduction.DeniedByAdmin = false;
    deduction.ApprovedByAdmin = false;

    switch (status) {
      case 'denied':
        deduction.DeniedByAdmin = true;
        break;
      case 'approved':
        deduction.ApprovedByAdmin = true;
        break;
      case 'underReview':
        // Both are false for 'Under Review'
        break;
    }
    
  }



  getRowType(id: any)
    {
        for (var i = 0; i < this.rowTypeList.length; i++)
        {
            if (this.rowTypeList[i].Name == id)
            {
                return this.rowTypeList[i];
            }
        }
    }

  getGroupTotalHours(group: any)
  {
    var totalMinutes = 0;

    for (var i = 0; i < group.InvoiceRows.length; i++)
    {
        totalMinutes += parseInt(group.InvoiceRows[i].HoursWorked) * 60;
        totalMinutes += parseInt(group.InvoiceRows[i].MinutesWorked);
    }
      
    return this.time_convert(totalMinutes);
  }

  printAllowances()
  {
      alert();
  }
 
  time_convert(num) {
    var hours = Math.floor(num / 60);
    var minutes = num % 60;

    if (minutes > 0)
    {
        return hours + " h " + minutes + " min";
    }
    else
    {
        return hours + " h";
    }
     
}

  uploadFile(event: any, file: any, fileTypeName: string = "")
  {
      if (event.target.files.length > 0) {
            
          let fileList: FileList = event.target.files;
          let f: File = fileList[0];
          file.Guid = this.newGuid();
          file.Url = null;

        this.invoiceService.uploadDocument(f, file.Guid, fileTypeName)
              .subscribe(
              response => this.fileUploaded(response, file),
              error => this.fileUploadError(error, file));
            
      }
  }

  fileUploaded(response: any, file: any) {

    this.save(this.data);
        
    //file.Guid = response.Guid;
    //file.Url = response.Url;
    //file.FileType = response.FileType;
    //file.Name = response.Name;   
  }

  fileUploadError(error: any, file: any) {
      file.Guid = null;
      alert('Ett fel inträffade, försök igen!');
  }

  loadFileTypes() {

    this.fileTypes = [];
    
    this.fileTypes.push(
      { Id: 304, Name: "Expressförfrågan", TechnicalName: "ExpressInquiry" } as IFileType,
      { Id: 15, Name: "Påminnelse 1", TechnicalName: "Reminder1" } as IFileType,
      { Id: 16, Name: "Påminnelse 2", TechnicalName: "Reminder2" } as IFileType,
      { Id: 17, Name: "Skickad till inkasso", TechnicalName: "SentToInkasso" } as IFileType 
    );

    if (this.data.ParentInvoice) {
      this.fileTypes.push(
        { Id: 803, Name: "Kontantfaktura", TechnicalName: "CashInvoice" } as IFileType
      );
    }

    //this.fileService.getFileTypes()
    //  .subscribe(value => this.fileTypesLoaded(value),
    //    error => console.log(error)
    //  );
  }

  fileTypesLoaded(fileTypes: any) {
    this.fileTypes = fileTypes;
  }

  saveDocument() {
    
    var document = {
      FileId: this.file.Id,
      FileTypeId: this.file.FileType.Id,
      EmploymentNumber: this.data.UserSettings.EmploymentNumber,
      FileName: this.file.Name,
      DisplayDate: Date.now()
    };

    this.userService.addFile(document)
      .subscribe(
        response => this.onDocumentCreated(response),
        error => console.log(error));

    this.data.Documents.push(document); 

    this.getDocuments();

    //Empty fields
    this.file.FileType.Id = null;
    this.file.Name = null;
    this.file.Name = null;
    this.file.DisplayDate = null;

  }

  uploadDocument(event: any, documentList: any) {    
    
    // Created new file placeholder
    let file = {
      FileType: {} as IFileType
    } as IFile;
    // Give it an id
    file.Guid = this.newGuid();
    

    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let f: File = fileList[0];
      file.Name = f.name;

      this.invoiceService.uploadDocument(f, file.Guid)
        .subscribe(
          response => this.onDocumentSaved(response, file),
          error => console.log(error));
    }
  }

  onDocumentSaved(response: any, file: IFile) {
    file.Id = response.Id;
    file.Url = response.Url;
    this.data.Documents.push(file);
  }

  onDocumentCreated(response: any) {

    this.file.Id = null;
    this.file.Url = null;
    this.file.Guid = null;

    this.getDocuments();
  }

  getDocuments() {
    var filter = {
      Skip: 0,
      Take: 100,
      Filter: this.filter != null ? this.filter : '',
      OrderBy: this.orderBy,
      Asc: this.asc
    };

    this.fileService.getDocuments(filter)
      .subscribe(response => this.documentsLoaded(response),
        error => console.log(error));
  }

  documentsLoaded(response: any) {

    this.documents = response.Items;
    this.dataSource = new MatTableDataSource(this.documents);
  }

  deleteDocumentFromList(file: IFile, documentList: Array<IFile>) {

    if (confirm("Vill du radera filen " + file.Name + " ?")) {
      for (var i = 0; i < documentList.length; i++) {
        if (documentList[i].Guid == file.Guid) {
          documentList.splice(i, 1);
        }
      }
    }
  }

  saveCalendar()
  {
      this.dialogRef.close(this.data.invoiceItemGroup);
  }

  saveRow() {
      this.dialogRef.close(this.data.invoiceItemGroup);
  }
  
  deleteRow()
  {
      this.data.invoiceItemGroup.action = 'delete';
      this.dialogRef.close(this.data.invoiceItemGroup);
  }    

  cancel()
  {
      this.dialogRef.close(undefined);
  }
    
  deleteListItem(item: any)
  {
      this.data.invoiceItemGroup.InvoiceRows.splice(this.data.invoiceItemGroup.InvoiceRows.indexOf(item), 1);
  }

  guid() {  
      return this.s4() + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' + this.s4() + this.s4() + this.s4();
  }

  s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
          .toString(16)
          .substring(1);
  }
    
  calendarSelect(event: any)
  {
      var date = event.toLocaleDateString();
      this.data.invoiceItemGroup.InvoiceRows.push({ StartDate: date, Id: this.guid(), HoursWorked: this.hoursSelected, Vat: this.vatSelected });
  }
    
  onError(error: any)
  {
      alert('An error occured');
      console.log(error);
  }

  showDocument(url: string) {
      window.open(url);
  }

  newGuid() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
          var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
          return v.toString(16);
      });
  }

  downloadTravelReport(invoiceNumber) {

    this.downloading = true;

    var invoice = invoice;

      this.invoiceService.getOverviewdReport(this.data.Id)
          .subscribe(x => {
              // It is necessary to create a new blob object with mime-type explicitly set
              // otherwise only Chrome works like it should
              var newBlob = new Blob([x], { type: "application/pdf" });

              // IE doesn't allow using a blob object directly as link href
              // instead it is necessary to use msSaveOrOpenBlob
              if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                  window.navigator.msSaveOrOpenBlob(newBlob);
                  return;
              }

              // For other browsers: 
              // Create a link pointing to the ObjectURL containing the blob.
              const data = window.URL.createObjectURL(newBlob);

              var link = document.createElement('a');
            link.href = data;
              
            //link.download = "travelReport.pdf";
            link.download = invoiceNumber + ".pdf";

              // this is necessary as link.click() does not work on the latest firefox
              link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

              setTimeout(function () {
                  // For Firefox it is necessary to delay revoking the ObjectURL
                  window.URL.revokeObjectURL(data);
                  link.remove();
                    
              }, 100);

              this.downloading = false;
          });

  }

  editCustomer(customer: any) {
      this.config.data.customer = customer;
      let dialogRef = this.dialog.open(EditCustomerDialogComponent, this.config);
      dialogRef.afterClosed().subscribe((result: ICompany) => {
          this.data.Customer = result;
          //this.updateCustomer(result);
          //this.setInvoiceEmail(result.InvoiceEmail);
          dialogRef = null;
      });
  }

  getSendTypeName(sendTypeId: number) {
      for (var i = 0; i < this.sendTypes.length; i++) {
          var sendType = this.sendTypes[i];
          if (sendTypeId == sendType.Id) {
              return sendType.Name;
          }
      }
      
}

  sendDocumentByEmailValidation(type: IFileType, recieverEmail: string, referenceEmail: string) {
        
    if (type != null && type.Id > 0) {
      if (type.Id == 304 && (referenceEmail == null || referenceEmail == '')) {
        this.sendDocumentByEmailValid = false;
        this.sendDocumentByEmailErrorMessage = 'Epost till referensperson saknas';
        return false;
      }
      else if (type.Id != 304 && (recieverEmail == null || recieverEmail == '')) {
        this.sendDocumentByEmailValid = false;
        this.sendDocumentByEmailErrorMessage = 'Epost till fakturamottagare saknas';
        return false;
      }
      else if (type.Id == 17) {
        this.sendDocumentByEmailValid = true;
        this.sendDocumentByEmailErrorMessage = '';
        return true;
      }
      else {
        this.sendDocumentByEmailValid = true;
        this.sendDocumentByEmailErrorMessage = '';
        return true;
      }
    }
    //else {
    //  this.sendDocumentByEmailValid = true;
    //  return true;
    //}
  }

  

    config = {
        disableClose: false,
        panelClass: 'full-screen-modal',
        hasBackdrop: true,
        backdropClass: '',
        width: '500px',
        maxWidth: '100vw',
        height: '100%',
        position: {
            top: '',
            bottom: '',
            left: '',
            right: ''
        },
        data: {
            invoiceItemGroup: {},
            customerTemplate: {},
            customer: {},
            contact: {},
            companyId: 0,
            description: '',
            ////status: {},
            //deduction: {} as IDeduction,
            //allowance: {} as IAllowance
        },
        dataTemplate: {
            Id: 0,
            Country: { Id: 1 },
            Type: {},
            HoursWorked: undefined,
            StartDate: undefined,
            EndDate: undefined,
            Comment: undefined,
            InvoiceId: 0
        }
    };
}
