import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { DataSource } from '@angular/cdk/collections';
import { takeUntil } from 'rxjs/operators';
import { ROUTE_ANIMATIONS_ELEMENTS } from '@app/core';
import { InvoiceService } from '../../../core/services/InvoiceService';
import { AuthenticationService } from '../../../core/services/AuthenticationService';
import { UserService } from '../../../core/services/UserService'
import { Observable, of } from 'rxjs';
import { EditCustomerDialogComponent } from '../../../shared/customer/EditCustomerDialog.Component';
//import { InvoiceAdminDialogComponent } from './invoice-admin.Dialog.Component';
import { ICompany } from '../../../core/interfaces/ICompany';
import { IInvoice } from '../../../core/interfaces/IInvoice';
import { IFile } from '../../../core/interfaces/IFile';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDatepicker, MatDatepickerToggle, MatPaginator, MatTableDataSource, MatSortModule, MatSort, Sort } from '@angular/material';
import { Router } from '@angular/router';
import { LocalStorageService } from '../../../core/local-storage/local-storage.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
//import { InvoiceWorkCertificateDialogComponent } from './invoice-work-cert.Dialog.Component';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Component({
    selector: 'organizationinvoices',
    templateUrl: './OrganizationInvoices.component.html',
    styleUrls: ['./OrganizationInvoices.component.scss']
})
export class OrganizationInvoicesComponent implements OnInit, OnDestroy {

    isLoading: boolean = false;
    private unsubscribe$: Subject<void> = new Subject<void>();
  routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS;
  displayedColumns = ['file', 'icon', 'id', 'created', 'dueDate', 'userName', 'customerName', 'status', 'payoutstatus', 'edit'];
    user: any;
    invoiceData: Array<any>;
    statuses = [];
    payoutStatuses: any;
    selectedFilterStatus: any = { Id: 0, Name: 'Alla fakturor' };
    filter: string;
    sortedData;
    dataSource: MatTableDataSource<IInvoice>;
    public listLength = "Laddar fakturor...";
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;
    public isAdmin: boolean = false;
    public search: string;
    searchUpdate = new Subject<string>();
  isMobile: any;
  organization: any;
    
  constructor(public store: Store<any>,
    public snackBar: MatSnackBar,
    public buttonToggleModule: MatButtonToggleModule,
    private invoiceService: InvoiceService,
    private router: Router,
    private userService: UserService,
    private localStorageService: LocalStorageService,
    private loadingService: LoadingBarService,
    private authenticationService: AuthenticationService,
    public dialog: MatDialog)
  {
    this.getInvoiceStatuses();

    // Debounce search.
    this.searchUpdate.pipe(
        debounceTime(600),
        distinctUntilChanged())
        .subscribe(value => {
            this.paginator.pageIndex = 0;
            this.filter = value.toString();
            this.getInvoices(null, 0, this.paginator.pageSize, value.toString());
        });
  }
    
  ngOnInit()
  {
    this.loadingService.start();
    this.organization = this.authenticationService.getCurrentUserOrganization();
    this.loadUser();     
        
  }

  loadUser() {

    this.userService.getCurrentUser()
        .subscribe(value => this.userLoaded(value),
        error => console.log(error)
        );

  }

  userLoaded(user: any) {
    this.user = this.authenticationService.userData;
    this.getPayoutStatuses();
    this.isAdmin = this.userService.userHasRole("admin");

    if (this.isAdmin) {
      this.displayedColumns = ['file', 'icon', 'customerNameMobileAdmin', 'id', 'invoicenumber', 'created', 'dueDate', 'edit', 'userName', 'customerName', 'status', 'payoutstatus', 'expand'];
    }

    // Default selectted item from storage
    var defaultItem = this.localStorageService.getItem("filterStatusDefault");
    if (defaultItem && this.isAdmin) {
        var item = JSON.parse(defaultItem);
        this.selectedFilterStatus = { Id: item.Id, Name: item.Name };

        this.getInvoices([item.Id], 0, 10, this.filter);

    }
    else {
        this.getInvoices(null, 0, 10, this.filter);
    }
  }

  checkMenuOption(invoice: IInvoice, menuOption: string) {
    
    if (menuOption == "credit") {
      if ((invoice.Status.TechnicalName == 'SentUnpaid' || invoice.Status.TechnicalName == 'SentReminder' || invoice.Status.TechnicalName == 'SentToInkasso' || invoice.Status.TechnicalName == 'SentBailiff') && invoice.PayoutStatus.TechnicalName == 'NotPaidOut') {
        return true;
      }
      else {
        return false;
      }
    }
    
  }
      
  onPaginatorChange(paginator: any)
  {
    this.loadingService.start();
    this.getInvoices(null, paginator.pageIndex, paginator.pageSize, this.filter);
  }
    
  editUser(userId: string) {
    var newWindow = window.open("#/users/" + userId);
  }

  filterStatusSetDefault(selectedItem: any)
  {
    this.localStorageService.setItem("filterStatusDefault", JSON.stringify(selectedItem));
  }

  filterStatusOnChange(status: any)
  {
    this.selectedFilterStatus = { Id: status.Id, Name: status.Name };
    this.getInvoices([this.selectedFilterStatus.Id], this.paginator.pageIndex, this.paginator.pageSize, this.filter);
  }

  showColumn(): string {
    return this.isAdmin ? null : 'hidden-row';
  }

  ngOnDestroy(): void {
      
  }

  showFile(file: IFile) {
    window.open(file.Url);
  }

  getInvoices(statuses: Array<number> = null, pageIndex: number, pageSize: number, filter: string) {

    this.isLoading = true;
        
    // All invoices selected
    if (statuses != null && statuses.length == 1 && statuses[0] == 0)
    {
        statuses = null;
    }
    
    this.invoiceService.getInvoicesByOrganization(this.organization.Id, statuses, pageIndex, pageSize, filter)
        .subscribe(value => this.onInvociesRetrieved(value),
        error => console.log(error)
        );
  }

  statusChange(invoice: any)
  {        
    if (this.isAdmin) {

      var sendMessage = confirm("Vill du uppdatera medlemmen om statusförändringen?");

      this.invoiceService.updateInvoiceStatus(invoice.Id, invoice.Status.Id, 'Fakturan uppdaterad!', sendMessage)
            .subscribe(value => this.onInvoiceUpdated(value, invoice),
                error => console.log(error)
            );
    }
    else {
      alert('Endast administratörer kan ändra fakturastatus')
    }
  }

  //Updates payout status in invoice view
  payoutStatusChange(invoice: any)
  {
    invoice.SendCustomerEmailUpdate = false;
        
    if (this.isAdmin) {

      this.invoiceService.updatePayoutStatus(invoice.Id, invoice.PayoutStatus.Id, 'Fakturan uppdaterad!', false)
          .subscribe(value => this.onInvoiceUpdated(value, invoice),
          error => console.log(error)
          );

    }
    else {
       alert('Endast administratörer kan ändra utbetalningsstatus')
    }
  }
    
  sortData(sort: Sort) {
    const data = this.invoiceData.slice();

    if (!sort.active || sort.direction == '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
            
      let isAsc = sort.direction == 'asc';
      switch (sort.active) {
          case 'id': return this.compare(a.Id, b.Id, isAsc);
          case 'invoicenumber': return this.compare(a.InvoiceNumber, b.InvoiceNumber, isAsc);
          case 'customerName': return this.compare(a.Customer != null ? a.Customer.Name : '', b.Customer.Name != null ? b.Customer.Name : '', isAsc);
          case 'payoutstatus': return this.compare(a.PayoutStatus.Name, b.PayoutStatus.Name, isAsc);
          case 'status': return this.compare(a.Status.Name, b.Status.Name, isAsc);
          case 'created': return this.compare(a.Created, b.Created, isAsc);
          default: return 0;
      }
    });

    this.dataSource.data = this.sortedData; 
  }

  compare(a, b, isAsc) {
    console.log((a < b ? -1 : 1) * (isAsc ? 1 : -1));
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  onInvoiceUpdated(response: any, invoice: any)
  {
    this.onPaginatorChange(this.paginator);
  }

  getInvoiceStatuses() {

    this.invoiceService.getInvoiceStatuses()
        .subscribe(value => this.onInvoiceStatusesRetrieved(value),
        error => console.log(error)
        );
  }

  getPayoutStatuses() {

    this.invoiceService.getPayoutStatuses()
        .subscribe(value => this.onPayoutStatusesRetrieved(value),
        error => console.log(error)
        );
  }

  onPayoutStatusesRetrieved(statuses: any)
  {
    this.payoutStatuses = statuses;
  }

  onInvoiceStatusesRetrieved(statuses: any)
  {
    for (var i = 0; i < statuses.length; i++)
    {
        statuses[i].Id = statuses[i].Id.toString(); 
    }
        
    this.statuses = statuses;
    this.statuses.push({ Id: 0, Name: 'Alla fakturor' });
  }

  editInvoice(invoice: any) {
    if (invoice.ParentInvoice == true) {
      //this.router.navigate(['/combined-invoice-tool', invoice.Id]);
      var newWindow = window.open("#/combined-invoice-tool/" + invoice.Id);
    }
    else {
      //this.router.navigate(['/invoice-tool', invoice.Id]);
      var newWindow = window.open("#/invoice-tool/" + invoice.Id);
    }
  }

  copyInvoice(invoice: any) {

    this.invoiceService.copyInvoice(invoice.Id)
        .subscribe(value => this.onCopyInvoiceFinished(value),
        error => console.log(error)
        );
  }

  onCopyInvoiceFinished(invoice: any)
  {
    if (invoice.ParentInvoice) {
      //this.router.navigate(['/combined-invoice-tool', invoice.Id]);
      var newWindow = window.open("#/combined-invoice-tool/" + invoice.Id);
    }
    else {
      //this.router.navigate(['/invoice-tool', invoice.Id]);
      var newWindow = window.open("#/invoice-tool/" + invoice.Id);
    }
  }


  creditInvoice(invoice: any) {
        
    var result = confirm("Vill du skapa en kreditfaktura på faktura " + invoice.InvoiceNumber + " ?");
    if (result) {

      this.invoiceService.copyInvoiceCredit(invoice.Id)
          .subscribe(value => this.onCopyInvoiceCreditFinished(value),
          error => console.log(error)
          );
    }
  }

  onCopyInvoiceCreditFinished(invoice: any) {
    //this.router.navigate(['/invoice-tool', invoice.Id]);
    var newWindow = window.open("#/invoice-tool/" + invoice.Id);
  }

  showSummary(invoice: any) {
      
    if (invoice.ParentInvoice || invoice.SubInvoices.length > 0) {
      var newWindow = window.open("#/combined-invoice-summary/" + invoice.Id);
    }
    else {
      var newWindow = window.open("#/invoice-summary/" + invoice.Id);
    }

    //this.router.navigate(['/invoice-summary', invoice.Id]);
    }

  deleteInvoice(invoice: any)
  {

    var message = "Vill du verkligen radera " + (invoice.InvoiceNumber != null ? " faktura " + invoice.InvoiceNumber : 'fakturan') + "?";

    var result = confirm(message);
    if (result) {

      this.invoiceService.deleteInvoice(invoice.Id)
          .subscribe(value => this.onInvoiceDeleted(invoice.Id),
          error => console.log(error)
          );
    }
  }

  onInvoiceDeleted(id: any)
  {
    this.reloadData();
  }

  reloadData() {
    if (this.selectedFilterStatus != undefined && this.isAdmin) {
      this.getInvoices([this.selectedFilterStatus.Id], this.paginator.pageIndex, this.paginator.pageSize, this.filter);
    }
    else {
      this.getInvoices(null, this.paginator.pageIndex, this.paginator.pageSize, this.filter);
    }
  }

  updateCustomer(company: ICompany) {

    for (var i = 0; i < this.invoiceData.length; i++) {

      var currentCompany = this.invoiceData[i];
      if (currentCompany.Id == company.Id) {

          this.UpdateCompany(company, currentCompany);
          return;
      }
    }
  }

  UpdateCompany(from: ICompany, to: ICompany) {
    to.Name = from.Name;
    to.Address = from.Address;
    to.Co = from.Co;
    to.Zip = from.Zip;
    to.City = from.City;
    to.Email = from.Email;
    to.OrgNumber = from.OrgNumber;
    to.Reference = from.Reference;
  }

  onInvociesRetrieved(response: any) {

    //for (var i = 0; i < response.Invoices.length; i++)
    //{
    //  if (response.Invoices[i].Status != undefined)
    //      response.Invoices[i].Status.Id = response.Invoices[i].Status.Id.toString();
    //}

    this.invoiceData = response.Invoices;
    this.listLength = response.TotalInvoices;
    this.dataSource = new MatTableDataSource(this.invoiceData);
    this.dataSource.sort = this.sort;
    
    // Set up custom search
    this.dataSource.filterPredicate = function (data, filter: string): boolean {
      return (data.Status != null ? data.Status.Name.toLowerCase().includes(filter) : '') ||
          (data.Id == undefined ? '' : data.Id.toString().toLowerCase().includes(filter)) ||
          (data.InvoiceNumber == undefined ? '' : data.InvoiceNumber.toString().toLowerCase().includes(filter)) ||
          (data.Customer != null ? data.Customer.Name.toLowerCase() : '').includes(filter) || 
          (data.Created.toString().toLowerCase()).includes(filter) || 
          ((data.User == null || data.User.AccountSettings == null) ? '' : data.User.AccountSettings.FirstName.toLowerCase() + ' ' + data.User.AccountSettings.LastName.toLowerCase()).includes(filter);
    };
        
    this.isLoading = false;
    this.loadingService.complete();
        
  }

  openAdminView(invoice: any) {
        
    this.invoiceService.getInvoice(invoice.Id)
        .subscribe(value => this.openAdminPopup(value),
        error => console.log(error)
        );
  }

  openAdminPopup(invoice: any)
  {
    //var config = JSON.parse(JSON.stringify(this.config));
    //config.height = '80%';
    //config.width = '900px';
    //config.data = invoice;

    //let dialogRef = this.dialog.open(InvoiceAdminDialogComponent, config);

    //dialogRef.afterClosed().subscribe((result: any) => {
    //    dialogRef = null;
    //    this.reloadData();
    //});
  }
    
  openWorkCertificateView(invoice: any) {

    this.invoiceService.getInvoice(invoice.Id)
        .subscribe(value => this.openWorkCertificatePopup(value),
        error => console.log(error)
        );

  }
    
  openWorkCertificatePopup(invoice: any) {
    //var config = JSON.parse(JSON.stringify(this.config));
    //config.height = '80%';
    //config.width = '850px';
    //config.data = invoice;

    //let dialogRef = this.dialog.open(InvoiceWorkCertificateDialogComponent, config);

    //dialogRef.afterClosed().subscribe((result: any) => {
    //    dialogRef = null;
    //    this.reloadData();
    //});
  }
    
  config = {
    disableClose: false,
    panelClass: 'custom-overlay-pane-class',
    hasBackdrop: true,
    backdropClass: '',
    width: '500px',
    height: '',
    position: {
        top: '',
        bottom: '',
        left: '',
        right: ''
    },
    data: {

    },
    dataTemplate: {
        "Id": 0,
        "Type": {},
        "HoursWorked": undefined,
        "Amount": undefined,
        "StartDate": undefined,
        "EndDate": undefined,
        "Comment": undefined,
        "InvoiceId": 0
    }
  };

}

export interface Invoice {
    Id: number,
    InvoiceNumber: string
}


export class InvoicesDataSource extends DataSource<any> {
    constructor(private data: Invoice[]) {
        super();
    }
    /** Connect function called by the table to retrieve one stream containing the data to render. */
    connect(): Observable<Invoice[]> {
        return of(this.data);
    }

    disconnect() { }

}
