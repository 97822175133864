import { MetaReducer } from '@ngrx/store';
import { storeFreeze } from 'ngrx-store-freeze';
import { environment } from '@env/environment';
import { debug } from './meta-reducers/debug.reducer';
import { initStateFromLocalStorage } from './meta-reducers/init-state-from-local-storage.reducer';
import { getSwedishPaginatorIntl } from '../core/adapters/swedish-paginator-intl';
export var metaReducers = [initStateFromLocalStorage];
if (!environment.production) {
    metaReducers.unshift(debug, storeFreeze);
}
var ɵ0 = getSwedishPaginatorIntl();
var CoreModule = /** @class */ (function () {
    function CoreModule(parentModule) {
        if (parentModule) {
            throw new Error('CoreModule is already loaded. Import only in AppModule');
        }
    }
    return CoreModule;
}());
export { CoreModule };
export { ɵ0 };
