var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { environment as env } from '@env/environment';
import { ROUTE_ANIMATIONS_ELEMENTS } from '@app/core';
import { MatDialog } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyService } from '../../core/services/CompanyService';
import { InvoiceService } from '../../core/services/InvoiceService';
import { CurrencyService } from '../../core/services/CurrencyService';
import { UserService } from '../../core/services/UserService';
import { EmployerTaxService } from '../../core/services/EmployerTaxService';
import { TransactionLogService } from '../../core/services/TransactionLogService';
import { forkJoin } from 'rxjs';
var CombinedInvoiceSummaryComponent = /** @class */ (function () {
    function CombinedInvoiceSummaryComponent(router, route, dialog, companyService, invoiceService, transactionLogService, currencyService, userService, employerTaxService) {
        this.router = router;
        this.route = route;
        this.dialog = dialog;
        this.companyService = companyService;
        this.invoiceService = invoiceService;
        this.transactionLogService = transactionLogService;
        this.currencyService = currencyService;
        this.userService = userService;
        this.employerTaxService = employerTaxService;
        this.routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS;
        this.versions = env.versions;
        this.companies = [];
        this.currencies = [];
        this.employerTaxes = [];
        //hoursWorkedTotal: number = 0;
        this.totalInvoiceAmount = 0;
        this.ref = { Id: 0, Address: "", City: "", Email: "", Name: "", NameLocked: false, Phone: "", Zip: "", IDNumber: "" };
        this.invoiceQuickGroupTemplate = { Vat: "25", Type: { Value: "4", Name: "Övrigt" }, rowTypeSelected: "4" };
        this.invoiceQuickGroup = JSON.parse(JSON.stringify(this.invoiceQuickGroupTemplate));
        this.vatList = [];
        this.invoiceShareTypes = [];
        this.addToInvoiceList = [{ Name: 'Ja', Value: true }, { Name: 'Nej', Value: false }];
        this.paymentTerms = [10, 15, 20, 25, 30];
        this.isLoading = true;
        this.feePaidByReciever = false;
        this.feePaidBySender = false;
        this.milageTotalAmount = 0;
        this.allowancesTotalAmount = 0;
        this.payoutEmployerTax = null;
        this.PayoutMunicipalityTax = 0;
        this.EmployerTax = 0;
        this.InvoiceFeeReceiver = 0;
        this.InvoiceFeeMember = 0;
        this.InvoiceFeePaidByCustomer = true;
        this.PensionSavingsTaxFactor = 0.2426;
        this.PensionSavingsTaxAmount = 0;
        this.municipalityTaxRounded = 0;
        this.ServiceFeeInfo = "";
        this.numberOfInvoices = 0;
        this.lastPayoutMessage = "";
        this.taxChangedThisMonth = false;
        this.lastPaymentIsNextMonth = false;
        this.config = {
            disableClose: false,
            panelClass: 'custom-overlay-pane-class',
            hasBackdrop: true,
            backdropClass: '',
            width: '500px',
            height: '',
            position: {
                top: '',
                bottom: '',
                left: '',
                right: ''
            },
            data: {
                invoiceItemGroup: {},
                customerTemplate: {},
                customer: {},
                contact: {},
                companyId: 0
            },
            dataTemplate: {
                "Id": 0,
                "Type": {},
                "HoursWorked": undefined,
                "StartDate": undefined,
                "EndDate": undefined,
                "Comment": undefined,
                "InvoiceId": 0
            }
        };
    }
    CombinedInvoiceSummaryComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.params.subscribe(function (params) {
            _this.id = params['id'] ? params['id'] : 0;
            _this.loadInvoice(_this.id);
        });
        this.getCompanies();
        this.loadCurrencies();
        this.loadEmployerTaxes();
        this.vatList.push({ Value: "25" }, { Value: "12" }, { Value: "6" }, { Value: "0" });
        this.userLastPayoutLogMap = new Map();
    };
    CombinedInvoiceSummaryComponent.prototype.changeValue = function (value) {
        this.InvoiceFeePaidByCustomer = value.checked;
        this.invoice.InvoiceFeePaidByCustomer = this.InvoiceFeePaidByCustomer;
    };
    CombinedInvoiceSummaryComponent.prototype.sendMessage = function (message) {
        var _this = this;
        this.invoiceService.sendMessage(message, this.id)
            .subscribe(function (response) { return _this.messageSent(response); }, function (error) { return console.log(error); });
    };
    CombinedInvoiceSummaryComponent.prototype.messageSent = function (message) {
        this.invoice.Message = undefined;
        this.invoice.Messages.push(message);
    };
    CombinedInvoiceSummaryComponent.prototype.currencyChanged = function () {
        for (var i = 0; i < this.currencies.length; i++) {
            var curr = this.currencies[i];
            if (curr.Id == this.invoice.Currency.Id) {
                this.invoice.Currency.Name = curr.Name;
            }
        }
    };
    CombinedInvoiceSummaryComponent.prototype.loadInvoice = function (id) {
        var _this = this;
        this.invoiceService.getInvoiceShareTypes()
            .subscribe(function (response) { return _this.invoiceShareTypes = response; }, function (error) { return console.log(error); });
        this.invoiceService.getCombinedInvoiceSummary(id)
            .subscribe(function (response) { return _this.onGetInvoiceCompleted(response); }, function (error) { return console.log(error); });
    };
    CombinedInvoiceSummaryComponent.prototype.loadCurrencies = function () {
        var _this = this;
        this.currencyService.getCurrencies()
            .subscribe(function (response) { return _this.currencies = response; }, function (error) { return console.log(error); });
    };
    CombinedInvoiceSummaryComponent.prototype.loadEmployerTaxes = function () {
        var _this = this;
        this.employerTaxService.getEmployerTaxes()
            .subscribe(function (response) { return _this.employerTaxes = response; }, function (error) { return console.log(error); });
    };
    CombinedInvoiceSummaryComponent.prototype.onGetInvoiceCompleted = function (invoice) {
        this.invoice = invoice;
        this.setInvoiceFee();
        //this.loadUserEmployerTax();
        for (var i = 0; i < invoice.SubInvoices.length; i++) {
            this.updateSubInvoiceAmounts(invoice.SubInvoices[i]);
        }
        this.updateAmounts();
        this.isLoading = false;
    };
    CombinedInvoiceSummaryComponent.prototype.getInvoiceNumberPart = function (invoiceNumber) {
        if (invoiceNumber && invoiceNumber.includes('-')) {
            return invoiceNumber.split('-')[0];
        }
        return invoiceNumber;
    };
    CombinedInvoiceSummaryComponent.prototype.loadUserEmployerTax = function (subInvoice, userId, checkZeroTax) {
        var _this = this;
        if (checkZeroTax === void 0) { checkZeroTax = false; }
        this.userService.getUserEmployerTax(userId, checkZeroTax)
            .subscribe(function (tax) { return _this.employerTaxLoaded(subInvoice, tax); }, function (error) { return console.log(error); });
    };
    CombinedInvoiceSummaryComponent.prototype.employerTaxLoaded = function (subInvoice, tax) {
        subInvoice.EmployerTaxType = tax;
        subInvoice.UserSettings.EmployerTax.Tax = subInvoice.EmployerTaxType.Tax;
        //var userId = subInvoice.UserSettings.Id;
        //this.loadUserNumberOfInvoices(userId);
    };
    CombinedInvoiceSummaryComponent.prototype.loadUserNumberOfInvoices = function (userId) {
        var _this = this;
        this.userService.getUserNumberOfInvoices(userId)
            .subscribe(function (tax) { return _this.numberOfInvoicesLoaded(_this.numberOfInvoices); }, function (error) { return console.log(error); });
    };
    CombinedInvoiceSummaryComponent.prototype.numberOfInvoicesLoaded = function (numberOfInvoices) {
        this.numberOfInvoices = numberOfInvoices;
    };
    CombinedInvoiceSummaryComponent.prototype.loadUserMunicipalityTax = function (subInvoice, userId, checkZeroTax) {
        var _this = this;
        if (checkZeroTax === void 0) { checkZeroTax = false; }
        var payoutdate = this.invoice.PayoutDate;
        if (payoutdate == null) {
            payoutdate = new Date();
        }
        this.userService.getUserMunicipalityTax(payoutdate, userId, checkZeroTax)
            .subscribe(function (tax) { return _this.municipalityTaxLoaded(subInvoice, tax); }, function (error) { return console.log(error); });
    };
    CombinedInvoiceSummaryComponent.prototype.municipalityTaxLoaded = function (subInvoice, tax) {
        debugger;
        subInvoice.UserSettings.Municipality.Tax = tax;
        //Makes sure municipality tax always is calculated with 2 decimals, even if 3 in db table
        // Use temporary tax if present instead of municipality standard tax
        if (subInvoice.UserSettings.TaxAdjusted >= 0) {
            subInvoice.UserSettings.Municipality.Tax = parseFloat(subInvoice.UserSettings.TaxAdjusted.toFixed(4));
        }
        else {
            subInvoice.UserSettings.Municipality.Tax = parseFloat(subInvoice.UserSettings.Municipality.Tax.toFixed(4));
        }
    };
    CombinedInvoiceSummaryComponent.prototype.loadLastExecutedTransaction = function (subInvoice) {
        var _this = this;
        this.transactionLogService.getLastExecutedTransactionByUserId(subInvoice.UserSettings.Guid)
            .subscribe(function (lastPaymentLog) { return _this.lastPayoutLoaded(subInvoice.UserSettings.Guid, lastPaymentLog, subInvoice); }, function (error) { return console.log(error); });
    };
    CombinedInvoiceSummaryComponent.prototype.lastPayoutLoaded = function (userId, lastPayoutLog, subInvoice) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.userLastPayoutLogMap.set(userId, lastPayoutLog);
                        return [4 /*yield*/, this.checkLastPayoutDateAndTaxes(subInvoice, lastPayoutLog)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    CombinedInvoiceSummaryComponent.prototype.checkIfYearOrMonthIsDifferent = function (lastPayoutLog) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) {
                        var lastPaymentMonth = new Date(lastPayoutLog.PayoutDate);
                        var thisMonth = new Date();
                        var lastPaymentYear = lastPaymentMonth.getFullYear();
                        var lastPaymentMonthIndex = lastPaymentMonth.getMonth();
                        var thisYear = thisMonth.getFullYear();
                        var thisMonthIndex = thisMonth.getMonth();
                        if (lastPaymentYear == thisYear && lastPaymentMonthIndex == thisMonthIndex) {
                            _this.taxChangedThisMonth = true;
                        }
                        if ((lastPaymentYear == thisYear && lastPaymentMonthIndex > thisMonthIndex) || lastPaymentYear > thisYear) {
                            _this.lastPaymentIsNextMonth = true;
                        }
                        //console.log("2");
                        resolve();
                    })];
            });
        });
    };
    CombinedInvoiceSummaryComponent.prototype.checkLastPayoutDateAndTaxes = function (subInvoice, lastPayoutLog) {
        return __awaiter(this, void 0, void 0, function () {
            var empMessage, munMessage, monthInfo, lastEmployerTax, lastMunicipalityTax, payoutDate, taxChanged;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        empMessage = "";
                        munMessage = "";
                        monthInfo = "";
                        lastEmployerTax = lastPayoutLog.EmployerTaxType.Tax;
                        lastMunicipalityTax = lastPayoutLog.MunicipalityTax;
                        payoutDate = new Date(lastPayoutLog.PayoutDate + 'Z').toISOString().split('T')[0];
                        taxChanged = false;
                        if (!(lastPayoutLog.InvoiceId == subInvoice.Id)) return [3 /*break*/, 1];
                        this.lastPayoutMessage = "Denna faktura är redan utbetald och är medlemmens senaste";
                        monthInfo = "";
                        return [3 /*break*/, 3];
                    case 1:
                        if (lastEmployerTax != subInvoice.UserSettings.EmployerTax.Tax) {
                            empMessage = "AGA skiljer sig från senaste utbet: " + (lastEmployerTax * 100).toFixed(2) + " %.";
                            taxChanged = true;
                        }
                        if (lastMunicipalityTax != subInvoice.UserSettings.Municipality.Tax) {
                            munMessage = "Kommunskatten skiljer sig mot senaste utbet: " + (lastMunicipalityTax * 100).toFixed(2) + " %.";
                            taxChanged = true;
                        }
                        // Check if last payment was current month or before. Only warn if same month.
                        return [4 /*yield*/, this.checkIfYearOrMonthIsDifferent(lastPayoutLog)];
                    case 2:
                        // Check if last payment was current month or before. Only warn if same month.
                        _a.sent();
                        // If tax changed, show if it is same month or not
                        if (taxChanged) {
                            if (this.taxChangedThisMonth) {
                                monthInfo = "<br>Senaste utbet: " + payoutDate + ". OBS! Innevarande månad.";
                            }
                            else {
                                monthInfo = "<br>Senaste utbet: " + payoutDate + ". Ej innevarande månad.";
                            }
                        }
                        if (this.lastPaymentIsNextMonth) {
                            monthInfo = "Senaste utbetalningen är schemalagd till en senare månad: " + payoutDate;
                            this.taxChangedThisMonth = true;
                        }
                        this.lastPayoutMessage = munMessage + " " + empMessage + monthInfo;
                        _a.label = 3;
                    case 3:
                        console.log("3");
                        return [2 /*return*/];
                }
            });
        });
    };
    CombinedInvoiceSummaryComponent.prototype.checkZeroTaxAndLoadTaxes = function (subInvoice) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) {
                        var userId = subInvoice.UserSettings.Id;
                        // Check if zeroTaxChecked is not a property, initialize it
                        if (typeof subInvoice.zeroTaxChecked === 'undefined') {
                            subInvoice.zeroTaxChecked = false;
                        }
                        if (subInvoice.zeroTaxChecked == false) {
                            if (subInvoice.PartialTotalAmount < 1000) {
                                _this.loadUserEmployerTax(subInvoice, userId, true);
                                _this.loadUserMunicipalityTax(subInvoice, userId, true);
                            }
                            else {
                                _this.loadUserEmployerTax(subInvoice, userId, false);
                                _this.loadUserMunicipalityTax(subInvoice, userId, false);
                            }
                            subInvoice.zeroTaxChecked = true;
                        }
                        console.log("1");
                        resolve();
                    })];
            });
        });
    };
    CombinedInvoiceSummaryComponent.prototype.updateAmounts = function () {
        this.invoice.BruttoLon = 0;
        this.invoice.NettoLon = 0;
        this.payoutEmployerTax = 0;
        //Add all fees 
        this.invoice.FeesTotalAmount = this.InvoiceFeeMember + this.InvoiceFeeReceiver + this.invoice.ExchangeFee + this.invoice.KronofogdenFee + this.invoice.ReminderFee + Math.round(this.invoice.ServiceFee) + this.invoice.InkassoFee + this.invoice.CustomFee;
        //Partial total amount from which payout is calculated
        this.PartialTotalAmount = Math.round(this.invoice.PaidTotal - this.invoice.TotalVatAmount - this.invoice.FeesTotalAmount + this.invoice.ManualDiscount);
        //Total amount of expenses
        this.invoice.ExpensesTotalAmount = Math.round(this.DeductionsTotalAmount + this.milageTotalAmount + this.allowancesTotalAmount);
        //Amount from which employer tax is deducted
        this.PartialAmountExclNetExpenses = Math.round(this.PartialTotalAmount - this.invoice.ExpensesTotalAmount);
        if (this.PartialAmountExclNetExpenses < 0) {
            this.PartialAmountExclNetExpenses = 0;
        }
        this.TotalInvoiceAmount = this.invoice.TotalInvoiceAmount;
        //Calculates the total payout amount
        this.invoice.PayOutTotal = Math.round(this.invoice.NettoLon + this.invoice.ExpensesTotalAmount);
        //If net expenses are exceeding the partial total amount, only the partial total amount can be paid
        if (this.invoice.ExpensesTotalAmount > this.PartialTotalAmount) {
            this.invoice.PayOutTotal = this.PartialTotalAmount;
        }
    };
    CombinedInvoiceSummaryComponent.prototype.checkCustomServiceFee = function (subInvoice) {
        //Check if salary reciever has custom service fee, only if invoice is not paid out
        if (subInvoice.UserSettings.HasCustomServiceFee == true && subInvoice.PayoutDate == null) {
            //If custom service fee, reset total service fee
            subInvoice.FeesTotalAmount = +subInvoice.ServiceFee;
            //Calculate new service fee
            subInvoice.ServiceFee = Math.round(subInvoice.PaidTotal * subInvoice.UserSettings.CustomServiceFee);
            var customServicePercent = this.invoice.UserSettings.CustomServiceFee * 100;
            this.ServiceFeeInfo = "Avtalad serviceavgift: " + customServicePercent + " %";
        }
    };
    CombinedInvoiceSummaryComponent.prototype.setAmountsForCommissionCashInvoice = function (subInvoice) {
        subInvoice.PartialTotalAmount = Math.round(subInvoice.PaidTotal - subInvoice.TotalVatAmount);
        subInvoice.ExpensesTotalAmount = 0;
        subInvoice.PartialAmountExclNetExpenses = subInvoice.PartialTotalAmount;
        subInvoice.TotalPayout = subInvoice.PartialTotalAmount;
    };
    CombinedInvoiceSummaryComponent.prototype.calculatePartialTotalAmount = function (subInvoice) {
        subInvoice.PartialTotalAmount = Math.round(subInvoice.PaidTotal - subInvoice.TotalVatAmount - subInvoice.FeesTotalAmount + subInvoice.ManualDiscount);
    };
    CombinedInvoiceSummaryComponent.prototype.calculatePensionSavingsAmount = function (subInvoice) {
        //Total pension savings amount
        subInvoice.PensionSavingsTaxAmount = Math.round(subInvoice.PensionSavingsAmount * this.PensionSavingsTaxFactor);
        subInvoice.PensionSavingsInclTax = Math.round(subInvoice.PensionSavingsAmount + subInvoice.PensionSavingsTaxAmount);
    };
    CombinedInvoiceSummaryComponent.prototype.calculateAllowancesTotalAmount = function (subInvoice) {
        var allowancesTotalAmount = 0;
        //Calculate allowances total amount
        for (var i = 0; i < subInvoice.Allowances.length; i++) {
            //Calculates total mileage amount
            if (subInvoice.Allowances[i].AllowanceType.Id == '1') {
                this.milageTotalAmount += Math.round(subInvoice.Allowances[i].Sum);
            }
            //Calculates total daily travel allowance amount
            else {
                allowancesTotalAmount += Math.round(subInvoice.Allowances[i].Sum);
                subInvoice.AllowancesTotalAmount = allowancesTotalAmount;
            }
        }
    };
    CombinedInvoiceSummaryComponent.prototype.calculateExpensesTotalAmount = function (subInvoice) {
        subInvoice.ExpensesTotalAmount = Math.round(subInvoice.DeductionsTotalAmount + subInvoice.MileageTotalAmount + subInvoice.AllowancesTotalAmount);
    };
    CombinedInvoiceSummaryComponent.prototype.calculatePartialAmountExclNetExpenses = function (subInvoice) {
        //Amount from which employer tax is deducted
        subInvoice.PartialAmountExclNetExpenses = Math.round(subInvoice.PartialTotalAmount - subInvoice.ExpensesTotalAmount - subInvoice.PensionSavingsInclTax);
        if (subInvoice.PartialAmountExclNetExpenses < 0) {
            subInvoice.PartialAmountExclNetExpenses = 0;
        }
    };
    CombinedInvoiceSummaryComponent.prototype.checkHealthCareDeductionAmount = function (subInvoice) {
        if (subInvoice.TotalHealthCareDeductionAmountThisYear + subInvoice.TotalHealthCareDeductionAmountThisInvoice > 5000) {
            subInvoice.HealthCareAmountExceedesLimit = true;
        }
    };
    CombinedInvoiceSummaryComponent.prototype.calculateGrossSalary = function (subInvoice) {
        subInvoice.UserSettings.EmployerTax.Tax = subInvoice.EmployerTaxType.Tax;
        subInvoice.BruttoLon = Math.round(subInvoice.PartialAmountExclNetExpenses / (1 + subInvoice.UserSettings.EmployerTax.Tax));
    };
    CombinedInvoiceSummaryComponent.prototype.calculateEmployerTaxAmount = function (subInvoice) {
        //Calculates employer tax amount
        subInvoice.ArbetsgivarAvgift = Math.round(subInvoice.PartialAmountExclNetExpenses - subInvoice.BruttoLon);
    };
    CombinedInvoiceSummaryComponent.prototype.calculateMunicipalityTaxAmount = function (subInvoice) {
        //How much municipality tax is paid in SEK
        subInvoice.PayoutMunicipalityTax = Math.round(subInvoice.BruttoLon * subInvoice.UserSettings.Municipality.Tax);
    };
    CombinedInvoiceSummaryComponent.prototype.calculateNetSalary = function (subInvoice) {
        //Calculates net salary
        subInvoice.NettoLon = Math.round(subInvoice.BruttoLon - subInvoice.PayoutMunicipalityTax);
    };
    CombinedInvoiceSummaryComponent.prototype.calculatePayout = function (subInvoice) {
        //Rounds payout up to closes int
        subInvoice.TotalPayout = Math.round(subInvoice.PayOutTotal);
        //Calculates the total payout amount
        subInvoice.PayOutTotal = Math.round(subInvoice.NettoLon + subInvoice.ExpensesTotalAmount);
        //If net expenses are exceeding the partial total amount, only the partial total amount can be paid
        if (subInvoice.ExpensesTotalAmount > subInvoice.PartialTotalAmount) {
            subInvoice.PayOutTotal = subInvoice.PartialTotalAmount;
        }
    };
    CombinedInvoiceSummaryComponent.prototype.calculateAttachmentOfSalaryPayment = function (subInvoice, attachment) {
        //if (subInvoice.AttachmentOfSalary.AttachmentAmount > 0) {
        //  this.calculateAttachmentOfSalaryPayment(subInvoice, subInvoice.AttachmentOfSalary)
        //}
    };
    CombinedInvoiceSummaryComponent.prototype.calculateCommissionAmount = function (subInvoice) {
        //PartialAmountExclNetExpenses x invoice?.Commission = invoice?.Commission * PartialAmountExclNetExpenses
        subInvoice.CommissionAmount = subInvoice.PartialAmountExclNetExpenses * this.invoice.Commission;
    };
    // Refaktorisera till mindre metoder med enkla namn som beskriver funktionen
    // Ta bort updateSubInvoiceAmounts helt ur koden, anropa metoderna i lösningen när data laddas
    CombinedInvoiceSummaryComponent.prototype.checkPreviousPensionSavings = function (subInvoice) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                //Check if there is a previous pension savings payment on user
                this.transactionLogService.HasUserPensionSavings(subInvoice.UserSettings.Id)
                    .subscribe(function (previousPensionSaving) { return _this.onCheckPreviousPensionSavingsFinished(previousPensionSaving, subInvoice); }, function (error) { return _this.onGetCompaniesError(error); });
                return [2 /*return*/];
            });
        });
    };
    CombinedInvoiceSummaryComponent.prototype.onCheckPreviousPensionSavingsFinished = function (previousPensionSaving, subInvoice) {
        var pensionSavingsMessage = "";
        if (previousPensionSaving != null && previousPensionSaving.Id > 0) {
            subInvoice.HasPreviousPensionSaving = true;
            var pensionSavingsCompany = "";
            if (previousPensionSaving.PensionSavingsCompany != null) {
                pensionSavingsCompany = previousPensionSaving.PensionSavingsCompany.Name;
            }
            pensionSavingsMessage = "Medlem har fått pension tidigare. " + pensionSavingsCompany;
        }
        else if (previousPensionSaving == null) {
            subInvoice.HasPreviousPensionSaving = false;
        }
    };
    CombinedInvoiceSummaryComponent.prototype.updateSubInvoiceAmounts = function (subInvoice) {
        var _this = this;
        this.checkHealthCareDeductionAmount(subInvoice);
        subInvoice.ZeroTaxChecked = false;
        //var municipalityTaxRounded = 0;
        var checkZeroTax = false;
        if (subInvoice.ServiceFeeShare == null || (subInvoice.ServiceFeeShare != null && subInvoice.ServiceFeeShare.Percentage == 0)) {
            this.checkCustomServiceFee(subInvoice);
        }
        else if (subInvoice.ServiceFeeShare != null && subInvoice.ServiceFeeShare.Percentage > 0) {
            var sharedServicePercent = subInvoice.ServiceFeeShare.Percentage;
            var parentServicePercent = subInvoice.ServiceFeeShare.InvoiceSenderServiceFeeFactor * 100;
            if (parentServicePercent != 0 && sharedServicePercent != 100) {
                this.ServiceFeeInfo = ", andel: " + sharedServicePercent + " % av moderfakturans ordinarie serviceavgift: " + parentServicePercent + " %";
            }
        }
        else {
            this.ServiceFeeInfo = "";
        }
        //Partial total amount from which payout is calculated
        //For commission payouts
        if (subInvoice.Type != null && subInvoice.Type.TechnicalName == "CommissionCashInvoice") {
            this.setAmountsForCommissionCashInvoice(subInvoice);
        }
        // For regular invoices
        else {
            this.calculatePartialTotalAmount(subInvoice);
            if (subInvoice.PartialTotalAmount < 1000) {
                checkZeroTax = true;
            }
            //this.loadUserEmployerTax(subInvoice, subInvoice.UserSettings.Id, checkZeroTax);
            //this.loadUserMunicipalityTax(subInvoice, subInvoice.UserSettings.Id, checkZeroTax);
            var userEmployerTax$ = this.userService.getUserEmployerTax(subInvoice.UserSettings.Id, checkZeroTax);
            var userMunicipalityTax$ = this.userService.getUserMunicipalityTax(subInvoice.PayoutDate, subInvoice.UserSettings.Id, checkZeroTax);
            forkJoin([userEmployerTax$, userMunicipalityTax$]).subscribe(function (_a) {
                var employerTax = _a[0], municipalityTax = _a[1];
                // Both observables have completed, so we can proceed with the calculations
                subInvoice.EmployerTaxType = employerTax;
                subInvoice.UserSettings.EmployerTax.Tax = employerTax;
                subInvoice.UserSettings.Municipality.Tax = municipalityTax;
                debugger;
                // Check if TaxAdjustments exists and has at least one item
                if (subInvoice.UserSettings.TaxAdjustments && subInvoice.UserSettings.TaxAdjustments.length > 0 && subInvoice.UserSettings.TaxAdjustments[0].UnhandledByAdmin) {
                    subInvoice.HasUnhandledTaxAdjustments = true;
                }
                // Call the remaining methods
                _this.calculatePensionSavingsAmount(subInvoice);
                _this.calculateAllowancesTotalAmount(subInvoice);
                _this.calculateAllowancesTotalAmount(subInvoice);
                _this.calculateExpensesTotalAmount(subInvoice);
                _this.calculatePartialAmountExclNetExpenses(subInvoice);
                _this.calculateGrossSalary(subInvoice);
                _this.calculateEmployerTaxAmount(subInvoice);
                _this.calculateMunicipalityTaxAmount(subInvoice);
                _this.calculateNetSalary(subInvoice);
                _this.checkPreviousPensionSavings(subInvoice);
                _this.loadLastExecutedTransaction(subInvoice);
                //this.calculateAttachmentOfSalaryPayment(subInvoice);
                _this.calculatePayout(subInvoice);
            }, function (error) { return console.log(error); });
        }
        return subInvoice;
    };
    CombinedInvoiceSummaryComponent.prototype.calculatePaidTotal = function (subInvoice) {
        this.updateSubInvoiceAmounts(subInvoice);
        return subInvoice.PaidTotal;
    };
    CombinedInvoiceSummaryComponent.prototype.setInvoiceFee = function () {
        if (this.invoice.FeePaidByReciever) {
            this.InvoiceFeeReceiver = this.invoice.InvoiceFee;
            this.InvoiceFeeMember = 0;
        }
        else {
            this.InvoiceFeeMember = this.invoice.InvoiceFee;
            this.InvoiceFeeReceiver = 0;
        }
    };
    CombinedInvoiceSummaryComponent.prototype.save = function (invoice) {
        var _this = this;
        this.isLoading = true;
        this.invoiceService.updateInvoiceSummary(invoice)
            .subscribe(function (response) { return _this.onSaved(response); }, function (error) { return console.log(error); });
    };
    CombinedInvoiceSummaryComponent.prototype.onSaved = function (response) {
        var _this = this;
        //Reload the view when page is saved
        this.route.params.subscribe(function (params) {
            _this.id = params['id'] ? params['id'] : 0;
            _this.loadInvoice(_this.id);
        });
    };
    CombinedInvoiceSummaryComponent.prototype.updatePayoutDate = function (id, date) {
        var _this = this;
        //this.isLoading = true;
        this.invoiceService.updatePayoutDate(id, date)
            .subscribe(function (response) { return _this.onPayoutDateSaved(response); }, function (error) { return console.log(error); });
    };
    CombinedInvoiceSummaryComponent.prototype.onPayoutDateSaved = function (response) {
        //this.isLoading = false;
    };
    CombinedInvoiceSummaryComponent.prototype.getCompanies = function () {
        var _this = this;
        this.companyService.getMyCompanies()
            .subscribe(function (value) { return _this.onGetCompaniesFinished(value); }, function (error) { return _this.onGetCompaniesError(error); });
    };
    CombinedInvoiceSummaryComponent.prototype.onGetCompaniesError = function (error) {
    };
    CombinedInvoiceSummaryComponent.prototype.onGetCompaniesFinished = function (companies) {
        this.companies = companies;
    };
    CombinedInvoiceSummaryComponent.prototype.getCustomer = function (id) {
        for (var i = 0; i < this.companies.length; i++) {
            if (this.companies[i].Id == this.invoice.Customer.Id) {
                //console.log(this.companies[i]);
                return JSON.parse(JSON.stringify(this.companies[i]));
            }
        }
    };
    CombinedInvoiceSummaryComponent.prototype.openLink = function (link) {
        window.open(link, '_blank');
    };
    CombinedInvoiceSummaryComponent.prototype.employerTaxUpdated = function (taxId) {
        for (var i = 0; i < this.employerTaxes.length; i++) {
            if (this.employerTaxes[i].Id == taxId) {
                this.invoice.EmployerTaxType.Tax = this.employerTaxes[i].Tax;
            }
        }
        this.updateAmounts();
    };
    CombinedInvoiceSummaryComponent.prototype.toFormattedDate = function (iso) {
        var date = new Date(iso);
        var d = date.getFullYear() + "-" + ((date.getMonth() + 1).toString().length == 1 ? ('0' + (date.getMonth() + 1)) : date.getMonth() + 1) + "-" + (date.getDate().toString().length == 1 ? ("0" + date.getDate().toString()) : date.getDate().toString());
        if (d == '1970-01-01')
            return undefined;
        return d;
    };
    return CombinedInvoiceSummaryComponent;
}());
export { CombinedInvoiceSummaryComponent };
