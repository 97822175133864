
<style>

  .main-heading
  {
    margin-left: 10px;
    margin-bottom: 20px;
  }

  .sp-form-field
  {
    margin-bottom: 30px;
  }
  .sp-form-field-label {
    margin-bottom: 15px;
    font-weight: 600;
  }
  
  .right {
    float: right;
  }
  .description
  {
    margin-bottom: 20px;
  }
  .warning
  {
    color: red;
  }

  mat-form-field
  {
    width: 100%;
  }
</style>


<!--<h1 mat-dialog-title *ngIf="!isNewCustomer" style="margin-top: 5px;margin-bottom: 35px;">Redigera kund</h1>-->

  <form name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
  <div class="">
    <div class="row">
      <div class="col-md-12">
        <div class="mat-headline">Reseersättning</div>

        <div class="row">
          <div class="col-12">
            <mat-form-field>
              <mat-select required [(ngModel)]="allowance.AllowanceType.Id" (ngModelChange)="allowance.AllowanceType.Id = $event;formUpdated()" name="allowancetypeasd" id="allowancetypeasd" placeholder="Typ av ersättning">
                <mat-option *ngFor="let item of allowanceTypes" value="{{item.Id}}">{{item.Name}}</mat-option>
              </mat-select>
            </mat-form-field>

          </div>
        </div>

        <div class="row" *ngIf="allowance.AllowanceType.Id == '1'">
          <div class="col-12" style="margin-bottom: 20px;">
            Observera att du enbart kan få milersättning för resor som är arbetsrelaterade. Resor till och från arbetet är inte ersättningsgilla.
          </div>
          <div class="col-12">
            <mat-form-field>
              <input type="text" matInput [ngModel]="allowance.TravelStart" (ngModelChange)="allowance.TravelStart = $event" required md-maxlength="50" placeholder="Resan börjande i (gatuadress/ort)" id="travelstart" name="travelstart" />
            </mat-form-field>
          </div>

          <div class="col-12">
            <mat-form-field>
              <input type="text" matInput [ngModel]="allowance.TravelEnd" (ngModelChange)="allowance.TravelEnd = $event" required md-maxlength="50" placeholder="Resan slutade i (gatuadress/ort)" id="travelend" name="travelend" />
            </mat-form-field>
          </div>

          <div class="col-12">
            <mat-form-field>
              <input required matInput [matDatepicker]="pickerDate" [ngModel]="allowance.TravelDateStart" (dateChange)="allowance.TravelDateStart = toFormattedDate($event.value)" name="TravelDate" placeholder="Datum">
              <mat-datepicker-toggle matSuffix [for]="pickerDate"></mat-datepicker-toggle>
              <mat-datepicker #pickerDate></mat-datepicker>
            </mat-form-field>
          </div>

          <!-- Only if added to invoice -->
          <!--<div *ngIf="allowance.AddedToInvoice">
            <div class="col-12">
              <div>Jag har kommit överens med min kund om en högre milersättning än den skattebefriad. Jag kommer få {{allowance.ReimbursementPerKm}}/km skattefritt och resten betalas ut som lön.</div>
              <mat-checkbox matInput [ngModel]="AddCustomReimbursementPerKm" [checked]="AddCustomReimbursementPerKm" (change)="changeValue($event)" name="AddCustomReimbursementPerKm">Ja</mat-checkbox>
            </div>

            <div *ngIf="AddCustomReimbursementPerKm" class="col-12">
              <mat-form-field>
                <input type="number" matInput [ngModel]="allowance.CustomReimbursementPerKm" (ngModelChange)="allowance.CustomReimbursementPerKm = $event" required md-maxlength="50" placeholder="Överenskommen ersättning per km (kr)" id="CustomReimbursementPerKm" name="CustomReimbursementPerKm" />
              </mat-form-field>
            </div>
          </div>-->
          <!--  -->

          <div class="col-6">
            <mat-form-field>
              <input type="number" matInput [disabled]="true" [ngModel]="allowance.ReimbursementPerKm" (ngModelChange)="allowance.ReimbursementPerKm = $event" required md-maxlength="50" placeholder="Skattefri ersättning per km (kr)" id="ReimbursementPerKm" name="ReimbursementPerKm" />
            </mat-form-field>
          </div>

          <div class="col-6">
            <mat-form-field>
              <input type="number" matInput [ngModel]="allowance.Mileage" (ngModelChange)="allowance.Mileage = $event;formUpdated()" required md-maxlength="15" placeholder="Sträcka (km)" id="mileage" name="mileage" />
            </mat-form-field>
          </div>

          <div class="col-6">
            <mat-form-field>
              <input type="text" matInput [ngModel]="allowance.CarRegistrationNumber" (ngModelChange)="allowance.CarRegistrationNumber = $event" required md-maxlength="10" placeholder="Registeringsnr" id="CarRegistrationNumber" name="CarRegistrationNumber" />
            </mat-form-field>
          </div>

          <div class="col-6">
            <mat-form-field>
              <input type="number" matInput [ngModel]="allowance.TotalCarMileage" (ngModelChange)="allowance.TotalCarMileage = $event" required md-maxlength="15" placeholder="Mätarställning totalt (km)" id="TotalCarMileage" name="TotalCarMileage" />
            </mat-form-field>
          </div>

          <div class="col-12">
            <mat-form-field>
              <input type="text" matInput [ngModel]="allowance.ReasonForTravel" (ngModelChange)="allowance.ReasonForTravel = $event" required md-maxlength="500" rows="3" placeholder="Anledning till resa" id="reason" name="reason" md-select-on-focus />
            </mat-form-field>
          </div>

          <div *ngIf="ReimbursementPerKm == 0" class="col-12">
            <span class="mat-subheading-2">Total reseersättning</span>
            <span class="right">{{sum | number : '1.2-2'}} SEK</span>
          </div>
          <div *ngIf="ReimbursementPerKm > 0" class="col-12">
            <span class="mat-subheading-2">Skattefri reseersättning</span>
            <span class="right">{{sum | number : '1.2-2'}} SEK</span>
            <br />
            <span class="mat-subheading-2">Skattefri reseersättning</span>
            <span class="right">{{sum | number : '1.2-2'}} SEK</span>
          </div>

        </div>

        <div class="row" *ngIf="allowance.AllowanceType.Id == '2' || allowance.AllowanceType.Id == '3'">

          <div class="col-12" *ngIf="allowance.AllowanceType.Id == 3">

            <mat-form-field>
              <mat-select required [(ngModel)]="allowance.CountryId" (ngModelChange)="allowance.CountryId = $event;formUpdated()" name="allowancecountry" placeholder="Land">
                <mat-option *ngFor="let item of countries" value="{{item.Name}}">{{item.Name}}</mat-option>
              </mat-select>
            </mat-form-field>

          </div>

          <div class="col-12">
            <mat-form-field>
              <input type="text" matInput [ngModel]="allowance.Destination" (ngModelChange)="allowance.Destination = $event;formUpdated()" required md-maxlength="50" placeholder="Destination" id="Destination" name="Destination" />
            </mat-form-field>
          </div>

          <div class="col-12">
            <mat-form-field>
              <input type="text" matInput [ngModel]="allowance.ReasonForTravel" (ngModelChange)="allowance.ReasonForTravel = $event" required md-maxlength="100" placeholder="Anledning till resa" id="travelreason" name="travelreason" />
            </mat-form-field>
          </div>

          <div class="row col-12">
            
            <div class="col-6">
              <mat-form-field>
                <input required matInput [matDatepicker]="pickerDateStart2" [ngModel]="allowance.TravelDateStart" (dateChange)="allowance.TravelDateStart = toFormattedDate($event.value);formUpdated()" placeholder="Startdatum" name="pickerDateStart2">
                <mat-datepicker-toggle matSuffix [for]="pickerDateStart2"></mat-datepicker-toggle>
                <mat-datepicker #pickerDateStart2></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-3">
              <mat-form-field>
                <mat-select required [(ngModel)]="allowance.TravelDateStartHours" (ngModelChange)="allowance.TravelDateStartHours = $event;formUpdated()" name="allowance.TravelDateStartHours" placeholder="h">
                  <mat-option *ngFor="let item of hours" [value]="item.Id">{{item.Name}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-3">
              <mat-form-field>
                <mat-select required [(ngModel)]="allowance.TravelDateStartMinutes" (ngModelChange)="allowance.TravelDateStartMinutes = $event;formUpdated()" name="allowance.TravelDateStartMinutes" placeholder="m">
                  <mat-option *ngFor="let item of minutes" [value]="item.Id">{{item.Name}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

          </div>

          <div class="row col-12">

            <div class="col-6">
              <mat-form-field>
                <input required matInput [matDatepicker]="pickerDateEnd2" [ngModel]="allowance.TravelDateEnd" (dateChange)="allowance.TravelDateEnd = toFormattedDate($event.value);formUpdated()" name="allowance.TravelDateEnd" placeholder="Slutdatum">
                <mat-datepicker-toggle matSuffix [for]="pickerDateEnd2"></mat-datepicker-toggle>
                <mat-datepicker #pickerDateEnd2></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-3">
              <mat-form-field>
                <mat-select required [(ngModel)]="allowance.TravelDateEndHours" (ngModelChange)="allowance.TravelDateEndHours = $event;formUpdated()" name="TravelDateEndHours" placeholder="h">
                  <mat-option *ngFor="let item of hours" [value]="item.Id">{{item.Name}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-3">
              <mat-form-field>
                <mat-select required [(ngModel)]="allowance.TravelDateEndMinutes" (ngModelChange)="allowance.TravelDateEndMinutes = $event;formUpdated()" name="TravelDateMinutes" placeholder="m">
                  <mat-option *ngFor="let item of minutes" [value]="item.Id">{{item.Name}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

          </div>

          <div class="col-12">
            <mat-form-field>
              <input type="text" matInput [disabled]="true" [(ngModel)]="allowance.AllowancePerDay" placeholder="Belopp per dag SEK" id="AllowancePerDay" name="AllowancePerDay" />
            </mat-form-field>
          </div>

          <div class="col-12">
            <p style="margin: 20px 0 0 0">Antal måltider jag blivit bjuden på under vistelsen:</p>            
          </div>

          <div class="col-4">
            <mat-form-field>
              <input type="number" min="0" oninput="this.value = Math.abs(this.value)" matInput [ngModel]="allowance.Brekafasts" (ngModelChange)="allowance.Brekafasts = $event;formUpdated()" placeholder="Antal frukostar" id="Breakfasts" name="Breakfasts" />
            </mat-form-field>
          </div>

          <div class="col-4">
            <mat-form-field>
              <input type="number" min="0" oninput="this.value = Math.abs(this.value)" matInput [ngModel]="allowance.Lunches" (ngModelChange)="allowance.Lunches = $event;formUpdated()" placeholder="Antal luncher" id="Lunches" name="Lunches" />
            </mat-form-field>
          </div>

          <div class="col-4">
            <mat-form-field>
              <input type="number" min="0" oninput="this.value = Math.abs(this.value)" matInput [ngModel]="allowance.Dinners" (ngModelChange)="allowance.Dinners = $event;formUpdated()" placeholder="Antal middagar" id="Dinners" name="Dinners" />
            </mat-form-field>
          </div>

          <div class="col-12" style="margin-top:20px;">
            <span class="mat-subheading-2">Traktamente</span>
            <span class="right">{{sumAllowance  | number : '1.2-2'}} SEK</span>
          </div>

          <div class="col-12" style="margin-top:20px;">
            <span class="mat-subheading-2">Måltider</span>
            <span class="right">- {{sumMeals  | number : '1.2-2'}} SEK</span>
          </div>

          <div class="col-12">
            <hr />
          </div>

          <div class="col-12">
            <span class="mat-subheading-2">Resultat</span>
            <span *ngIf="sumMeals < sumAllowance" class="right">{{sum | number : '1.2-2'}} SEK</span>
          </div>

          <div *ngIf="sumMeals >= sumAllowance && sumAllowance > 0" class="col-12">
            <span class="mat-subheading-2" style="color: red;">Värdet av måltiderna överstiger traktamentet och inget traktamente kommer därför att utbetalas.</span>
          </div>

        </div>

        <div class="col-sm-12 sp-form-field" style="margin-top: 40px;">
          <button mat-raised-button [disabled]="!f.form.valid || sum <= 0" color="accent" class="right">Spara</button>&nbsp;
          <a mat-stroked-button (click)="cancel()" color="secondary" class="right" style="margin-right:10px;">Avbryt</a>&nbsp;
        </div>

      </div>
    </div>
  </div>

</form>
