var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var SETTINGS_KEY = 'SETTINGS';
export var SettingsActionTypes;
(function (SettingsActionTypes) {
    SettingsActionTypes["CHANGE_THEME"] = "[Settings] Change Theme";
    SettingsActionTypes["CHANGE_AUTO_NIGHT_AUTO_MODE"] = "[Settings] Change Auto Night Mode";
    SettingsActionTypes["CHANGE_ANIMATIONS_PAGE"] = "[Settings] Change Animations Page";
    SettingsActionTypes["CHANGE_ANIMATIONS_PAGE_DISABLED"] = "[Settings] Change Animations Page Disabled";
    SettingsActionTypes["CHANGE_ANIMATIONS_ELEMENTS"] = "[Settings] Change Animations Elements";
    SettingsActionTypes["PERSIST"] = "[Settings] Persist";
})(SettingsActionTypes || (SettingsActionTypes = {}));
var ActionSettingsChangeTheme = /** @class */ (function () {
    function ActionSettingsChangeTheme(payload) {
        this.payload = payload;
        this.type = SettingsActionTypes.CHANGE_THEME;
    }
    return ActionSettingsChangeTheme;
}());
export { ActionSettingsChangeTheme };
var ActionSettingsChangeAutoNightMode = /** @class */ (function () {
    function ActionSettingsChangeAutoNightMode(payload) {
        this.payload = payload;
        this.type = SettingsActionTypes.CHANGE_AUTO_NIGHT_AUTO_MODE;
    }
    return ActionSettingsChangeAutoNightMode;
}());
export { ActionSettingsChangeAutoNightMode };
var ActionSettingsChangeAnimationsPage = /** @class */ (function () {
    function ActionSettingsChangeAnimationsPage(payload) {
        this.payload = payload;
        this.type = SettingsActionTypes.CHANGE_ANIMATIONS_PAGE;
    }
    return ActionSettingsChangeAnimationsPage;
}());
export { ActionSettingsChangeAnimationsPage };
var ActionSettingsChangeAnimationsPageDisabled = /** @class */ (function () {
    function ActionSettingsChangeAnimationsPageDisabled(payload) {
        this.payload = payload;
        this.type = SettingsActionTypes.CHANGE_ANIMATIONS_PAGE_DISABLED;
    }
    return ActionSettingsChangeAnimationsPageDisabled;
}());
export { ActionSettingsChangeAnimationsPageDisabled };
var ActionSettingsChangeAnimationsElements = /** @class */ (function () {
    function ActionSettingsChangeAnimationsElements(payload) {
        this.payload = payload;
        this.type = SettingsActionTypes.CHANGE_ANIMATIONS_ELEMENTS;
    }
    return ActionSettingsChangeAnimationsElements;
}());
export { ActionSettingsChangeAnimationsElements };
var ActionSettingsPersist = /** @class */ (function () {
    function ActionSettingsPersist(payload) {
        this.payload = payload;
        this.type = SettingsActionTypes.PERSIST;
    }
    return ActionSettingsPersist;
}());
export { ActionSettingsPersist };
export var NIGHT_MODE_THEME = 'BLACK-THEME';
export var initialState = {
    theme: 'DEFAULT-THEME',
    autoNightMode: false,
    pageAnimations: true,
    pageAnimationsDisabled: false,
    elementsAnimations: true
};
export var selectorSettings = function (state) {
    return (state.settings || { theme: '' });
};
export function settingsReducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case SettingsActionTypes.CHANGE_THEME:
            return __assign({}, state, { theme: action.payload.theme });
        case SettingsActionTypes.CHANGE_AUTO_NIGHT_AUTO_MODE:
            return __assign({}, state, { autoNightMode: action.payload.autoNightMode });
        case SettingsActionTypes.CHANGE_ANIMATIONS_PAGE:
            return __assign({}, state, { pageAnimations: action.payload.pageAnimations });
        case SettingsActionTypes.CHANGE_ANIMATIONS_ELEMENTS:
            return __assign({}, state, { elementsAnimations: action.payload.elementsAnimations });
        case SettingsActionTypes.CHANGE_ANIMATIONS_PAGE_DISABLED:
            return __assign({}, state, { pageAnimations: false, pageAnimationsDisabled: action.payload.pageAnimationsDisabled });
        default:
            return state;
    }
}
