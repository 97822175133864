import { Component, Inject, ViewChild, TemplateRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDatepicker, MatDatepickerToggle, NativeDateAdapter } from '@angular/material';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Http, RequestOptions, Headers, Response } from '@angular/http';
import { Observable } from 'rxjs';
import { UserService } from '../../../core/services/UserService';
import { IAccountSettings } from '../../../core/interfaces/user/IAccountSettings';
import { IUser } from '../../../core/interfaces/user/IUser';
import { OrganizationService } from '../../../core/services/OrganizationService';
import { AuthenticationService } from '../../../core/services/AuthenticationService';
import { SalaryRecieverEditComponent } from '../../../static/users/edit-salaryreciever-modal/edit-salaryreciever-modal.component';

@Component({
  selector: 'add-payment-recievers-dialog',
  templateUrl: 'add-payment-recievers-Dialog.Component.html'
})

export class AddPaymentRecieversDialogComponent {

  public invoiceGroupTemplate = {
    Amount: null,
    Vat: '25',
    Description: '',
    EmploymentTypeId: 2,
    PaymentRecievers: [],
    SelectedPaymentRecievers: []
  };

  paymentRecievers: Array<any> = null;

  vatList = [{ Value: "25" }, { Value: "12" }, { Value: "6" }, { Value: "0" }];

  searchTerm: string = '';
  filteredPaymentRecievers: any[] = [];

    constructor(
      public dialogRef: MatDialogRef<AddPaymentRecieversDialogComponent>,
      private userService: UserService,
      public dialog: MatDialog,
      private authenticationService: AuthenticationService,
      private organizationService: OrganizationService,
      @Inject(MAT_DIALOG_DATA) public data: any
    ) 
    {}

  ngOnInit() {
    this.getPaymentRecievers();
  }

  filterPaymentReceivers() {
    if (this.searchTerm) {
      this.filteredPaymentRecievers = this.paymentRecievers.filter(item =>
        `${item.FirstName} ${item.LastName} (${item.PersonalNumber})`
          .toLowerCase()
          .includes(this.searchTerm.toLowerCase())
      );
    } else {
      this.filteredPaymentRecievers = [...this.paymentRecievers];
    }
  }

  // Reset the search term when the dropdown is closed or opened
  resetSearchTerm() {
    this.searchTerm = '';
    this.filteredPaymentRecievers = [...this.paymentRecievers];
  }

  onSubmit() {
    
    for (var i = 0; i < this.invoiceGroupTemplate.SelectedPaymentRecievers.length; i++) {

      var currentSelectedUserId = this.invoiceGroupTemplate.SelectedPaymentRecievers[i];

      // Look for payment reciever user and add to list
      for (var ii = 0; ii < this.paymentRecievers.length; ii++) {
        var currentUser = this.paymentRecievers[ii];
        if (currentUser.Id == currentSelectedUserId) {
          this.invoiceGroupTemplate.PaymentRecievers.push(currentUser);
        }
      }

    }
    
    this.dialogRef.close(this.invoiceGroupTemplate);
  }

  getPaymentRecievers() {
    this.organizationService.getPaymentRecievers(this.authenticationService.getCurrentUserOrganization().Id)
      .subscribe(response => this.onPaymentRecieversReturned(response));
  }

  onPaymentRecieversReturned(paymentRecievers) {
    this.paymentRecievers = paymentRecievers;
    // Initially, show all payment receivers
    this.filteredPaymentRecievers = [...this.paymentRecievers];

  }

  createSalaryReciever(event: MouseEvent,selectedRecievers) {
    event.preventDefault();
    var currentUserOrg = this.authenticationService.getCurrentUserOrganization()

    const dialogRef = this.dialog.open(SalaryRecieverEditComponent, {
      width: '350px',
      height: '500px',
      data: {
        id: 0,
        headline: 'Ny betalmottagare',
        organizationId: currentUserOrg.Id
      }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result != null) {
        selectedRecievers.push(result);
        this.getPaymentRecievers();
      }

    });
  }

  GetPersonFromSpar(personalNumber: string) {

    if (personalNumber == null || personalNumber == "") {
      return;
    }

    personalNumber = personalNumber.replace("-", "");

    this.userService.getPersonFromSparByPersonalNumber(personalNumber)
      .subscribe(
        response => this.onGetPersonFromSparCompleted(response, personalNumber),
        error => this.onGetPersonFromSparError(error));
  }

  onGetPersonFromSparError(error) {
    alert('Err')
  }

  onGetPersonFromSparCompleted(response, term) {

  }
  
  //cancel(response: any) {

  //  this.dialogRef.close(response);
  //}

  cancel() {

    this.dialogRef.close();
  }

}
