<style>
  .fix-static-bug {
    width: 100% !important;
    position: static !important;
    margin-top: 20px;
  }

  .pointer {
    cursor: pointer;
  }

  .prel {
    font-style: italic;
    position: relative;
    left: 12px;
  }

  .italic {
    font-style: italic;
  }

  .iconPreli {
    font-size: 14px;
  }
</style>


<div class="fix-static-bug">
  <div class="container">
    <div class="row d-none d-lg-block">
      <div class="col-md-12">
        <h3 class="main-heading">EKONOMI</h3>
        <div style="margin: 20px 0">
          Här ser du hur vi har räknat ut din lön. Om du skickat din faktura till en svensk kund i SEK ser du den här så fort du skickat den till oss. Om du skickat den i annan valuta dyker den upp så fort vi granskat den och skickat den till din kund.<br />
          Om du har en kund som fakturerat för din räkning, eller använder oss som löneadministratör, ser du bara utbetalningsdatumen för de fakturorna.
        </div>
      </div>
    </div>
    <div class="backgroundcolor container">


      <mat-card class="mat-elevation-z0 backgroundcolor">
        <div class="container-margin backgroundcolor">
          <mat-card-content class="mat-elevation-z1">

            <div class="invoice-table">
              <div class="d-none d-lg-block">
                <div class="col white">
                  <!--<mat-form-field color="accent" appearance="standard">
                    <mat-label>Sök</mat-label>
                    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Hitta rätt faktura">
                    <mat-icon matSuffix color="accent">search</mat-icon>
                  </mat-form-field>-->
                  <mat-form-field floatPlaceholder="never">
                    <input matInput [(ngModel)]="search"
                         type="text" name="search" id="search"
                         (ngModelChange)="this.searchUpdate.next($event)" placeholder="Sök">
                </mat-form-field>
                </div>
              </div>
            </div>
            <div class="mobile-title">
              <div class="col">
                <mat-card-title>EKONOMI</mat-card-title>
                <mat-card-subtitle>Tryck på datumen för att se hur vi har räknat</mat-card-subtitle>
              </div>
            </div>


            <div class="invoice-table">
              <mat-table [dataSource]="dataSource" [class.isMobile]="isMobile" matSort (matSortChange)="sortData($event)" matSortActive="name" matSortDirection="asc" matSortDisableClear>


                <ng-container matColumnDef="id">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Faktura </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    <div *ngIf="row.TransactionLogs.length != 0 && row?.InvoiceDocument?.Url != null"><a href="{{row?.InvoiceDocument.Url}}" target="_blank">{{row?.Id}}</a> </div>
                    <div *ngIf="row.TransactionLogs.length != 0 && row?.InvoiceDocument?.Url == null">{{row?.Id}}</div>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="customer">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Kund </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    <span class="mobile-label">Din kund:</span>
                    <div *ngIf="'row.TransactionLogs.length != 0' && 'row?.Customer != null'">{{row?.Customer?.Name}}</div>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="user">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Medlem </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    <span class="mobile-label">Din kund:</span>
                    <div *ngIf="'row.TransactionLogs.length != 0' && 'row?.Customer? != null'">{{row?.UserSettings?.FirstName + ' ' + row?.UserSettings?.LastName}}</div>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="invoicenumber">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Fakturanummer </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    <span class="mobile-label invoiceNumberMobile">Fakturanummer:</span>
                    <div *ngIf="row.TransactionLogs.length != 0 && row?.InvoiceDocument?.Id != null"><a href="{{row?.InvoiceDocument?.Url}}" target="_blank">{{row?.InvoiceNumber}}</a> </div>
                    <div *ngIf="row.TransactionLogs.length != 0 && row?.InvoiceDocument?.Id == null">{{row?.InvoiceNumber}}</div>
                    <div *ngIf="row?.InvoiceNumber == null">-</div>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="payment">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Inbetalningsdatum </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    <span class="mobile-label">Inbetalningsdatum:</span>
                    <div (click)="openDialog('payment', row)" class="pointer prel accent" *ngIf="row?.TransactionLogs[0]?.IsPreliminary == true && !row?.SubInvoice">{{row?.TransactionLogs[0]?.PaymentDue | date: 'yyyy-MM-dd'}} <mat-icon class="iconPreli" color="accent" matTooltipPosition="after" area-label="Hover to display info" matTooltip="Detta är ett preliminärt datum">info</mat-icon></div>
                    <div (click)="openDialog('payment', row)" class="pointer accent" *ngIf="row?.TransactionLogs[0]?.IsPreliminary == false && !row?.SubInvoice">{{ row?.TransactionLogs[0]?.PaymentDate | date: 'yyyy-MM-dd' }}</div>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="payout">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Utbetalningsdatum </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    <span class="mobile-label">Utbetalningsdatum:</span>
                    <div (click)="openDialog('payout', row)" class="pointer prel accent" *ngIf="row?.TransactionLogs[1]?.IsPreliminary == true">{{getPrelPayoutDate(row?.TransactionLogs[1]?.PaymentDue) | date: 'yyyy-MM-dd'}} <mat-icon class="iconPreli" color="accent" matTooltipPosition="after" area-label="Hover to display info" matTooltip="Detta är ett preliminärt datum">info</mat-icon></div>
                    <div (click)="openDialog('payout', row)" class="pointer accent" *ngIf="row?.Type?.TechnicalName != 'CommissionCashInvoice' && row?.TransactionLogs[1]?.IsPreliminary == false">{{row?.TransactionLogs[1]?.PayoutDate | date: 'yyyy-MM-dd'}}</div>
                    <div class="accent" *ngIf="row?.Type?.TechnicalName == 'CommissionCashInvoice'">{{row?.TransactionLogs[0]?.PayoutDate | date: 'yyyy-MM-dd'}}</div>
                  </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;">
                </mat-row>
              </mat-table>

              <mat-paginator [pageSizeOptions]="[5, 20, 50, 100, 500]" [pageSize]="10" (page)="onPaginatorChange(paginator)" [pageIndex]="0" [length]="listLength" showFirstLastButtons="true"></mat-paginator>

            </div>



          </mat-card-content>
        </div>
      </mat-card>

      <!--Shows all transaction logs separately to admin-->
      <!--<mat-card class="mat-elevation-z0" [hidden]="!isAdmin">
        <mat-card class="mat-elevation-z0">
          <div class="container-margin container">
            <mat-card-content class="mat-elevation-z8">

              <div class="d-none d-lg-block">
                <div class="col">
                  <mat-card-title>ALLA TRANSAKTIONSRADER (ADMIN)</mat-card-title>
                </div>
                <div class="col d-flex justify-content-between" style="background-color: #FAFAFA;">
                  <div class="col">
                    <mat-form-field color="accent" appearance="standard">
                      <mat-label>Sök</mat-label>
                      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Hitta rätt faktura">
                      <mat-icon matSuffix color="accent">search</mat-icon>
                    </mat-form-field>
                  </div>

                </div>
              </div>-->
      <!--This activates the correct default sort-->
      <!--<table mat-table matSort [dataSource]="dataSourceAdmin" matSortActive="date" matSortDirection="asc" #mytable class="my-table">

                <ng-container matColumnDef="id">
                  <th mat-header-cell mat-sort-header *matHeaderCellDef> <span style="color: #E91E63">Fakturaid </span></th>
                  <td mat-cell *matCellDef="let element">{{element?.Invoice?.Id}}</td>
                </ng-container>

                <ng-container matColumnDef="invoicenumberadm">
                  <th mat-header-cell mat-sort-header *matHeaderCellDef> <span style="color: #E91E63">Fakturanr </span></th>
                  <td mat-cell *matCellDef="let element">
                    <a href="{{element?.Invoice?.InvoiceDocument?.Url}}" target="_blank">
                      <span *ngIf="element?.PayoutDate != null || element?.PaymentDate != null ">{{element?.Invoice?.InvoiceNumber}}</span>
                    </a>
                  </td>
                </ng-container>

                <ng-container matColumnDef="customer">
                  <th mat-header-cell mat-sort-header *matHeaderCellDef> <span style="color: #E91E63">Kund </span></th>
                  <td mat-cell *matCellDef="let element">{{element?.Invoice?.Customer.Name}} </td>
                </ng-container>

                <ng-container matColumnDef="member">
                  <th mat-header-cell mat-sort-header *matHeaderCellDef> <span style="color: #E91E63">Medlem </span></th>
                  <td mat-cell *matCellDef="let element">{{element?.Invoice?.UserSettings?.FirstName}} {{element?.Invoice?.UserSettings?.LastName}}</td>
                </ng-container>

                <ng-container matColumnDef="paymentdate">
                  <th mat-header-cell mat-sort-header *matHeaderCellDef> <span style="color: #E91E63">Inbetalat från kund </span></th>
                  <td mat-cell (click)="openDialogAdmin('open',element)" class="pointer" *matCellDef="let element">
                    <span *ngIf="element?.PaymentDate != null">{{element?.PaymentDate | slice:0:10}}</span>
                    <span class="italic" *ngIf="element?.PaymentDate == null && element?.IsPayoutTransLog == false">{{element?.Invoice?.PaymentDue | slice:0:10}} (prel)</span>
                  </td>
                </ng-container>

                <ng-container matColumnDef="payoutdate">
                  <th mat-header-cell mat-sort-header *matHeaderCellDef> <span style="color: #E91E63">Utbetalat till konto</span></th>
                  <td mat-cell (click)="openDialogAdmin('open',element)" class="pointer" *matCellDef="let element">
                    <span *ngIf="element?.PayoutDate != null">{{element?.PayoutDate | slice:0:10}}</span>
                    <span class="italic" *ngIf="element?.PayoutDate == null && element?.IsPayoutTransLog == true">{{element?.Invoice?.PaymentDue | slice:0:10}} (prel)</span>
                  </td>
                </ng-container>

                <ng-container matColumnDef="admin">
                  <th mat-header-cell mat-sort-header *matHeaderCellDef> <span style="color: #E91E63">Admin</span></th>
                  <td mat-cell class="pointer" *matCellDef="let element">
                    <a mat-icon-button (click)="deleteTransactionLog(element?.Id)" style="color:red;" class="pointer"><mat-icon>delete_forever</mat-icon></a>
                  </td>
                </ng-container>

                <ng-container matColumnDef="icon">
                  <th mat-header-cell mat-sort-header *matHeaderCellDef style="padding: 0 !important;"><span style="color: #E91E63">Se beräkning</span></th>
                  <td mat-cell (click)="openDialog('Update',element)" class="pointer" *matCellDef="let element" style="padding: 0 !important;"><mat-icon color="accent">list_alt</mat-icon></td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsAdmin"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsAdmin;"></tr>

              </table>
              <mat-paginator [pageSizeOptions]="[5, 20, 50, 100, 500]" [pageSize]="10" [pageIndex]="0" [length]="listLength" showFirstLastButtons="true"></mat-paginator>
            </mat-card-content>
          </div>
        </mat-card>
      </mat-card>-->
    </div>
  </div>
</div>
