import { Component, Inject, ViewChild, TemplateRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDatepicker, MatDatepickerToggle, NativeDateAdapter } from '@angular/material';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Http, RequestOptions, Headers, Response } from '@angular/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../../core/services/AuthenticationService'
import { AppConfig } from '../../config/app.config';
import { IDeduction } from '../../../core/interfaces/invoice/IDeduction';
import { IFile } from '../../../core/interfaces/IFile';
import { ITimeReportStatus } from '../../core/interfaces/invoice/TimeReportStatus';
import { InvoiceService } from '../../../core/services/InvoiceService';
import { CompanyService } from '../../core/services/CompanyService';
import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { UserService } from '../../../core/services/UserService';


@Component({
    selector: 'edit-deduction-dialog',
    templateUrl: 'edit-deduction-Dialog.Component.html'
})

export class EditDeductionDialogComponent {
    private _dimesionToggle = false;
    private _formValid = false;
    public _imageList: any[] = [];
    public _hasImages = false;
    private _showImageList = false;
    private _showAttachments = false;
    private _hasAttachments = false;
    private _showComments = false;
    //public customer: ICompany;
    public isNewCustomer: boolean;
    @ViewChild('myInput', { static: false })
    myInputVariable: any;
    private _apiBaseUrl: string;
    @ViewChild("datepicker", { static: false }) datepicker: MatDatepicker<Date>;
    selectedDate: Date = new Date();
    private hoursList = [];
    private minutesList = [];
    public rowTypeList = [];
    private hoursSelected: string = "8";
    private minutesSelected: string = "0";
    private rowTypeSelected: string = "0"
    private row: any = {};
    private selectedDates = [];
    TravelExpense = true;
    HealthCare = true;
    HealthCareAmountThisYear: number = 0;


    vatList = [{ Value: "25" }, { Value: "12" }, { Value: "6" }, { Value: "0" }];
    //private timeReportStatuses: Array<ITimeReportStatus> = [];


    deduction: IDeduction = <IDeduction>{};

    constructor(
        public dialogRef: MatDialogRef<EditDeductionDialogComponent>,
        //private http: Http,
        //private authenticationService: AuthenticationService,
        private invoiceService: InvoiceService,
        private userService: UserService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) 
    { 
        
    }

    ngOnInit() {

      this.deduction = JSON.parse(JSON.stringify(this.data.deduction));
      this.TravelExpense = this.deduction.TravelExpense;
      this.HealthCare = this.deduction.HealthCare;

      if (this.HealthCare) {
        this.getHealthCareAmountThisYear();
      }

      console.log("Data")
      console.log(this.data);
    }

    onSubmit()
    {
        this.save();
    }
    
    canEditGroup()
    {
        let statuses = ["Draft"];
        return statuses.includes(this.data.status);
    }

    getTimeReportStatuses()
    {
        //this.invoiceService.getTimeReportStatuses()
        //    .subscribe(
        //    response => this.getTimeReportStatusesFinished(response),
        //    error => console.log(error));
    }

    deleteFile(file: IFile)
    {
        file.Guid = null;
        file.Id = 0;
    }

    showFile(file: IFile) {
        window.open(file.Url);
    }

    rowTypeChange(event: any)
    {
        this.data.invoiceItemGroup.Type = this.getRowType(event.value);
        
        if (event.value == "Övrigt")
        {
            // No rows allowed in the misc category
            this.data.invoiceItemGroup.InvoiceRows = [];
        }
    }

    getRowType(id: any)
    {
        for (var i = 0; i < this.rowTypeList.length; i++)
        {
            if (this.rowTypeList[i].Name == id)
            {
                return { Id: this.rowTypeList[i].Id, Name: this.rowTypeList[i].Name};
            }
        }
    }

    save()
    {
        this.dialogRef.close(this.deduction);
    }
    
    cancel()
    {
        this.dialogRef.close(null);
    }
    
    deleteRow()
    {
        this.data.invoiceItemGroup.action = 'delete';
        this.dialogRef.close(this.data.invoiceItemGroup);
    }    

    
    deleteListItem(item: any)
    {
        console.log(item);

        this.data.invoiceItemGroup.InvoiceRows.splice(this.data.invoiceItemGroup.InvoiceRows.indexOf(item), 1);
    }

    guid() {  
        return this.s4() + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' + this.s4() + this.s4() + this.s4();
    }

    s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
  }

  changeValue(value, type) {


    if (type == 'travel') { debugger
      this.TravelExpense = value.checked;
      this.deduction.TravelExpense = this.TravelExpense;
      if (this.TravelExpense) {
        this.deduction.HealthCare = false;
        this.HealthCare = false;
      }
    }
    if (type == 'health') { debugger
      this.HealthCare = value.checked;
      this.deduction.HealthCare = this.HealthCare;
      if (this.HealthCare) {
        this.deduction.TravelExpense = false;
        this.TravelExpense = false;
      }

      this.getHealthCareAmountThisYear();
      
    }
  }

  getHealthCareAmountThisYear() {
    this.userService.getTotalAmountOfHealthCareDeduction(this.data.userId)
      .subscribe(
        response => this.onHealthCareAmountReceived(response),
        error => console.log(error));
  }

  onHealthCareAmountReceived(response: any) { debugger
    this.HealthCareAmountThisYear = response;

  }

    
    calendarSelect(event: any)
    {
        var date = event.toLocaleDateString('sv-SE');

        // Duplicate dates not allowed
        for (var i = 0; i < this.data.invoiceItemGroup.InvoiceRows.length; i++)
        {
            var item = this.data.invoiceItemGroup.InvoiceRows[i];

            if (item.StartDate == date)
            {
                return;
            }
        }

        // Add item
        this.data.invoiceItemGroup.InvoiceRows.push({ StartDate: date, Id: this.guid(), HoursWorked: this.hoursSelected, MinutesWorked: this.minutesSelected });

        // Sort list by date
        this.data.invoiceItemGroup.InvoiceRows.sort((val1: any, val2: any) => {
            var d1 = <any>new Date(val1.StartDate.toString());
            var d2 = <any>new Date(val2.StartDate.toString());
            
            return d1 - d2;
        })
        
    }
    
    onError(error: any)
    {
        alert('An error occured');
        console.log(error);
    }

    fileChange(event: any, file: IFile) {

      var fileType = "";

      if (this.TravelExpense) {
        fileType = "AllowanceReceipt";
      }
      else {
        fileType = 'DeductionReceipt';
      }

      file.Guid = this.newGuid();
        let fileList: FileList = event.target.files;
        if (fileList.length > 0) {
            let f: File = fileList[0];

            this.invoiceService.uploadDocument(f, file.Guid, fileType)
                .subscribe(
                response => this.onDocumentSaved(response, file),
                error => console.log(error));

        }
    }

    newGuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    onDocumentSaved(response: any, file: IFile)
    {
        file.Id = response.Id;
        file.Url = response.Url;
        file.FileType = response.FileType;
        file.Guid = response.Guid;

    }


    
}
