import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { ICompany } from '../interfaces/ICompany';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment as env } from '@env/environment';
import { HttpHeaders } from '@angular/common/http';
import { HttpErrorHandler, HandleError } from './http-error-handler';
import { tap } from "rxjs/internal/operators/tap";
import { AuthGuardService } from '../auth/auth-guard.service';
import { Store } from '@ngrx/store';
import { AuthenticationService } from '../services/AuthenticationService';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { RequestOptions } from "@angular/http";


@Injectable()
export class FileService {
    public apiAuthUrl: string;
    private apiBaseUrl: string;
    public token: string;
    private userString: string;
    private httpOptions: any;
    private handleError: HandleError;
    
    constructor(
        private http: HttpClient,
        private router: Router,
        private authenticationService: AuthenticationService,
        httpErrorHandler: HttpErrorHandler,
        public snackBar: MatSnackBar,
        private route: ActivatedRoute)
    {
        this.apiBaseUrl = env.hostApi;
        this.setHttpOptions();
    }
    
    showMessage(message: string)
    {
        let config = new MatSnackBarConfig();
        config.duration = 7000;
        this.snackBar.open(message, undefined, config);
    }

    getFileTypes(): Observable<any> {
      
        var url = this.apiBaseUrl + 'FileTypes';

        return this.http.get<any>(url, this.httpOptions)
            .pipe(
            tap((response: any) => this.onFinished(response)),
            catchError(
                (err: any) => {
                    return new Observable<any>();
                }
            ));
    }

    getPublicFileTypes(): Observable<any> {

      var url = this.apiBaseUrl + 'PublicFileTypes';

      return this.http.get<any>(url, this.httpOptions)
        .pipe(
          tap((response: any) => this.onFinished(response)),
          catchError(
            (err: any) => {
              return new Observable<any>();
            }
          ));
    }


    deleteDocument(id: number): Observable<any> {

    var url = this.apiBaseUrl + 'Documents/' + id.toString();

    return this.http.delete<any>(url, this.httpOptions)
      .pipe(
        tap((response: any) => this.onFinished(response)),
        catchError(
          (err: any) => {
            return new Observable<any>();
          }
        ));
  }

    getDocuments(filter: any): Observable<any> {

    var url = this.apiBaseUrl + 'Documents?Skip=' + filter.Skip + "&Take=" + filter.Take + "&Asc=" + filter.Asc + "&Filter=" + filter.Filter + "&OrderBy=" + filter.OrderBy;
    
    return this.http.get<any>(url, this.httpOptions)
      .pipe(
        tap((response: any) => this.onFinished(response)),
        catchError(
          (err: any) => {
            return new Observable<any>();
          }
        ));
  }

    onFinished(response: any) {
        console.log(response);
    }
    
    
    setHttpOptions() {
        var bearerToken = 'bearer ' + this.authenticationService.token;

        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': bearerToken
            })
        };
    }
}

