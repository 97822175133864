import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { ICompany } from '../interfaces/ICompany';
import { Injectable, Provider } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment as env } from '@env/environment';
import { HttpHeaders } from '@angular/common/http';
import { HttpErrorHandler, HandleError } from './http-error-handler';
import { tap } from "rxjs/internal/operators/tap";
import { AuthGuardService } from '../auth/auth-guard.service';
import { Store } from '@ngrx/store';
import { AuthenticationService } from '../services/AuthenticationService';


@Injectable()
export class ReportService {
  public apiAuthUrl: string;
  private apiBaseUrl: string;
  public token: string;
  private userString: string;
  private httpOptions: any;
  private handleError: HandleError;

  constructor(
    private http: HttpClient,
    private router: Router,
    private authenticationService: AuthenticationService,
    httpErrorHandler: HttpErrorHandler,
    private route: ActivatedRoute) {
    this.apiBaseUrl = env.hostApi;
    this.setHttpOptions();
  }

  getReportTypes(): Observable<any> {
    
    var url = this.apiBaseUrl + 'ReportType';

    return this.http.get<any>(url, this.httpOptions)
      .pipe(
        tap((response: any) => console.log(response)),
        catchError(
          (err: any) => {

            console.log(err)
            //if (err.status == '401') {
            //    this.router.navigate(['/login']);
            //}
            return new Observable<any>();

          }
        ));
  }

  getReport(memberId: string, reportType: number, startDate: Date, endDate: Date): Observable<any> {
        
    var url = this.apiBaseUrl + 'Report?reportType=' + reportType + '&memberId=' + memberId + '&startDate=' + startDate + '&endDate=' + endDate;

    return this.http.get<any>(url, this.httpOptions)
      .pipe(
        tap((response: any) => console.log(response)),
        catchError(
          (err: any) => {

            console.log(err)
            //if (err.status == '401') {
            //    this.router.navigate(['/login']);
            //}
            return new Observable<any>();

          }
        ));
  }

  getMonthlyAttachmentOfSalaryReport(userId: string, invoiceId: number, month: Date): Observable<any> {
    
    var url = this.apiBaseUrl + '/Users/MonthlyAttachmentOfSalaryReport?userId=' + userId + '/Salaries/Attachments/&invoiceId=' + invoiceId + '&month=' + month;

    return this.http.get<any>(url, this.httpOptions)
      .pipe(
        tap((response: any) => console.log(response)),
        catchError(
          (err: any) => {

            console.log(err)
            //if (err.status == '401') {
            //    this.router.navigate(['/login']);
            //}
            return new Observable<any>();

          }
        ));
  }



  setHttpOptions() {
    var bearerToken = 'bearer ' + this.authenticationService.token;

    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': bearerToken
      })
    };
  }
}

