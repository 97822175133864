<style>
  .MainCardLeft, .MainCardRight {
    margin-bottom: 30px;
  }

  .warning-box {
    border: 1px solid red;
    background-color: #ffe5e5; /* Light red background */
    padding: 20px;
    margin: 10px auto 20px auto;
    font-weight: bold;
  }

</style>

<!-- ***************************** -->
<!-- ****** PERSONAL ACCOUNT ***** -->
<!-- ***************************** -->
<!--If private account on private view (logged in on correct site)-->
<div *ngIf="appId == 1" class="static-fix">
  <!--<div class="Web" fxShow="false" fxShow.gt-md>-->
  <h1 class="greeting" *ngIf="user">Välkommen <span color="accent" class="accent">{{user.AccountSettings.FirstName}}</span>!</h1>
  <div class="container">
    <div *ngIf="!isLoading">
      <div class="container backgroundColor">
        <div class="row">
          <div class="warning-box" *ngIf="user.AccountSettings.Suspended">
            <span>Ditt konto är spärrat pga misstänkt missbruk eller brott mot våra villkor.<br /> Kontakta supporten för att låsa upp kontot. Ring 08 - 51 81 64 40.</span>
          </div>

          <!--Income -->
          <div class="col-lg-6 col-sm-12 MainCardLeft">
            <mat-card [hidden]="invoiceData == 0">
              <mat-card-title>
                <h4 class="incomeTextHead accent">Kommande löner</h4>
              </mat-card-title>
              <mat-card-subtitle>
                <p class="incomeText">Nedan är preliminära utbetalningar, för mer detaljer gå till din <a href="#/transactions"><span class="accent">ekonomisida</span></a></p>
              </mat-card-subtitle>
              <mat-card-content>
                <div class="example-container mat-elevation-z0 economy">
                  <mat-table class="myTable" [dataSource]="dataSource">

                    <!-- Active Column -->
                    <ng-container matColumnDef="icon">
                      <mat-header-cell *matHeaderCellDef fxShow="false" fxShow.gt-sm></mat-header-cell>
                      <mat-cell *matCellDef="let element" fxShow="false" fxShow.gt-sm>
                        <mat-icon color="accent">description</mat-icon>
                      </mat-cell>
                    </ng-container>

                    <!-- Customer Column -->
                    <ng-container matColumnDef="customer">
                      <mat-header-cell *matHeaderCellDef fxShow="false" fxShow.gt-sm><span class="accent">Kund:</span></mat-header-cell>
                      <mat-cell *matCellDef="let element" fxShow="false" fxShow.gt-sm>
                        <span *ngIf="element.Customer != null">{{element?.Customer?.Name}}</span>
                        <span *ngIf="element.Customer == null">{{element?.RecieverCompanyName}}</span>
                      </mat-cell>
                    </ng-container>

                    <!-- PaymentDue Column -->
                    <ng-container matColumnDef="paymentdue">
                      <mat-header-cell *matHeaderCellDef fxShow="false" fxShow.gt-sm><span class="accent">Preliminärt datum:<mat-icon class="iconPreli" color="accent" matTooltipPosition="before" area-label="Hover to display info" matTooltip="Du kan förvänta dig att få din betalning 1-2 dagar efter utbetalningsdatumet nedan">info</mat-icon></span></mat-header-cell>
                      <mat-cell *matCellDef="let element" class="paymentdue-margin" fxShow="false" fxShow.gt-sm>
                        <span [ngStyle]="{'font-style':element?.TransactionLogs[1]?.IsPreliminary == true ? 'italic' : 'inherit' }">{{element?.PaymentDue | slice:0:10 }}</span>
                        <span *ngIf="element?.TransactionLogs[1]?.IsPreliminary == true"><mat-icon class="iconPreli" color="accent" matTooltipPosition="after" area-label="Hover to display info" matTooltip="Detta är ett preliminärt datum">info</mat-icon></span>
                      </mat-cell>
                    </ng-container>

                    <!-- Money Column -->
                    <ng-container matColumnDef="money">
                      <mat-header-cell *matHeaderCellDef fxShow="false" fxShow.gt-sm><span class="accent">Belopp:</span></mat-header-cell>
                      <mat-cell *matCellDef="let element" class="money-less" fxShow="false" fxShow.gt-sm>
                        {{element?.TransactionLogs[1]?.TotalPayout | number: '1.0-2'}} {{ element?.Currency.Name }}
                      </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                  </mat-table>
                </div>
              </mat-card-content>
            </mat-card>
            <mat-card *ngIf="invoiceData == 0">
              <mat-card-title>
                <h4 class="incomeTextHead accent">Kommande löner</h4>
              </mat-card-title>
              <mat-card-subtitle>
                <p class="incomeText">Här var det tomt, välj <a href="#/invoice-tool"><span class="accent">Ny faktura</span></a> för att skicka en faktura</p>
              </mat-card-subtitle>
              <mat-card-content>
                <div class="d-flex justify-content-center">
                  <a mat-button mat-raised-button color="accent" href="#/invoice-tool">Ny faktura</a>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
          <!--Messages -->
          <div *ngIf="!isAdmin" class="col-lg-6 col-sm-12 MainCardLeft">
            <mat-card>
              <mat-card-title>
                <h4 class="incomeTextHead accent">Meddelanden</h4>
              </mat-card-title>
              <mat-card-subtitle *ngIf="appId == 1">
                <p class="incomeText">Här visas dina meddelanden från <a href="https://www.sampoolen.se" target="_blank"><span class="accent">SAMpoolen</span></a></p>
              </mat-card-subtitle>
              <mat-card-content>
                <mat-list>
                  <mat-list-item *ngFor="let message of globalmessages">
                    <mat-icon *ngIf="isNotRead" mat-list-icon (click)="openDialog(message, $event.currentTarget)" class="notread-icon message-pointer">notifications_active</mat-icon>
                    <mat-icon *ngIf="isRead" mat-list-icon (click)="openDialog(message, $event.currentTarget)" class="isread-icon message-pointer">check</mat-icon>
                    <h4 mat-line (click)="openDialog(message, $event.currentTarget)" class="message-pointer">{{message.Headline}}</h4>
                    <p mat-line (click)="openDialog(message, $event.currentTarget)" class="message-pointer">{{message.Created | date: 'yyyy-MM-dd'}}</p>
                  </mat-list-item>
                </mat-list>
              </mat-card-content>
            </mat-card>
          </div>
          <div *ngIf="isAdmin" class="col-lg-6 col-sm-12 MainCardLeft">
            <mat-card>
              <mat-card-title>
                <h4 class="incomeTextHead accent">Meddelanden</h4>
              </mat-card-title>
              <mat-card-subtitle>
                <p class="incomeText">Här kommer meddelanden från medlemmar att visas</p>
              </mat-card-subtitle>
              <mat-card-content>
                <mat-list>
                  <mat-list-item *ngFor="let message of globalmessages">
                    <mat-icon *ngIf="isNotRead" mat-list-icon (click)="openDialog(message, $event.currentTarget)" class="notread-icon message-pointer">notifications_active</mat-icon>
                    <mat-icon *ngIf="isRead" mat-list-icon (click)="openDialog(message, $event.currentTarget)" class="isread-icon message-pointer">check</mat-icon>
                    <h4 mat-line (click)="openDialog(message, $event.currentTarget)" class="message-pointer">{{message.Headline}}</h4>
                    <p mat-line (click)="openDialog(message, $event.currentTarget)" class="message-pointer">{{message.Created | date: 'yyyy-MM-dd'}}</p>
                  </mat-list-item>
                </mat-list>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
        <div class="row">
          <!-- calculator -->
          <div class="col-lg-6 col-sm-12 MainCardRight">
            <mat-card>
              <mat-card-title>
                <h4 class="incomeTextHead accent">Kalkylator</h4>
              </mat-card-title>
              <div style="margin-bottom: 10px; font-size: 80%;">
                <span *ngIf="user?.AccountSettings?.Municipality?.Tax > 0 && user?.AccountSettings?.Municipality?.Name != 'Generell'">{{municipalityTaxMessage}}</span>
                <span *ngIf="user?.AccountSettings?.Municipality?.Name == 'Generell'">Du har inte angivit din riktiga kommun, och därför räknar vi med schablonvärdet 30 %. Gå till <a href="#/settings"><span class="accent">Inställningar</span></a> för att ändra detta.</span>
                <span *ngIf="user.AccountSettings.Municipality.Tax == 0">
                  <mat-card-subtitle class="calc-warning-tax">
                    <b>Var god ange din kommun i <a href="#/settings"><span class="accent">inställningar</span></a> för att kunna använda Din Lön</b>
                  </mat-card-subtitle>
                </span>
              </div>
              <mat-tab-group color="accent" mat-stretch-tabs dynamicHeight>
                <!-- Tab 1 -->
                <mat-tab>
                  <ng-template mat-tab-label>
                    <mat-icon color="accent" class="example-tab-icon">attach_money</mat-icon>
                    Din Lön
                  </ng-template>
                  <div class="d-flex justify-content-start calc-slider">
                    <mat-slide-toggle [(ngModel)]="samFeeSlide">Express?</mat-slide-toggle>
                  </div>
                  <!-- Normal samFee Calc -->
                  <mat-card-content *ngIf="!samFeeSlide" class="totalInvoice">
                    <div class="d-flex justify-content-center">
                      <mat-form-field class="calc">
                        <mat-label>Belopp du ska fakturera</mat-label>
                        <input matInput type="number" [disabled]="user.AccountSettings.Municipality.Tax == 0" [(ngModel)]="totalInvoiceAmount" (ngModelChange)="myCalculator($event)">
                        <mat-icon matSuffix color="accent">description</mat-icon>
                      </mat-form-field>
                    </div>
                    <div class="d-flex justify-content-center">
                      <mat-form-field class="calc">
                        <mat-label>Belopp efter skatt</mat-label>
                        <input matInput type="number" [disabled]="user.AccountSettings.Municipality.Tax == 0" readonly [(ngModel)]="netSalary" (ngModelChange)="netSalary = $event">
                        <mat-icon matSuffix color="accent">money</mat-icon>
                      </mat-form-field>
                    </div>

                    <div class="d-flex justify-content-center">
                      <mat-expansion-panel *ngIf="totalInvoiceAmount"
                                           (opened)="panelOpenStateCalc = true"
                                           (closed)="panelOpenStateCalc = false"
                                           class="calcExpand mat-elevation-z0" hideToggle="true">
                        <mat-expansion-panel-header class="calcHeaderPanel">
                          <mat-panel-title>
                            Se hur vi har räknat&nbsp;&nbsp;
                            <mat-icon *ngIf="!panelOpenStateCalc" matSuffix color="accent" class="expandCalcIcon">add</mat-icon>
                            <mat-icon *ngIf="panelOpenStateCalc" matSuffix color="accent" class="expandCalcIcon">remove</mat-icon>
                          </mat-panel-title>
                          <mat-panel-description>
                          </mat-panel-description>
                        </mat-expansion-panel-header>
                        <div>
                          <div class="col-12">
                            <div class="d-flex justify-content-between">
                              <b class="marginCalctext">Fakturabelopp exkl. moms:</b>
                              <p><span class="accent">{{totalInvoiceAmount}}</span> SEK</p>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="d-flex justify-content-between">
                              <b class="marginCalctext">SAMpoolen-avgift + fakturaavgift:</b>
                              <p><span class="accent">{{samFeeNew}}</span> SEK</p>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="d-flex justify-content-between">
                              <b class="marginCalctext">Sociala avgifter:</b>
                              <p><span class="accent">{{arbetsgivarAvgift}}</span> SEK</p>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="d-flex justify-content-between">
                              <b class="marginCalctext">Bruttolön:</b>
                              <p><span class="accent">{{grossSalary}}</span> SEK</p>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="d-flex justify-content-between">
                              <b class="marginCalctext">Skatt:</b>
                              <p><span class="accent">{{displayTax}}</span> SEK</p>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="d-flex justify-content-between">
                              <b class="marginCalctext">Nettolön:</b>
                              <p><span class="accent">{{netSalary}}</span> SEK</p>
                            </div>
                          </div>
                        </div>
                      </mat-expansion-panel>
                    </div>
                  </mat-card-content>
                  <!--Express samFeeExpress Calc -->
                  <mat-card-content *ngIf="samFeeSlide" class="totalInvoice">
                    <div class="d-flex justify-content-center">
                      <mat-form-field class="calc">
                        <mat-label>Belopp du ska fakturera</mat-label>
                        <input matInput type="number" [disabled]="user.AccountSettings.Municipality.Tax == 0" [(ngModel)]="totalInvoiceAmountExpress" (ngModelChange)="myExpressCalculator($event)">
                        <mat-icon matSuffix color="accent">description</mat-icon>
                      </mat-form-field>
                    </div>
                    <div class="d-flex justify-content-center">
                      <mat-form-field class="calc">
                        <mat-label>Belopp efter skatt</mat-label>
                        <input matInput type="number" [disabled]="user.AccountSettings.Municipality.Tax == 0" readonly [(ngModel)]="netSalaryExpress" (ngModelChange)="netSalaryExpress = $event">
                        <mat-icon matSuffix color="accent">money</mat-icon>
                      </mat-form-field>
                    </div>
                    <div class="d-flex justify-content-center">
                      <mat-expansion-panel *ngIf="totalInvoiceAmountExpress"
                                           (opened)="panelOpenStateCalc = true"
                                           (closed)="panelOpenStateCalc = false"
                                           class="calcExpand mat-elevation-z0" hideToggle="true">
                        <mat-expansion-panel-header class="calcHeaderPanel">
                          <mat-panel-title>
                            Se hur vi har räknat&nbsp;&nbsp;
                            <mat-icon *ngIf="!panelOpenStateCalc" matSuffix color="accent" class="expandCalcIcon">add</mat-icon>
                            <mat-icon *ngIf="panelOpenStateCalc" matSuffix color="accent" class="expandCalcIcon">remove</mat-icon>
                          </mat-panel-title>
                          <mat-panel-description>
                          </mat-panel-description>
                        </mat-expansion-panel-header>
                        <div>
                          <div class="col-12">
                            <div class="d-flex justify-content-between">
                              <b class="marginCalctext">Fakturabelopp exkl. moms:</b>
                              <p><span class="accent">{{totalInvoiceAmountExpress}}</span> SEK</p>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="d-flex justify-content-between">
                              <b class="marginCalctext">SAMpoolen avgift:</b>
                              <p><span class="accent">{{samFeeNew}}</span> SEK</p>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="d-flex justify-content-between">
                              <b class="marginCalctext">Sociala avgifter:</b>
                              <p><span class="accent">{{arbetsgivarAvgift}}</span> SEK</p>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="d-flex justify-content-between">
                              <b class="marginCalctext">Bruttolön:</b>
                              <p><span class="accent">{{grossSalary}}</span> SEK</p>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="d-flex justify-content-between">
                              <b class="marginCalctext">Skatt:</b>
                              <p><span class="accent">{{displayTax}}</span> SEK</p>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="d-flex justify-content-between">
                              <b class="marginCalctext">Nettolön:</b>
                              <p><span class="accent">{{netSalaryExpress}}</span> SEK</p>
                            </div>
                          </div>
                        </div>
                      </mat-expansion-panel>
                    </div>
                  </mat-card-content>
                </mat-tab>
                <!--Tab 2 -->
                <mat-tab>
                  <ng-template mat-tab-label>
                    <mat-icon color="accent" class="example-tab-icon">shuffle</mat-icon>
                    Inkl / exkl moms
                  </ng-template>
                  <mat-card-content class="totalInvoice">
                    <div style="margin-bottom: 15px;">
                      Här kan du räkna ut hur stor en summa (utan moms) blir om du lägger på moms, och tvärt om - vad en summa inkl moms blir om du tar bort momsen.
                    </div>
                    <div class="d-flex buttonGroup justify-content-center">
                      <mat-button-toggle-group #group="matButtonToggleGroup" name="changeVat" aria-label="Vat" [value]="1" [(ngModel)]="vatButton">
                        <mat-button-toggle [value]="1" mat-button>25%</mat-button-toggle>
                        <mat-button-toggle [value]="2" mat-button>12%</mat-button-toggle>
                        <mat-button-toggle [value]="3" mat-button>6%</mat-button-toggle>
                        <mat-button-toggle [value]="4" mat-button>0%</mat-button-toggle>
                      </mat-button-toggle-group>
                    </div>
                    <div *ngIf="vatButton == 1">
                      <div class="col calcVat">
                        <mat-form-field class="col-6">
                          <mat-label>Belopp utan 25% moms</mat-label>
                          <input matInput type="number" [(ngModel)]="noVat" (ngModelChange)="myVatCalc($event)">
                          <mat-icon matSuffix color="accent">money</mat-icon>
                        </mat-form-field>
                        <mat-form-field class="col-6">
                          <mat-label>Belopp med 25% moms</mat-label>
                          <input matInput type="number" [(ngModel)]="withVat" (ngModelChange)="myNoVatCalc($event)">
                          <mat-icon matSuffix color="accent">money</mat-icon>
                        </mat-form-field>
                      </div>
                    </div>

                    <div *ngIf="vatButton == 2">
                      <div class="col calcVat">
                        <mat-form-field class="col-6">
                          <mat-label>Belopp utan 12% moms</mat-label>
                          <input matInput type="number" [(ngModel)]="noVatMedium" (ngModelChange)="myVatCalcMedium($event)">
                          <mat-icon matSuffix color="accent">money</mat-icon>
                        </mat-form-field>
                        <mat-form-field class="col-6">
                          <mat-label>Belopp med 12% moms</mat-label>
                          <input matInput type="number" [(ngModel)]="withVatMedium" (ngModelChange)="myNoVatCalcMedium($event)">
                          <mat-icon matSuffix color="accent">money</mat-icon>
                        </mat-form-field>
                      </div>
                    </div>

                    <div *ngIf="vatButton == 3">
                      <div class="col calcVat">
                        <mat-form-field class="col-6">
                          <mat-label>Belopp utan 6% moms</mat-label>
                          <input matInput type="number" [(ngModel)]="noVatLow" (ngModelChange)="myVatCalcLow($event)">
                          <mat-icon matSuffix color="accent">money</mat-icon>
                        </mat-form-field>
                        <mat-form-field class="col-6">
                          <mat-label>Belopp med 6% moms</mat-label>
                          <input matInput type="number" [(ngModel)]="withVatLow" (ngModelChange)="myNoVatCalcLow($event)">
                          <mat-icon matSuffix color="accent">money</mat-icon>
                        </mat-form-field>
                      </div>
                    </div>

                    <div *ngIf="vatButton == 4">
                      <div class="col calcVat">
                        <mat-form-field class="col-6">
                          <mat-label>Belopp utan 0% moms</mat-label>
                          <input matInput type="number" [(ngModel)]="noVatLow">
                          <mat-icon matSuffix color="accent">money</mat-icon>
                        </mat-form-field>
                        <mat-form-field class="col-6">
                          <mat-label>Belopp med 0% moms</mat-label>
                          <input matInput type="number" [(ngModel)]="noVatLow">
                          <mat-icon matSuffix color="accent">money</mat-icon>
                        </mat-form-field>
                      </div>
                    </div>
                  </mat-card-content>
                </mat-tab>

              </mat-tab-group>

            </mat-card>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--</div>-->
</div>
<!--If company account on private view (logged in on wrong site)-->
<!--<div *ngIf="appId == 1 && isCompanyUser" class="static-fix">
  <div class="Web" fxShow="false" fxShow.gt-md>
    <h1 class="greeting" *ngIf="user">Välkommen <span color="accent" class="accent">{{user.AccountSettings?.FirstName}}</span>!</h1>
    <div class="container">
      <div *ngIf="!isLoading">
        <div class="container" style="text-align: center;">
          Vi ser att har loggat in med ett företagskonto, välkommen till vår systersida <a class="accent" href="https://app.samekonomi.se">SAMekonomi</a> istället.
        </div>
      </div>
    </div>
  </div>
</div>-->

<!-- ***************************** -->
<!-- DESKTOP VIEW, COMPANY ACCOUNT -->
<!-- ***************************** -->
<!--If company account on company view (logged in on correct site)-->
<div *ngIf="appId == 2" class="static-fix">
  <div class="Web" fxShow="false" fxShow.gt-md>
    <h1 class="greeting" *ngIf="user">Välkommen <span color="accent" class="accent">{{user.AccountSettings.FirstName}}</span>!</h1>
    <div class="container">
      <div *ngIf="!isLoading && (isCompanyAdmin || isAdmin)">
        <div class="container backgroundColor">
          <div class="row">
            <!--Invoice lists -->
            <div class="col-6 MainCardLeft">
              <mat-card [hidden]="invoiceData == 0">
                <mat-card-title>
                  <h4 class="incomeTextHead accent">Skickade 
                    </h4>
                </mat-card-title>
                <mat-card-subtitle>
                  <p class="incomeText">Här är dina senaste fakturor</p>
                </mat-card-subtitle>
                <mat-card-content>
                  <div class="example-container mat-elevation-z0 economy">
                    <mat-table class="myTable" [dataSource]="dataSource">

                      <!-- Invoice number Column -->
                      <ng-container matColumnDef="invoicenumbercomp">
                        <mat-header-cell *matHeaderCellDef fxShow="false" fxShow.gt-sm><span class="accent">Fakturanr</span></mat-header-cell>
                        <mat-cell *matCellDef="let element" fxShow="false" fxShow.gt-sm>
                          <span *ngIf="element?.InvoiceNumber != 0 || element?.InvoiceNumber != null">
                            <a *ngIf="element?.InvoiceDocument?.Url != null" href="{{element?.InvoiceDocument?.Url}}" target="_blank">{{element?.InvoiceNumber}}</a>
                            <span *ngIf="element?.InvoiceDocument?.Url == null">{{element?.InvoiceNumber}}</span>
                          </span>
                        </mat-cell>
                      </ng-container>

                      <!-- Amount Column -->
                      <ng-container matColumnDef="amountcomp">
                        <mat-header-cell *matHeaderCellDef fxShow="false" fxShow.gt-sm><span class="accent">Totalsumma</span></mat-header-cell>
                        <mat-cell *matCellDef="let element" fxShow="false" fxShow.gt-sm style="text-align: right;">
                          <span *ngIf="element.Customer != null">{{element?.TotalInvoiceAmountIncVat | number: '1.2-2'}}</span>
                        </mat-cell>
                      </ng-container>

                      <!-- Customer Column -->
                      <ng-container matColumnDef="invoicestatuscomp">
                        <mat-header-cell *matHeaderCellDef fxShow="false" fxShow.gt-sm><span class="accent">Fakturastatus</span></mat-header-cell>
                        <mat-cell *matCellDef="let element" fxShow="false" fxShow.gt-sm>
                          <span>{{element?.Status?.Name}}</span>
                        </mat-cell>
                      </ng-container>

                      <!-- Customer Column -->
                      <ng-container matColumnDef="paymentduecomp">
                        <mat-header-cell *matHeaderCellDef fxShow="false" fxShow.gt-sm><span class="accent">Förfallodatum</span></mat-header-cell>
                        <mat-cell *matCellDef="let element" fxShow="false" fxShow.gt-sm>
                          <span>{{element?.PaymentDue | date: 'yyyy-MM-dd'}}</span>
                        </mat-cell>
                      </ng-container>

                      <mat-header-row *matHeaderRowDef="displayedColumnsCompany"></mat-header-row>
                      <mat-row *matRowDef="let row; columns: displayedColumnsCompany;"></mat-row>
                    </mat-table>
                  </div>
                </mat-card-content>
              </mat-card>
              <mat-card *ngIf="invoiceData == 0">
                <mat-card-title>
                  <div color="accent">Skickade fakturor</div>
                </mat-card-title>
                <mat-card-subtitle>
                  <p class="incomeText">Här var det tomt, välj <a href="#/combined-invoice-tool"><span class="accent">Ny faktura</span></a> för att skicka en faktura</p>
                </mat-card-subtitle>
                <mat-card-content>
                  <div class="d-flex justify-content-center">
                    <a mat-button mat-raised-button color="accent" href="#/combined-invoice-tool">Ny faktura</a>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
            <!--Messages -->
            <div class="col-6 MainCardLeft">
              <mat-card>
                <mat-card-title>
                  <h4 class="incomeTextHead accent">Meddelanden</h4>
                </mat-card-title>
                <mat-card-subtitle *ngIf="appId == 1">
                  <p class="incomeText">Här visas dina meddelanden från <a href="https://www.sampoolen.se" target="_blank"><span class="accent">SAMpoolen </span></a></p>
                </mat-card-subtitle>
                <mat-card-content>
                  <mat-list>
                    <mat-list-item *ngFor="let message of globalmessages">
                      <mat-icon *ngIf="isNotRead" mat-list-icon (click)="openDialog(message, $event.currentTarget)" class="notread-icon message-pointer">notifications_active</mat-icon>
                      <mat-icon *ngIf="isRead" mat-list-icon (click)="openDialog(message, $event.currentTarget)" class="isread-icon message-pointer">check</mat-icon>
                      <h4 mat-line (click)="openDialog(message, $event.currentTarget)" class="message-pointer">{{message.Headline}}</h4>
                      <p mat-line (click)="openDialog(message, $event.currentTarget)" class="message-pointer">{{message.Created | date: 'yyyy-MM-dd'}}</p>
                    </mat-list-item>
                  </mat-list>
                </mat-card-content>
              </mat-card>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-2">

        </div>
        <div class="col-8" *ngIf="!isLoading && !isCompanyAdmin && !isAdmin">
          Hej! <br />
          Vi har byggt om lite på SAMekonomi och lagt till nya funktioner för att underlätta för dig som skickar fakturor via oss. <br />
          Vi ser att denna inloggning behöver kopplas till ett nytt företagskonto för att du kunna använda våra tjänster.<br />
          <br />
          Kontakta oss på <a href="Mailto:info@samekonomi.se">info@samekonomi.se</a> så hjälper vi dig.<br />

        </div>
      </div>
    </div>
  </div>
</div>
<!--If company account on private view (logged in on wrong site)-->
<!--<div *ngIf="appId == 2 && !isCompanyUser && !isAdmin" class="static-fix">
  <div class="Web" fxShow="false" fxShow.gt-md>
    <h1 class="greeting" *ngIf="user">Välkommen <span color="accent" class="accent">{{user.AccountSettings?.FirstName}}</span>!</h1>
    <div class="container">
      <div *ngIf="!isLoading">
        <div class="container" style="text-align: center;">
          
        </div>
      </div>
    </div>
  </div>
</div>-->

<!-- **************************** -->
<!-- MOBILE VIEW, COMPANY ACCOUNT -->
<!-- **************************** -->
<!--If company account on company view (logged in on correct site)-->
<div *ngIf="appId == 2" class="static-fix">
  <div class="Mobile">
    <div *ngIf="!isLoading">
      <h1 class="greeting" *ngIf="user">Välkommen <span class="accent">{{user.AccountSettings.CompanyName}}</span>!</h1>
      <div class="container">
        <div class="row">
          <!--Income -->
          <div class="col-12 MainCardLeft">
            <mat-card [hidden]="invoiceData == 0">
              <mat-card-title>
                <h4 class="incomeTextHead accent">Skickade fakturor</h4>
              </mat-card-title>
              <mat-card-subtitle>
                <p class="incomeText"><span class="accent">Här ser du dina senaste fakturor</span></p>
              </mat-card-subtitle>
              <mat-card-content>
                <div class="example-container mat-elevation-z0 economy">
                  <mat-table class="myTable" [class.isMobile]="isMobile" [dataSource]="dataSource">

                    <!-- Invoice number Column -->
                    <ng-container matColumnDef="invoicenumbercomp">
                      <mat-header-cell *matHeaderCellDef fxShow="false" fxShow.gt-sm><span class="accent">Fa.nr</span></mat-header-cell>
                      <mat-cell *matCellDef="let element" fxShow="false" fxShow.gt-sm>
                        <span>{{element?.InvoiceNumber}}</span>
                      </mat-cell>
                    </ng-container>

                    <!-- Amount Column -->
                    <ng-container matColumnDef="amountcomp">
                      <mat-header-cell *matHeaderCellDef fxShow="false" fxShow.gt-sm><span class="accent">Totalsumma</span></mat-header-cell>
                      <mat-cell *matCellDef="let element" fxShow="false" fxShow.gt-sm style="text-align: right;">
                        <span *ngIf="element.Customer != null">{{element?.TotalInvoiceAmountIncVat | number: '1.2-2'}}</span>
                      </mat-cell>
                    </ng-container>

                    <!-- Customer Column -->
                    <ng-container matColumnDef="invoicestatuscomp">
                      <mat-header-cell *matHeaderCellDef fxShow="false" fxShow.gt-sm><span class="accent">Fa.status</span></mat-header-cell>
                      <mat-cell *matCellDef="let element" fxShow="false" fxShow.gt-sm>
                        <span>{{element?.Status?.Name}}</span>
                      </mat-cell>
                    </ng-container>

                    <!-- Customer Column -->
                    <ng-container matColumnDef="paymentduecomp">
                      <mat-header-cell *matHeaderCellDef fxShow="false" fxShow.gt-sm><span class="accent">Förfallodatum</span></mat-header-cell>
                      <mat-cell *matCellDef="let element" fxShow="false" fxShow.gt-sm>
                        <span>{{element?.PaymentDue | date: 'yyyy-MM-dd'}}</span>
                      </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumnsCompany"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumnsCompany;"></mat-row>
                  </mat-table>
                </div>
              </mat-card-content>
            </mat-card>
            <mat-card *ngIf="invoiceData == 0">
              <mat-card-title>
                <h4 class="incomeTextHead accent">Skickade fakturor</h4>
              </mat-card-title>
              <mat-card-subtitle>
                <p class="incomeText">Här var det tomt, välj <a href="#/combined-invoice-tool"><span class="accent">Ny faktura</span></a> för att skicka en faktura</p>
              </mat-card-subtitle>
              <mat-card-content>
                <div class="d-flex justify-content-center">
                  <a mat-button mat-raised-button color="accent" href="#/combined-invoice-tool">Ny faktura</a>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
          <mat-divider class="devider" [inset]="true"></mat-divider>
          <!--Messages -->
          <div *ngIf="!isAdmin" class="col-12 MainCardLeft">
            <mat-card>
              <mat-card-title>
                <h4 class="incomeTextHead accent">Meddelanden</h4>
              </mat-card-title>
              <mat-card-subtitle>
                <p class="incomeText">Här visas dina meddelanden från <a href="https://www.sampoolen.se" target="_blank"><span class="accent">SAMpoolen</span></a></p>
              </mat-card-subtitle>
              <mat-card-content>
                <mat-list>
                  <mat-list-item *ngFor="let message of globalmessages">
                    <mat-icon *ngIf="isNotRead" mat-list-icon (click)="openDialog(message, $event.currentTarget)" class="notread-icon message-pointer">notifications_active</mat-icon>
                    <mat-icon *ngIf="isRead" mat-list-icon (click)="openDialog(message, $event.currentTarget)" class="isread-icon message-pointer">check</mat-icon>
                    <h4 mat-line (click)="openDialog(message, $event.currentTarget)" class="message-pointer">{{message.Headline}}</h4>
                    <p mat-line (click)="openDialog(message, $event.currentTarget)" class="message-pointer">{{message.Created | date: 'yyyy-MM-dd'}}</p>
                  </mat-list-item>
                </mat-list>
              </mat-card-content>
            </mat-card>
          </div>
          <div *ngIf="isAdmin" class="col-12 MainCardLeft">
            <mat-card>
              <mat-card-title>
                <h4 class="incomeTextHead accent">Meddelanden</h4>
              </mat-card-title>
              <mat-card-subtitle>
                <p class="incomeText">Här kommer meddelanden från medlemmar att visas</p>
              </mat-card-subtitle>
              <mat-card-content>
                <mat-list>
                  <mat-list-item *ngFor="let message of globalmessages">
                    <mat-icon *ngIf="isNotRead" mat-list-icon (click)="openDialog(message, $event.currentTarget)" class="notread-icon message-pointer">notifications_active</mat-icon>
                    <mat-icon *ngIf="isRead" mat-list-icon (click)="openDialog(message, $event.currentTarget)" class="isread-icon message-pointer">check</mat-icon>
                    <h4 mat-line (click)="openDialog(message, $event.currentTarget)" class="message-pointer">{{message.Headline}}</h4>
                    <p mat-line (click)="openDialog(message, $event.currentTarget)" class="message-pointer">{{message.Created | date: 'yyyy-MM-dd'}}</p>
                  </mat-list-item>
                </mat-list>
              </mat-card-content>
            </mat-card>
          </div>
          <mat-divider class="devider" [inset]="true"></mat-divider>
        </div>
      </div>
    </div>
  </div>
</div>
<!--If private account on company view (logged in on wrong site)-->
<!--<div *ngIf="appId == 2 && !isCompanyUser && !isAdmin" class="static-fix">
  <div class="Mobile">
    <div *ngIf="!isLoading">
      <h1 class="greeting" *ngIf="user">Välkommen <span class="accent">{{user.AccountSettings.FirstName}}</span>!</h1>
      <div class="container" style="text-align: center;">
        Vi ser att har loggat in med ett personligt konto, välkommen till vår systersida <a class="accent" href="https://app.sampoolen.se">SAMpoolen</a> istället.
      </div>
    </div>
  </div>
</div>-->
