import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { ICompany } from '../interfaces/ICompany';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment as env } from '@env/environment';
import { HttpHeaders } from '@angular/common/http';
import { HttpErrorHandler, HandleError } from './http-error-handler';
import { tap } from "rxjs/internal/operators/tap";
import { AuthGuardService } from '../auth/auth-guard.service';
import { Store } from '@ngrx/store';
import { AuthenticationService } from '../services/AuthenticationService';


@Injectable()
export class TransactionLogService {
    public apiAuthUrl: string;
    private apiBaseUrl: string;
    public token: string;
    private userString: string;
    private httpOptions: any;
    private handleError: HandleError;
    
    constructor(
        private http: HttpClient,
        private router: Router,
        private authenticationService: AuthenticationService,
        httpErrorHandler: HttpErrorHandler,
        private route: ActivatedRoute)
    {
        this.apiBaseUrl = env.hostApi;
        this.setHttpOptions();
    }
    
  getTransactionLogs(): Observable<any> {

        var url = this.apiBaseUrl + 'TransactionLog';
        
        return this.http.get<any>(url, this.httpOptions)
            .pipe(
            tap((response: any) => console.log(response)),
            catchError(
                (err: any) => {
                    console.log(err)
                    return new Observable<any>();
                }
            ));
  }

  getPayoutTransactionLog(id: number) { 

    var url = this.apiBaseUrl + 'PayoutTransactionLog/' + id;

    return this.http.get<any>(url, this.httpOptions)
      .pipe(
        tap((response: any) => console.log(response)),
        catchError(
          (err: any) => {
            console.log(err)
            return new Observable<any>();
          }
        ));
  }

  getTransactionPayoutLogs(skip: number = 0, take: number = 10, startDate: Date = null, endDate: Date = null, query: string): Observable<any> {
    
    var url = this.apiBaseUrl + 'TransactionPayoutLog?from=' + startDate + '&to=' + endDate;

    return this.http.get<any>(url, this.httpOptions)
      .pipe(
        tap((response: any) => console.log(response)),
        catchError(
          (err: any) => {
            console.log(err)
            return new Observable<any>();
          }
        ));
  }

  getAllExecutedTransactions(skip: number = 0, take: number = 10, startDate: Date = null, endDate: Date = null, query: string): Observable<any> {

    var url = this.apiBaseUrl + 'ExecutedTransactions?from=' + startDate + '&to=' + endDate;

    return this.http.get<any>(url, this.httpOptions)
      .pipe(
        tap((response: any) => console.log(response)),
        catchError(
          (err: any) => {
            console.log(err)
            return new Observable<any>();
          }
        ));
  }

  HasUserPensionSavings(id: number): Observable<any> {

    var url = this.apiBaseUrl + 'HasUserPensionSavings/' + id;

    return this.http.get<any>(url, this.httpOptions)
      .pipe(
        tap((response: any) => console.log(response)),
        catchError(
          (err: any) => {
            console.log(err)
            return new Observable<any>();
          }
        ));
  }

  getLastExecutedTransactionByUserId(userId: string): Observable<any> {

    var url = this.apiBaseUrl + 'LastExecutedPayoutTransLog?userid=' + userId;

    return this.http.get<any>(url, this.httpOptions)
      .pipe(
        //tap((response: any) => console.log(response)),
        catchError(
          (err: any) => {
            console.log(err)
            return new Observable<any>();
          }
        ));
  }

  deleteTransactionLog(id: number) {
        var url = this.apiBaseUrl + 'TransactionLog/' + id; 
        return this.http.delete(url, this.httpOptions)
            .pipe(
                tap((response: any) => console.log(response)),
                catchError(
                    (err: any) => {
                        console.log(err)
                        return new Observable<any>();
                    }
                ));
    }
    
  setHttpOptions() {
        var bearerToken = 'bearer ' + this.authenticationService.token;

        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': bearerToken
            })
        };
    }
}

