import { NgModule, Optional, SkipSelf, ɵinitServicesIfNeeded } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MetaReducer, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { storeFreeze } from 'ngrx-store-freeze';
import { environment } from '@env/environment';
import { debug } from './meta-reducers/debug.reducer';
import { initStateFromLocalStorage } from './meta-reducers/init-state-from-local-storage.reducer';
import { LocalStorageService } from './local-storage/local-storage.service';
import { authReducer } from './auth/auth.reducer';
import { AuthEffects } from './auth/auth.effects';
import { AuthGuardService } from './auth/auth-guard.service';
import { CanDeactivateGuard } from './guards/CanDeactivate.Component';
import { AnimationsService } from './animations/animations.service';
import { HttpErrorHandler } from './services/http-error-handler';
import { MessageService } from './services/message';
import { CompanyService } from './services/CompanyService';
import { ChatService } from './services/ChatService';
import { ReportService } from './services/ReportService';
import { InvoiceService } from './services/InvoiceService';
import { AllowanceService } from './services/AllowanceService';
import { OrganizationService } from './services/OrganizationService';
import { BankService } from './services/BankService';
import { CurrencyService } from './services/CurrencyService';
import { UserService } from './services/UserService';
import { TransactionLogService } from './services/TransactionLogService';
import { MunicipalityService } from './services/municipalityService';
import { AuthenticationService } from './services/AuthenticationService';
import { AutoLogoutService } from './services/AutoLogoutService';
import { MatDialogModule } from '@angular/material';
import { SwedishDateAdapter } from './adapters/SwedishDateAdapter';
import { MatPaginatorIntl } from '@angular/material';
import { CustomHttpInterceptorService } from './services/HttpInterceptorService';
import { getSwedishPaginatorIntl } from '../core/adapters/swedish-paginator-intl';
import { EmployerTaxService } from './services/EmployerTaxService';
import { SalaryTypeService } from './services/SalaryTypeService';
import { GlobalMessageService } from './services/GlobalMessageService';
import { FileService } from './services/FileService';
import { FeeService } from './services/FeeService';
import { TruncatePipe } from './pipes/truncate';
import { DeviceDetectorModule } from 'ngx-device-detector';

export const metaReducers: MetaReducer<any>[] = [initStateFromLocalStorage];

if (!environment.production) {
  metaReducers.unshift(debug, storeFreeze);
}

@NgModule({
  imports: [
    // angular
    CommonModule,
    HttpClientModule,

    // Material
    MatDialogModule,

    DeviceDetectorModule.forRoot(),

    // ngrx
    StoreModule.forRoot(
      {
        auth: authReducer
      },
      { metaReducers }
    ),
    EffectsModule.forRoot([AuthEffects])
  ],
  declarations: [
  ],
  providers: [
      LocalStorageService,
      AuthGuardService,
      CanDeactivateGuard,
      AnimationsService, 
      HttpErrorHandler,
      MessageService,
      CompanyService,
      ChatService,
      ReportService,
      InvoiceService,
      AllowanceService,
      OrganizationService,
      BankService,
      CurrencyService,
      AuthenticationService,
      AutoLogoutService,
      UserService,
      TransactionLogService,
      MunicipalityService,
      EmployerTaxService,
      SalaryTypeService,
      FeeService,
      GlobalMessageService,
      FileService,
      { provide: MatPaginatorIntl, useValue: getSwedishPaginatorIntl() },
      {
          provide: HTTP_INTERCEPTORS,
          useClass: CustomHttpInterceptorService,
          multi: true
      }
  ]
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import only in AppModule');
    }
  }
}
