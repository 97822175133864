import { Component, OnInit, ElementRef } from '@angular/core';
import { environment as env } from '@env/environment';
import { ROUTE_ANIMATIONS_ELEMENTS } from '@app/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpModule, Headers, RequestOptions } from '@angular/http';
import { AuthenticationService, User, PasswordReset } from "../../core/services/AuthenticationService"
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
//import { AppConfig } from '../config/app.config';

@Component({
  selector: 'reset-password',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  public user = {} as User;
  passwordReset: PasswordReset = <PasswordReset>{};
  routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS;
  versions = env.versions;
  public errorMsg = '';
  loading = false;
  returnUrl: string;
  message: string = 'Felaktigt lösenord!';
  actionButtonLabel: string = 'Retry';
  action: boolean = false;
  setAutoHide: boolean = true;
  autoHide: number = 10000;

  constructor(
      private authenticationService: AuthenticationService,
      private router: Router,
      private route: ActivatedRoute,
      public snackBar: MatSnackBar
  ) {}
  
  ngOnInit() {
      this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
      this.passwordReset.Code = this.route.snapshot.queryParams['code'] || undefined;
      this.user.Email = this.route.snapshot.queryParams['email'] || undefined;
      this.passwordReset.Email = this.route.snapshot.queryParams['email'] || undefined;
  }

  onSubmit() {
      this.reset();
  }

  validatePasswordReset()
  {
      var validates = this.passwordReset.Password == this.passwordReset.ConfirmPassword && this.passwordReset.ConfirmPassword != undefined;

      console.log(validates);

      return validates;
  }
  
  reset() {
      
      this.loading = true;
      
      if (this.passwordReset.Code != undefined) {

          this.authenticationService.resetPassword(this.passwordReset)
              .subscribe(
              data => {
                  this.router.navigateByUrl("/");
              },
              error => {
                  this.loading = false;
              });
      }
      else
      {
        this.authenticationService.requestResetPassword(this.user, env.appId)
              .subscribe(
              data => {

                  if (data == null)
                  {
                      this.router.navigateByUrl(this.returnUrl);
                  }
                  else
                  {
                      this.user.Email = undefined;
                  }
                  
                  this.loading = false;
              },
              error => {
                  this.loading = false;
              });
      }
  }

  openLink(link: string) {
    window.open(link, '_blank');
  }
}
