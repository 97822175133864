import { Component, Inject, ViewChild, TemplateRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDatepicker, MatDatepickerToggle } from '@angular/material';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Http, RequestOptions, Headers, Response } from '@angular/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../../core/services/AuthenticationService'
import { AppConfig } from '../../config/app.config';
import { ICompany } from '../../core/interfaces/ICompany';
import { IFile } from '../../core/interfaces/IFile';
import { CompanyService } from '../../core/services/CompanyService';
import { InvoiceService } from '../../core/services/InvoiceService';
import { EditCustomerDialogComponent } from '../../shared/customer/EditCustomerDialog.Component';
import { TransactionLogService } from '../../core/services/TransactionLogService';
import { ITransactionLog } from '../../core/interfaces/invoice/ITransactionLog';
import { Subscription } from 'rxjs';


@Component({
  selector: 'invoice-work-cert-dialog',
  templateUrl: 'invoice-work-cert.Dialog.Component.html'
})


export class InvoiceWorkCertificateDialogComponent {
  private _dimesionToggle = false;
  private _formValid = false;
  public _imageList: any[] = [];
  public _hasImages = false;
  private _showImageList = false;
  private _showAttachments = false;
  private _hasAttachments = false;
  private _showComments = false;
  public customer: ICompany;
  public isNewCustomer: boolean;
  @ViewChild('myInput', { static: false })
  myInputVariable: any;
  private _apiBaseUrl: string;
  private hoursList = [];
  private vatList = [];
  private rowTypeList = [];
  private hoursSelected: string = "8";
  private vatSelected: string = "25";
  private rowTypeSelected: string = "0"
  private row: any = {};
  EnrollmentDate = null;
  downloading = false;
  totalInvoiceHours = 0;
  totalDaysWorked = 0;
  grossSalary = 0;
  averageHourlySalary = 0;
  datesGroupedByMonth: { [monthYear: string]: MonthData } = {};
  payoutInfo: ITransactionLog;
  firstDate: any;
  lastDate: any;
  errorMessage: string;
  private payoutSubscription: Subscription;
  workingTitles: string = '';

    constructor(
    private router: Router,
    private route: ActivatedRoute,
    public dialogRef: MatDialogRef<InvoiceWorkCertificateDialogComponent>,
    private http: Http,
    private authenticationService: AuthenticationService,
    private invoiceService: InvoiceService,
    private companyService: CompanyService,
    private transactionLogService: TransactionLogService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

  }

  ngOnInit() {

    this.data.DeductionsTotalAmount = 0;
    if (this.data.Deductions != undefined) {
      for (var i = 0; i < this.data.Deductions.length; i++) {
        this.data.DeductionsTotalAmount += this.data.Deductions[i].Amount;
      }
    }

    if (this.data.InvoiceDocument == null) {
      this.data.InvoiceDocument = { Guid: null, Url: null };
    }

    this.getPayoutInfo();
    this.getAllWorkingTitles();

  }
 
  getPayoutInfo() {
    this.payoutSubscription = this.transactionLogService.getPayoutTransactionLog(this.data.Id)
      .subscribe(
        (response: ITransactionLog) => {
          this.payoutInfo = response;
          this.onGetPayoutInfoCompleted();
        },
        (error) => {
          console.error("Error fetching payout transaction log:", error);
        }
      );
  }

  onGetPayoutInfoCompleted() {

    if (this.payoutInfo == undefined) {
      this.errorMessage = "Denna faktura saknar transaktionsrad för utbetalning. Du behöver spara utbetalningen i in/ut"
    }
    this.getInvoiceTotalHours();
    this.getAverageHourlySalary();
    this.getInvoiceTotalDaysWorked();
    this.aggregateGroupAndSortDates();    
  }
  
  aggregateGroupAndSortDates(): void {
    const allDates = [];

    // Extract all dates and additional details
    this.data.InvoiceGroups.forEach(group => {
      group.InvoiceRows.forEach(row => {
        allDates.push({
          date: new Date(row.StartDate),
          hoursWorked: row.HoursWorked,
          minutesWorked: row.MinutesWorked,
          dateString: row.StartDate // Keep the original date string for display
        });
      });
    });

    // Sort dates
    allDates.sort((a, b) => a.date - b.date);

    // Group by month and year
    allDates.forEach(dateObj => {
      const monthYearKey = `${dateObj.date.getFullYear()}-${dateObj.date.getMonth() + 1}`;
      if (!this.datesGroupedByMonth[monthYearKey]) {
        this.datesGroupedByMonth[monthYearKey] = {
          dates: [],
          totalHoursWorked: 0
        };
      }
      this.datesGroupedByMonth[monthYearKey].dates.push(dateObj);
      this.datesGroupedByMonth[monthYearKey].totalHoursWorked += dateObj.hoursWorked + dateObj.minutesWorked / 60; // Convert minutes to hours
    });

    this.firstDate = allDates[0].dateString;
    this.lastDate = allDates[allDates.length - 1].dateString;
  }

  getAllWorkingTitles() { debugger
      this.data.InvoiceGroups.forEach(group => {      
          if(group.WorkingTitle) {
              if(this.workingTitles) {
                  this.workingTitles += ", ";
              }
              this.workingTitles += group.WorkingTitle;    
          }
      });
  }

  
  getGroupTotalHours(group: any) {
    var totalMinutes = 0;

    for (var i = 0; i < group.InvoiceRows.length; i++) {
      totalMinutes += parseInt(group.InvoiceRows[i].HoursWorked) * 60;

      if (group.InvoiceRows[i].MinutesWorked != null)
        totalMinutes += parseInt(group.InvoiceRows[i].MinutesWorked);
    }

    return this.time_convert(totalMinutes);
  }

  getInvoiceTotalHours() {

    //Iterera alla invoiceGroups
    //För varja grupp --> Iterera alla invoiceRows
    var totalInvoiceMinutes = 0;

    var invoice = this.data;

    for (var g = 0; g < invoice.InvoiceGroups.length; g++) {

      for (var i = 0; i < invoice.InvoiceGroups[g].InvoiceRows.length; i++) {
        var currentRow = invoice.InvoiceGroups[g].InvoiceRows[i];

        totalInvoiceMinutes += parseInt(currentRow.HoursWorked) * 60;

        if (currentRow.MinutesWorked != null)
          totalInvoiceMinutes += parseInt(currentRow.MinutesWorked);
      }

    }

    this.totalInvoiceHours = totalInvoiceMinutes / 60; 
  }

  getAverageHourlySalary() { 

    this.grossSalary = this.payoutInfo.GrossSalary;
    
    if (this.totalInvoiceHours == 0) {
      this.averageHourlySalary = 0;
      this.errorMessage = "Denna faktura saknar arbetade timmar."; 
      return;
    }

    this.averageHourlySalary = Math.round((this.grossSalary / this.totalInvoiceHours) * 100) / 100;
  }

  getInvoiceTotalDaysWorked() {

    var invoice = this.data;

    for (var i = 0; i < invoice.InvoiceGroups.length; i++) {
      this.totalDaysWorked += invoice.InvoiceGroups[i].InvoiceRows.length;
    }    
  }

  time_convert(num) {
    var hours = Math.floor(num / 60);
    var minutes = num % 60;

    if (minutes > 0) {
      return hours + " h " + minutes + " min";
    }
    else {
      return hours + " h";
    }

  }

  toFormattedDate(iso: string) {

    const date = new Date(iso);

    var d = `${date.getFullYear()}-${(date.getMonth() + 1).toString().length == 1 ? ('0' + (date.getMonth() + 1)) : date.getMonth() + 1}-${date.getDate().toString().length == 1 ? ("0" + date.getDate().toString()) : date.getDate().toString()}`;

    if (d == '1970-01-01')
      return undefined;

    return d;
  }

  objectKeys(obj: object): string[] {
    //Used for the aggregated dates
    return Object.keys(obj);
  }

  //getRowType(id: any) {
  //  for (var i = 0; i < this.rowTypeList.length; i++) {
  //    if (this.rowTypeList[i].Name == id) {
  //      return this.rowTypeList[i];
  //    }
  //  }
  //}

  //printAllowances() {
  //  alert();
  //}  

  //onDataChange(newdate) {

  //  //const _ = moment();
  //  //const date = moment(newdate).add({ hours: _.hour(), minutes: _.minute(), seconds: _.second() })
  //  //this.date = date.toDate();
  //  //console.log({ hours: _.hour(), minutes: _.minute(), seconds: _.second() })
  //}

  //onDateChange(evt: any) {
  //  console.log(evt);

  //  //var d = this.datePipe.transform(evt.value, "yyyy-MM-dd");

  //}

  //setDate(date: any) {
  //  this.data.InvoiceSentByUser = date;
  //  console.log(date);
  //}

  //rowTypeChange(event: any) {
  //  this.data.invoiceItemGroup.Type = this.getRowType(event.value);

  //  if (event.value == "Övrigt") {
  //    // No rows allowed in the misc category
  //    this.data.invoiceItemGroup.InvoiceRows = [];
  //  }
  //}

  //save(invoice: any) {

  //    console.log(this.data.InvoiceSentByUser);

  //    this.invoiceService.updateInvoiceSummary(invoice)
  //        .subscribe(
  //        response => this.onSaved(response),
  //        error => console.log(error));
  //}

  //onSaved(response: any) {
  //  this.dialogRef.close(this.data);
  //}

  //uploadFile(event: any, file: any)
  //{

  //    if (event.target.files.length > 0) {

  //        let fileList: FileList = event.target.files;
  //        let f: File = fileList[0];
  //        file.Guid = this.newGuid();
  //        file.Url = null;

  //        this.invoiceService.uploadDocument(f, file.Guid)
  //            .subscribe(
  //            response => this.fileUploaded(response, file),
  //            error => this.fileUploadError(error, file));

  //    }
  //}

  //fileUploaded(response: any, file: any) {

  //    file.Guid = response.Guid;
  //    file.Url = response.Url;
  //}

  //fileUploadError(error: any, file: any) {
  //    file.Guid = null;
  //    alert('Ett fel inträffade, försök igen!');
  //}

  //saveCalendar()
  //{
  //    this.dialogRef.close(this.data.invoiceItemGroup);
  //}

  //saveRow() {
  //    this.dialogRef.close(this.data.invoiceItemGroup);
  //}

  //deleteRow()
  //{
  //    this.data.invoiceItemGroup.action = 'delete';
  //    this.dialogRef.close(this.data.invoiceItemGroup);
  //}    

  //cancel() {
  //  this.dialogRef.close(undefined);
  //}

  //deleteListItem(item: any)
  //{
  //    this.data.invoiceItemGroup.InvoiceRows.splice(this.data.invoiceItemGroup.InvoiceRows.indexOf(item), 1);
  //}

  //guid() {  
  //    return this.s4() + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' + this.s4() + this.s4() + this.s4();
  //}

  //s4() {
  //    return Math.floor((1 + Math.random()) * 0x10000)
  //        .toString(16)
  //        .substring(1);
  //}

  //calendarSelect(event: any)
  //{
  //    var date = event.toLocaleDateString();
  //    this.data.invoiceItemGroup.InvoiceRows.push({ StartDate: date, Id: this.guid(), HoursWorked: this.hoursSelected, Vat: this.vatSelected });
  //}

  //onError(error: any)
  //{
  //    alert('An error occured');
  //    console.log(error);
  //}

  //showDocument(url: string) {
  //    window.open(url);
  //}

  //newGuid() {
  //    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
  //        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
  //        return v.toString(16);
  //    });
  //}


  config = {
    disableClose: false,
    panelClass: 'full-screen-modal',
    hasBackdrop: true,
    backdropClass: '',
    width: '500px',
    maxWidth: '100vw',
    height: '100%',
    position: {
      top: '',
      bottom: '',
      left: '',
      right: ''
    },
    data: {
      invoiceItemGroup: {},
      customerTemplate: {},
      customer: {},
      contact: {},
      companyId: 0
      ////status: {},
      //deduction: {} as IDeduction,
      //allowance: {} as IAllowance
    },
    dataTemplate: {
      Id: 0,
      Country: { Id: 1 },
      Type: {},
      HoursWorked: undefined,
      StartDate: undefined,
      EndDate: undefined,
      Comment: undefined,
      InvoiceId: 0
    }
  };
}

interface MonthData {
  dates: any[]; // Array to hold dates
  totalHoursWorked: number; // Total hours worked for the month
}
