
<style>


  .main-heading
  {
    margin-left: 10px;
    margin-bottom: 20px;
  }

  .sp-form-field
  {
    margin-bottom: 30px;
  }
  .sp-form-field-label {
    margin-bottom: 15px;
    font-weight: 600;
  }
  
  .right {
    float: right;
  }
  .description
  {
    margin-bottom: 20px;
  }
  .warning
  {
    color: red;
  }

  mat-form-field
  {
    width: 100%;
  }
</style>


<!--<h1 mat-dialog-title *ngIf="!isNewCustomer" style="margin-top: 5px;margin-bottom: 35px;">Redigera kund</h1>-->

  <form name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
  <div class="">
    <div class="row">
      <div class="col-md-12">
        <div class="mat-headline">Utmätning av lön</div>
              
        <div class="row">          
          <div class="col-12" style="margin-top: 20px;">  
            <div class="col-sm-12 sp-form-field">
              <div class="sp-form-field-label">Ladda upp PDF-dokument</div>
              <input *ngIf="attachmentOfSalary.Document.Guid == null" type="file" mat-stroked-button mat-raised-button (change)="fileChange($event,attachmentOfSalary.Document)" accept=".pdf,.doc,.docx,.jpeg,.jpg,.png,.gif">
              <a *ngIf="attachmentOfSalary.Document.Guid != null" class="col-sm-8" mat-stroked-button color="secondary" (click)="showFile(attachmentOfSalary.Document)"><mat-icon>open_in_new</mat-icon>&nbsp; visa dokument</a>&nbsp;
              <a *ngIf="attachmentOfSalary.Document.Guid != null" class="warning right col-sm-3" mat-stroked-button color="secondary" (click)="deleteFile(attachmentOfSalary.Document)">radera</a>&nbsp;
            </div>
          </div>
        </div>
        
        <div class="col-12">

          <div class="row">
            <div class="col-12">
              <h3>Admin</h3>
            </div>

            <!-- Startdatum -->
            <div class="col-6">
              <mat-form-field>
                <input required matInput [matDatepicker]="pickerStartDate" [ngModel]="attachmentOfSalary.DateStart" (dateChange)="attachmentOfSalary.DateStart = toFormattedDate($event.value)" name="pickerStartDate" id="pickerStartDate" placeholder="Startdatum">
                <mat-datepicker-toggle matSuffix [for]="pickerStartDate"></mat-datepicker-toggle>
                <mat-datepicker #pickerStartDate></mat-datepicker>
              </mat-form-field>
            </div>

            <!-- Slutdatum -->
            <div class="col-6">
              <mat-form-field>
                <input matInput [matDatepicker]="pickerEndDate" [ngModel]="attachmentOfSalary.DateEnd" (dateChange)="attachmentOfSalary.DateEnd = toFormattedDate($event.value)" name="pickerEndDate" id="pickerEndDate" placeholder="Slutdatum">
                <mat-datepicker-toggle matSuffix [for]="pickerEndDate"></mat-datepicker-toggle>
                <mat-datepicker #pickerEndDate></mat-datepicker>
              </mat-form-field>
            </div>

            <!-- Reserverat belopp -->
            <div class="col-6">
              <mat-form-field>
                <input type="text" matInput [ngModel]="attachmentOfSalary.ReservedAmount" (ngModelChange)="attachmentOfSalary.ReservedAmount = $event" md-maxlength="50" placeholder="Reserverat belopp" id="amount" name="reservedamount" />
              </mat-form-field>
            </div>

            <!-- Utmätt belopp -->
            <div class="col-6">
              <mat-form-field>
                <input type="number" step="0.01" matInput [ngModel]="attachmentOfSalary.AttachmentAmount" (ngModelChange)="attachmentOfSalary.AttachmentAmount = $event" md-maxlength="50" placeholder="Utmätt belopp" id="temptax" name="attamount" />
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="col-sm-12 sp-form-field" style="margin-top: 40px;">
          <button mat-flat-button [disabled]="!(calculateAddButtonValid() && f.form.valid)" color="accent" class="right" style="margin-left: 10px;">Lägg till</button>
          <a mat-stroked-button (click)="cancel()" color="secondary" class="right" style="margin-left:10px;">Avbryt</a>
        </div>
      </div>
        </div>
    </div>

</form>

