import { Component, Inject, ViewChild, TemplateRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDatepicker, MatDatepickerToggle, NativeDateAdapter } from '@angular/material';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Http, RequestOptions, Headers, Response } from '@angular/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../../core/services/AuthenticationService'
import { AppConfig } from '../../config/app.config';
import { IAllowance } from '../../../core/interfaces/invoice/IAllowance';
import { IFile } from '../../../core/interfaces/IFile';
import { ITimeReportStatus } from '../../core/interfaces/invoice/TimeReportStatus';
import { InvoiceService } from '../../../core/services/InvoiceService';
import { CompanyService } from '../../core/services/CompanyService';
import { AllowanceService } from '../../../core/services/AllowanceService';
import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { start } from 'repl';

@Component({
    selector: 'edit-allowance-dialog',
    templateUrl: 'edit-allowance-Dialog.Component.html'
})

export class EditAllowanceDialogComponent {
    private _dimesionToggle = false;
    private _formValid = false;
    public _imageList: any[] = [];
    public _hasImages = false;
    private _showImageList = false;
    private _showAttachments = false;
    private _hasAttachments = false;
    private _showComments = false;
    public isNewCustomer: boolean;
    @ViewChild('myInput', { static: false })
    myInputVariable: any;
    private _apiBaseUrl: string;
    @ViewChild("datepicker", { static: false }) datepicker: MatDatepicker<Date>;
    selectedDate: Date = new Date();
    private hoursList = [];
    private minutesList = [];
    public rowTypeList = [];
    private hoursSelected: string = "8";
    private minutesSelected: string = "0";
    private rowTypeSelected: string = "0"
    private row: any = {};
    private selectedDates = [];
    private AddCustomReimbursementPerKm: boolean = false;
    
    vatList = [{ Value: "25" }, { Value: "12" }, { Value: "6" }, { Value: "0" }];

    allowanceTypes = [  { Id: "1", Name: 'Milersättning' },
                        { Id: "2", Name: 'Traktamente inrikes' },
                        { Id: "3", Name: 'Traktamente utrikes' }];
    
    countries = [];
    hours = [];
    minutes = [];
    DailyAllowance = 0;
    sumMeals = 0;
    sumAllowance = 0;
    sum = 0;
    allowance: IAllowance = <IAllowance>{};
    HalfDays = 0;
    FullDays = 0;
    Difference_In_Days = 0;
    _startDate: Date;
    _endDate: Date;

    constructor(
        public dialogRef: MatDialogRef<EditAllowanceDialogComponent>,
        //private http: Http,
        //private authenticationService: AuthenticationService,
        private invoiceService: InvoiceService,
        private allowanceService: AllowanceService,
        //private companyService: CompanyService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) 
    { 
        
    }

    ngOnInit() {

        this.loadCountries();
        this.allowance = JSON.parse(JSON.stringify(this.data.allowance));
        
        if (this.allowance.AllowanceType != null)
        this.allowance.AllowanceType.Id = this.allowance.AllowanceType.Id.toString();

        if (this.allowance.AllowanceType == null) {
            this.allowance.AllowanceType = {} as IAllowance;
            this.allowance.AllowanceType.Id = "0";
        }
        
        for (var i = 0; i < 24; i++)
        {
            var hour = i.toString().length > 1 ? i.toString() : "0" + i.toString();
            this.hours.push({ Id: i, Name: hour });
        }
        
        this.minutes.push({ Id: 0, Name: "00" });
        this.minutes.push({ Id: 15, Name: "15" });
        this.minutes.push({ Id: 30, Name: "30" });
        this.minutes.push({ Id: 45, Name: "45" });

        this.allowance.ReimbursementPerKm = 2.5;

        this.formUpdated();

    }

    formUpdated()
    {
        // default year
        var year = new Date().getFullYear();

        // Select year if startdate set
        if (this.allowance.TravelDateStart != null) {

            var date = new Date(this.allowance.TravelDateStart);
            year = date.getFullYear();
        }
        
        if (this.allowance.AllowanceType.Id == "1")
        {
            // Reseersättning

            this.sum = this.allowance.Mileage * this.allowance.ReimbursementPerKm;
        }
        else if (this.allowance.AllowanceType.Id == "2")
        {
            // Traktamente Sverige

            var country = this.getCountry("Sverige", year);
            
            this.getTravelDays(this.allowance.TravelDateStart, this.allowance.TravelDateStartHours, this.allowance.TravelDateStartMinutes, this.allowance.TravelDateEnd, this.allowance.TravelDateEndHours, this.allowance.TravelDateEndMinutes)
            
            if (country != null) {
                this.allowance.AllowancePerDay = country.DailyAllowance;
            }

            var travelDays = this.getTravelDays(this.allowance.TravelDateStart, this.allowance.TravelDateStartHours, this.allowance.TravelDateStartMinutes, this.allowance.TravelDateEnd, this.allowance.TravelDateEndHours, this.allowance.TravelDateEndMinutes);

            this.sumAllowance = travelDays * this.allowance.AllowancePerDay;

            this.sumMeals = this.getMealsDeduction(this.allowance.Brekafasts, this.allowance.Lunches, this.allowance.Dinners, country.Id, this.allowance.AllowancePerDay, country);

            this.sum = this.sumAllowance - this.sumMeals;

        }
        else if (this.allowance.AllowanceType.Id == "3")
        {
            // Traktamente utomlands

            var country = this.getCountry(this.allowance.CountryId, year);

            console.log(country);

            if (country != null)
            {
              this.allowance.AllowancePerDay = country.DailyAllowance;              
            }
            else
            {
                this.allowance.AllowancePerDay = null;
            }

            
            var travelDays = this.getTravelDays(this.allowance.TravelDateStart, this.allowance.TravelDateStartHours, this.allowance.TravelDateStartMinutes, this.allowance.TravelDateEnd, this.allowance.TravelDateEndHours, this.allowance.TravelDateEndMinutes);

            this.sumAllowance = travelDays * this.allowance.AllowancePerDay;

            this.sumMeals = this.getMealsDeduction(this.allowance.Brekafasts, this.allowance.Lunches, this.allowance.Dinners, country.Id, this.allowance.AllowancePerDay);
            
            this.sum = this.sumAllowance - this.sumMeals;


        } 
        
    }


    getMealsDeduction(brekafasts: number, lunches: number, dinners: number, countryId: string, dailyAllowance: number, country: any = null)
    {
        
        var _totalDeduction = 0;

        //Total meal deduction for travels within Sweden
        if (this.allowance.AllowanceType.Id == "2")
        {
            
          if (brekafasts != null) {
               _totalDeduction += brekafasts * country.BreakfastAllowance;
            this.allowance.BrekafastsAmount = Math.round(brekafasts * country.BreakfastAllowance);
        }
          if (lunches != null) {
              _totalDeduction += lunches * country.LunchAllowance;
            this.allowance.LunchesAmount = Math.round(lunches * country.LunchAllowance);
          }

          if (dinners != null) {
              _totalDeduction += dinners * country.DinnerAllowance;
            this.allowance.DinnersAmount = Math.round(dinners * country.DinnerAllowance);
          }
        }
        //Total meal deductions for travels outside Sweden
        else 
        {
          if (brekafasts != null) {
            _totalDeduction += brekafasts * 0.15 * dailyAllowance;
            this.allowance.BrekafastsAmount = Math.round(brekafasts * 0.15 * dailyAllowance);
          }

          if (lunches != null) {
            _totalDeduction += lunches * 0.35 * dailyAllowance;
            this.allowance.LunchesAmount = Math.round(lunches * 0.35 * dailyAllowance);
          }

          if (dinners != null) {
            _totalDeduction += dinners * 0.35 * dailyAllowance;
            this.allowance.DinnersAmount = Math.round(dinners * 0.35 * dailyAllowance);
          }
        }

      return Math.round(_totalDeduction);
    }

    //Gives the correct amount of days reimbursed (half days deducted)
    getTravelDays(startDate: Date, startHour: number, startMinutes: number, endDate: Date, endHour: number, endMinutes: number)
    {
         
        if (startDate == null || startMinutes == null || startMinutes == null || endDate == null || endHour == null || endMinutes == null)
            return null;

        var startDay = new Date(startDate);
        var endDay = new Date(endDate);
        
        this._startDate = new Date(startDate);
        this._endDate = new Date(endDate);
        this._startDate.setHours(startHour);
        this._startDate.setMinutes(startMinutes);
        this._endDate.setHours(endHour);
        this._endDate.setMinutes(endMinutes);
        
        var _travelDays = this.dateDiffinDays(this._startDate, this._endDate);

        
        
        // Same day travel, no reimburstment
      if (this._startDate.getDate() == this._endDate.getDate())
            return 0;
        
        //If travel is started before 12, it is only a half day
      if (this._startDate.getHours() >= 12) {
          _travelDays = _travelDays - 0.5;

      }

        //If travel ends before 19, it is only a half day
      if (this._endDate.getHours() < 19) {
          _travelDays = _travelDays - 0.5;

      }

        //If travel starts after 12 and ends after 19, it is 2 half days
      if (this._startDate.getHours() >= 12 && this._endDate.getHours() < 19) {

      }

        
        
        return _travelDays;
    }

    getHalfDays() {
      if (this._startDate.getHours() >= 12) {
        this.HalfDays = 1;
      }

      //If travel ends before 19, it is only a half day
      if (this._endDate.getHours() < 19) {
        this.HalfDays = 1;
      }

      //If travel starts after 12 and ends after 19, it is 2 half days
      if (this._startDate.getHours() >= 12 && this._endDate.getHours() < 19) {

        this.HalfDays = 2;
      }

    }

    getFullDays() {

      this.FullDays = this.Difference_In_Days - this.HalfDays;
      
    }

    //Calculates how many days that are affected by the travel (half days not considered)
    dateDiffinDays(startDate: Date, endDate: Date) {

        //Makes date to full date. and sets time to midnight for all dates 
        var date1Start = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
        var date2End = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());

        //Checks if there is a difference in timezones/DST and adjust them back to 00:00:00 for time
        if (date1Start.getTimezoneOffset() - date2End.getTimezoneOffset() < 0) {
            date1Start.setHours(date1Start.getHours() + 1);
        }
        else if (date1Start.getTimezoneOffset() - date2End.getTimezoneOffset() > 0) {
            date2End.setHours(date2End.getHours() + 1);
        }

        // To calculate the time difference of two dates 
        var Difference_In_Time = date2End.getTime() - date1Start.getTime();

        // To calculate the no. of days between two dates 
        this.Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24) + 1;

        return this.Difference_In_Days;

       
    }

    getCountry(name: string, year: number)
    {

        if (name == null || year == null)
            return null;

        for (var i = 0; i < this.countries.length; i++) {

            if (this.countries[i].Name == name && this.countries[i].Year == year)
            {
                return this.countries[i];
            }

        }
    }

    loadCountries() {
        this.allowanceService.getCountries()
            .subscribe(
            response => this.countriesLoaded(response),
            error => console.log(error));
    }

    countriesLoaded(response: any)
    {
        this.countries = response;
        this.formUpdated();
    }

    onSubmit()
    {      
      if (this.allowance.AllowanceType.Id == "2" || this.allowance.AllowanceType.Id == "3") {
        this.getHalfDays();
        this.getFullDays();
      }

      this.save();
    }
    
    canEditGroup()
    {
        let statuses = ["Draft"];
        return statuses.includes(this.data.status);
    }

    getTimeReportStatuses()
    {
        //this.invoiceService.getTimeReportStatuses()
        //    .subscribe(
        //    response => this.getTimeReportStatusesFinished(response),
        //    error => console.log(error));
    }

    deleteFile(file: IFile)
    {
        file.Guid = null;
        file.Id = 0;
    }

    showFile(file: IFile) {
        window.open(file.Url);
    }

    rowTypeChange(event: any)
    {
        this.data.invoiceItemGroup.Type = this.getRowType(event.value);
        
        if (event.value == "Övrigt")
        {
            // No rows allowed in the misc category
            this.data.invoiceItemGroup.InvoiceRows = [];
        }
    }

    getRowType(id: any)
    {
        for (var i = 0; i < this.rowTypeList.length; i++)
        {
            if (this.rowTypeList[i].Name == id)
            {
                return { Id: this.rowTypeList[i].Id, Name: this.rowTypeList[i].Name};
            }
        }
    }

    save()
    {

        this.allowance.FullDays = this.FullDays;
        this.allowance.HalfDays = this.HalfDays;
        this.allowance.BrekafastsAmount;
        this.allowance.LunchesAmount;
        this.allowance.DinnersAmount;
        this.allowance.AddedToInvoice;
        this.allowance.Sum = this.sum;
        this.allowance.sumAllowance = this.sumAllowance;
      this.allowance.sumMeals = this.sumMeals;
      
      this.dialogRef.close(this.allowance);
      
    }
    
    cancel()
    {
        debugger
        this.dialogRef.close(null);
    }
    
    deleteRow()
    {
        this.data.invoiceItemGroup.action = 'delete';
        this.dialogRef.close(this.data.invoiceItemGroup);
    }    
    
    deleteListItem(item: any)
    {
        this.data.invoiceItemGroup.InvoiceRows.splice(this.data.invoiceItemGroup.InvoiceRows.indexOf(item), 1);
    }

    guid() {  
        return this.s4() + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' + this.s4() + this.s4() + this.s4();
    }

    s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }
    
    calendarSelect(event: any)
    {
        var date = event.toLocaleDateString('sv-SE');

        // Duplicate dates not allowed
        for (var i = 0; i < this.data.invoiceItemGroup.InvoiceRows.length; i++)
        {
            var item = this.data.invoiceItemGroup.InvoiceRows[i];

            if (item.StartDate == date)
            {
                return;
            }
        }

        // Add item
        this.data.invoiceItemGroup.InvoiceRows.push({ StartDate: date, Id: this.guid(), HoursWorked: this.hoursSelected, MinutesWorked: this.minutesSelected });

        // Sort list by date
        this.data.invoiceItemGroup.InvoiceRows.sort((val1: any, val2: any) => {
            var d1 = <any>new Date(val1.StartDate.toString());
            var d2 = <any>new Date(val2.StartDate.toString());
            
            return d1 - d2;
        })
        
  }

  changeValue(value) {
    debugger
    this.AddCustomReimbursementPerKm = value.checked;

    if (!this.AddCustomReimbursementPerKm) {
      this.allowance.CustomReimbursementPerKm = 0;
    }
  }
    
    onError(error: any)
    {
        alert('An error occured');
        console.log(error);
    }

    fileChange(event: any, file: IFile) {

        file.Guid = this.newGuid();
        let fileList: FileList = event.target.files;
        if (fileList.length > 0) {
            let f: File = fileList[0];

            this.invoiceService.uploadDocument(f, file.Guid)
                .subscribe(
                response => this.onDocumentSaved(response, file),
                error => console.log(error));

        }
    }

    newGuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    

    toFormattedDate(iso: string, hours: number = null, minutes: number = null) {
        
        
      

        const date = new Date(iso);

        var d = null;

        if (hours != null || minutes != null) {

            if (hours == null)
                hours = 0;
            if (minutes == null)
                minutes = 0;


            d = `${date.getFullYear()}-${(date.getMonth() + 1).toString().length == 1 ? ('0' + (date.getMonth() + 1)) : date.getMonth() + 1}-${date.getDate().toString().length == 1 ? ("0" + date.getDate().toString()) : date.getDate().toString()} ${hours.toString()}:${minutes.toString()}:00`;
        }
        else
        {
            d = `${date.getFullYear()}-${(date.getMonth() + 1).toString().length == 1 ? ('0' + (date.getMonth() + 1)) : date.getMonth() + 1}-${date.getDate().toString().length == 1 ? ("0" + date.getDate().toString()) : date.getDate().toString()}`;
        }

        if (d == '1970-01-01')
            return undefined;

        

        return d;
    }

    onDocumentSaved(response: any, file: IFile)
    {
        file.Id = response.Id;
        file.Url = response.Url;
    }


    
}
