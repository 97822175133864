var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { ROUTE_ANIMATIONS_ELEMENTS } from '@app/core';
import { MatTable, MatTableDataSource } from '@angular/material';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from '../../core/services/UserService';
import { InvoiceService } from '../../core/services/InvoiceService';
import { LocalStorageService } from '../../core/local-storage/local-storage.service';
import { MunicipalityService } from '../../core/services/municipalityService';
import { MessagesDialogComponent } from '../../shared/messages/messages-dialog-component';
import { GlobalMessageService } from '../../core/services/GlobalMessageService';
import { environment as env } from '@env/environment';
import { OrganizationService } from '../../core/services/OrganizationService';
import { AuthenticationService } from '../../core/services/AuthenticationService';
var AboutComponent = /** @class */ (function () {
    function AboutComponent(invoiceService, localStorageService, loadingService, userService, municipalityService, globalmessageService, router, organizationService, authenticationService, dialog) {
        this.invoiceService = invoiceService;
        this.localStorageService = localStorageService;
        this.loadingService = loadingService;
        this.userService = userService;
        this.municipalityService = municipalityService;
        this.globalmessageService = globalmessageService;
        this.router = router;
        this.organizationService = organizationService;
        this.authenticationService = authenticationService;
        this.dialog = dialog;
        this.routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS;
        this.releaseButler = require('../../../assets/release-butler.png');
        this.displayedColumns = ['icon', 'customer', 'paymentdue', 'money'];
        this.displayedColumnsCompany = ['invoicenumbercomp', 'amountcomp', 'paymentduecomp', 'invoicestatuscomp'];
        this.isLoading = true;
        this.isAdmin = false;
        this.statuses = [];
        this.selectedFilterStatus = { Id: 0, Name: 'Alla fakturor' };
        this.appId = env.appId;
        this.isCompanyAdmin = false;
        // Calculator
        this.invoiceFee = 29;
        this.samFee = 0.02; // Check if in DataBase
        this.samFeeExpress = 0.05; // Check if in DataBase
        this.samFeeSlide = false;
        this.tempEmployerTax = 0.3; // Check if in DataBase
        this.vatStandard = 1.25; // Check if in DataBase
        this.vatMedium = 1.12; // Check if in DataBase
        this.vatLow = 1.06; // Check if in DataBase
        this.isCompanyUser = false;
    }
    AboutComponent.prototype.ngOnInit = function () {
        this.loadUser();
        this.loadingService.start();
        if (this.appId == 1) {
            this.loadGlobalMessages();
            this.loadMunicipalities();
        }
    };
    AboutComponent.prototype.loadUser = function () {
        var _this = this;
        this.userService.getCurrentUser()
            .subscribe(function (value) { return _this.userLoaded(value); }, function (error) { return console.log(error); });
    };
    AboutComponent.prototype.userLoaded = function (user) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b, defaultItem, item;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.user = user;
                        _a = this;
                        return [4 /*yield*/, this.userService.userHasRole("admin")];
                    case 1:
                        _a.isAdmin = _c.sent();
                        _b = this;
                        return [4 /*yield*/, this.userService.userHasRole('companyadmin')];
                    case 2:
                        _b.isCompanyAdmin = _c.sent();
                        if (this.user.AccountSettings.IsCompany || this.isCompanyAdmin) {
                            this.isCompanyUser = true;
                        }
                        if (this.appId == 2 && !this.isAdmin) {
                            this.organization = this.authenticationService.getCurrentUserOrganization();
                        }
                        defaultItem = this.localStorageService.getItem("filterStatusDefault");
                        if (defaultItem && this.isAdmin && this.appId == 1) {
                            item = JSON.parse(defaultItem);
                            this.selectedFilterStatus = { Id: item.Id, Name: item.Name };
                            this.getInvoices([item.Id], 0, 5, this.filter);
                        }
                        else {
                            this.getInvoices(null, 0, 5, this.filter);
                        }
                        if (this.appId == 1 && !this.isAdmin) {
                            this.getUserEmployerTax();
                            this.getUserMunicipalityTax();
                            //console.log(this.user);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    AboutComponent.prototype.getUserEmployerTax = function () {
        var _this = this;
        this.userService.getUserEmployerTax(this.user.AccountSettings.Id)
            .subscribe(function (value) { return _this.employerTaxLoaded(value); }, function (error) { return console.log(error); });
    };
    AboutComponent.prototype.employerTaxLoaded = function (userEmployerTax) {
        this.employertax = userEmployerTax;
    };
    AboutComponent.prototype.getUserMunicipalityTax = function () {
        var _this = this;
        var today = new Date();
        this.userService.getUserMunicipalityTax(today, this.user.AccountSettings.Id)
            .subscribe(function (value) { return _this.municipalityTaxLoaded(value); }, function (error) { return console.log(error); });
    };
    AboutComponent.prototype.municipalityTaxLoaded = function (userMunicipalityTax) {
        this.municipalitytax = userMunicipalityTax;
        var municipalityTaxPercent = this.municipalitytax * 100;
        if (this.municipalitytax != this.user.AccountSettings.Municipality.Tax) {
            this.municipalityTaxMessage = "Du har en skattejustering eller -jämkning och vi räknar med skattesatsen " + municipalityTaxPercent + " %.";
        }
        else {
            this.municipalityTaxMessage = "Din kommun är angiven till " + this.user.AccountSettings.Municipality.Name + " (" + this.user.AccountSettings.Municipality.Tax * 100 + " %).";
        }
    };
    AboutComponent.prototype.getInvoices = function (statuses, pageIndex, pageSize, filter) {
        var _this = this;
        if (statuses === void 0) { statuses = null; }
        this.isLoading = true;
        // All invoices selected
        if (statuses != null && statuses.length == 1 && statuses[0] == 0) {
            statuses = null;
        }
        //if (this.isAdmin == true) {
        //  this.invoiceService.getInvoices(statuses, pageIndex,pageSize, filter)
        //    .subscribe(value => this.onInvociesRetrieved(value),
        //      error => console.log(error)
        //    )
        //}
        if (this.appId == 1 || this.isAdmin) {
            this.invoiceService.getInvoicesWithTransLogs(statuses, pageIndex, pageSize, filter)
                .subscribe(function (value) { return _this.onInvociesRetrieved(value); }, function (error) { return console.log(error); });
        }
        if (this.appId == 2 && !this.isAdmin) {
            this.invoiceService.getInvoicesByOrganization(this.organization.Id, statuses, pageIndex, pageSize, filter)
                .subscribe(function (value) { return _this.onInvociesRetrieved(value); }, function (error) { return console.log(error); });
        }
    };
    AboutComponent.prototype.onInvociesRetrieved = function (response) {
        for (var i = 0; i < response.Invoices.length; i++) {
            if (response.Invoices[i].Status != undefined)
                response.Invoices[i].Status.Id = response.Invoices[i].Status.Id.toString();
        }
        this.invoiceData = response.Invoices;
        this.dataSource = new MatTableDataSource(this.invoiceData);
        this.isLoading = false;
        this.loadingService.complete();
    };
    // salaryCalculator
    AboutComponent.prototype.myCalculator = function (finalAmount) {
        //this.samFeeNew = Math.round(this.totalInvoiceAmount * this.samFee); // this.invoice.ServiceFee
        this.samFeeNew = this.calculateServiceFeeAmount(this.totalInvoiceAmount) + 29; //Service fee + invoice fee
        this.partialTotalAmount = Math.round(this.totalInvoiceAmount - this.samFeeNew);
        this.grossSalary = Math.round(this.partialTotalAmount / (1 + this.employertax.Tax));
        this.netSalary = Math.round(this.grossSalary - (this.grossSalary * this.municipalitytax));
        this.arbetsgivarAvgift = Math.round(this.partialTotalAmount - this.grossSalary);
        this.displayTax = Math.round(this.grossSalary * this.municipalitytax);
        finalAmount = this.netSalary;
    };
    // expressSalaryCalculator
    AboutComponent.prototype.myExpressCalculator = function (finalAmountExpress) {
        this.samFeeNew = Math.round(this.totalInvoiceAmountExpress * this.samFeeExpress);
        this.partialTotalAmount = Math.round(this.totalInvoiceAmountExpress - this.samFeeNew);
        this.grossSalary = Math.round(this.partialTotalAmount / (1 + this.tempEmployerTax));
        this.netSalaryExpress = Math.round(this.grossSalary - (this.grossSalary * this.municipalitytax));
        this.arbetsgivarAvgift = Math.round(this.partialTotalAmount - this.grossSalary);
        this.displayTax = Math.round(this.grossSalary * this.municipalitytax);
        finalAmountExpress = this.netSalaryExpress;
    };
    AboutComponent.prototype.calculateServiceFeeAmount = function (totalInvoiceAmount) {
        var _serviceFeeFactor = this.samFee;
        var tier1FeeFactor = _serviceFeeFactor;
        var tier2FeeFactor = 0.015;
        var tier3FeeFactor = 0.010;
        var tier4FeeFactor = 0.005;
        var tier1Fee = tier1FeeFactor;
        var tier2Fee = _serviceFeeFactor !== 0 ? Math.min(_serviceFeeFactor, tier2FeeFactor) : tier1FeeFactor;
        var tier3Fee = _serviceFeeFactor !== 0 ? Math.min(_serviceFeeFactor, tier3FeeFactor) : tier2FeeFactor;
        var tier4Fee = _serviceFeeFactor !== 0 ? Math.min(_serviceFeeFactor, tier4FeeFactor) : tier3FeeFactor;
        var amountInTier1 = Math.max(Math.min(totalInvoiceAmount, 100000), 0);
        var amountInTier2 = Math.max(Math.min(totalInvoiceAmount - 100000, 100000), 0);
        var amountInTier3 = Math.max(Math.min(totalInvoiceAmount - 200000, 300000), 0);
        var amountInTier4 = Math.max(totalInvoiceAmount - 500000, 0);
        var serviceFeeTier1 = amountInTier1 * tier1Fee;
        var serviceFeeTier2 = amountInTier2 * tier2Fee;
        var serviceFeeTier3 = amountInTier3 * tier3Fee;
        var serviceFeeTier4 = amountInTier4 * tier4Fee;
        var totalServiceFee = serviceFeeTier1 + serviceFeeTier2 + serviceFeeTier3 + serviceFeeTier4;
        debugger;
        return parseFloat(totalServiceFee.toFixed(2));
    };
    // vatCalcStandard 25%
    AboutComponent.prototype.myVatCalc = function (totalAmount) {
        this.withVat = Math.round(this.noVat * this.vatStandard);
        totalAmount = this.withVat;
    };
    AboutComponent.prototype.myNoVatCalc = function (totalNoVat) {
        this.noVat = Math.round(this.withVat / this.vatStandard);
        totalNoVat = this.noVat;
    };
    // vatCalcMedium 12%
    AboutComponent.prototype.myVatCalcMedium = function (totalAmountMedium) {
        this.withVatMedium = Math.round(this.noVatMedium * this.vatMedium);
        totalAmountMedium = this.withVatMedium;
    };
    AboutComponent.prototype.myNoVatCalcMedium = function (totalNoVatMedium) {
        this.noVatMedium = Math.round(this.withVatMedium / this.vatMedium);
        totalNoVatMedium = this.noVatMedium;
    };
    // vatCalcMedium 6%
    AboutComponent.prototype.myVatCalcLow = function (totalAmountLow) {
        this.withVatLow = Math.round(this.noVatLow * this.vatLow);
        totalAmountLow = this.withVatLow;
    };
    AboutComponent.prototype.myNoVatCalcLow = function (totalNoVatLow) {
        this.noVatLow = Math.round(this.withVatLow / this.vatLow);
        totalNoVatLow = this.noVatLow;
    };
    AboutComponent.prototype.loadMunicipalities = function () {
        var _this = this;
        this.municipalityService.getMunicipalities()
            .subscribe(function (value) { return _this.municipalitiesLoaded(value); }, function (error) { return console.log(error); });
    };
    AboutComponent.prototype.loadGlobalMessages = function () {
        var _this = this;
        this.globalmessageService.getGlobalMessages()
            .subscribe(function (value) { return _this.globalMessagesLoaded(value); }, function (error) { return console.log(error); });
    };
    AboutComponent.prototype.globalMessagesLoaded = function (globalmessages) {
        this.globalmessages = globalmessages;
        console.log(this.globalmessages);
    };
    AboutComponent.prototype.municipalitiesLoaded = function (municipalities) {
        this.municipalities = municipalities;
    };
    AboutComponent.prototype.municipalityChange = function (municipalityId) {
        for (var i = 0; i < this.municipalities.length; i++) {
            if (this.municipalities[i].Id == municipalityId) {
                this.user.AccountSettings.Municipality.Tax = this.municipalities[i].Tax;
            }
        }
    };
    AboutComponent.prototype.openDialog = function (data) {
        var _this = this;
        var dialogRef = this.dialog.open(MessagesDialogComponent, {
            width: '500px;',
            data: __assign({}, data),
        });
        localStorage.setItem('test', JSON.stringify(this.isRead == true));
        console.log(this.isRead);
        dialogRef.afterClosed().subscribe(function (result) {
            localStorage.getItem("test");
            console.log('The dialog was closed', _this.isRead);
        });
    };
    return AboutComponent;
}());
export { AboutComponent };
