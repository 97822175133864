import { Routes } from '@angular/router';
import { AuthGuardService } from '@app/core';
import { CanDeactivateGuard } from './core/guards/CanDeactivate.Component';
import { SettingsComponent } from './settings';
import { CompanyUserSettingsComponent } from './settings/settings/companyuser-settings.component';
import { InvoiceToolComponent } from './static/invoice-tool/invoice-tool.component';
import { CombinedInvoiceToolComponent } from './static/invoice-tool/combined-invoice-tool.component';
import { ResetPasswordComponent } from './static/resetpassword/resetpassword.component';
import { InvoiceSummaryComponent } from './static/invoice-summary/invoice-summary.component';
import { CombinedInvoiceSummaryComponent } from './static/combined-invoice-summary/combined-invoice-summary.component';
import { ContactComponent } from './static/contact/contact.component';
import { AboutComponent } from './static/about/about.component';
import { UsersComponent } from './static/users/users.component';
import { UserEditComponent } from './static/users/edit-user/user-edit.component';
import { EmailpassComponent } from './static/create-account/emailpass/emailpass.component';
import { LoginEmailpassComponent } from './static/login/login-emailpass/login-emailpass.component';
var ɵ0 = {
    title: 'Start'
}, ɵ1 = {
    title: 'Medlemmar'
}, ɵ2 = {
    title: 'Redigera Medarbetare'
}, ɵ3 = { title: 'Logga in' }, ɵ4 = {
    title: 'Återställ lösenord'
}, ɵ5 = { title: 'Skapa konto' }, ɵ6 = {
    title: 'Fakturaverktyget'
}, ɵ7 = {
    title: 'Samlingsfaktura'
}, ɵ8 = {
    title: 'Combined Invoice Tool'
}, ɵ9 = {
    title: 'Invoice Tool'
}, ɵ10 = {
    title: 'In- och utbetalning'
}, ɵ11 = {
    title: 'In- och utbetalning samlingsfaktura'
}, ɵ12 = {
    title: 'Inställningar'
}, ɵ13 = {
    title: 'Inställningar företag'
}, ɵ14 = {
    title: 'Kontakt'
};
//import { PayoutReportsComponent } from './admin/payout-reports/payout-reports.component';
var routes = [
    {
        path: '',
        redirectTo: 'about',
        pathMatch: 'full'
    },
    {
        path: 'about',
        component: AboutComponent,
        data: ɵ0,
        canActivate: [AuthGuardService]
    },
    {
        path: 'users',
        component: UsersComponent,
        data: ɵ1,
        canActivate: [AuthGuardService]
    },
    {
        path: 'users/:id',
        component: UserEditComponent,
        data: ɵ2,
        canActivate: [AuthGuardService]
    },
    {
        path: 'login',
        component: LoginEmailpassComponent,
        data: ɵ3
    },
    {
        path: 'resetpassword',
        component: ResetPasswordComponent,
        data: ɵ4
    },
    {
        path: 'create-account',
        component: EmailpassComponent,
        data: ɵ5
    },
    {
        path: 'invoice-tool',
        component: InvoiceToolComponent,
        data: ɵ6,
        canActivate: [AuthGuardService],
    },
    {
        path: 'combined-invoice-tool',
        component: CombinedInvoiceToolComponent,
        data: ɵ7,
        canActivate: [AuthGuardService],
    },
    {
        path: 'combined-invoice-tool/:id',
        component: CombinedInvoiceToolComponent,
        data: ɵ8,
        canActivate: [AuthGuardService],
    },
    {
        path: 'invoice-tool/:id',
        component: InvoiceToolComponent,
        data: ɵ9,
        canActivate: [AuthGuardService],
    },
    {
        path: 'invoice-summary/:id',
        component: InvoiceSummaryComponent,
        data: ɵ10,
        canActivate: [AuthGuardService],
    },
    {
        path: 'combined-invoice-summary/:id',
        component: CombinedInvoiceSummaryComponent,
        data: ɵ11,
        canActivate: [AuthGuardService],
    },
    {
        path: 'settings',
        component: SettingsComponent,
        data: ɵ12,
        canActivate: [AuthGuardService],
        canDeactivate: [CanDeactivateGuard]
    },
    {
        path: 'companyuser-settings',
        component: CompanyUserSettingsComponent,
        data: ɵ13,
        canActivate: [AuthGuardService],
        canDeactivate: [CanDeactivateGuard]
    },
    //{
    //  path: 'admin/payout-reports',
    //  component: PayoutReportsComponent,
    //  data: {
    //    title: 'Återrapporteringar'
    //  },
    //  canActivate: [AuthGuardService]
    //},
    {
        path: 'contact',
        component: ContactComponent,
        data: ɵ14,
        canActivate: [AuthGuardService],
    },
    {
        path: 'mypages',
        loadChildren: 'app/mypages/mypages.module#MypagesModule',
        canActivate: [AuthGuardService]
    },
    {
        path: 'admin',
        loadChildren: 'app/admin/admin.module#AdminModule',
        canActivate: [AuthGuardService]
    },
    {
        path: '**',
        redirectTo: '/login/login'
    }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export var routingComponents = [EmailpassComponent, LoginEmailpassComponent,];
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14 };
//MbankidComponent, BankidComponent, LoginBankidComponent, LoginMbankidComponent
