
<style>


  .main-heading
  {
    margin-left: 10px;
    margin-bottom: 20px;
  }

  .sp-form-field
  {
    margin-bottom: 30px;
  }
  .sp-form-field-label {
    margin-bottom: 15px;
    font-weight: 600;
  }
  
  .right {
    float: right;
  }
  .description
  {
    margin-bottom: 20px;
  }
  .warning
  {
    color: red;
  }

  mat-form-field
  {
    width: 100%;
  }

  .errormessage {
      float: right;
      font-size: 80%;
      color: red;
      margin-top: 15px;
  }

</style>


<!--<h1 mat-dialog-title *ngIf="!isNewCustomer" style="margin-top: 5px;margin-bottom: 35px;">Redigera kund</h1>-->

  <form name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
  <div class="">
    <div class="row">
      <div class="col-md-12">
        <div class="mat-headline">Skattejämkning / frivillig skatteändring</div>
        <div class="row">
          <div class="col-12">
            <p *ngIf="taxAdjustment.TaxAdjustmentType.Id == 1 || taxAdjustment.TaxAdjustmentType.Id == 2">Kommer du under kommande inkomstår att få en total ersättning över eller under gränsvärdet på högst 24 238 SEK (2024)?</p>
            <mat-form-field>
              <mat-select required [(ngModel)]="taxAdjustment.TaxAdjustmentType.Id" (ngModelChange)="onTaxAdjustmentTypeChange($event)" name="taxadjustmenttype" id="taxadjustmenttype" placeholder="Nivå på ersättning">
                <mat-option *ngFor="let item of taxAdjustmentTypes" [value]="item.Id">{{item.Name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <!--Om under gränsvärdet-->
        <div class="row" *ngIf="taxAdjustment.TaxAdjustmentType.Id == 1">
          <div class="col-12">
            <p>Min totala ersättning under kommande inkomstår kommer inte överstiga 24 238 SEK. </p>
            <mat-checkbox required [ngModel]="taxAdjustment.LowerLevelTaxAdjustmentApproved" (change)="taxAdjustment.LowerLevelTaxAdjustmentApproved = $event.checked" name="LowerLevelTaxAdjustmentApproved">Jag intygar ovanstående</mat-checkbox>
          </div>
          <div class="col-12" style="margin-top: 20px;">
            <p>Om du ansökt om skattejämkning för en summa under 24 238 SEK behöver du ladda upp intyget <a target="_blank" href="https://api.sampoolen.se/Documents/skv434.pdf"><u>Skatteverket (SKV 434)</u></a>. </p>

            <div class="col-sm-12 sp-form-field">
              <div class="sp-form-field-label">Ladda upp PDF-dokument eller bild *</div>
              <input *ngIf="taxAdjustment.Attachment.Guid == null" type="file" mat-stroked-button mat-raised-button (change)="fileChange($event,taxAdjustment.Attachment)" accept=".pdf,.doc,.docx,.jpeg,.jpg,.png,.gif">
              <a *ngIf="taxAdjustment.Attachment.Guid != null" class="col-sm-8" mat-stroked-button color="secondary" (click)="showFile(taxAdjustment.Attachment)"><mat-icon>open_in_new</mat-icon>&nbsp; visa dokument</a>&nbsp;
              <a *ngIf="taxAdjustment.Attachment.Guid != null" class="warning right col-sm-3" mat-stroked-button color="secondary" (click)="deleteFile(taxAdjustment.Attachment)">radera</a>&nbsp;
            </div>


          </div>
        </div>

        <!--Om över gränsvärdet-->
        <div class="row" *ngIf="taxAdjustment.TaxAdjustmentType.Id == 2">
          <div class="col-12">
            <p>Om du ansökt om skattejämkning för en summa över 24 238 SEK behöver du ladda upp ett intyg från Skatteverket. </p>

            <div class="col-sm-12 sp-form-field">
              <div class="sp-form-field-label">Ladda upp PDF-dokument eller bild *</div>
              <input *ngIf="taxAdjustment.Attachment.Guid == null" type="file" mat-stroked-button mat-raised-button (change)="fileChange($event,taxAdjustment.Attachment)" accept=".pdf,.doc,.docx,.jpeg,.jpg,.png,.gif">
              <a *ngIf="taxAdjustment.Attachment.Guid != null" class="col-sm-8" mat-stroked-button color="secondary" (click)="showFile(taxAdjustment.Attachment)"><mat-icon>open_in_new</mat-icon>&nbsp; visa dokument</a>&nbsp;
              <a *ngIf="taxAdjustment.Attachment.Guid != null" class="warning right col-sm-3" mat-stroked-button color="secondary" (click)="deleteFile(taxAdjustment.Attachment)">radera</a>&nbsp;
            </div>


          </div>
        </div>

        <!-- Om frivillig skatteändring -->
        <div class="row" *ngIf="!isAdmin && taxAdjustment.TaxAdjustmentType.Id == 3">

          <div class="col-12">
            <p>
              En frivillig skatteändringen behöver vara högre än din nuvarande skatt. Önskar du ansöka om lägre skatt, välj Jämkning under gränsvärdet eller Jämkning över gränsvärdet i menyn ovan.
            </p>
          </div>
          <!--Startdatum-->
          <div class="col-6">
            <mat-form-field>
              <input required matInput [matDatepicker]="pickerStartDate" [ngModel]="taxAdjustment.DateStart" (dateChange)="taxAdjustment.DateStart = toFormattedDate($event.value)" name="pickerStartDate" id="pickerStartDate" placeholder="Startdatum" [formControl]="dateControl">
              <mat-datepicker-toggle matSuffix [for]="pickerStartDate"></mat-datepicker-toggle>
              <mat-datepicker #pickerStartDate [startAt]="firstDateOfNextMonth"></mat-datepicker>
            </mat-form-field>
          </div>

          <!--Slutdatum-->
          <div class="col-6">
            <mat-form-field>
              <input required matInput [matDatepicker]="pickerEndDate" [ngModel]="taxAdjustment.DateEnd" (dateChange)="taxAdjustment.DateEnd = toFormattedDate($event.value)" name="pickerEndDate" id="pickerEndDate" placeholder="Slutdatum">
              <mat-datepicker-toggle matSuffix [for]="pickerEndDate"></mat-datepicker-toggle>
              <mat-datepicker #pickerEndDate [startAt]="firstDateOfNextMonth"></mat-datepicker>
            </mat-form-field>
          </div>

          <!-- Tillfällig skatt -->
          <div class="col-6">
            <mat-form-field>
              <input required type="number" step="1" matInput [ngModel]="taxAdjustment.TemporaryTax" (ngModelChange)="taxAdjustment.TemporaryTax = $event" md-maxlength="50" placeholder="Frivillig skattesats (ange procent)" id="temptax" name="temptax" />
            </mat-form-field>
          </div>

          <div class="col-6">
            <span style="font-size: 80%;">Den frivilliga skatten måste vara högre än din ordinarie skatt {{data.user.AccountSettings.Municipality.Tax * 100 | number:'1.2-2'}} %.</span>
          </div>
        </div>

        <!-- SINK -->
        <div class="row" *ngIf="taxAdjustment.TaxAdjustmentType.Id == 4">

          <div class="col-12">
            <p>Ladda upp ditt beslut från Skatteverket här. </p>

            <div class="col-sm-12 sp-form-field">
              <div class="sp-form-field-label">Ladda upp PDF-dokument eller bild *</div>
              <input *ngIf="taxAdjustment.Attachment.Guid == null" type="file" mat-stroked-button mat-raised-button (change)="fileChange($event,taxAdjustment.Attachment)" accept=".pdf,.doc,.docx,.jpeg,.jpg,.png,.gif">
              <a *ngIf="taxAdjustment.Attachment.Guid != null" class="col-sm-8" mat-stroked-button color="secondary" (click)="showFile(taxAdjustment.Attachment)"><mat-icon>open_in_new</mat-icon>&nbsp; visa dokument</a>&nbsp;
              <a *ngIf="taxAdjustment.Attachment.Guid != null" class="warning right col-sm-3" mat-stroked-button color="secondary" (click)="deleteFile(taxAdjustment.Attachment)">radera</a>&nbsp;
            </div>
          </div>

        </div>

        <!-- ******************************************************* -->
        <!-- ************************ Admin ************************ -->
        <!-- ******************************************************* -->

        <div class="col-12" *ngIf="isAdmin">

          <!-- **************** -->
          <!-- Under gränsvärde -->
          <!-- **************** -->

          <div class="row" *ngIf="taxAdjustment.TaxAdjustmentType.Id == 1">
            <div class="col-12">
              <h3>Admin</h3>
            </div>

            <!-- Startdatum -->
            <div class="col-6">
              <mat-form-field>
                <input required matInput [matDatepicker]="pickerStartDate" [ngModel]="taxAdjustment.DateStart" (dateChange)="taxAdjustment.DateStart = toFormattedDate($event.value)" name="pickerStartDate" id="pickerStartDate" placeholder="Startdatum">
                <mat-datepicker-toggle matSuffix [for]="pickerStartDate"></mat-datepicker-toggle>
                <mat-datepicker #pickerStartDate></mat-datepicker>
              </mat-form-field>
            </div>

            <!-- Slutdatum -->
            <div class="col-6">
              <mat-form-field>
                <input required matInput [matDatepicker]="pickerEndDate" [ngModel]="taxAdjustment.DateEnd" (dateChange)="taxAdjustment.DateEnd = toFormattedDate($event.value)" name="pickerEndDate" id="pickerEndDate" placeholder="Slutdatum">
                <mat-datepicker-toggle matSuffix [for]="pickerEndDate"></mat-datepicker-toggle>
                <mat-datepicker #pickerEndDate></mat-datepicker>
              </mat-form-field>
            </div>

            <!-- Maxsumma -->
            <div class="col-6">
              <mat-form-field>
                <input type="text" matInput [ngModel]="taxAdjustment.MaxAmount" (ngModelChange)="taxAdjustment.MaxAmount = $event" md-maxlength="50" placeholder="Maximal ersättning" id="amount" name="amount" />
              </mat-form-field>
            </div>

            <!-- Tillfällig skatt -->
            <div class="col-6">
              <mat-form-field>
                <input required type="number" step="1" matInput [ngModel]="taxAdjustment.TemporaryTax" (ngModelChange)="taxAdjustment.TemporaryTax = $event" md-maxlength="50" placeholder="Frivillig skattesats (ange procent)" id="temptax" name="temptax" />
              </mat-form-field>
            </div>

          </div>

          <!-- **************** -->
          <!-- Frivillig skatt  -->
          <!-- Över gränsvärdet -->
          <!-- **************** -->

          <div class="row" *ngIf="taxAdjustment.TaxAdjustmentType.Id == 2 || taxAdjustment.TaxAdjustmentType.Id == 3">
            <div class="col-12">
              <h3>Admin</h3>
            </div>

            <!-- Startdatum -->
            <div class="col-6">
              <mat-form-field>
                <input required matInput [matDatepicker]="pickerStartDate" [ngModel]="taxAdjustment.DateStart" (dateChange)="taxAdjustment.DateStart = toFormattedDate($event.value)" name="pickerStartDate" id="pickerStartDate" placeholder="Startdatum">
                <mat-datepicker-toggle matSuffix [for]="pickerStartDate"></mat-datepicker-toggle>
                <mat-datepicker #pickerStartDate></mat-datepicker>
              </mat-form-field>
            </div>

            <!-- Slutdatum -->
            <div class="col-6">
              <mat-form-field>
                <input required matInput [matDatepicker]="pickerEndDate" [ngModel]="taxAdjustment.DateEnd" (dateChange)="taxAdjustment.DateEnd = toFormattedDate($event.value)" name="pickerEndDate" id="pickerEndDate" placeholder="Slutdatum">
                <mat-datepicker-toggle matSuffix [for]="pickerEndDate"></mat-datepicker-toggle>
                <mat-datepicker #pickerEndDate></mat-datepicker>
              </mat-form-field>
            </div>

            <!-- Maxsumma -->
            <div class="col-6">
              <mat-form-field>
                <input type="text" matInput [ngModel]="taxAdjustment.MaxAmount" (ngModelChange)="taxAdjustment.MaxAmount = $event" md-maxlength="50" placeholder="Maximal ersättning" id="amount" name="amount" />
              </mat-form-field>
            </div>

            <!-- Tillfällig skatt -->
            <div class="col-6">
              <mat-form-field>
                <input required type="number" step="1" matInput [ngModel]="taxAdjustment.TemporaryTax" (ngModelChange)="taxAdjustment.TemporaryTax = $event" md-maxlength="50" placeholder="Frivillig skattesats (ange procent)" id="temptax" name="temptax" />
              </mat-form-field>
            </div>
          </div>

          <!-- ************ -->
          <!-- *** SINK *** -->
          <!-- ************ -->

          <div class="row" *ngIf="taxAdjustment.TaxAdjustmentType.Id == 4">
            <div class="col-12">
              <h3>Admin</h3>
            </div>

            <!-- Startdatum -->
            <div class="col-6">
              <mat-form-field>
                <input required matInput [matDatepicker]="pickerStartDate" [ngModel]="taxAdjustment.DateStart" (dateChange)="taxAdjustment.DateStart = toFormattedDate($event.value)" name="pickerStartDate" id="pickerStartDate" placeholder="Startdatum">
                <mat-datepicker-toggle matSuffix [for]="pickerStartDate"></mat-datepicker-toggle>
                <mat-datepicker #pickerStartDate></mat-datepicker>
              </mat-form-field>
            </div>

            <!-- Slutdatum -->
            <div class="col-6">
              <mat-form-field>
                <input required matInput [matDatepicker]="pickerEndDate" [ngModel]="taxAdjustment.DateEnd" (dateChange)="taxAdjustment.DateEnd = toFormattedDate($event.value)" name="pickerEndDate" id="pickerEndDate" placeholder="Slutdatum">
                <mat-datepicker-toggle matSuffix [for]="pickerEndDate"></mat-datepicker-toggle>
                <mat-datepicker #pickerEndDate></mat-datepicker>
              </mat-form-field>
            </div>

            <!-- Tillfällig skatt -->
            <div class="col-6">
              <mat-form-field>
                <input required type="number" step="1" matInput [ngModel]="taxAdjustment.TemporaryTax" (ngModelChange)="taxAdjustment.TemporaryTax = $event" md-maxlength="50" placeholder="Frivillig skattesats (ange procent)" id="temptax" name="temptax" />
              </mat-form-field>
            </div>

            <!-- Tillfällig arbetsgivaravgift -->
            <div class="col-6">
              <mat-checkbox [ngModel]="taxAdjustment.ZeroEmployerTax" (change)="taxAdjustment.ZeroEmployerTax = $event.checked" name="ZeroEmployerTax">Har 0 % arbetsgivaravgift</mat-checkbox>
            </div>
          </div>

          <div *ngIf="taxAdjustment && taxAdjustment.TaxAdjustmentType.Id > 0">
            <div class="col-lg-12">
              <mat-radio-group [(ngModel)]="taxAdjustment.Status" (change)="changeApprovalStatus($event.value)" [ngModelOptions]="{standalone: true}">
                <mat-radio-button style="margin-right: 10px;" value="underReview">Granskas</mat-radio-button>
                <mat-radio-button style="margin-right: 10px;" value="approved">Godkänt</mat-radio-button>
                <mat-radio-button value="denied">Ej godkänt</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>

        </div>

        
        <div class="col-sm-12 sp-form-field" style="margin-top: 40px;">
          <button mat-flat-button [disabled]="!(calculateAddButtonValid() && f.form.valid)" color="accent" class="right" style="margin-left: 10px;">Lägg till</button>
          <a mat-stroked-button (click)="cancel()" color="secondary" class="right" style="margin-left:10px;">Avbryt</a>
        </div>
        <div class="errormessage">{{errorMessage}}</div>
      </div>
        </div>
    </div>

</form>

