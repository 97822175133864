import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, OnChanges, ElementRef, ViewChild, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { trigger, transition, animate, style } from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { ICompany } from '../interfaces/ICompany';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment as env } from '@env/environment';
import { HttpHeaders } from '@angular/common/http';
import { HttpErrorHandler, HandleError } from './http-error-handler';
import { tap } from "rxjs/internal/operators/tap";
import { AuthenticationService } from '../../core/services/AuthenticationService';

@Component({
  selector: 'chatbot-component',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('200ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ transform: 'translateX(+100%)' }))
      ])
    ])
  ]
})

export class ChatBotComponent {
  public coursesFollowUp: any;
  public isLoading: boolean = false;
  public currentUserId: string;

  constructor(private router: Router,
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private renderer: Renderer2,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.messages.push({ Role: 'bot', Text: 'Hej! Hur kan jag hjälpa dig idag?' });
    this.currentUserId = this.authenticationService.currentUserUserId();
  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  ngAfterViewInit() {
   
  }

  @Input() show: boolean = true;
  @Input() conversation: any;
  @Output() close = new EventEmitter<boolean>();

  messages: any[] = [];
  newMessage = '';

  addTestMessages() {
    for (var i = 0; i < 100; i++) {
      this.onMessageRetrieved("Här kommer en testrad");
    }

    setTimeout(() => {
      this.updateScroll();
    }, 3000);
  }

  updateScroll() {
    setTimeout(() => {
      var element = document.getElementById("content");
      element.scrollTop = element.scrollHeight;
    }, 500);
  }

  sendMessage(userId: string) {
    if (this.newMessage.trim() !== '') {

      this.messages.push({ Role: 'user', Text: this.newMessage });
      this.isLoading = true;
      this.updateScroll();
      this.sendMessageToAI(this.newMessage, userId)
        .subscribe(response => this.onMessageRetrieved(response), err => alert(err));

      this.newMessage = ''; // Clear the input field

    }
  }

  sendMessageToAI(message: string, userId: string): Observable<any> {

    var url = env.hostApi + '/Chat';

    var payload = JSON.parse('{"Text": "' + message + '","ConversationId": "","UserId": "' + userId + '","Actor": "1"}');

    return this.http.post<any>(url, payload, this.getHttpOptions())
      .pipe(
        catchError(
          (err: any) => {
            return new Observable<any>();
          }
        ));
  }

  onMessageRetrieved(response) {

    this.messages.push({ Role: 'bot', Text: response });
    this.isLoading = false;
    this.updateScroll();
  }

  getHttpOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
  }

  onKeyPress(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.sendMessage(this.currentUserId);
      event.preventDefault(); // Prevent the default Enter key behavior (e.g., line break in the input)
    }
  }

  loadData() {
  }

  courseDataLoaded(response: any) {

  }

  closeWindow() {
    this.show = false;
    this.close.emit(true);
  }

  onCoursesFollowUpRetrieved(response: any) {
    this.coursesFollowUp = response;
  }

  editCourse(courseId: number) {
    var config = JSON.parse(JSON.stringify(this.config));
    config.width = '100%';
    config.height = '95%';
    config.panelClass = 'edit-course';
    config.data.courseId = courseId;
  }

  open(url: string) {
    window.open(url, "_blank");
  }

  config = {
    disableClose: false,
    panelClass: 'custom-overlay-pane-class',
    hasBackdrop: true,
    backdropClass: '',
    width: '98%',
    height: '95%',
    position: {
      top: '',
      bottom: '',
      left: '',
      right: ''
    },
    data: {
      courseId: undefined,
    },
    dataTemplate: {
    }
  };
}
