<style>
  .fix-static-bug {
    width: 100% !important;
    position: static !important;
    margin-top: 20px;
  }

  .pointer
  {
      cursor: pointer;
  }
  .prel {
    font-style: italic;
    position: relative;
    left: 12px;
  }

  .italic{
    font-style: italic;
  }

  .iconPreli {
    font-size: 14px;
  }
  
</style>


<div class="fix-static-bug">
  <div class="container container-margin">
    <div class="row d-none d-lg-block">
      <div class="col-md-12">
        <h3 class="main-heading">Kontosaldo</h3>
        <div style="margin: 20px 0">
          <!--Här ser du hur vi har räknat ut din lön. Om du skickat din faktura till en svensk kund i SEK ser du den här så fort du skickat den till oss. Om du skickat den i annan valuta dyker den upp så fort vi granskat den och skickat den till din kund.<br />
          Om du har en kund som fakturerat för din räkning, eller använder oss som löneadministratör, ser du bara utbetalningsdatumen för de fakturorna.-->
        </div>
       </div>
    </div>
    <div class="backgroundcolor container">

      <!--Shows all transaction logs separately to admin-->
      <mat-card class="mat-elevation-z0">
        <mat-card class="mat-elevation-z0">
          <div class="container-margin container">
            <mat-card-content class="mat-elevation-z8">

              <div class="d-none d-lg-block">                
                <div class="col d-flex justify-content-between" style="background-color: #FAFAFA;">
                  <div class="col">
                    <!--<mat-form-field color="accent" appearance="standard">
                      <mat-label>Sök</mat-label>
                      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Hitta rätt faktura">
                      <mat-icon matSuffix color="accent">search</mat-icon>
                    </mat-form-field>-->
                  </div>

                </div>
              </div>

              <!--This activates the correct default sort-->
              <table mat-table matSort [dataSource]="dataSource" matSortDirection="asc" #mytable class="my-table">

                <ng-container matColumnDef="id">
                  <th mat-header-cell mat-sort-header *matHeaderCellDef> <span style="color: #E91E63">Fakturaid </span></th>
                  <td mat-cell *matCellDef="let element">{{element?.Invoice?.Id}}</td>
                </ng-container>

                <ng-container matColumnDef="event">
                  <th mat-header-cell mat-sort-header *matHeaderCellDef> <span style="color: #E91E63">Händelse</span></th>
                  <td mat-cell (click)="openDialogAdmin('open',element)" class="pointer" *matCellDef="let element">
                    <span>{{element?.Type?.Name}}</span>
                  </td>
                </ng-container>

                <ng-container matColumnDef="date">
                  <th mat-header-cell mat-sort-header *matHeaderCellDef> <span style="color: #E91E63">Datum</span></th>
                  <td mat-cell (click)="openDialogAdmin('open',element)" class="pointer" *matCellDef="let element">
                    <span *ngIf="element?.PayoutDate != null">{{element?.PayoutDate | date: 'yyyy-MM-dd'}}</span>
                    <span *ngIf="element?.PaymentDate != null">{{element?.PaymentDate | date: 'yyyy-MM-dd'}}</span>
                  </td>
                </ng-container>

                <ng-container matColumnDef="amount">
                  <th mat-header-cell mat-sort-header *matHeaderCellDef> <span style="color: #E91E63">Summa</span></th>
                  <td mat-cell (click)="openDialogAdmin('open',element)" class="pointer" *matCellDef="let element">
                    <span *ngIf="element?.PayoutDate != null">{{element?.TotalPayout}}</span>
                  </td>
                </ng-container>

                <ng-container matColumnDef="admin">
                  <th mat-header-cell mat-sort-header *matHeaderCellDef> <span style="color: #E91E63">Admin</span></th>
                  <td mat-cell class="pointer" *matCellDef="let element">
                    <a mat-icon-button (click)="deleteTransactionLog(element?.Id)" style="color:red;" class="pointer"><mat-icon>delete_forever</mat-icon></a>
                  </td>
                </ng-container>

                <ng-container matColumnDef="icon">
                  <th mat-header-cell mat-sort-header *matHeaderCellDef style="padding: 0 !important;"><span style="color: #E91E63">Se beräkning</span></th>
                  <td mat-cell (click)="openDialog('Update',element)" class="pointer" *matCellDef="let element" style="padding: 0 !important;"><mat-icon color="accent">list_alt</mat-icon></td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

              </table>
              <mat-paginator [pageSizeOptions]="[5, 20, 50, 100, 500]" [pageSize]="10" [pageIndex]="0" [length]="listLength" showFirstLastButtons="true"></mat-paginator>
            </mat-card-content>
          </div>
        </mat-card>
      </mat-card>
    </div>
  </div>
</div>
