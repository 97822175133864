import { Component, Inject, ViewChild, TemplateRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDatepicker, MatDatepickerToggle } from '@angular/material';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

import { Http, RequestOptions, Headers, Response } from '@angular/http';
import { Observable } from 'rxjs';
//import { ServiceService } from '../../core/services/ServiceService';
import { AuthenticationService } from '../../core/services/AuthenticationService'
import { AppConfig } from '../../config/app.config';
import { ICompany } from '../../core/interfaces/ICompany';
//import { InvoiceService } from '../../core/services/InvoiceService';
import { CompanyService } from '../../core/services/CompanyService';

@Component({
    selector: 'edit-customer-dialog',
    templateUrl: 'invoice-tool.invoicePreviewDialog.Component.html'
})


export class InvoicePreviewDialogComponent {
    private _dimesionToggle = false;
    private _formValid = false;
    public _imageList: any[] = [];
    public _hasImages = false;
    private _showImageList = false;
    private _showAttachments = false;
    private _hasAttachments = false;
    private _showComments = false;
    public customer: ICompany;
    public isNewCustomer: boolean;
    @ViewChild('myInput', { static: false })
    myInputVariable: any;
    private _apiBaseUrl: string;
    @ViewChild("datepicker", { static: false }) datepicker: MatDatepicker<Date>;
    selectedDate: Date = new Date();
    //private invoiceItemGroup = {InvoiceRows:[]};
    private hoursList = [];
    private vatList = [];
    private rowTypeList = [];
    private hoursSelected: string = "8";
    private vatSelected: string = "25";
    private rowTypeSelected: string = "0"
    private row: any = {};

    constructor(
        public dialogRef: MatDialogRef<InvoicePreviewDialogComponent>,
        private http: Http,
        //private service: ServiceService,
        private authenticationService: AuthenticationService,
        //private invoiceService: InvoiceService,
        private companyService: CompanyService,
        //private config: AppConfig,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) 
    { 
        //this._apiBaseUrl = config.getConfig('host');
    }

    ngOnInit() {

    console.log(this.data);

        // Create new group
    //    if (this.data.invoiceItemGroup == undefined)
    //    {
    //        this.data.invoiceItemGroup = { Id: this.guid(), Description: '', InvoiceRows: [], Type: {}};
    //    }

    //    this.data.invoiceItemGroup = JSON.parse(JSON.stringify(this.data.invoiceItemGroup));


    //    if (this.data.invoiceItemGroup.InvoiceRows != undefined) {
    //        for (var i = 0; i < this.data.invoiceItemGroup.InvoiceRows.length; i++) {
    //            this.data.invoiceItemGroup.InvoiceRows[i].HoursWorked = this.data.invoiceItemGroup.InvoiceRows[i].HoursWorked.toString();
    //        }
    //    }

    //    this.hoursList.push({ Value: "8" }, { Value: "7.5" }, { Value: "7" }, { Value: "6.5" }, { Value: "6" }, { Value: "5.5" }, { Value: "5" }, { Value: "4.5" }, { Value: "4" }, { Value: "3.5" }, { Value: "3" }, { Value: "2.5" }, { Value: "2" }, { Value: "1.5" }, { Value: "1" }, { Value: "0.5" });
    //    this.vatList.push({ Value: "25" }, { Value: "6" }, { Value: "0" })
    //    this.rowTypeList.push({ Value: "1", Name: "Arbetad tid" }, { Value: "2", Name: "Övrigt" })
    }

    rowTypeChange(event: any)
    {
        this.data.invoiceItemGroup.Type = this.getRowType(event.value);
        
        if (event.value == "Övrigt")
        {
            // No rows allowed in the misc category
            this.data.invoiceItemGroup.InvoiceRows = [];
        }
    }

    //setRow()
    //{
    //    this.row.totalAmount = this.data.invoiceItemGroup.totalAmount;
    //    this.row.description = this.data.invoiceItemGroup.description;
    //    this.row.vat = this.data.invoiceItemGroup.InvoiceRows[0].vat;
    //}

    getRowType(id: any)
    {
        for (var i = 0; i < this.rowTypeList.length; i++)
        {
            if (this.rowTypeList[i].Name == id)
            {
                return this.rowTypeList[i];
            }
        }
    }

    saveCalendar()
    {
        this.dialogRef.close(this.data.invoiceItemGroup);
    }

    saveRow() {
        this.dialogRef.close(this.data.invoiceItemGroup);
    }
  
    deleteRow()
    {
        this.data.invoiceItemGroup.action = 'delete';
        this.dialogRef.close(this.data.invoiceItemGroup);
    }    

    cancel()
    {
        this.dialogRef.close(undefined);
    }
    
    deleteListItem(item: any)
    {
        this.data.invoiceItemGroup.InvoiceRows.splice(this.data.invoiceItemGroup.InvoiceRows.indexOf(item), 1);
    }

    guid() {  
        return this.s4() + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' + this.s4() + this.s4() + this.s4();
    }

    s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }
    
    calendarSelect(event: any)
    {
        var date = event.toLocaleDateString();
        this.data.invoiceItemGroup.InvoiceRows.push({ StartDate: date, Id: this.guid(), HoursWorked: this.hoursSelected, Vat: this.vatSelected });
    }
    
    onError(error: any)
    {
        alert('An error occured');
        console.log(error);
    }
    
}