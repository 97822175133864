<!-- BankId -->
<div class="container" style="position: static !important;">
  <form name="form" #g="ngForm" novalidate>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8 d-flex">
          <mat-form-field color="accent" floatPlaceholder="never" style="width: 100%; text-align: center">
            <mat-icon after color="accent" matSuffix>account_circle</mat-icon>
            <input matInput [ngModel]="user.PersonalNumber" required autocomplete="off" (ngModelChange)="user.PersonalNumber = $event" placeholder="Personnummer 12 siffror" id="username" name="username">
          </mat-form-field>
        </div>
        <div class="col-lg-8">
          <a color="accent" [routerLink]="['/resetpassword']" preserveQueryParams preserveFragment>
          </a>
          <!-- Visable on large devices -->
          <div class="row d-none d-lg-block py-3">
            <div class="col-lg-12">

              <button type="button"
                      mat-raised-button
                      [hidden]="loading"
                      [disabled]="user == null || user?.PersonalNumber?.length != 12"
                      (click)="loginBankId(true)"
                      color="accent"
                      name="action"
                      class="col-lg-auto"
                      style="float: right;">
                Använd BankId på annan enhet
              </button>
              <button type="button"
                      mat-raised-button
                      [hidden]="loading"
                      [disabled]="user == null || user?.PersonalNumber?.length != 12"
                      (click)="loginBankId(false)"
                      color="basic"
                      name="action"
                      class="col-lg-auto"
                      style="float: left;">
                Använd BankID på denna enhet
              </button>
            </div>
          </div>

          <!-- Visable on medium and lower devices -->
          <div class="d-lg-none d-md-block">
            <button type="button"
                    mat-raised-button
                    [hidden]="loading"
                    [disabled]="user == null || user?.PersonalNumber?.length != 12"
                    (click)="loginBankId(false)"
                    color="accent"
                    name="action"
                    class="col-12">
              Använd BankID på denna enhet
            </button><br /><br />
            <button type="button"
                    mat-raised-button
                    [hidden]="loading"
                    [disabled]="user == null || user?.PersonalNumber?.length != 12"
                    (click)="loginBankId(true)"
                    color="basic"
                    name="action"
                    class="col-12">
              Använd BankId på annan enhet
            </button>
          </div>

          <mat-spinner color="accent" [diameter]="20" [hidden]="!loading"></mat-spinner>
        </div>
      </div>
    </div>
  </form>
</div>
<!-- Slut BankId -->
