

<form name="form" (ngSubmit)="onSubmit()" #f="ngForm" novalidate>
  <h1 mat-dialog-title>Användarkonto</h1>
  <div class="mat-dialog-content>">
    <div class="row">
      <div class="col-12">

        <div class="row">

          <div class="col-12">
            <mat-form-field style="width: 100%" floatPlaceholder="never">
              <input matInput [ngModel]="user?.AccountSettings.FirstName" (ngModelChange)="user.AccountSettings.FirstName = $event" placeholder="Förnamn" id="firstname" name="firstname">
            </mat-form-field>
          </div>

          <div class="col-12">
            <mat-form-field style="width: 100%" floatPlaceholder="never">
              <input matInput [ngModel]="user?.AccountSettings.LastName" (ngModelChange)="user.AccountSettings.LastName = $event" placeholder="Efternamn" id="lastname" name="lastname">
            </mat-form-field>
          </div>

          <div class="col-12">
            <mat-form-field style="width: 100%" floatPlaceholder="never">
              <input matInput [ngModel]="user?.AccountSettings.Email" (ngModelChange)="user.AccountSettings.Email = $event" placeholder="E-post" id="email" name="email">
            </mat-form-field>
          </div>
          <div class="col-12">
            <mat-form-field style="width: 100%" floatPlaceholder="never">
              <input matInput [ngModel]="user?.AccountSettings.Phone" (ngModelChange)="user.AccountSettings.Phone = $event" placeholder="Telefon" id="telephone" name="telephone">
            </mat-form-field>
          </div>
          <div class="col-12">
            <mat-checkbox matInput [ngModel]="user?.AccountSettings.Suspended" (change)="changeValueSuspended($event)" id="suspended" name="suspended">Spärra kontot</mat-checkbox>
          </div>
          </div>
        </div>
    </div>

  </div>

  <div mat-dialog-actions style="display: flex; align-items: center; justify-content: space-between;">
    <div style="color: red; font-size: 80%; font-style: italic;">{{validationErrorMessage}}</div>
    <button color="accent" mat-flat-button [disabled]="isLoading">Spara</button>
  </div>

</form>




