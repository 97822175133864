import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { HttpInterceptor } from '@angular/common/http';
import { HttpRequest } from '@angular/common/http';
import { HttpHandler } from '@angular/common/http';
import { HttpEvent } from '@angular/common/http';
import { MsalService } from '../services/msal.service';
import { HttpHeaders } from '@angular/common/http';
import { Observable, of, from } from 'rxjs';
import { AuthenticationService } from './AuthenticationService';

@Injectable()
export class CustomHttpInterceptorService implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        
        const token = this.authenticationService.token;
        let changedRequest = request;

        if (token) {

            const headerSettings: { [name: string]: string | string[]; } = {};
            
            for (const key of request.headers.keys()) {
                headerSettings[key] = request.headers.getAll(key);
            }

            if (token) {
                headerSettings['Authorization'] = 'Bearer ' + token;
            }

            const newHeader = new HttpHeaders(headerSettings);

            changedRequest = request.clone({
                headers: newHeader
            });

            return next.handle(changedRequest);
        }

        return next.handle(request);
    }
}