import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ROUTE_ANIMATIONS_ELEMENTS } from '@app/core';
import { CompanyService } from '../../core/services/CompanyService';
import { ProjectService } from '../../core/services/ProjectService';
import { DataSource } from '@angular/cdk/collections';
import { Observable, of } from 'rxjs';
import { ICompany } from '../../core/interfaces/ICompany';
import { MatTableModule, MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDatepicker, MatDatepickerToggle } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { IAccountSettings } from '../../core/interfaces/user/IAccountSettings';
import { UserService } from '../../core/services/UserService';
import { MunicipalityService } from '../../core/services/municipalityService';
import { IUser } from '../../core/interfaces/user/IUser';
import { CheckboxGroupComponent, CheckboxItem } from '../../shared/checkbox-group/checkbox-group.component';
import { MatTable, MatSnackBar, MatSnackBarConfig, MatTableDataSource } from '@angular/material';
import { ITaxAdjustment } from '../../core/interfaces/user/ITaxAdjustment';
import { IAttachmentOfSalary } from '../../core/interfaces/user/IAttachmentOfSalary';
import { EditTaxAdjustmentDialogComponent } from '../../settings/settings/tax-adjustment/edit-tax-adjustment-Dialog.Component';
import { IFile } from '../../core/interfaces/IFile';
import { IFileType } from '../../core/interfaces/IFileType';
import { AttachmentOfSalaryDialogComponent } from '../../settings/settings/attachment-of-salary/attachment-of-salary-Dialog.Component';

@Component({
    selector: 'anms-todos',
    templateUrl: './EditAccountDialog.component.html',
    styleUrls: ['./EditAccountDialog.component.scss']
})
export class EditAccountDialogComponent implements OnInit, OnDestroy {

    public id: string;
    private unsubscribe$: Subject<void> = new Subject<void>();
    routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS;
    private userId: string;
    user: any;
    isLoading: boolean = true;
    company: any;
    selectedWorkRoles: any = [];
    countries = [];
    selectedTeams: any = [];
    selectedProjects: any = [];
    teams: any;
    projects: any;
    yesNo = [{ Name: "Ja", Value: true }, { Name: "Nej", Value: false }];
    newCourse = { External: false, GradeCertificate: {} };
    public selectedRoles = [];
    overview: any;
    municipalities: any;
    @ViewChild(MatTable, { static: false }) table: MatTable<any>;
    public isAdmin: boolean = false;
    public dataSourceTaxAdjustments: MatTableDataSource<ITaxAdjustment>;
    public dataSourceAttachmentOfSalary: MatTableDataSource<IAttachmentOfSalary>;
    public displayedColumnsTaxAdjustments;
    public displayedColumnsAttachmentsOfSalary;
    validationErrorMessage: string;
    CustomServiceFee: number = 0;
    Commission: number = 0;
    selectedNotificationTypes = [];
    


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public store: Store<any>,
        public snackBar: MatSnackBar,
        private companyService: CompanyService,
        private router: Router,
        private municipalityService: MunicipalityService,
        private userService: UserService,
        private route: ActivatedRoute,
        public dialog: MatDialog) {
    }

  ngOnInit() {

    this.id = this.data.id;

    if (this.id == "0") {
      this.user = {
        AccountSettings: {}
      };
      this.isLoading = false;
    }
    else {
      this.loadUser(this.id);
    }    
    //this.route.params.subscribe(params => {
    //    this.userId = params['id'];
    //    this.config.data.userId = this.userId;
    //    this.loadUser(this.userId);
    //    this.loadMunicipalities();
    //    this.getCountries();
    //    this.isAdmin = this.userService.userHasRole("admin");
    //    this.setTableColumns(this.isAdmin);        
    //});
  }

  setTableColumns(isAdmin: boolean) {
    if (isAdmin) {
        this.displayedColumnsTaxAdjustments = ['type', 'startdate', 'enddate', 'maxamount', 'temptax', 'tempemptax', 'attachment', 'admin'];
    }
    else {
        this.displayedColumnsTaxAdjustments = ['type', 'startdate', 'enddate', 'attachment'];
    }

    this.displayedColumnsAttachmentsOfSalary = ['startdate', 'enddate', 'reservedamount', 'attachmentamount', 'attachment', 'admin']
      
  }

  loadUser(userid: string) {
      this.userService.getUser(userid)
          .subscribe(value => this.userLoaded(value),
          error => console.log(error)
          );
  }

  loadMunicipalities() {
      this.municipalityService.getMunicipalities()
          .subscribe(value => this.municipalities = value,
          error => console.log(error)
          );
  }

  municipalityChange(municipalityId: any) {

      for (var i = 0; i < this.municipalities.length; i++) {
          if (this.municipalities[i].Id == municipalityId) {
              this.user.AccountSettings.Municipality.Tax = this.municipalities[i].Tax;
          }
      }

  }

  getCountries() {
    this.userService.getCountries()
      .subscribe(value => this.countries = value,
        error => this.onError(error)
      );
  }

  onError(error: any) {
    alert('An error occured');
    console.log(error);
  }

  getCountryName(countryId: number) {
    for (var i = 0; i < this.countries.length; i++) {
      if (this.countries[i].Id == countryId) {

        this.user.AccountSettings.Country.Name = this.countries[i].Name;
        this.user.AccountSettings.Country.Eu = this.countries[i].Eu;
      }
    }
  }

  setSelectedNotificationSettings() {

    var selectedNotifications = [];
    
    // Loop all settings
    for (var i = 0; i < this.user.AccountSettings.NotificationSettings.length; i++) {
      var setting = this.user.AccountSettings.NotificationSettings[i];
      
      // Loop selected setting
      if (setting.Active) {
        this.selectedNotificationTypes.push(setting.Type.Id);
      }
    }    
  }

  notificationSettingsChange(selectedItems) {
    
    // Loop all settings
    for (var i = 0; i < this.user.AccountSettings.NotificationSettings.length; i++) {
      var setting = this.user.AccountSettings.NotificationSettings[i];
      // Loop selected setting
      setting.Active = false;

      for (var e = 0; e < selectedItems.length; e++) {
        var currentSettingId = selectedItems[e];
        if (currentSettingId == setting.Type.Id)
          setting.Active = true; // Selected
      }
    }
  }

  userReloaded(user: any) {
      //this.dataSource = user.Enrollments
      this.table.renderRows();
  }
    
  overviewLoaded(overview: any) {
      this.isLoading = false;
      this.overview = overview;
  }
    
  userLoaded(user: IUser) {
    this.user = user;
    //this.dataSourceTaxAdjustments = new MatTableDataSource(user.AccountSettings.TaxAdjustments);
    //this.dataSourceAttachmentOfSalary = new MatTableDataSource(user.AccountSettings.AttachmentOfSalaries);
    //this.CustomServiceFee = user.AccountSettings.CustomServiceFee * 100;
    //this.Commission = user.AccountSettings.Commission * 100;
    //this.setSelectedNotificationSettings();
    //this.setDefaultPensionSavingsSettings();
    this.isLoading = false;
  }

  setDefaultPensionSavingsSettings() {

    if (this.user.AccountSettings.PensionSavingsSettings.PensionSavingsCompany == null) {
      this.pensionSavingsCompanyChanged(1);
    }
    if (this.user.AccountSettings.PensionSavingsSettings.Unit == null) {
      this.pensionSavingsTypeChanged(1);
    }
  }
    
  ngOnDestroy(): void {
  }

  updateCustomServiceFee() {

    if (this.user.AccountSettings.HasCustomServiceFee) {
      this.user.AccountSettings.CustomServiceFee = this.CustomServiceFee / 100;
    }
    else {
      this.user.AccountSettings.CustomServiceFee = 0;
    }

  }

  updateCommission() {
        
    this.user.AccountSettings.Commission = this.Commission / 100;
    
  }

  onUserError(errorMessage: any) {

    console.log(errorMessage);

      this.isLoading = false;
      this.showMessage(errorMessage);
  }

  showMessage(message: string) {
      let config = new MatSnackBarConfig();
      config.duration = 7000;
      this.snackBar.open(message, undefined, config);
  }

  userUpdated(response: any) {
      //this.isLoading = false;
      this.router.navigate(['/users']);
  }

  onDataRetrieved(data: any) {
      this.company = data;
  }

  saveValidates() {

    if (this.user.AccountSettings.IsCompany) {
      
      //Country must be set
      if (this.user.AccountSettings.Country.Name == null) {
        this.validationErrorMessage = "Du måste ange ett land";
        return false;
      }

      //Email address must be given
      if (this.user.AccountSettings.Email == null || this.user.AccountSettings.Email == "") {
        this.validationErrorMessage = "Du måste ange en mailadress";
        return false;
      }

      //If Sweden
      if (this.user.AccountSettings.Country.Name == 'Sverige') {

        //Orgnr must be given
        if (this.user.AccountSettings.OrgNumber == null || this.user.AccountSettings.OrgNumber == "") {
          this.validationErrorMessage = "Du måste ange ett organisationsnummer";
          return false;
        }

        //Bank account, Bankgiro or Plusgiro must be used
        if (((this.user.AccountSettings.BankName == null || this.user.AccountSettings.BankName == "") || (this.user.AccountSettings.AccountNumber == null || this.user.AccountSettings.AccountNumber == "") || (this.user.AccountSettings.ClearingNumber == null || this.user.AccountSettings.ClearingNumber == "")) && (this.user.AccountSettings.Bankgiro == null || this.user.AccountSettings.Bankgiro == "") && (this.user.AccountSettings.Plusgiro == null || this.user.AccountSettings.Plusgiro == "")) {
          this.validationErrorMessage = "Du måste ange bankuppgifter, Bankgiro eller Plusgiro";
          return false;
        }
      } 

      //If not Sweden
      if (this.user.AccountSettings.Country.Name != null && this.user.AccountSettings.Country.Name != 'Sverige') {

        if (this.user.AccountSettings.CompanyName == null || this.user.AccountSettings.CompanyName == "") {
          this.validationErrorMessage = "Du måste ange ett namn";
          return false;
        }

        if (this.user.AccountSettings.Address == null || this.user.AccountSettings.Address == "") {
          this.validationErrorMessage = "Du måste ange en gatuadress";
          return false;
        }

        if (this.user.AccountSettings.Zip == null || this.user.AccountSettings.Zip == "") {
          this.validationErrorMessage = "Du måste ange ett postnummer";
          return false;
        }

        if (this.user.AccountSettings.City == null || this.user.AccountSettings.City == "") {
          this.validationErrorMessage = "Du måste ange en stad";
          return false;
        }

        if (this.user.AccountSettings.Iban == null || this.user.AccountSettings.Iban == "") {
          this.validationErrorMessage = "Du måste ange IBAN";
          return false;
        }

        if (this.user.AccountSettings.Bic == null || this.user.AccountSettings.Bic == "") {
          this.validationErrorMessage = "Du måste ange BIC";
          return false;
        }

        else {
          this.validationErrorMessage = ""
          return true;
        }

      }

      //If not Sweden, but EU country
      if (this.user.AccountSettings.Country.Eu && this.user.AccountSettings.Country.Name != 'Sverige') {

        //Orgnr must be given
        if (this.user.AccountSettings.Vat == null || this.user.AccountSettings.Vat == "") {
          this.validationErrorMessage = "Du måste ange VAT";
          return false;
        }
      }

      else {
        this.validationErrorMessage = ""
        return true;
      }
      
    }

    if (!this.user.AccountSettings.IsCompany) {

    }
  }

  editCustomer(customer: any) {
      this.router.navigate(['/customers', customer.Id]);
  }

  addTaxAdjustment(taxAdjustment: ITaxAdjustment) {
      
    this.config.data.taxAdjustment = null;
 
    let dialogRef = this.dialog.open(EditTaxAdjustmentDialogComponent, this.config);


    dialogRef.afterClosed().subscribe((result: ITaxAdjustment) => {

      if (result) {
          if (this.user.AccountSettings.TaxAdjustments == null)
              this.user.AccountSettings.TaxAdjustments = [];
          this.user.AccountSettings.TaxAdjustments.push(result);
          this.dataSourceTaxAdjustments = new MatTableDataSource(this.user.AccountSettings.TaxAdjustments);
      }

      dialogRef = null;
    });
  }

  editTaxAdjustment(taxAdjustment: ITaxAdjustment) {
     
    if (taxAdjustment != null) {
        
      this.config.data.taxAdjustment = taxAdjustment;

      if (this.config.data.taxAdjustment.Attachment == null)
        this.config.data.taxAdjustment.Attachment = <IFile>
        {
          Id: 0, Guid: null, FileType: {}
        }
    }
    else {          
      this.config.data.taxAdjustment = {
        Id: this.newGuid(),
        Attachment: <IFile>{ Id: 0, Guid: null, FileType: {} }
      }
    }

    let dialogRef = this.dialog.open(EditTaxAdjustmentDialogComponent, this.config);

    dialogRef.afterClosed().subscribe((result: ITaxAdjustment) => {

      if (result != null) {
        for (var i = 0; i < this.user.AccountSettings.TaxAdjustments.length; i++) {
            var d = this.user.AccountSettings.TaxAdjustments[i];

          if (d.Guid == result.Guid || d.Id == result.Id)
          {
                this.user.AccountSettings.TaxAdjustments[i] = result;
                return;
          }                  
        }

        this.user.AccountSettings.TaxAdjustments.push(result);              
      }
            
      dialogRef = null;
    });
  }

  deleteTaxAdjustment(taxAdjustment: any) {
    var result = confirm("Vill du radera jämkningen?");
    if (result)
    {        
      for (var i = 0; i < this.user.AccountSettings.TaxAdjustments.length; i++)
      {
        var d = this.user.AccountSettings.TaxAdjustments[i];
        if (d.Guid == taxAdjustment.Guid)
        {
            this.user.AccountSettings.TaxAdjustments.splice(i, 1);
            this.dataSourceTaxAdjustments = new MatTableDataSource(this.user.AccountSettings.TaxAdjustments);
            return;
        }
      }
    }
  }

  addAttachmentOfSalary(attachmentOfSalary: IAttachmentOfSalary) {
     
    this.config.data.attachmentOfSalary = null;

    let dialogRef = this.dialog.open(AttachmentOfSalaryDialogComponent, this.config);


    dialogRef.afterClosed().subscribe((result: IAttachmentOfSalary) => {
      
      if (result) {
        console.log("Resultat");
        console.log(result);
        if (this.user.AccountSettings.AttachmentOfSalaries == null)
          this.user.AccountSettings.AttachmentOfSalaries = [];
        this.user.AccountSettings.AttachmentOfSalaries.push(result);
        this.dataSourceAttachmentOfSalary = new MatTableDataSource(this.user.AccountSettings.AttachmentOfSalaries);
      }

      dialogRef = null;
    });
  }

  editAttachmentOfSalary(attachmentOfSalary: IAttachmentOfSalary) {

    if (attachmentOfSalary != null) {
      
      this.config.data.attachmentOfSalary = attachmentOfSalary;

      if (this.config.data.attachmentOfSalary.Document == null)
        this.config.data.attachmentOfSalary.Document = <IFile>
          {
            Id: 0, Guid: null, FileType: {}
          }
    }
    else {
      this.config.data.attachmentOfSalary = {
        Id: this.newGuid(),
        Document: <IFile>{ Id: 0, Guid: null, FileType: {} }
      }
    }
        
    let dialogRef = this.dialog.open(AttachmentOfSalaryDialogComponent, this.config);

    dialogRef.afterClosed().subscribe((result: IAttachmentOfSalary) => {

      if (result != null) {
        for (var i = 0; i < this.user.AccountSettings.AttachmentOfSalaries.length; i++) {
          var d = this.user.AccountSettings.AttachmentOfSalaries[i];

          if (d.Guid == result.Guid || d.Id == result.Id) {
            this.user.AccountSettings.AttachmentOfSalaries[i] = result;
            return;
          }
        }

        this.user.AccountSettings.AttachmentOfSalaries.push(result);
      }

      dialogRef = null;
    });
  }

  deleteAttachmentOfSalary(attachmentOfSalary: any) {
    var result = confirm("Vill du radera utmätningen?");
    if (result) {
      for (var i = 0; i < this.user.AccountSettings.AttachmentOfSalaries.length; i++) {
        var d = this.user.AccountSettings.AttachmentOfSalaries[i];
        if (d.Guid == attachmentOfSalary.Guid) {
          this.user.AccountSettings.AttachmentOfSalaries.splice(i, 1);
          this.dataSourceAttachmentOfSalary = new MatTableDataSource(this.user.AccountSettings.AttachmentOfSalaries);
          return;
        }
      }
    }
  }

  pensionSavingsCompanyChanged(event: any) {

    if (event == 1) {
      this.user.AccountSettings.PensionSavingsSettings.PensionSavingsCompany.Id = 1;
      this.user.AccountSettings.PensionSavingsSettings.PensionSavingsCompany.TechnicalName = "SPP";
    }
    else if (event == 2) {
      this.user.AccountSettings.PensionSavingsSettings.PensionSavingsCompany.Id = 2;
      this.user.AccountSettings.PensionSavingsSettings.PensionSavingsCompany.TechnicalName = "Avanza";
    }
    else {
      this.user.AccountSettings.PensionSavingsSettings.PensionSavingsCompany.Id = 3;
      this.user.AccountSettings.PensionSavingsSettings.PensionSavingsCompany.TechnicalName = "NothingSelected";
    }
    
  }

  pensionSavingsTypeChanged(event: any) {debugger

    if (event == 1) {
      this.user.AccountSettings.PensionSavingsSettings.Unit.Id = 1;
      this.user.AccountSettings.PensionSavingsSettings.Unit.TechnicalName = "Percent";
    }
    else {
      this.user.AccountSettings.PensionSavingsSettings.Unit.Id = 2;
      this.user.AccountSettings.PensionSavingsSettings.Unit.TechnicalName = "Share";
    }

  }

  changeValue(value) {
    this.user.AccountSettings.HasCustomServiceFee = value.checked;
  }

  changeValueSuspended(value) {
    this.user.AccountSettings.Suspended = value.checked;
  }

  changeFTaxValue(value) {
    this.user.AccountSettings.FTax = value.checked;
  }

  changeCoordinationNumberValue(value) {
    this.user.AccountSettings.HasCoordinationNumber = value.checked;
  }

  newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
  }

  showFile(file: IFile) {
    window.open(file.Url);
  }

  onSubmit() {
    
    this.isLoading = true;

    if (this.user.Id == null || this.user.Id == "0") {
      this.userService.addCompanyUser(this.user)
        .subscribe(
          response => this.onUserCreated(response), err => this.onUserError(err.error.ExceptionMessage)
        );
    }
    else {
      this.userService.updatetUser(this.user)
        .subscribe(
          () => this.isLoading = false, err => this.onUserError(err.error.ExceptionMessage)
        );
    }
  }

  onUserCreated(user: IUser) {
    this.user.AccountSettings.Id = user.AccountSettings.Id;
    this.isLoading = false;
    this.dialog.closeAll();
  }
  
  config = {
      disableClose: false,
      panelClass: 'custom-overlay-pane-class',
      hasBackdrop: true,
      backdropClass: '',
      width: '500px',
      height: '',
      position: {
          top: '',
          bottom: '',
          left: '',
          right: ''
      },
      data: {
          userId: undefined,
          taxAdjustment: undefined,
          attachmentOfSalary: undefined
      },
      dataTemplate: {
          "Id": 0,
          "Type": {},
          "HoursWorked": undefined,
          "StartDate": undefined,
          "EndDate": undefined,
          "Comment": undefined,
          "InvoiceId": 0
      }
  };
}
