<div *ngIf="show" [@slideInOut] class="course-info-pane">
  <div class="header row">
    <div class="col-12">
      <span *ngIf="!conversation" class="name">Chatbotten Sam</span>
      <span *ngIf="conversation" class="name">{{conversation?.User?.FirstName}} {{conversation?.User?.LastName}} ({{conversation?.Start | date: 'yyyy-MM-dd'}})</span>
      <mat-icon class="close" (click)="closeWindow();">close</mat-icon>
    </div>
  </div>

  <!-- Vanliga chattfönstret -->
  <div *ngIf="conversation == null" class="content" id="content">

    <div class="container" id="container">
      <div *ngFor="let message of messages" class="message" [ngClass]="{ 'user': message.Role === 'user', 'bot': message.Role === 'bot' }">
        {{ message.Role }}: {{ message.Text }}
      </div>
      <div class="bot typing-indicator" *ngIf="isLoading">
        <span class="dot"></span>
        <span class="dot"></span>
        <span class="dot"></span>
      </div>
    </div>

    <div class="footer row">
      <div class="input">
        <input type="text" [(ngModel)]="newMessage" placeholder="Skriv.." (keypress)="onKeyPress($event)">
      </div>
      <div class="button" style="margin-left:5px;">
        <button (click)="sendMessage()">Skicka</button>
      </div>
    </div>
  </div>

  <!-- Visa befintlig chat för admin -->
  <div *ngIf="conversation != null" class="content" id="content">

    <div class="container" id="container">
      <div *ngFor="let message of conversation.Messages" class="message" [ngClass]="{ 'user': message.Actor === 1, 'bot': message.Actor === 2 }">
        {{ message.Actor == 1 ? 'Du' : 'Bot' }} : {{ message.Message }}
      </div>
      <div class="bot typing-indicator" *ngIf="isLoading">
        <span class="dot"></span>
        <span class="dot"></span>
        <span class="dot"></span>
      </div>
    </div>

    <!--<div class="footer row">
      <div class="input">
        <input type="text" [(ngModel)]="newMessage" placeholder="Skriv.." (keypress)="onKeyPress($event)">
      </div>
      <div class="button" style="margin-left:5px;">
        <button (click)="sendMessage()">Skicka</button>
      </div>
    </div>-->
  </div>


</div>
