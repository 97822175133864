/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal-spinner-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i4 from "@angular/material/progress-spinner";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/common";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "./ModalSpinnerDialog.Component";
import * as i9 from "@angular/http";
import * as i10 from "../../core/services/AuthenticationService";
import * as i11 from "../../core/services/CompanyService";
var styles_ModalSpinnerDialogComponent = [i0.styles];
var RenderType_ModalSpinnerDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalSpinnerDialogComponent, data: {} });
export { RenderType_ModalSpinnerDialogComponent as RenderType_ModalSpinnerDialogComponent };
export function View_ModalSpinnerDialogComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { myInputVariable: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 6, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "spinner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "mat-spinner", [["class", "spinner mat-spinner mat-progress-spinner"], ["color", "accent"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i3.View_MatSpinner_0, i3.RenderType_MatSpinner)), i1.ɵdid(5, 114688, null, 0, i4.MatSpinner, [i1.ElementRef, i5.Platform, [2, i6.DOCUMENT], [2, i7.ANIMATION_MODULE_TYPE], i4.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { color: [0, "color"], diameter: [1, "diameter"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["V\u00E4nta medan vi h\u00E4mtar din information"]))], function (_ck, _v) { var currVal_3 = "accent"; var currVal_4 = 100; _ck(_v, 5, 0, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 5).diameter; var currVal_2 = i1.ɵnov(_v, 5).diameter; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_ModalSpinnerDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "modal-spinner-dialog", [], null, null, null, View_ModalSpinnerDialogComponent_0, RenderType_ModalSpinnerDialogComponent)), i1.ɵdid(1, 114688, null, 0, i8.ModalSpinnerDialogComponent, [i2.MatDialogRef, i9.Http, i10.AuthenticationService, i11.CompanyService, i2.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModalSpinnerDialogComponentNgFactory = i1.ɵccf("modal-spinner-dialog", i8.ModalSpinnerDialogComponent, View_ModalSpinnerDialogComponent_Host_0, {}, {}, []);
export { ModalSpinnerDialogComponentNgFactory as ModalSpinnerDialogComponentNgFactory };
