/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./chatbot.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/forms";
import * as i4 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i5 from "@angular/material/icon";
import * as i6 from "./chatbot.component";
import * as i7 from "@angular/router";
import * as i8 from "@angular/common/http";
import * as i9 from "../../core/services/AuthenticationService";
import * as i10 from "@angular/material/dialog";
var styles_ChatBotComponent = [i0.styles];
var RenderType_ChatBotComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChatBotComponent, data: { "animation": [{ type: 7, name: "slideInOut", definitions: [{ type: 1, expr: ":enter", animation: [{ type: 6, styles: { transform: "translateX(100%)" }, offset: null }, { type: 4, styles: { type: 6, styles: { transform: "translateX(0%)" }, offset: null }, timings: "200ms ease-in" }], options: null }, { type: 1, expr: ":leave", animation: [{ type: 4, styles: { type: 6, styles: { transform: "translateX(+100%)" }, offset: null }, timings: "200ms ease-in" }], options: null }], options: {} }] } });
export { RenderType_ChatBotComponent as RenderType_ChatBotComponent };
function View_ChatBotComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "name"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Chatbotten Sam"]))], null, null); }
function View_ChatBotComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "name"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " ", " (", ")"])), i1.ɵppd(2, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.conversation == null) ? null : ((_co.conversation.User == null) ? null : _co.conversation.User.FirstName)); var currVal_1 = ((_co.conversation == null) ? null : ((_co.conversation.User == null) ? null : _co.conversation.User.LastName)); var currVal_2 = i1.ɵunv(_v, 1, 2, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent, 0), ((_co.conversation == null) ? null : _co.conversation.Start), "yyyy-MM-dd")); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
function View_ChatBotComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "message"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "user": 0, "bot": 1 }), (_l()(), i1.ɵted(4, null, [" ", ": ", " "]))], function (_ck, _v) { var currVal_0 = "message"; var currVal_1 = _ck(_v, 3, 0, (_v.context.$implicit.Role === "user"), (_v.context.$implicit.Role === "bot")); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.Role; var currVal_3 = _v.context.$implicit.Text; _ck(_v, 4, 0, currVal_2, currVal_3); }); }
function View_ChatBotComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "bot typing-indicator"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "span", [["class", "dot"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "span", [["class", "dot"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "span", [["class", "dot"]], null, null, null, null, null))], null, null); }
function View_ChatBotComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "content"], ["id", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "container"], ["id", "container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChatBotComponent_5)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChatBotComponent_6)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 10, "div", [["class", "footer row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 6, "div", [["class", "input"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 5, "input", [["placeholder", "Skriv.."], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "keypress"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 9).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 9)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 9)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.newMessage = $event) !== false);
        ad = (pd_4 && ad);
    } if (("keypress" === en)) {
        var pd_5 = (_co.onKeyPress($event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 16384, null, 0, i3.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.DefaultValueAccessor]), i1.ɵdid(11, 671744, null, 0, i3.NgModel, [[8, null], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i1.ɵdid(13, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), (_l()(), i1.ɵeld(14, 0, null, null, 2, "div", [["class", "button"], ["style", "margin-left:5px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.sendMessage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Skicka"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.messages; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.isLoading; _ck(_v, 5, 0, currVal_1); var currVal_9 = _co.newMessage; _ck(_v, 11, 0, currVal_9); }, function (_ck, _v) { var currVal_2 = i1.ɵnov(_v, 13).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 13).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 13).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 13).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 13).ngClassValid; var currVal_7 = i1.ɵnov(_v, 13).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 13).ngClassPending; _ck(_v, 8, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
function View_ChatBotComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "message"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "user": 0, "bot": 1 }), (_l()(), i1.ɵted(4, null, [" ", " : ", " "]))], function (_ck, _v) { var currVal_0 = "message"; var currVal_1 = _ck(_v, 3, 0, (_v.context.$implicit.Actor === 1), (_v.context.$implicit.Actor === 2)); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = ((_v.context.$implicit.Actor == 1) ? "Du" : "Bot"); var currVal_3 = _v.context.$implicit.Message; _ck(_v, 4, 0, currVal_2, currVal_3); }); }
function View_ChatBotComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "bot typing-indicator"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "span", [["class", "dot"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "span", [["class", "dot"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "span", [["class", "dot"]], null, null, null, null, null))], null, null); }
function View_ChatBotComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "content"], ["id", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "container"], ["id", "container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChatBotComponent_8)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChatBotComponent_9)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.conversation.Messages; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.isLoading; _ck(_v, 5, 0, currVal_1); }, null); }
function View_ChatBotComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "course-info-pane"]], [[24, "@slideInOut", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "header row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChatBotComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChatBotComponent_3)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "mat-icon", [["class", "close mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeWindow() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatIcon_0, i4.RenderType_MatIcon)), i1.ɵdid(8, 9158656, null, 0, i5.MatIcon, [i1.ElementRef, i5.MatIconRegistry, [8, null], [2, i5.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["close"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChatBotComponent_4)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChatBotComponent_7)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.conversation; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.conversation; _ck(_v, 6, 0, currVal_2); _ck(_v, 8, 0); var currVal_5 = (_co.conversation == null); _ck(_v, 11, 0, currVal_5); var currVal_6 = (_co.conversation != null); _ck(_v, 13, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); var currVal_3 = i1.ɵnov(_v, 8).inline; var currVal_4 = (((i1.ɵnov(_v, 8).color !== "primary") && (i1.ɵnov(_v, 8).color !== "accent")) && (i1.ɵnov(_v, 8).color !== "warn")); _ck(_v, 7, 0, currVal_3, currVal_4); }); }
export function View_ChatBotComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChatBotComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.show; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ChatBotComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "chatbot-component", [], null, null, null, View_ChatBotComponent_0, RenderType_ChatBotComponent)), i1.ɵdid(1, 4833280, null, 0, i6.ChatBotComponent, [i7.Router, i8.HttpClient, i9.AuthenticationService, i1.Renderer2, i10.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ChatBotComponentNgFactory = i1.ɵccf("chatbot-component", i6.ChatBotComponent, View_ChatBotComponent_Host_0, { show: "show", conversation: "conversation" }, { close: "close" }, []);
export { ChatBotComponentNgFactory as ChatBotComponentNgFactory };
