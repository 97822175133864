import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { ICompany } from '../interfaces/ICompany';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError  } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment as env } from '@env/environment';
import { HttpHeaders } from '@angular/common/http';
import { HttpErrorHandler, HandleError } from './http-error-handler';
import { tap } from "rxjs/internal/operators/tap";
import { AuthGuardService } from '../auth/auth-guard.service';
import { Store } from '@ngrx/store';
import { IAccountSettings } from '../interfaces/user/IAccountSettings';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { AuthenticationService } from './AuthenticationService';
import { IUser } from '../interfaces/user/IUser';

@Injectable()
export class ChatService {
  public apiAuthUrl: string;
  private apiBaseUrl: string;
  public token: string;
  private userString: string;
  private httpOptions: any;

  constructor(
      private http: HttpClient,
      private router: Router,
      private httpErrorHandler: HttpErrorHandler,
      private route: ActivatedRoute,
      public snackBar: MatSnackBar,
      public authService: AuthenticationService
  )
  {
      this.apiBaseUrl = env.hostApi;
      this.setHttpOptions();
  }

  showMessage(message: string) {
      let config = new MatSnackBarConfig();
      config.duration = 7000;
      this.snackBar.open(message, undefined, config);
  }

  getAll(skip: number = 0, take: number = 5): Observable<any> {

    var url = this.apiBaseUrl + 'Chat?skip=' + skip + "&take=" + take;

      return this.http.get<any>(url, this.httpOptions)
        .pipe(
        tap((response: any) => console.log(response)),
        catchError(
            (err: any) => {
                return new Observable<any>();
            }
        ));
}
    
  setHttpOptions() {
      var bearerToken = 'bearer ' + this.authService.token;

      this.httpOptions = {
          headers: new HttpHeaders({
              'Content-Type': 'application/json',
              'Authorization': bearerToken
          })
      };
  }
  

  onCreated(response: any) {
    console.log(response);
  }
  
}

