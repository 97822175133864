var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit, QueryList } from '@angular/core';
import { MatPaginator, MatSort, Sort, MatTableDataSource, MatDialog, MatDialogRef, MatTable } from '@angular/material';
import { UserService } from '../../core/services/UserService';
import { InvoiceService } from '../../core/services/InvoiceService';
import { TransactionLogService } from '../../core/services/TransactionLogService';
import { AuthenticationService } from '../../core/services/AuthenticationService';
import { LocalStorageService } from '../../core/local-storage/local-storage.service';
import { Subject } from 'rxjs';
import { ROUTE_ANIMATIONS_ELEMENTS } from '@app/core';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
var TransactionLogsComponent = /** @class */ (function () {
    function TransactionLogsComponent(dialog, invoiceService, transactionLogService, authenticationService, localStorageService, loadingService, userService) {
        var _this = this;
        this.dialog = dialog;
        this.invoiceService = invoiceService;
        this.transactionLogService = transactionLogService;
        this.authenticationService = authenticationService;
        this.localStorageService = localStorageService;
        this.loadingService = loadingService;
        this.userService = userService;
        this.isAdmin = false;
        this.expensesTotalAmount = 0;
        this.sort = new QueryList();
        this.isLoading = false;
        this.unsubscribe$ = new Subject();
        this.routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS;
        this.displayedColumns = ['invoicenumber', 'customer', 'payment', 'payout'];
        this.displayedColumnsAdmin = [];
        this.statuses = [];
        this.selectedFilterStatus = { Id: 0, Name: 'Alla fakturor' };
        this.listLength = "Laddar fakturor...";
        this.searchUpdate = new Subject();
        this.dataSource = new MatTableDataSource(this.invoiceData);
        this.dataSourceAdmin = new MatTableDataSource(this.transactionLogs);
        // Debounce search.
        this.searchUpdate.pipe(debounceTime(600), distinctUntilChanged())
            .subscribe(function (value) {
            _this.paginator.pageIndex = 0;
            _this.filter = value.toString();
            _this.getInvoices(null, 0, _this.paginator.pageSize, value.toString());
        });
    }
    TransactionLogsComponent.prototype.ngOnInit = function () {
        this.loadUser();
        this.isAdmin = this.userService.userHasRole("admin");
        this.setTableColumns(this.isAdmin);
        this.loadTransactions;
    };
    TransactionLogsComponent.prototype.loadUser = function () {
        var _this = this;
        this.userService.getCurrentUser()
            .subscribe(function (value) { return _this.userLoaded(value); }, function (error) { return console.log(error); });
    };
    TransactionLogsComponent.prototype.userLoaded = function (user) {
        this.user = this.authenticationService.userData;
        this.isAdmin = this.userService.userHasRole("admin");
        if (this.isAdmin) {
            this.loadTransactions();
        }
        // Default selected item from storage
        var defaultItem = this.localStorageService.getItem("filterStatusDefault");
        if (defaultItem && this.isAdmin) {
            var item = JSON.parse(defaultItem);
            this.selectedFilterStatus = { Id: item.Id, Name: item.Name };
            this.getInvoices([item.Id], 0, 10, this.filter);
        }
        else {
            this.getInvoices(null, 0, 10, this.filter);
        }
    };
    TransactionLogsComponent.prototype.setTableColumns = function (isAdmin) {
        if (isAdmin) {
            this.displayedColumns = ['invoicenumber', 'customer', 'user', 'payment', 'payout'];
        }
    };
    TransactionLogsComponent.prototype.applyFilter = function (filterValue) {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
        this.dataSource.filter = filterValue;
    };
    TransactionLogsComponent.prototype.onPaginatorChange = function (paginator) {
        debugger;
        this.loadingService.start();
        this.getInvoices(null, paginator.pageIndex, paginator.pageSize, this.filter);
        debugger;
    };
    TransactionLogsComponent.prototype.getInvoices = function (statuses, pageIndex, pageSize, filter) {
        var _this = this;
        if (statuses === void 0) { statuses = null; }
        this.isLoading = true;
        // All invoices selected
        if (statuses != null && statuses.length == 1 && statuses[0] == 0) {
            statuses = null;
        }
        this.invoiceService.getInvoicesWithTransLogs(statuses, pageIndex, pageSize, filter)
            .subscribe(function (value) { return _this.onInvociesRetrieved(value); }, function (error) { return console.log(error); });
    };
    TransactionLogsComponent.prototype.onInvociesRetrieved = function (response) {
        for (var i = 0; i < response.Invoices.length; i++) {
            if (response.Invoices[i].Status != undefined)
                response.Invoices[i].Status.Id = response.Invoices[i].Status.Id.toString();
        }
        this.invoiceData = response.Invoices;
        this.listLength = response.TotalInvoices;
        this.dataSource = new MatTableDataSource(this.invoiceData);
        //this.dataSource.paginator = this.paginator.toArray()[0];
        this.dataSource.sort = this.sort.toArray()[0];
        // Set up custom search
        this.dataSource.filterPredicate = function (data, filter) {
            return (data.Status != null ? data.Status.Name.toLowerCase().includes(filter) : '') ||
                (data.Id == undefined ? '' : data.Id.toString().toLowerCase().includes(filter)) ||
                (data.InvoiceNumber == undefined ? '' : data.InvoiceNumber.toString().toLowerCase().includes(filter)) ||
                (data.Customer != null ? data.Customer.Name.toLowerCase() : '').includes(filter) ||
                (data.Created.toString().toLowerCase()).includes(filter) ||
                ((data.User == null || data.User.AccountSettings == null) ? '' : data.User.AccountSettings.FirstName.toLowerCase() + ' ' + data.User.AccountSettings.LastName.toLowerCase()).includes(filter);
        };
        this.isLoading = false;
        this.loadingService.complete();
    };
    TransactionLogsComponent.prototype.loadTransactions = function () {
        //this.transactionLogService.getTransactionLogs()
        //  .subscribe(transactions => this.transactionsLoaded(transactions),
        //    error => alert("An error occured!"));
    };
    TransactionLogsComponent.prototype.transactionsLoaded = function (transactions) {
        this.transactionLogs = transactions;
        this.dataSourceAdmin = new MatTableDataSource(this.transactionLogs);
        //this.dataSourceAdmin.paginator = this.paginator.toArray()[1];
        this.dataSourceAdmin.sort = this.sort.toArray()[1];
    };
    TransactionLogsComponent.prototype.deleteTransactionLog = function (id) {
        var _this = this;
        var result = confirm("Vill du radera transaktionsraden?");
        if (result) {
            this.transactionLogService.deleteTransactionLog(id)
                .subscribe(function (response) { return _this.onDeleteFinished(response); }, function (error) { return console.log(error); });
        }
    };
    TransactionLogsComponent.prototype.onDeleteFinished = function (response) {
        this.loadTransactions();
    };
    TransactionLogsComponent.prototype.sortData = function (sort) {
        var _this = this;
        var data = this.invoiceData.slice();
        //if (!sort.active || sort.direction == '') {
        //  this.sortedData = data;
        //  return;
        //}
        debugger;
        this.sortedData = data.sort(function (a, b) {
            var isAsc = sort.direction == 'asc';
            switch (sort.active) {
                case 'id': return _this.compare(a.Id, b.Id, isAsc);
                case 'customer': return _this.compare(a.InvoiceNumber, b.InvoiceNumber, isAsc);
                case 'invoicenumber': return _this.compare(a.Customer != null ? a.Customer.Name : '', b.Customer.Name != null ? b.Customer.Name : '', isAsc);
                case 'payment': return _this.compare(a.PayoutStatus.Name, b.PayoutStatus.Name, isAsc);
                case 'payout': return _this.compare(a.Status.Name, b.Status.Name, isAsc);
                default: return 0;
            }
        });
        this.dataSource.data = this.sortedData;
    };
    TransactionLogsComponent.prototype.getPrelPayoutDate = function (date) {
        var prelPayoutDate = new Date(date);
        prelPayoutDate.setDate(prelPayoutDate.getDate() + 1);
        return prelPayoutDate;
    };
    TransactionLogsComponent.prototype.compare = function (a, b, isAsc) {
        console.log((a < b ? -1 : 1) * (isAsc ? 1 : -1));
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    };
    TransactionLogsComponent.prototype.openDialog = function (action, invoice) {
        invoice.action = action;
        var dialogRef = this.dialog.open(TransactionLogMemberDialog, {
            width: '750px',
            data: invoice
        });
        dialogRef.afterClosed();
        console.log('The dialog is open');
    };
    TransactionLogsComponent.prototype.openDialogAdmin = function (action, obj) {
        obj.action = action;
        var dialogRef = this.dialog.open(TransactionLogAdminDialog, {
            width: '750px',
            data: obj
        });
        dialogRef.afterClosed();
        console.log('The dialog is open');
    };
    return TransactionLogsComponent;
}());
export { TransactionLogsComponent };
var TransactionLogMemberDialog = /** @class */ (function () {
    function TransactionLogMemberDialog(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
        // Datat kommer in via parametern data och placeras i den lokala egenskapen invoice
        this.invoice = __assign({}, data);
        // I den inskickade fakturaunderlaget så lade vi till parametern action som vi nu kontrollerar och sedan väljer trans-rad efter
        if (this.invoice.action == 'payment') {
            this.TransactionLog = this.invoice.TransactionLogs[0];
        }
        else if (this.invoice.action == 'payout') {
            this.TransactionLog = this.invoice.TransactionLogs[1];
        }
    }
    // Stäng popupen och returnera ev data
    TransactionLogMemberDialog.prototype.closeDialog = function () {
        this.dialogRef.close(null);
    };
    return TransactionLogMemberDialog;
}());
export { TransactionLogMemberDialog };
var TransactionLogAdminDialog = /** @class */ (function () {
    function TransactionLogAdminDialog(dialogRef, 
    //@Optional() is used to prevent error if no data is passed
    data) {
        this.dialogRef = dialogRef;
        this.data = data;
        console.log("Öppnad faktura");
        console.log(data);
        this.local_data = __assign({}, data);
        this.action = this.local_data.action;
    }
    TransactionLogAdminDialog.prototype.closeDialog = function () {
        this.dialogRef.close();
        console.log('The dialog was closed');
    };
    return TransactionLogAdminDialog;
}());
export { TransactionLogAdminDialog };
