import { Component, Inject, ViewChild, TemplateRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDatepicker, MatDatepickerToggle } from '@angular/material';

import { Http, RequestOptions, Headers, Response } from '@angular/http';
import { Observable } from 'rxjs';
//import { ServiceService } from '../../core/services/ServiceService';
import { AuthenticationService } from '../../core/services/AuthenticationService'
import { AppConfig } from '../../config/app.config';
import { ICompany } from '../../core/interfaces/ICompany';
import { IContact } from '../../core/interfaces/IContact';
//import { InvoiceService } from '../../core/services/InvoiceService';
import { CompanyService } from '../../core/services/CompanyService';

@Component({
  styleUrls: ['modal-spinner-dialog.component.scss'],
    selector: 'modal-spinner-dialog',
    template: `

<mat-dialog-content>

<div class="spinner">
<mat-spinner class="spinner" [diameter]="100" color="accent"></mat-spinner>
</div>
<span class="content">Vänta medan vi hämtar din information</span>
</mat-dialog-content>

`
})


export class ModalSpinnerDialogComponent {
    private _dimesionToggle = false;
    private _formValid = false;
    public _imageList: any[] = [];
    public _hasImages = false;
    private _showImageList = false;
    private _showAttachments = false;
    private _hasAttachments = false;
    private _showComments = false;
    public contact: IContact;
    private companyId: number;
    public isNew: boolean;
    @ViewChild('myInput', { static: false })
    myInputVariable: any;
    private _apiBaseUrl: string;
        
    constructor(
      public dialogRef: MatDialogRef<ModalSpinnerDialogComponent>,
        private http: Http,
        //private service: ServiceService,
        private authenticationService: AuthenticationService,
        //private invoiceService: InvoiceService,
        private companyService: CompanyService,
        //private config: AppConfig,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) 
    { 
        //this._apiBaseUrl = config.getConfig('host');
    }

    ngOnInit() {
        
        
    }

    
    
}
