

<div class="container col-sm-12 col-md-6">
  <mat-card class="mat-elevation-z0">
    <form name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
      
      <table *ngIf="passwordReset.Code == undefined" style="width: 100%" cellspacing="0">
        <tr>
          <td>
            <p>Fyll i din e-postadress så skickar vi en länk för att skapa nytt lösenord.</p>
            
            <mat-form-field style="width: 100%" floatPlaceholder="never">
              <input matInput type="email" [ngModel]="user.Email" required (ngModelChange)="user.Email = $event" placeholder="E-postadress" id="userEmail" name="userEmail">
            </mat-form-field>

            <button [disabled]="loading" mat-raised-button color="accent" type="submit" name="action" style="float:right;">
              Skicka länk
            </button>
          </td>
        </tr>
      </table>
      
      <table *ngIf="passwordReset.Code != undefined" style="width: 100%" cellspacing="0">
        <tr>
          <td>
            <h3>ANGE NYTT LÖSENORD</h3>
            <p>Fyll i din e-postadress så skickar vi en återställningslänk för ditt lösenord.</p>
            
            <mat-form-field style="width: 100%" floatPlaceholder="never">
              <input matInput [ngModel]="passwordReset?.Email" required (ngModelChange)="passwordReset.Email = $event" type="email" placeholder="E-postadress" id="passwordResetuserEmail" name="passwordResetuserEmail">
            </mat-form-field>
            
            <mat-form-field style="width: 100%" floatPlaceholder="never">
              <input matInput [ngModel]="passwordReset?.Password" required (ngModelChange)="passwordReset.Password = $event" placeholder="Lösenord" type="password" id="passwor" name="password">
            </mat-form-field>

            <mat-form-field style="width: 100%" floatPlaceholder="never">
              <input matInput [ngModel]="passwordReset?.ConfirmPassword" required (ngModelChange)="passwordReset.ConfirmPassword = $event" placeholder="Upprepa lösenord" type="password" id="passwordrepeat" name="passwordrepeat">
            </mat-form-field>

            <div class="errorMessage" *ngIf="!validatePasswordReset()">
              Lösenorden matchar inte varandra!
            </div>

            <button [disabled]="loading || !validatePasswordReset()" mat-stroked-button md-raised-button color="primary" type="submit" name="action" style="float:right;">
              återställ lösenord
            </button>

          </td>
        </tr>
      </table>
      
    </form>
  </mat-card>
</div>
