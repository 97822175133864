import { Component, Inject, ViewChild, TemplateRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDatepicker, MatDatepickerToggle, NativeDateAdapter } from '@angular/material';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Http, RequestOptions, Headers, Response, ResponseType } from '@angular/http';
import { Observable, from } from 'rxjs';
import { AuthenticationService } from '../../core/services/AuthenticationService'
import { AppConfig } from '../../config/app.config';
import { IFile } from '../../../core/interfaces/IFile';
import { ITimeReportStatus } from '../../core/interfaces/invoice/TimeReportStatus';
import { InvoiceService } from '../../../core/services/InvoiceService';
import { CompanyService } from '../../core/services/CompanyService';
import { AllowanceService } from '../../../core/services/AllowanceService';
import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { start } from 'repl';
import { ITaxAdjustment } from '../../../core/interfaces/user/ITaxAdjustment';
import { UserService } from '../../../core/services/UserService';
import { debounce } from 'rxjs/operators';
import { FormControl, Validators } from '@angular/forms';

@Component({
    selector: 'edit-tax-adjustment-dialog',
    templateUrl: 'edit-tax-adjustment-Dialog.Component.html'
})

export class EditTaxAdjustmentDialogComponent {
    private _dimesionToggle = false;
    private _formValid = false;
    public _imageList: any[] = [];
    public _hasImages = false;
    private _showImageList = false;
    private _showAttachments = false;
    private _hasAttachments = false;
    private _showComments = false;
    public isNewCustomer: boolean;
    @ViewChild('myInput', { static: false })
    myInputVariable: any;
    private _apiBaseUrl: string;
    //@ViewChild("pickerStartDate", { static: false }) pickerStartDate: MatDatepicker<Date>;
    selectedDate: Date = new Date();
    private hoursList = [];
    private minutesList = [];
    public rowTypeList = [];
    private hoursSelected: string = "8";
    private minutesSelected: string = "0";
    private rowTypeSelected: string = "0";
    private row: any = {};
    private selectedDates = [];
    public taxAdjustmentTypes: any;
    public isAdmin: boolean = false;
    public sum: number;
    public addButtonValid: boolean = false;
    public errorMessage: string = "";
    public belowTresholdAmount: number = 20435;
    chosenDate: Date;
    firstDateOfNextMonth: Date = new Date();
    dateControl = new FormControl('', [Validators.required, this.dateValidator.bind(this)]);
    startDateValid: boolean = false ;
  
    
     
        taxAdjustment: ITaxAdjustment;

    constructor(
        public dialogRef: MatDialogRef<EditTaxAdjustmentDialogComponent>,
        //private http: Http,
        //private authenticationService: AuthenticationService,
        private invoiceService: InvoiceService,
        private allowanceService: AllowanceService,
        private userService: UserService,
      @Inject(MAT_DIALOG_DATA) public data: any
          ) 
    { 
        
    }

  ngOnInit()
  {
      //Create new tax adjustment
      if (this.data.taxAdjustment == null) {
        this.taxAdjustment = <ITaxAdjustment>{
            Id: 0,
            Guid: this.guid(),
            TaxAdjustmentType: {},
            Attachment: {
              FileType:
              {
                Id: 0,
                TechnicalName: 'TaxAdjustment'
              }
            },
            TemporaryTax: 0,
            ZeroEmployerTax: false,
            MaxAmount: 0           
          };
      }
      //Edit tax adjustment
      else {
        JSON.parse(JSON.stringify(this.data.taxAdjustment));
        this.taxAdjustment = this.data.taxAdjustment;
        var taxAdjustmentPercent = this.data.taxAdjustment.TemporaryTax * 100;
        this.taxAdjustment.TemporaryTax = taxAdjustmentPercent;
      }
          
      this.getTaxAdjustmentTypes();
      this.isAdmin = this.userService.userHasRole("admin");        

    this.taxAdjustment.Attachment.Id = 0;
    this.setInitialTaxAdjustmentStatus();   
    this.getFirstDayOfNextMonth();    
  }

  setInitialTaxAdjustmentStatus() {        

      // Set deduction status based on existing properties
      if (this.taxAdjustment.DeniedByAdmin) {
        this.taxAdjustment.Status = 'denied';
      }
      else if (this.taxAdjustment.ApprovedByAdmin) {
        this.taxAdjustment.Status = 'approved';
      }
      else {
        // Assuming both false represents 'Under Review'
        this.taxAdjustment.Status = 'underReview';        
      }
  }

  changeApprovalStatus(status: string) {   
    // Reset flags
    this.taxAdjustment.DeniedByAdmin = false;
    this.taxAdjustment.ApprovedByAdmin = false;
    
    switch (status) {
      case 'denied':
        this.taxAdjustment.DeniedByAdmin = true;
        break;
      case 'approved':
        this.taxAdjustment.ApprovedByAdmin = true;
        break;
      case 'underReview':
        // Both are false for 'Under Review'
        break;
    }

  }

  addTaxAdjustment() {

  }

  formUpdated()
    {
               
  }

  getFirstDayOfNextMonth() {
    this.firstDateOfNextMonth.setMonth(this.firstDateOfNextMonth.getMonth() + 1);
    this.firstDateOfNextMonth.setDate(1);

    this.firstDateOfNextMonth = this.toFormattedDate(this.firstDateOfNextMonth.toString());
    
  }

  dateValidator(control: FormControl) {
    if (control.value != "" && control.value != undefined) {      
      if (control.value >= this.firstDateOfNextMonth) {
        this.startDateValid = true;
        this.errorMessage = "";
      }
      else {
        this.startDateValid = false;
        this.errorMessage = "En skattejustering kan starta tidigast första dagen i kommande månad"
      }
    }
  }

  getTaxAdjustmentTypeName(taxAdjustmentTypeId: number) {
      for (var i = 0; i < this.taxAdjustmentTypes.length; i++) {

          if (this.taxAdjustmentTypes[i].Id == taxAdjustmentTypeId) {
              return this.taxAdjustmentTypes[i].Name;
          }
      }
  }

  onSubmit()
  {
      this.save();
  }
    
  calculateAddButtonValid()
  {
    if (this.isAdmin)
    {
      return true;
    }
    else {
      //Add button is valid if type 1, 2 and 4 has an attached file and a valid form. Type 3 does not require file.

      //If type Over or Under limit value
      if (this.taxAdjustment.TaxAdjustmentType.Id == 1 || this.taxAdjustment.TaxAdjustmentType.Id == 2 || this.taxAdjustment.TaxAdjustmentType.Id == 4) {

        //If file is attached
        if (this.taxAdjustment.Attachment.Id > 0 || this.taxAdjustment.Attachment.Guid != null) {
          return true;
        }
      }

      if (this.taxAdjustment.TaxAdjustmentType.Id == 3 || this.taxAdjustment.TaxAdjustmentType.Id == 4) {
        
        if (!this.startDateValid) { 
            return false;
        }
        else if (this.taxAdjustment.TemporaryTax >= 0 && (this.taxAdjustment.TemporaryTax <= (this.data.user.AccountSettings.Municipality.Tax * 100))) {
          this.errorMessage = "";
          return false;
        }
        else if (this.taxAdjustment.DateStart > this.taxAdjustment.DateEnd) {
          this.errorMessage = "Slutdatumet måste vara senare än startdatumet";
          return false;
        }
        else {
          this.errorMessage = "";
          return true;
        }
      }

      
    }
  }
    

  getBelowTresholdAmount() {

    if (this.taxAdjustment.Id == 0) {
      this.taxAdjustment.MaxAmount = 0;
    }

  }

  deleteFile(file: IFile)
  {
      file.Guid = null;
      file.Id = 0;
  }

  showFile(file: IFile) {
      window.open(file.Url);
  }
    
  onTaxAdjustmentTypeChange(taxAdjustmentTypeId: number) {

    this.taxAdjustment.TaxAdjustmentType.Id = taxAdjustmentTypeId;
    this.taxAdjustment.TaxAdjustmentType.Name = this.getTaxAdjustmentTypeName(taxAdjustmentTypeId);
    this.getBelowTresholdAmount();


    if (this.taxAdjustment.TaxAdjustmentType.Id === 4) {
      this.taxAdjustment.TemporaryTax = 25;
    }
    else {
      this.taxAdjustment.TemporaryTax = 0;
    }
  }

  save() {
    // Only adjust back if TemporaryTax is in percentage form
    if (this.taxAdjustment.TemporaryTax > 1) {
      this.taxAdjustment.TemporaryTax /= 100;
    }

    this.dialogRef.close(this.taxAdjustment);
  }
    
  cancel()
  {
      this.dialogRef.close(null);
  }
    
  deleteRow()
  {
      this.data.invoiceItemGroup.action = 'delete';
      this.dialogRef.close(this.data.invoiceItemGroup);
  }    
    
  deleteListItem(item: any)
  {
      this.data.invoiceItemGroup.InvoiceRows.splice(this.data.invoiceItemGroup.InvoiceRows.indexOf(item), 1);
  }

  guid() {  
      return this.s4() + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' + this.s4() + this.s4() + this.s4();
  }

  s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
          .toString(16)
          .substring(1);
  }
    
  calendarSelect(event: any)
  {
      var date = event.toLocaleDateString('sv-SE');

      // Duplicate dates not allowed
      for (var i = 0; i < this.data.invoiceItemGroup.InvoiceRows.length; i++)
      {
          var item = this.data.invoiceItemGroup.InvoiceRows[i];

          if (item.StartDate == date)
          {
              return;
          }
      }

      // Add item
      this.data.invoiceItemGroup.InvoiceRows.push({ StartDate: date, Id: this.guid(), HoursWorked: this.hoursSelected, MinutesWorked: this.minutesSelected });

      // Sort list by date
      this.data.invoiceItemGroup.InvoiceRows.sort((val1: any, val2: any) => {
          var d1 = <any>new Date(val1.StartDate.toString());
          var d2 = <any>new Date(val2.StartDate.toString());
            
          return d1 - d2;
      })
        
  }
    
  onError(error: any)
  {
      alert('An error occured');
      console.log(error);
  }

  fileChange(event: any, file: IFile) {

    file.Guid = this.newGuid();      

      let fileList: FileList = event.target.files;
      if (fileList.length > 0) {
          let f: File = fileList[0];

          this.invoiceService.uploadDocument(f, file.Guid)
              .subscribe(
              response => this.onDocumentSaved(response, file),
              error => console.log(error));

      }
  }

  newGuid() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
          var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
          return v.toString(16);
      });
  }

  toFormattedDate(iso: string, hours: number = null, minutes: number = null) {
        
        
      

      const date = new Date(iso);

      var d = null;

      if (hours != null || minutes != null) {

          if (hours == null)
              hours = 0;
          if (minutes == null)
              minutes = 0;


          d = `${date.getFullYear()}-${(date.getMonth() + 1).toString().length == 1 ? ('0' + (date.getMonth() + 1)) : date.getMonth() + 1}-${date.getDate().toString().length == 1 ? ("0" + date.getDate().toString()) : date.getDate().toString()} ${hours.toString()}:${minutes.toString()}:00`;
      }
      else
      {
          d = `${date.getFullYear()}-${(date.getMonth() + 1).toString().length == 1 ? ('0' + (date.getMonth() + 1)) : date.getMonth() + 1}-${date.getDate().toString().length == 1 ? ("0" + date.getDate().toString()) : date.getDate().toString()}`;
      }

      if (d == '1970-01-01')
          return undefined;

      return d;
  }

  onDocumentSaved(response: any, file: IFile)
  {
      file.Id = response.Id;
    file.Url = response.Url;
    file.FileType = response.FileType;
  }

  getTaxAdjustmentTypes() {

      this.userService.getTaxAdjustmentTypes()
          .subscribe(value => this.taxAdjustmentTypes = value,
              error => console.log(error)
          );

  }
    
}
