<style>
  .time-row, .text-input, textarea {
    font-size: 16px;
    margin-left: 0px !important;
    margin-bottom: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 8px;
    border: 1px solid gray;
  }

  mat-select.select-hours, mat-select.select-vat, textarea {
    padding: 9px !important;
    border: 1px solid gray !important;
  }

  .mat-form-field-label {
    margin-left: 10px !important;
  }

  textarea:focus, input:focus {
    caret-color: black;
  }

  .segment {
    margin-bottom: 20px;
  }

  .invoicegroup {
    margin-bottom: 20px;
  }

  .angular-with-newlines {
    white-space: pre-wrap;
  }

  .border {
    border: 1px solid;
    margin: 10px 10px;
    padding: 10px 10px;
  }

  .deduction {
    border: solid 1px black;
    margin: 10px 10px;
    padding: 10px 10px;
  }
</style>

<div style='height:100%;'>

  <div class="row">

    <div class="row segment" *ngIf="data.Customer != undefined">

      <div class="col-12">
        <h1>Arbetsgivarintyg</h1>
      </div>

      <div class="col-12" style="color: red; border-bottom: 1px solid #eee; padding-bottom: 20px;">
        <span *ngIf="data.SalaryShares != null && data.SalaryShares.length > 0" class="col-12" style="margin-bottom: 10px;"> Det finns en lönedelning på denna faktura      </span>
        <span>{{errorMessage}}</span>
      </div>
      <div class="col-12">
        <b>Medlemmens namn:</b><br />
        {{data?.UserSettings.FirstName}} <br />
        {{data?.UserSettings.LastName}}
      </div>
      <div class="col-12">
        <b>Personnummer:</b><br />
        {{data?.UserSettings.PersonalNumber}}
      </div>
      <div class="col-12" style="border-bottom: 1px solid #eee; padding-bottom: 20px; margin-bottom: 20px;">
        <b>E-postadress</b><br />
        {{data?.UserSettings.Email}}
      </div>

      <div class="col-12" style="padding-bottom: 20px; padding-top: 20px;">
        <b>Befattning / titel / yrke</b><br />
        {{workingTitles}}
      </div>


      <div class="col-6">
        <b>Anställningstid från:</b><br />
        {{firstDate | date: 'yyyy-MM-dd'}}
      </div>

      <div class="col-6">
        <b>Anställningstid till:</b><br />
        {{lastDate | date: 'yyyy-MM-dd'}}
      </div>

      <div class="col-12" style="border-bottom: 1px solid #eee; padding-bottom: 20px; margin-bottom: 20px; padding-top: 20px;">
        <b>Genomsnittlig timlön:</b><br />
        {{averageHourlySalary}}
      </div>

      <!--<div class="col-12">
    <div *ngFor="let group of data.InvoiceGroups" style="border-bottom: 1px solid #eee; padding: 20px 0;">
      <div>
        <div><b>Beskrivning:</b></div>
        <div class="angular-with-newlines">{{group.Description.trim()}}</div>
        <div><b>Summerad tid:</b></div>
        <div>Startdatum: {{group?.InvoiceRows[0]?.StartDate | date: 'yyyy-MM-dd'}}</div>
        <div>Slutdatum: {{group?.InvoiceRows[group.InvoiceRows.length-1]?.StartDate | date: 'yyyy-MM-dd'}}</div>
        <div>Totalt arbetade timmar: {{getGroupTotalHours(group)}}</div>
      </div><br />
      <div><b>Detaljerad tid:</b></div>
      <div style="margin-bottom:10px;">
        <div *ngFor="let row of group.InvoiceRows">
          {{row?.StartDate | date: 'yyyy-MM-dd'}}  ({{row.HoursWorked}} h{{row.MinutesWorked > 0 ? ' ' + row.MinutesWorked.toString() + ' min' : ''}})
        </div>
      </div>
    </div>
  </div>-->



      <div class="col-12">

        <div *ngFor="let monthYear of objectKeys(datesGroupedByMonth)" style="margin-bottom: 20px;">
          <!-- Display the month and year as a headline -->
          <div><b>{{ monthYear | date: 'MMMM yyyy' }}</b></div>
          <!-- Display the total hours worked for the month -->
          <div style="margin-bottom: 10px;">Totalt antal timmar: {{ datesGroupedByMonth[monthYear].totalHoursWorked.toFixed(2) }}</div>
          <!-- Display the dates and additional details -->
          <div *ngFor="let item of datesGroupedByMonth[monthYear].dates">
            {{ item.dateString | date: 'yyyy-MM-dd' }} ({{item.hoursWorked}} h{{item.minutesWorked > 0 ? ' ' + item.minutesWorked.toString() + ' min' : ''}})
          </div>
        </div>
      </div>

      <div class="col-12" style="margin-top: 20px;">
        <span>
          {{data?.UserSettings?.FirstName}} {{data?.UserSettings?.LastName}} har varit anställd på uppdrag via SAMpoolen (SAMgruppen AB)
        </span>
        <div style="margin-bottom: 20px;">
          <div style="margin-bottom: 20px;">
            <!-- Display all dates inline -->
            <span *ngFor="let monthYear of objectKeys(datesGroupedByMonth)">
              <span *ngFor="let item of datesGroupedByMonth[monthYear].dates">
                {{ item.dateString | date: 'yyyy-MM-dd' }} ({{item.hoursWorked}} h{{item.minutesWorked > 0 ? ' ' + item.minutesWorked.toString() + ' min' : ''}}),
              </span>
            </span>
          </div>

          <div>
            <div>Totalt arbetade timmar: {{totalInvoiceHours}}</div>
            <div>Totalt antal dagar: {{totalDaysWorked}}</div>
            <div>Fakturanr: {{data?.InvoiceNumber}}</div>
            <div>Bruttolön: {{grossSalary}}</div>
            <div>Genomsnittlig timlön: {{averageHourlySalary}}</div>
          </div>

        </div>
      </div>

    </div>
  </div>
</div>
