
<style>

  .main-heading
  {
    margin-left: 10px;
    margin-bottom: 20px;
  }

  .sp-form-field
  {
    margin-bottom: 30px;
  }
  .sp-form-field-label {
    margin-bottom: 15px;
    font-weight: 600;
  }
  
  .right {
    float: right;
  }
  .description
  {
    margin-bottom: 20px;
  }
  .warning
  {
    color: red;
  }

  mat-form-field
  {
    width: 100%;
  }
</style>


<!--<h1 mat-dialog-title *ngIf="!isNewCustomer" style="margin-top: 5px;margin-bottom: 35px;">Redigera kund</h1>-->

<form name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>

  <div class="col-md-12">
    <div class="mat-headline">Mottagaren saknas i vårt register</div>
    <div>
      <p>
        Det här personnumret finns inte i vårt register.
      </p>
      <p>
        Ange mottagarens information så kontaktar vi vederbörande för att få de uppgifter vi behöver för att göra en utbetalning.
      </p>
    </div>
  </div>

  <div class="col-md-12">

    <mat-radio-group name="employmentType" [(ngModel)]="IsCompanyLocal">
      <mat-radio-button class="ml-2 radiobuttonmargin" [value]="false">Privatperson</mat-radio-button> &nbsp;
      <!--<mat-radio-button class="ml-2 radiobuttonmargin" [value]="true">Företag</mat-radio-button>-->
    </mat-radio-group>
  </div>

  <div *ngIf="errorMessage" style="color:red;">
    {{errorMessage}}
  </div>

  <div *ngIf="IsCompanyLocal != null" style="margin-top:20px;">


    <div *ngIf="!IsCompanyLocal">
      <div class="col-12">
        <mat-form-field>
          <input type="text" matInput [ngModel]="accountSettings.PersonalNumber" (ngModelChange)="accountSettings.PersonalNumber = $event;onPersonalNumberUpdated(accountSettings.PersonalNumber);" required md-maxlength="50" placeholder="Personnummer" id="PersonalNumber" name="PersonalNumber" />
        </mat-form-field>
        <span style="color:red;">{{errorMessagePersonalNumber}}</span>
      </div>
    </div>

    <div *ngIf="!IsCompanyLocal && personalNumberIsValid">
      <div class="col-12">
        <mat-form-field>
          <input type="text" matInput [disabled]="true" [ngModel]="accountSettings.FirstName" (ngModelChange)="accountSettings.FirstName = $event" required md-maxlength="50" placeholder="Förnamn" id="firstname" name="firstname" />
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field>
          <input type="text" matInput [disabled]="true" [ngModel]="accountSettings.LastName" (ngModelChange)="accountSettings.LastName = $event" required md-maxlength="50" placeholder="Efternamn" id="lastname" name="lastname" />
        </mat-form-field>
      </div>
    </div>

    <div *ngIf="IsCompanyLocal">
      <div>
        <div class="col-12">
          <mat-form-field>
            <input type="text" matInput [disabled] [ngModel]="accountSettings.CompanyName" (ngModelChange)="accountSettings.CompanyName = $event" required md-maxlength="50" placeholder="Företagsnamn" id="companyname" name="companyname" />
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-form-field>
            <input type="text" matInput [ngModel]="accountSettings.OrgNumber" (ngModelChange)="accountSettings.OrgNumber = $event;onOrganizationNumberUpdated(accountSettings.OrgNumber)" required md-maxlength="50" placeholder="Organisationsnummer" id="orgnr" name="orgnr" />
          </mat-form-field>
          {{errorMessageOrganizationNumber}}
        </div>
      </div>
    </div>

    <div>
      <div class="col-12">
        <mat-form-field>
          <input type="text" matInput [ngModel]="accountSettings.Email" (ngModelChange)="accountSettings.Email = $event" required md-maxlength="50" placeholder="E-postadress" id="email" name="email" />
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field>
          <input type="text" matInput [ngModel]="accountSettings.Phone" (ngModelChange)="accountSettings.Phone = $event" required md-maxlength="50" placeholder="Mobilnummer" id="phone" name="phone" />
        </mat-form-field>
      </div>
    </div>

    <div class="col-sm-12 sp-form-field" style="margin-top: 40px;">
      <button mat-raised-button [disabled]="!f.form.valid && (personalNumberIsValid || organizationNumberIsValid)" color="accent" class="right">Spara</button>&nbsp;
      <a mat-stroked-button (click)="cancel()" color="secondary" class="right" style="margin-right:10px;">Avbryt</a>&nbsp;
    </div>
  </div>

</form>

