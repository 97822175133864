

<mat-dialog-content>

<div class="spinner">
<mat-spinner class="spinner" [diameter]="100" color="accent"></mat-spinner>
</div>
<span class="content">Vänta medan vi hämtar din information</span>
</mat-dialog-content>

