<style>
  .fix-static-bug {
    width: 100% !important;
    position: static !important;
  }
</style>
<div class="fix-static-bug">
  <div class="container">
    <div class="d-flex justify-content-center mt-3">
      <div class="col">
        <div class="d-flex justify-content-center">
          <h1 class="py-3">Kontakt</h1>
        </div>
        <div class="col mt-4 d-flex justify-content-center">
          <b class="breadcrumb">
            Kontaktuppgifter:<br />
          </b>
        </div>
        <div class="col mt-1 d-flex justify-content-center">
          <p class="breadcrumb">
            Mail: <a href="mailto:medlem@sampoolen.se">medlem@sampoolen.se</a><br />
            Telefon: 08-51 81 64 40<br />
          </p>
        </div>
        <div class="col mt-4 d-flex justify-content-center">
          <b class="breadcrumb">
            SAMpoolenägs och drivs av:
          </b>
        </div>
        <div class="col mt-1 d-flex justify-content-center">
          <p class="breadcrumb">
            SAMgruppen AB<br />
            Org.nr. 559012-7253<br />
            Hantverkargatan 52<br />
            112 31 Stockholm<br />
          </p>
        </div>
        <div class="col mt-4 d-flex justify-content-center">
          <b class="breadcrumb">
            VD:
          </b>
        </div>
        <div class="col mt-1 d-flex justify-content-center">
          <i class="breadcrumb">
            Staffan Moberg<br />
            0762-088 099<br />
          </i>
        </div>
      </div>
    </div>
  </div>
</div>
