import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';


@Component({
    selector: 'transaction-dialog-component',
    template: `
<style>

  .invoicevalue {
    text-align: right;
  }

  .marginbottom {
    margin-bottom: 20px;
  }

  .margintop {
    margin-top: 20px;
  }
</style>

<div mat-dialog-content>
  <div class="container">

    <div class="row col-lg-12 col-sm-12 marginbottom">
      <div class="col-sm-12 col-lg-6">
        <b>Fakturanummer: <span class="accent">{{ data.Invoice.InvoiceNumber }}</span></b>
      </div>
      <div class="col-sm-12 col-lg-6">
        <b>Kund: <span class="accent">{{ data.Invoice.Customer.Name }}</span></b>
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12" *ngIf="data.TotalInvoiceAmount != 0">
      <div class="col-sm-12 col-lg-8">
        Totalt fakturerat exkl moms:
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent">{{ data.TotalInvoiceAmount  | number: '1.2-2'}}</span> SEK
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12" *ngIf="data.PaidTotal > 0">
      <div class="col-sm-12 col-lg-8">
        Fakturaavgift, kund:
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent">{{ data.Invoice.InvoiceFee }}</span> SEK
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12 marginbottom" *ngIf="data.TotalInvoiceAmountIncVat != 0">
      <div class="col-sm-12 col-lg-8">
        Totalt fakturerat inkl moms:
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent">{{ data.TotalInvoiceAmountIncVat  | number: '1.2-2'}}</span> SEK
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12 marginbottom" *ngIf="data.PaidTotal != 0">
      <div class="col-sm-12 col-lg-8">
        Totalt inbetalt från kund:
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent"> + {{ data.PaidTotal  | number}}</span> SEK
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12" *ngIf="data.TotalVatAmount > 0">
      <div class="col-sm-12 col-lg-8">
        Avdragen moms:
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent">- {{ data.TotalVatAmount }}</span> SEK
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12" *ngIf="data.Invoice.FakturaAvgiftMedlem > 0">
      <div class="col-sm-12 col-lg-8">
        Din fakturaavgift:
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent">- {{ data.Invoice.FakturaAvgiftMedlem }}</span> SEK
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12" *ngIf="data.PaidTotal > 0">
      <div class="col-sm-12 col-lg-8">
        Fakturaavgift, kund:
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent">- {{ data.Invoice.InvoiceFee }}</span> SEK
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12" *ngIf="data.ServiceFee > 0">
      <div class="col-sm-12 col-lg-8">
        Avgift till SAMpoolen:
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent">- {{ data.ServiceFee }}</span> SEK
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12" *ngIf="data.Invoice.ExchangeFee > 0">
      <div class="col-sm-12 col-lg-8">
        Bankavgift:
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent">- {{ data.ExchangeFee }}</span> SEK
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12" *ngIf="data.ReminderFee > 0">
      <div class="col-sm-12 col-lg-8">
        Påminnelseavgift:
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent">- {{ data.ReminderFee }}</span> SEK
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12" *ngIf="data.InkassoFee > 0">
      <div class="col-sm-12 col-lg-8">
        Inkassoavgift:
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent">- {{ data.InkassoFee }}</span> SEK
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12" *ngIf="data.KronofogdenFee > 0">
      <div class="col-sm-12 col-lg-8">
        Kronofogdeavgift:
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent">- {{ data.KronofogdenFee }}</span> SEK
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12" *ngIf="data.CustomFee > 0">
      <div class="col-sm-12 col-lg-8 marginbottom">
        Annan avgift (se kommentar):
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent">- {{ data.CustomFee }}</span> SEK
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12" *ngIf="data.ManualDiscount > 0">
      <div class="col-sm-12 col-lg-8 marginbottom">
        Rabatt / gåva / kompensation (se kommentar):
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent">+ {{ data.ManualDiscount  | number}}</span> SEK
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12" *ngIf="data.FeeDescription != null">
      <div class="col-sm-12 col-lg-12">
        Kommentar:
      </div>
      <div class="col-sm-12 col-lg-8">
        <span>{{ data.FeeDescription }}</span>
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12 marginbottom margintop" style="border-top: 1px solid pink; border-bottom: 1px solid pink; padding: 5px 0;">
      <div class="col-sm-12 col-lg-8">
        Summa vi beräknar din lön utifrån:
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent" style="font-weight: bold;">{{ data.PartialTotalAmount | number}}</span> SEK
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12 marginbottom" *ngIf="data.DeductionsTotalAmount > 0">

      <div *ngIf="data.DeductionsTotalAmount >0 || data.AllowancesTotalAmount >0 || data.MileageTotalAmount >0">
        <div class="col-sm-12 col-lg-8">
          <i>Detta är ersättningar och avdrag som du får tillbaka skattefritt. De dras bort från beloppet ovan för att få fram din bruttolön, från vilken lagstadgade skatter dras ifrån. </i>
        </div>
      </div>
    </div>

      <div class="row col-lg-12 col-sm-12" *ngIf="data.DeductionsTotalAmount > 0">
        <div class="col-sm-12 col-lg-8">
          Privata avdrag:
        </div>
        <div class="col-sm-12 col-lg-4 invoicevalue">
          <span class="accent">{{ data.DeductionsTotalAmount | number}}</span> SEK
        </div>
      </div>

      <div class="row col-lg-12 col-sm-12 marginbottom" *ngIf="data.AllowancesTotalAmount > 0">
        <div class="col-sm-12 col-lg-8">
          Traktamenten:
        </div>
        <div class="col-sm-12 col-lg-4 invoicevalue">
          <span class="accent">{{ data.AllowancesTotalAmount | number}}</span> SEK
        </div>
      </div>

      <div class="row col-lg-12 col-sm-12" *ngIf="data.MileageTotalAmount > 0">
        <div class="col-sm-12 col-lg-8">
          Milersättning:
        </div>
        <div class="col-sm-12 col-lg-4 invoicevalue">
          <span class="accent">{{ data.MileageTotalAmount | number}}</span> SEK
        </div>
      </div>

      <div class="row col-lg-12 col-sm-12" *ngIf="data.GrossSalary > 0">
        <div class="col-sm-12 col-lg-8">
          Bruttolön:
        </div>
        <div class="col-sm-12 col-lg-4 invoicevalue">
          <span class="accent">{{ data.GrossSalary | number}}</span> SEK
        </div>
      </div>

      <div class="row col-lg-12 col-sm-12" *ngIf="data.EmployerTaxAmount > 0">
        <div class="col-sm-12 col-lg-8">
          Arbetsgivaravgift:
        </div>
        <div class="col-sm-12 col-lg-4 invoicevalue">
          <span class="accent">{{ data.EmployerTaxAmount | number: '1.0-2' }}</span> SEK
        </div>
      </div>

      <div class="row col-lg-12 col-sm-12" *ngIf="data.MunicipalityTaxAmount > 0">
        <div class="col-sm-12 col-lg-8">
          Kommunalskatt:
        </div>
        <div class="col-sm-12 col-lg-4 invoicevalue">
          <span class="accent">{{ data.MunicipalityTaxAmount | number: '1.0-2'}}</span> SEK
        </div>
      </div>

      <div class="row col-lg-12 col-sm-12" *ngIf="data.NetSalary > 0">
        <div class="col-sm-12 col-lg-8">
          Nettolön:
        </div>
        <div class="col-sm-12 col-lg-4 invoicevalue">
          <span class="accent">{{ data.NetSalary | number: '1.0-2' }}</span> SEK
        </div>
      </div>

      <div class="row col-lg-12 col-sm-12" *ngIf="data.TotalPayout > 0">
        <div class="col-sm-12 col-lg-8">
          Summa utlägg och reseersättningar:
        </div>
        <div class="col-sm-12 col-lg-4 invoicevalue">
          <span class="accent">{{ data.DeductionsTotalAmount + data.AllowancesTotalAmount + data.MileageTotalAmount | number}}</span> SEK
        </div>
      </div>

      <div class="row col-lg-12 col-sm-12 marginbottom margintop" style="border-top: 1px solid pink; border-bottom: 1px solid pink; padding: 3px 0;" *ngIf="data.TotalPayout > 0">
        <div class="col-sm-12 col-lg-8">
          Total utbetalning:
        </div>
        <div class="col-sm-12 col-lg-4 invoicevalue">
          <span class="accent" style="font-weight: bold;">{{ data.TotalPayout | number: '1.0-2' }}</span> SEK
        </div>
      </div>
    </div>

  </div>
<div mat-dialog-actions class="d-flex justify-content-end">
  <button mat-button (click)="closeDialog()" mat-flat-button color="warn">Stäng</button>
</div>

`

})

export class TransactionDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<TransactionDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    )
    { }

    closeDialog() {
        this.dialogRef.close();
        console.log('The dialog was closed');
    }

}
