<mat-sidenav-container style="background-color:#fff;">
  <div class="wrapper">

    <div class="toolbar">
      <mat-toolbar color="primary">
        <div class="col d-inline d-sm-none">
          <div class="d-flex justify-content-start">
            <div routerLink="" class="branding center">
              <img style="max-height: 50px; width: auto;" [src]="logo" />
            </div>
          </div>
        </div>
        <div class="d-sm-inline d-md-none d-lg-none" *ngIf="isAuthenticated">
          <button mat-icon-button [matMenuTriggerFor]="menu" class="hoverColorAccentThin" aria-label="icon-button with a menu">
            <mat-icon>view_headline</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <span *ngFor="let item of navigationSideMenu">
              <button mat-menu-item class="nav-button hoverColorAccent" *ngIf="isAuthenticated"
                      [routerLink]="[item.link]" routerLinkActive="active">
                <mat-icon *ngIf="item.icon" color="accent">{{ item.icon }}</mat-icon> {{item.label}}
              </button>
            </span>
            <div class="col">
              <button mat-icon-button (click)="onLogoutClick()">
                <mat-icon color="accent" class="hoverColorAccentThin">exit_to_app</mat-icon>
                <div style="margin-left: 20px; display: inline-block;">Logga ut</div>
              </button>
            </div>
          </mat-menu>
        </div>
        <span routerLink=""
              class="branding center d-none d-sm-inline d-md-none">
          <img style="max-height: 50px; width: auto;" [src]="logo" />
        </span>
        <span routerLink="" class="branding spacer d-none d-md-inline">
          <img style="max-height: 50px; width: auto;" [src]="logo" />
        </span>

        <div class="d-none d-md-inline d-lg-none justify-content-end" *ngIf="isAuthenticated">
          <button mat-icon-button [matMenuTriggerFor]="menu" class="hoverColorAccentThin" aria-label="icon-button with a menu">
            <mat-icon>view_headline</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <span *ngFor="let item of navigationSideMenu">
              <button mat-menu-item class="nav-button hoverColorAccent" *ngIf="isAuthenticated"
                      [routerLink]="[item.link]" routerLinkActive="active">
                <mat-icon *ngIf="item.icon" color="accent">{{ item.icon }}</mat-icon> {{item.label}}
              </button>
            </span>
            <div class="col">
              <button mat-icon-button color="accent" (click)="onLogoutClick()">
                <mat-icon class="hoverColorAccentThin">exit_to_app</mat-icon>
                <span style="margin-left: 20px;">Logga ut</span>
              </button>
            </div>
          </mat-menu>
        </div>

        <div class="d-none d-md-none d-lg-inline">
          <div *ngIf="isAuthenticated">

            <button *ngIf="!isProd" mat-button class="sign-in-button"
                    color="accent"
                    (click)="openChatBot();">
              Chatsupport &nbsp; <mat-icon>chat</mat-icon>
            </button>

            <span *ngFor="let item of navigation">
              <button mat-button class="nav-button hoverColorAccent" title="{{ item.title }}" *ngIf="isAuthenticated"
                      [routerLink]="[item.link]" routerLinkActive="active">
                <mat-icon *ngIf="item.icon">{{ item.icon }}</mat-icon>
              </button>
            </span>
            
            <button mat-button class="nav-button hoverColorAccent"
                    title="Hantera medarbetare"
                    *ngIf="appId == 2 && isAuthenticated && isCompanyAdmin"
                    (click)="openOrganizationModal()">
              <mat-icon>group_add</mat-icon>
            </button>

            <!-- settings menu -->
            <button *ngIf="navigation.length > 0" mat-icon-button [matMenuTriggerFor]="menu" title="Fler alternativ" class="hoverColorAccentThin" style="margin-right: 13px;" aria-label="icon-button with a menu">
              <mat-icon>view_headline</mat-icon>
            </button>
            <mat-menu #menu="matMenu" xPosition="before">
              <span *ngFor="let item of navigtionMenu">
                <button mat-menu-item class="nav-button hoverColorAccent"
                        [routerLink]="[item.link]" routerLinkActive="active">
                  <mat-icon *ngIf="item.icon" color="accent">{{ item.icon }}</mat-icon> {{item.label}}
                </button>
              </span>
            </mat-menu>
            <button mat-icon-button title="Logga ut" class="nav-button" (click)="onLogoutClick()">
              <mat-icon color="accent" class="hoverColorWhite">exit_to_app</mat-icon>
            </button>
          </div>
        </div>

        <div *ngIf="!isAuthenticated" class="col">
          <div class="d-flex justify-content-end">
            <button *ngIf="appId == 1" mat-button class="sign-in-button "
                    (click)="onCreateAccountClick()">
              Skapa konto
            </button>
            <button mat-raised-button color="accent" class="sign-in-button "
                    (click)="onLoginClick()">
              Logga in
            </button>
          </div>
        </div>
      </mat-toolbar>
    </div>

    
    <div class="content" [@routeAnimations]="o.isActivated && o.activatedRoute.routeConfig.path">
      <ngx-loading-bar class="accent" [fixed]="false" style="top:0px;"></ngx-loading-bar>
      <router-outlet #o="outlet"></router-outlet>
    </div>

    <div class="footer">
      <div class="row">
        <div class="col-sm-12 links">
        </div>
      </div>
      <div class="row d-flex justify-content-center">
        <div class="footer-padding col-6 d-flex justify-content-center signature">
          &#169; <span class="year">{{year}} - <a class="sPoolFooter" href="https://www.sampoolen.se/">SAMgruppen AB</a></span>
          <br class="d-block d-sm-none">
          <!--<button mat-button class="sign-in-button "
                  (click)="openChatBot();">
            Chatbot
          </button>-->
          <chatbot-component [show]="showChatBot" [conversation]="chatConversation" (close)="onChatBotClose($event)"></chatbot-component>
        </div>
      </div>
    </div>

  </div>

</mat-sidenav-container>
