import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { ICompany } from '../interfaces/ICompany';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment as env } from '@env/environment';
import { HttpHeaders } from '@angular/common/http';
import { HttpErrorHandler, HandleError } from './http-error-handler';
import { tap } from "rxjs/internal/operators/tap";
import { AuthGuardService } from '../auth/auth-guard.service';
import { AuthenticationService } from '../services/AuthenticationService';
import { IContact} from '../interfaces/IContact';

@Injectable()
export class BankService {
    public apiAuthUrl: string;
    private apiBaseUrl: string;
    public token: string;
    private userString: string;
    private httpOptions: any;
    private handleError: HandleError;
    
    constructor(
        private http: HttpClient,
        private router: Router,
        private authenticationService: AuthenticationService,
        httpErrorHandler: HttpErrorHandler,
        private route: ActivatedRoute)
    {
        this.apiBaseUrl = env.hostApi;
        this.handleError = httpErrorHandler.createHandleError('CompanyService');
        this.setHttpOptions();
    }
    
    getAll(): Observable<any> {
        
        var url = this.apiBaseUrl + 'Banks';
        
        return this.http.get<any>(url, this.httpOptions)
            .pipe(
            tap((response: any) => this.onRetrieved(response)),
            catchError(
                (err: any) => {
                    return new Observable<any>();
                }
            ));    
    }
    
    onRetrieved(response: any)
    {
        console.log(response);
    }
    
    setHttpOptions() {
        var bearerToken = 'bearer ' + this.authenticationService.token;

        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': bearerToken
            })
        };
    }
}

