
<style>
  .frame::-webkit-scrollbar {
    -webkit-appearance: none;
  }

    .frame::-webkit-scrollbar:vertical {
      width: 11px;
    }

    .frame::-webkit-scrollbar:horizontal {
      height: 11px;
    }

  .frame::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, .5);
  }

  .frame::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 8px;
  }

  /*No footer in dialog fix */
  mat-dialog-content {
    overflow: initial !important;
  }

  .mat-dialog-container {
    height: auto !important;
  }

  .errormessage {
    color: red;
    font-size: 80%;
    font-style: italic;
  }

  .warning-box {
    border: 1px solid red;
    background-color: #ffe5e5; /* Light red background */
    padding: 20px;
    margin: 10px auto 20px auto;
    font-weight: bold;
  }
</style>

<h1 mat-dialog-title *ngIf="isNewUser" style="margin-top: 5px;"> {{data.headline != null ? data.headline : 'Ny användare' }}</h1>
<h2 mat-dialog-title *ngIf="!isNewUser" style="margin-top: 5px;">Redigera användare</h2>

<div *ngIf="personalNumberError" style="color:red;margin-bottom:15px;">{{personalNumberError}}</div>

<form #personForm="ngForm" novalidate>
  <!-- Personnummer -->
  <mat-form-field style="width:100%;">
    <mat-label>Personnummer {{personalNumberError}}</mat-label>
    <input matInput type="text"
           (ngModelChange)="pNumberValidCheck($event)"
           [(ngModel)]='user.PersonalNumber'
           [disabled]="!isNewUser"
           placeholder="ÅÅÅÅMMDDNNNN (12 siffror utan bindestreck)"
           required
           name="Personnummer">
    <mat-icon *ngIf="!pNrValid && isNewUser" color="accent" matSuffix matTooltipPosition="before" matTooltip="Personen ej verifierad" style="color:red;">close</mat-icon>
  </mat-form-field>

  <span *ngIf="!pNrValid" class="errormessage">{{errorMessagePnr}}</span>

  <mat-form-field style="width:100%;">
    <input matInput type="text" [disabled]="true" ngModel [(ngModel)]='user.FirstName' placeholder="Förnamn" name="firstname">
  </mat-form-field>

  <mat-form-field style="width:100%;">
    <input matInput type="text" [disabled]="true" ngModel [(ngModel)]='user.LastName' placeholder="Efternamn" name="lastname">
  </mat-form-field>

  <mat-form-field style="width: 100%;">
    <input matInput type="email" #correctEmail="ngModel" [(ngModel)]="user.Email" placeholder="Epost" name="Email" required email>
    <mat-icon *ngIf="correctEmail.invalid && correctEmail.touched" matSuffix color="accent">warning</mat-icon>
  </mat-form-field>

  <div *ngIf="!isNewUser" style="width:100%;margin-bottom:15px;">
    <mat-checkbox [(ngModel)]="user.Suspended" name="Suspended">
      Kontot spärrat
    </mat-checkbox>
  </div>

  <mat-form-field style="width: 100%;">
    <mat-select [(ngModel)]="user.RoleIds"
                placeholder="Role"
                (ngModelChange)="user.RoleIds = $event"
                name="roles"
                #roleSelect="ngModel"
                multiple>
      <mat-option *ngFor="let role of roles" [value]="role.Id">{{role.Name}}</mat-option>
    </mat-select>
  </mat-form-field>


  <!--<mat-form-field style="width: 100%;">
    <mat-select [ngModel]="user.RoleId"
                placeholder="Roll"
                (ngModelChange)="user.RoleId = $event"
                name="role"
                #roleSelect="ngModel"
                required>
      <mat-option *ngFor="let role of roles" [value]="role.Id">{{role.Name}}</mat-option>
    </mat-select>
    <mat-icon *ngIf="roleSelect.invalid && roleSelect.touched" matSuffix color="accent">warning</mat-icon>
  </mat-form-field>-->
  <!--<mat-form-field *ngIf="pNrValid || orgNumberValid || !isNewUser" style="width:100%;">
    <input matInput type="text" [disabled]="customer.PhoneLocked && !isAdmin" (ngModelChange)="phoneNumberValidCheck($event)"
           [(ngModel)]='customer.Phone' placeholder="Telefon" name="Phone">
  </mat-form-field>

  <span class="errormessage">{{errorMessagePhoneNr}}</span>

  <mat-form-field *ngIf="pNrValid || orgNumberValid || !isNewUser" style="width:100%;">
    <input matInput type="email" [disabled]="customer.InvoiceEmailLocked && !isAdmin" #correctEmail='ngModel' ngModel [(ngModel)]='customer.InvoiceEmail' placeholder="Epost för faktura" name="InvoiceEmail">
    <mat-icon *ngIf="!correctEmail.valid && !customer.InvoiceEmailLocked" matSuffix color="accent">warning</mat-icon>
  </mat-form-field>

  <mat-form-field *ngIf="isAdmin" style="width:100%;">
    <input matInput type="email" ngModel [(ngModel)]='customer.MailinInboxEmail' placeholder="Epost för mailin" name="MailinInboxEmail">
  </mat-form-field>

  <mat-form-field *ngIf="pNrValid || orgNumberValid || !isNewUser" style="width:100%;">
    <input matInput type="text" [disabled]="customer.CoLocked && !isAdmin" [(ngModel)]='customer.Co' placeholder="C/o" name="Co">
  </mat-form-field>

  <mat-form-field *ngIf="pNrValid || orgNumberValid || !isNewUser" style="width:100%;">
    <input matInput type="text" [disabled]="customer.AddressLocked && !isAdmin"
           [(ngModel)]='customer.Address' placeholder="Adress" required name="Address">
  </mat-form-field>

  <mat-form-field *ngIf="pNrValid || orgNumberValid || !isNewUser" style="width:100%;">
    <input matInput type="text" [disabled]="customer.Address2Locked && !isAdmin"
           [(ngModel)]='customer.Address2' placeholder="Adress 2" name="Address 2">
  </mat-form-field>

  <mat-form-field *ngIf="pNrValid || orgNumberValid || !isNewUser" style="width:100%;">
    <input matInput type="text" [disabled]="customer.ZipLocked && !isAdmin"
           [(ngModel)]='customer.Zip' placeholder="Postnummer" required name="Zip">
  </mat-form-field>

  <mat-form-field *ngIf="pNrValid || orgNumberValid || !isNewUser" style="width:100%;">
    <input matInput type="text" [disabled]="customer.CityLocked && !isAdmin"
           [(ngModel)]='customer.City' placeholder="Ort" required name="City">
  </mat-form-field>

  <mat-form-field *ngIf="pNrValid || orgNumberValid || !isNewUser" appearance="outline" style="width:100%;">
    <mat-label>GLN-nummer (ej obligatorisk)</mat-label>
    <input matInput type="text" [disabled]="customer.GlnNumberLocked && !isAdmin" [(ngModel)]='customer.GlnNumber' placeholder="GLN-nummer för EDI" name="glnnumber">
    <mat-icon color="accent" matSuffix matTooltipPosition="before" area-label="Hover to display info" matTooltip="GLN-nummer är endast krav när man skickar en EDI (e-faktura), vilket inte ska förväxlas med PDF-faktura via e-post. Man kan förklara GLN-nummer som en adress dit man skickar den elektroniska fakturan. Din kund kan förse dig med den information du behöver.">info</mat-icon>
  </mat-form-field>

  <div *ngIf="isAdmin || orgNumberValid || pNrValid || !isNewUser">
    <p>Förvald leveransmetod för faktura</p>
    <mat-radio-group name="invoiceSendType" [(ngModel)]="customer.InvoiceSendType">
      <mat-radio-button class="ml-2 radiobuttonmargin" [disabled]="customer.InvoiceSendTypeLocked && !isAdmin" [value]="0">Ingen förvald</mat-radio-button>
      <mat-radio-button class="ml-2 radiobuttonmargin" [disabled]="customer.InvoiceSendTypeLocked && !isAdmin" [value]="2">Post</mat-radio-button>
      <mat-radio-button class="ml-2 radiobuttonmargin" [disabled]="customer.InvoiceSendTypeLocked && !isAdmin" [value]="1">E-post (pdf)</mat-radio-button>
      <mat-radio-button class="ml-2 radiobuttonmargin" [disabled]="customer.InvoiceSendTypeLocked && !isAdmin" [value]="3">EDI</mat-radio-button>
    </mat-radio-group>
    <br><br>

    <div *ngIf="isAdmin" class="row" style="margin-bottom: 20px;">
      <div class="col-lg-12">
        <span class="bold" style="position: relative; top: 18px;">Betalvillkor:</span>
      </div>
      <div class="col-lg-12">
        <mat-form-field>
          <mat-select [ngModel]="customer.PaymentTerm" (ngModelChange)="customer.PaymentTerm = $event" name="paymentterm">
            <mat-option *ngFor="let item of paymentTerms" [value]="item">{{item}} dagar</mat-option>
          </mat-select>
        </mat-form-field>
        <p style="font-style: italic;">0 dagar = inget förvalt värde</p>
      </div>
    </div>

    <div *ngIf="isAdmin" class="row" style="margin-bottom: 10px;">
      <div class="col-12">
        <span class="bold">Kunden kräver elektronisk referens: </span>
      </div>
      <div class="col-12">
        <mat-checkbox [(ngModel)]="customer.ElectronicReferenceMandatory" (change)="customer.ElectronicReferenceMandatory = $event.checked;" name="referenceyes">Ja</mat-checkbox>
        <mat-checkbox [(ngModel)]="!customer.ElectronicReferenceMandatory" (change)="customer.ElectronicReferenceMandatory = !$event.checked;" style="margin-left:15px;" name="referenceno">Nej</mat-checkbox>
      </div>
    </div>-->
  <!--If electronic reference is mandatory-->
  <!--<div *ngIf="customer.ElectronicReferenceMandatory && !isAdmin" style="margin-bottom: 20px;">
    <mat-form-field appearance="outline" style="width:100%;">
      <mat-label>Elektronisk referens</mat-label>
      <input matInput type="text" [(ngModel)]='customer.ElectronicReference' placeholder="Elektronisk referens obligatorisk" name="ereference">
      <mat-icon color="accent" matSuffix matTooltipPosition="before" area-label="Hover to display info" matTooltip="Din kund kräver elektronisk referens på e-fakturor. Oftast är detta en sifferkod, men kan variera. Om du inte fått info från din kund behöver du fråga din kund efter elektronisk referens på e-fakturor.">info</mat-icon>
    </mat-form-field>
    <span style="font-size: 80%; font-style: italic;">Din kund kräver elektronisk referens på e-fakturor. Oftast är detta en sifferkod, men kan variera. Om du inte fått info från din kund behöver du fråga din kund efter elektronisk referens på e-fakturor. Du kan fylla i en referenskod här så att de automatiskt adderas på framtida fakturor (som går att ändra när du skapar en faktura) eller så kan du fylla i informationen när du skapar en faktura.</span>
  </div>-->
  <!--If electronic reference is not mandatory-->
  <!--<div *ngIf="(!customer.ElectronicReferenceMandatory && !isAdmin) || isAdmin">
    <mat-form-field appearance="outline" style="width:100%;">
      <mat-label>Elektronisk referens</mat-label>
      <input matInput type="text" [(ngModel)]='customer.ElectronicReference' placeholder="Elektronisk referens" name="ereference">
      <mat-icon color="accent" matSuffix matTooltipPosition="before" area-label="Hover to display info" matTooltip="Din kund kräver elektronisk referens på e-fakturor. Oftast är detta en sifferkod, men kan variera. Om du inte fått info från din kund behöver du fråga din kund efter elektronisk referens på e-fakturor.">info</mat-icon>
    </mat-form-field>
  </div>

  <div *ngIf="isAdmin" class="row" style="margin-bottom: 10px;">
    <div class="col-12">
      <span class="bold">Kunden godkänner fakturaavgift: </span>
    </div>
    <div class="col-12">
      <mat-checkbox [disabled]="customer.AcceptsInvoiceFeeLocked && !isAdmin" [(ngModel)]="customer.AcceptsInvoiceFee" (change)="customer.AcceptsInvoiceFee = $event.checked;" name="yes">Ja</mat-checkbox>
      <mat-checkbox [disabled]="customer.AcceptsInvoiceFeeLocked && !isAdmin " [(ngModel)]="!customer.AcceptsInvoiceFee" (change)="customer.AcceptsInvoiceFee = !$event.checked;" style="margin-left:15px;" name="no">Nej</mat-checkbox>
    </div>
  </div>
  <div *ngIf="!isNewUser &&!isAdmin && customer.AcceptsInvoiceFeeLocked && !customer.AcceptsInvoiceFee" class="row" style="margin-bottom: 10px;">
    <div class="col-12">
      <span class="bold">Denna kund godkänner inte att du fakturerar dem för fakturaavgiften.</span>
    </div>
  </div>

  <div *ngIf="isAdmin" class="row" style="margin-bottom: 10px;">
    <div class="col-12">
      <span class="bold">Kunden kräver detaljerad tidrapport: </span>
    </div>
    <div class="col-12">
      <mat-checkbox [disabled]="customer.TimeReportMandatory && !isAdmin" [(ngModel)]="customer.TimeReportMandatory" (change)="customer.TimeReportMandatory = $event.checked;" name="timeyes">Ja</mat-checkbox>
      <mat-checkbox [disabled]="customer.TimeReportMandatory && !isAdmin " [(ngModel)]="!customer.TimeReportMandatory" (change)="customer.TimeReportMandatory = !$event.checked;" style="margin-left:15px;" name="timeno">Nej</mat-checkbox>
    </div>
  </div>
  <div *ngIf="!isAdmin && customer.TimeReportMandatory" class="row" style="margin-bottom: 10px;">
    <div class="col-12">
      <span class="bold">Denna kund kräver att du anger detaljerad tidrapport på din faktura.</span>
    </div>
  </div>-->
  <!--</div>-->

</form>

<div>{{errorMessage}}</div>

<button mat-raised-button style="float: right;" color="accent"
        [disabled]="!personForm.valid || (!pNrValid && isNewUser) || !correctEmail.valid"
        (click)="save(user)">
  <mat-icon>check</mat-icon>
  &nbsp;Spara&nbsp;
</button>&nbsp;

