var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { environment as env } from '@env/environment';
import { ROUTE_ANIMATIONS_ELEMENTS } from '@app/core';
import { MatDialog } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyService } from '../../core/services/CompanyService';
import { InvoiceService } from '../../core/services/InvoiceService';
import { CurrencyService } from '../../core/services/CurrencyService';
import { ReportService } from '../../core/services/ReportService';
import { UserService } from '../../core/services/UserService';
import { EmployerTaxService } from '../../core/services/EmployerTaxService';
import { TransactionLogService } from '../../core/services/TransactionLogService';
var InvoiceSummaryComponent = /** @class */ (function () {
    function InvoiceSummaryComponent(router, route, dialog, companyService, invoiceService, currencyService, userService, employerTaxService, reportService, transactionLogService) {
        this.router = router;
        this.route = route;
        this.dialog = dialog;
        this.companyService = companyService;
        this.invoiceService = invoiceService;
        this.currencyService = currencyService;
        this.userService = userService;
        this.employerTaxService = employerTaxService;
        this.reportService = reportService;
        this.transactionLogService = transactionLogService;
        this.routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS;
        this.versions = env.versions;
        this.companies = [];
        this.currencies = [];
        this.employerTaxes = [];
        //hoursWorkedTotal: number = 0;
        this.totalInvoiceAmount = 0;
        this.ref = { Id: 0, Address: "", City: "", Email: "", Name: "", NameLocked: false, Phone: "", Zip: "", IDNumber: "" };
        this.invoiceQuickGroupTemplate = { Vat: "25", Type: { Value: "4", Name: "Övrigt" }, rowTypeSelected: "4" };
        this.invoiceQuickGroup = JSON.parse(JSON.stringify(this.invoiceQuickGroupTemplate));
        this.vatList = [];
        this.invoiceShareTypes = [];
        this.addToInvoiceList = [{ Name: 'Ja', Value: true }, { Name: 'Nej', Value: false }];
        this.paymentTerms = [10, 15, 20, 25, 30];
        this.isLoading = true;
        this.feePaidByReciever = false;
        this.feePaidBySender = false;
        this.milageTotalAmount = 0;
        this.allowancesTotalAmount = 0;
        this.payoutEmployerTax = null;
        this.PayoutMunicipalityTax = 0;
        this.EmployerTax = 0;
        this.InvoiceFeeReceiver = 0;
        this.InvoiceFeeMember = 0;
        this.pensionSavingsTaxFactor = 0.2426;
        this.pensionSavingsTaxAmount = 0;
        this.attachmentPaymentToKronofogdenAmount = 0;
        this.serviceFeeFactor = 0;
        this.zeroTaxChecked = false;
        this.ServiceFeeInfo = "";
        this.numberOfInvoices = 0;
        this.hasPreviousPensionSaving = false;
        this.pensionSavingsMessage = "";
        this.lastPayoutMessage = "";
        this.taxChangedThisMonth = false;
        this.lastPaymentIsNextMonth = false;
        this.saveValid = false;
        this.healthCareDeductionsTotalAmount = 0;
        this.healthCareTotalAmountThisInvoice = 0;
        this.healthCareAmountExceedesLimit = false;
        this.hasUnhandledTaxAdjustments = false;
        this.municipalityTaxFactor = 0;
        this.config = {
            disableClose: false,
            panelClass: 'custom-overlay-pane-class',
            hasBackdrop: true,
            backdropClass: '',
            width: '500px',
            height: '',
            position: {
                top: '',
                bottom: '',
                left: '',
                right: ''
            },
            data: {
                invoiceItemGroup: {},
                customerTemplate: {},
                customer: {},
                contact: {},
                companyId: 0
            },
            dataTemplate: {
                "Id": 0,
                "Type": {},
                "HoursWorked": undefined,
                "StartDate": undefined,
                "EndDate": undefined,
                "Comment": undefined,
                "InvoiceId": 0
            }
        };
    }
    InvoiceSummaryComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.params.subscribe(function (params) {
            _this.id = params['id'] ? params['id'] : 0;
            _this.loadInvoice(_this.id);
        });
        this.getCompanies();
        this.loadCurrencies();
        this.loadEmployerTaxes();
        this.vatList.push({ Value: "25" }, { Value: "12" }, { Value: "6" }, { Value: "0" });
    };
    InvoiceSummaryComponent.prototype.changeValue = function (value) {
        this.InvoiceFeePaidByCustomer = value.checked;
        this.invoice.InvoiceFeePaidByCustomer = this.InvoiceFeePaidByCustomer;
    };
    InvoiceSummaryComponent.prototype.sendMessage = function (message) {
        var _this = this;
        this.invoiceService.sendMessage(message, this.id)
            .subscribe(function (response) { return _this.messageSent(response); }, function (error) { return console.log(error); });
    };
    InvoiceSummaryComponent.prototype.messageSent = function (message) {
        this.invoice.Message = undefined;
        this.invoice.Messages.push(message);
    };
    InvoiceSummaryComponent.prototype.currencyChanged = function () {
        for (var i = 0; i < this.currencies.length; i++) {
            var curr = this.currencies[i];
            if (curr.Id == this.invoice.Currency.Id) {
                this.invoice.Currency.Name = curr.Name;
            }
        }
    };
    InvoiceSummaryComponent.prototype.loadInvoice = function (id) {
        var _this = this;
        this.invoiceService.getInvoiceShareTypes()
            .subscribe(function (response) { return _this.invoiceShareTypes = response; }, function (error) { return console.log(error); });
        this.invoiceService.getInvoice(id)
            .subscribe(function (response) { return _this.onGetInvoiceCompleted(response); }, function (error) { return console.log(error); });
    };
    InvoiceSummaryComponent.prototype.loadCurrencies = function () {
        var _this = this;
        this.currencyService.getCurrencies()
            .subscribe(function (response) { return _this.currencies = response; }, function (error) { return console.log(error); });
    };
    InvoiceSummaryComponent.prototype.loadEmployerTaxes = function () {
        var _this = this;
        this.employerTaxService.getEmployerTaxes()
            .subscribe(function (response) { return _this.employerTaxes = response; }, function (error) { return console.log(error); });
    };
    InvoiceSummaryComponent.prototype.checkPreviousPensionSavings = function () {
        var _this = this;
        //Check if there is a previous pension savings payment on user
        this.transactionLogService.HasUserPensionSavings(this.invoice.UserSettings.Id)
            .subscribe(function (value) { return _this.onCheckPreviousPensionSavingsFinished(value); }, function (error) { return _this.onGetCompaniesError(error); });
    };
    InvoiceSummaryComponent.prototype.onCheckPreviousPensionSavingsFinished = function (previousPensionSaving) {
        if (previousPensionSaving != null && previousPensionSaving.Id > 0) {
            this.hasPreviousPensionSaving = true;
            var pensionSavingsCompany = "";
            if (previousPensionSaving.PensionSavingsCompany != null) {
                pensionSavingsCompany = previousPensionSaving.PensionSavingsCompany.Name;
            }
            this.pensionSavingsMessage = "Medlem har fått pension tidigare. " + pensionSavingsCompany;
        }
        else if (previousPensionSaving == null) {
            this.hasPreviousPensionSaving = false;
        }
    };
    InvoiceSummaryComponent.prototype.checkZeroTaxAndLoadTaxes = function () {
        if (this.zeroTaxChecked == false) {
            if (this.PartialTotalAmount < 1000) {
                this.loadUserEmployerTax(true);
                this.loadUserMunicipalityTax(true);
            }
            else {
                this.loadUserEmployerTax(false);
                this.loadUserMunicipalityTax(false);
            }
            this.zeroTaxChecked = true;
        }
    };
    InvoiceSummaryComponent.prototype.onGetInvoiceCompleted = function (invoice) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.invoice = invoice;
                        this.setInvoiceFee();
                        this.loadGlobalServiceFee();
                        this.getServiceFee();
                        return [4 /*yield*/, this.loadLastExecutedTransaction()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.updateAmounts()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.checkZeroTaxAndLoadTaxes()];
                    case 3:
                        _a.sent();
                        if (!(this.invoice.Deductions.length > 0)) return [3 /*break*/, 5];
                        return [4 /*yield*/, this.getHealthCareDeductionsTotalAmount()];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5: return [4 /*yield*/, this.calculateDeductionsTotalAmount()];
                    case 6:
                        _a.sent();
                        this.allowancesTotalAmount = 0;
                        this.milageTotalAmount = 0;
                        if (!(this.PensionSavingsInclTax > 0)) return [3 /*break*/, 8];
                        return [4 /*yield*/, this.checkPreviousPensionSavings()];
                    case 7:
                        _a.sent();
                        _a.label = 8;
                    case 8:
                        if (this.invoice.Allowances.length > 0) {
                            this.calculateAllowancesTotalAmount();
                        }
                        return [4 /*yield*/, this.saveValidates()];
                    case 9:
                        _a.sent();
                        this.isLoading = false;
                        //Default is that customer has paid their invoice fee
                        if (this.invoice.PaymentDate == null) {
                            this.InvoiceFeePaidByCustomer = true;
                        }
                        else {
                            this.InvoiceFeePaidByCustomer = this.invoice.InvoiceFeePaidByCustomer;
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    InvoiceSummaryComponent.prototype.calculateAllowancesTotalAmount = function () {
        //Calculate allowances total amount
        for (var i = 0; i < this.invoice.Allowances.length; i++) {
            var allowance = this.invoice.Allowances[i];
            if (allowance.AllowanceType.Id == '1') {
                this.milageTotalAmount += Math.round(allowance.Sum);
            }
            else {
                this.allowancesTotalAmount += Math.round(allowance.Sum);
            }
        }
    };
    InvoiceSummaryComponent.prototype.calculateDeductionsTotalAmount = function () {
        this.DeductionsTotalAmount = 0; // Resetting total amount
        this.healthCareTotalAmountThisInvoice = 0;
        if (this.invoice.Deductions.length > 0) {
            for (var i = 0; i < this.invoice.Deductions.length; i++) {
                var deduction = this.invoice.Deductions[i];
                //Add to total amount only if deduction is not denied by admin
                if (deduction.ApprovedByAdmin) {
                    //If deduction is approved and health care, chech the total amount of health care deductions
                    if (deduction.HealthCare) {
                        this.healthCareTotalAmountThisInvoice += Math.round(deduction.Amount);
                    }
                    this.DeductionsTotalAmount += Math.round(deduction.Amount);
                    if (this.healthCareDeductionsTotalAmount + this.healthCareTotalAmountThisInvoice > 5000) {
                        this.healthCareAmountExceedesLimit = true;
                        debugger;
                        this.saveValidates();
                    }
                }
            }
            this.approvedDeductions = this.invoice.Deductions.filter(function (deduction) { return deduction.ApprovedByAdmin; });
        }
    };
    InvoiceSummaryComponent.prototype.getHealthCareDeductionsTotalAmount = function () {
        var _this = this;
        var payoutYear;
        debugger;
        if (this.invoice.PayoutDate != null) {
            this.invoice.PayoutDate = new Date(this.invoice.PayoutDate);
        }
        if (this.invoice.PayoutDate) {
            debugger;
            payoutYear = this.invoice.PayoutDate.getFullYear();
        }
        else {
            payoutYear = new Date().getFullYear();
        }
        this.userService.getTotalAmountOfHealthCareDeduction(this.invoice.UserSettings.Id, payoutYear)
            .subscribe(function (healthCareAmount) { return _this.healthCareTotalAmountLoaded(healthCareAmount); }, function (error) { return console.log(error); });
    };
    InvoiceSummaryComponent.prototype.healthCareTotalAmountLoaded = function (healthCareAmount) {
        this.healthCareDeductionsTotalAmount = healthCareAmount;
        debugger;
        this.healthCareDeductionsMessage = "Tidigare utbetalt för friskvård innevarande år: " + this.healthCareDeductionsTotalAmount + " SEK.";
        this.calculateDeductionsTotalAmount();
    };
    InvoiceSummaryComponent.prototype.loadUserEmployerTax = function (checkZeroTax) {
        var _this = this;
        if (checkZeroTax === void 0) { checkZeroTax = false; }
        this.userService.getUserEmployerTax(this.invoice.UserSettings.Id, checkZeroTax)
            .subscribe(function (tax) { return _this.employerTaxLoaded(tax); }, function (error) { return console.log(error); });
    };
    InvoiceSummaryComponent.prototype.employerTaxLoaded = function (tax) {
        this.invoice.EmployerTaxType = tax;
        var userId = this.invoice.UserSettings.Id;
        this.loadUserNumberOfInvoices(userId);
        this.updateAmounts();
        this.checkLastPayoutDateAndTaxes();
    };
    InvoiceSummaryComponent.prototype.loadUserMunicipalityTax = function (checkZeroTax) {
        var _this = this;
        if (checkZeroTax === void 0) { checkZeroTax = false; }
        this.userService.getUserMunicipalityTax(this.invoice.PayoutDate, this.invoice.UserSettings.Id, checkZeroTax)
            .subscribe(function (tax) { return _this.municipalityTaxLoaded(tax); }, function (error) { return console.log(error); });
    };
    InvoiceSummaryComponent.prototype.municipalityTaxLoaded = function (tax) {
        //this.invoice.UserSettings.Municipality.Tax = tax;
        this.municipalityTaxFactor = tax;
        this.updateAmounts();
    };
    InvoiceSummaryComponent.prototype.loadUserNumberOfInvoices = function (userId) {
        var _this = this;
        this.userService.getUserNumberOfInvoices(userId)
            .subscribe(function (numberOfInvoices) { return _this.numberOfInvoicesLoaded(numberOfInvoices); }, function (error) { return console.log(error); });
    };
    InvoiceSummaryComponent.prototype.numberOfInvoicesLoaded = function (numberOfInvoices) {
        this.numberOfInvoices = numberOfInvoices;
    };
    InvoiceSummaryComponent.prototype.loadLastExecutedTransaction = function () {
        var _this = this;
        this.transactionLogService.getLastExecutedTransactionByUserId(this.invoice.UserSettings.Guid)
            .subscribe(function (lastPaymentLog) { return _this.lastPayoutLoaded(lastPaymentLog); }, function (error) { return console.log(error); });
    };
    InvoiceSummaryComponent.prototype.lastPayoutLoaded = function (lastPayoutLog) {
        this.lastPayoutLog = lastPayoutLog;
        this.checkLastPayoutDateAndTaxes();
    };
    InvoiceSummaryComponent.prototype.checkIfYearOrMonthIsDifferent = function () {
        var lastPaymentMonth = new Date(this.lastPayoutLog.PayoutDate);
        var thisMonth = new Date();
        var lastPaymentYear = lastPaymentMonth.getFullYear();
        var lastPaymentMonthIndex = lastPaymentMonth.getMonth();
        var thisYear = thisMonth.getFullYear();
        var thisMonthIndex = thisMonth.getMonth();
        if (lastPaymentYear == thisYear && lastPaymentMonthIndex == thisMonthIndex) {
            this.taxChangedThisMonth = true;
        }
        if ((lastPaymentYear == thisYear && lastPaymentMonthIndex > thisMonthIndex) || lastPaymentYear > thisYear) {
            this.lastPaymentIsNextMonth = true;
        }
    };
    InvoiceSummaryComponent.prototype.checkLastPayoutDateAndTaxes = function () {
        var empMessage = "";
        var munMessage = "";
        var monthInfo = "";
        var lastEmployerTax = this.lastPayoutLog.EmployerTaxType.Tax;
        var lastMunicipalityTax = this.lastPayoutLog.MunicipalityTax;
        var payoutDate = new Date(this.lastPayoutLog.PayoutDate + 'Z').toISOString().split('T')[0];
        var taxChanged = false;
        //If last paid invoice is the same as current invoice, there are no other payments
        if (this.lastPayoutLog.InvoiceId == this.invoice.Id) {
            this.lastPayoutMessage = "Denna faktura är redan utbetald och är medlemmens senaste";
            monthInfo = "";
        }
        else {
            if (lastEmployerTax != this.invoice.EmployerTaxType.Tax) {
                empMessage = "AGA skiljer sig från senaste utbet: " + (lastEmployerTax * 100).toFixed(2) + " %. ";
                taxChanged = true;
            }
            else {
                empMessage = "";
            }
            if (lastMunicipalityTax != this.municipalityTaxRounded) {
                munMessage = "Kommunskatten skiljer sig mot senaste utbet: " + (lastMunicipalityTax * 100).toFixed(2) + " %. ";
                taxChanged = true;
            }
            else {
                munMessage = "";
            }
            //Check if last payment was current month or before. Only warn if same month.
            this.checkIfYearOrMonthIsDifferent();
            //If tax changed, show if it is same month or not
            if (taxChanged) {
                if (this.taxChangedThisMonth) {
                    monthInfo = "<br>Senaste utbet: " + payoutDate + ". OBS! Innevarande månad.";
                }
                else {
                    monthInfo = "<br>Senaste utbet: " + payoutDate + ". Ej innevarande månad.";
                }
            }
            if (this.lastPaymentIsNextMonth) {
                monthInfo = "Senaste utbetalningen är schemalagd till en senare månad: " + payoutDate;
                this.taxChangedThisMonth = true;
            }
            this.lastPayoutMessage = munMessage + " " + empMessage + monthInfo;
        }
    };
    InvoiceSummaryComponent.prototype.saveValidates = function () {
        if (this.invoice.Status.TechnicalName == "Draft" || this.invoice.Status.TechnicalName == "Deleted" || this.invoice.Status.TechnicalName == "UnderReview" || this.invoice.Status.TechnicalName == "Paired" || this.invoice.Status.TechnicalName == "Credited" || this.invoice.Status.TechnicalName == "Paused") {
            this.saveMessage = "Fakturans status är " + this.invoice.Status.Name;
            this.saveValid = false;
        }
        else if (this.invoice.HasUnhandledDeductions) {
            this.saveMessage = "Det finns ohanterade avdrag";
            this.saveValid = false;
        }
        else if (this.healthCareAmountExceedesLimit) {
            this.saveMessage = "Friskvårdsavdragen överstiger gränsen";
            this.saveValid = false;
        }
        else {
            this.saveMessage = "";
            this.saveValid = true;
        }
    };
    //loadMonthlyAttachmentOfSalaryReport() {
    //  this.reportService.getMonthlyAttachmentOfSalaryReport(this.invoice.UserSettings.Guid, this.invoice.Id, this.invoice.PayoutDate)
    //    .subscribe(
    //      monthlyAttachmentReport => this.monthlyAttachmentOfSalaryReportLoaded(monthlyAttachmentReport),
    //      error => console.log(error));
    //}
    //monthlyAttachmentOfSalaryReportLoaded(monthlyAttachmentReport: any) {
    //  this.invoice.EmployerTaxType = monthlyAttachmentReport;
    //  console.log("Att rep")
    //  console.log(monthlyAttachmentReport);
    //  //this.updateAmounts();
    //}
    InvoiceSummaryComponent.prototype.updateAmounts = function () {
        this.invoice.BruttoLon = 0;
        this.invoice.NettoLon = 0;
        this.payoutEmployerTax = 0;
        var totalVatAmount = this.invoice.TotalVatAmount;
        this.getServiceFee(); //?
        if (this.invoice.Currency.Name != "SEK") {
            totalVatAmount = this.invoice.TotalVatAmount * this.invoice.ExchangeRateInvoiceSent;
        }
        //Add all fees 
        this.invoice.FeesTotalAmount = this.InvoiceFeeMember + this.InvoiceFeeReceiver + this.invoice.ExchangeFee + this.invoice.KronofogdenFee + this.invoice.ReminderFee + Math.round(this.invoice.ServiceFee) + this.invoice.InkassoFee + this.invoice.CustomFee;
        //Partial total amount from which payout is calculated
        this.PartialTotalAmount = Math.round(this.invoice.PaidTotal - totalVatAmount - this.invoice.FeesTotalAmount + this.invoice.ManualDiscount);
        //Makes sure municiplaity tax always is calculated with 2 decimals, even if 3 in db table
        // Use temporary tax if present instead of municipality standard tax
        if (this.invoice.Type != null && this.invoice.Type.TechnicalName == "CommissionCashInvoice") {
            this.municipalityTaxRounded = 0;
        }
        else if (this.invoice.UserSettings.TaxAdjustments != null && this.invoice.UserSettings.TaxAdjustments.length > 0) {
            this.municipalityTaxRounded = parseFloat(this.municipalityTaxFactor.toFixed(4));
            if (this.invoice.UserSettings.TaxAdjustments[0].UnhandledByAdmin) {
                this.hasUnhandledTaxAdjustments = true;
            }
        }
        else {
            this.municipalityTaxRounded = parseFloat(this.municipalityTaxFactor.toFixed(4));
        }
        //Total amount of expenses
        this.invoice.ExpensesTotalAmount = (this.DeductionsTotalAmount + this.milageTotalAmount + this.allowancesTotalAmount);
        //Total pension savings amount
        this.pensionSavingsTaxAmount = (this.invoice.PensionSavingsAmount * this.pensionSavingsTaxFactor);
        this.PensionSavingsInclTax = (this.invoice.PensionSavingsAmount + this.pensionSavingsTaxAmount);
        //Amount from which employer tax is deducted
        this.PartialAmountExclNetExpenses = (this.PartialTotalAmount - this.invoice.ExpensesTotalAmount - this.PensionSavingsInclTax);
        if (this.PartialAmountExclNetExpenses < 0) {
            this.PartialAmountExclNetExpenses = 0;
        }
        //Calculates gross salary 
        this.invoice.BruttoLon = this.PartialAmountExclNetExpenses / (1 + this.invoice.EmployerTaxType.Tax);
        //Calculates employer tax
        this.invoice.ArbetsgivarAvgift = (this.PartialAmountExclNetExpenses - this.invoice.BruttoLon);
        //How much municipality tax is paid in SEK
        this.PayoutMunicipalityTax = (this.invoice.BruttoLon * this.municipalityTaxRounded);
        //Calculates net salary
        this.invoice.NettoLon = (this.invoice.BruttoLon - this.PayoutMunicipalityTax);
        //Rounds payout up to closes int
        //this.TotalPayout = Math.round(this.invoice.PayOutTotal);
        this.TotalPayout = this.invoice.PayOutTotal;
        this.TotalInvoiceAmount = this.invoice.TotalInvoiceAmount;
        //Calculates the total payout amount
        this.invoice.PayOutTotal = Math.round(this.invoice.NettoLon + this.invoice.ExpensesTotalAmount);
        //If net expenses are exceeding the partial total amount, only the partial total amount can be paid
        if (this.invoice.ExpensesTotalAmount > this.PartialTotalAmount) {
            this.invoice.PayOutTotal = this.PartialTotalAmount;
        }
        this.invoice.BruttoLon = Math.round(this.invoice.BruttoLon);
        this.invoice.NettoLon = Math.round(this.invoice.NettoLon);
        this.PayoutMunicipalityTax = Math.round(this.PayoutMunicipalityTax);
        this.PartialAmountExclNetExpenses = Math.round(this.PartialAmountExclNetExpenses);
        this.PensionSavingsInclTax = Math.round(this.PensionSavingsInclTax);
        this.invoice.ArbetsgivarAvgift = Math.round(this.invoice.ArbetsgivarAvgift);
        this.invoice.PensionSavingsAmount = Math.round(this.invoice.PensionSavingsAmount);
        this.pensionSavingsTaxAmount = Math.round(this.pensionSavingsTaxAmount);
        if (this.invoice.ExchangeRateInvoiceSent > 0 && this.invoice.ExchangeRateInvoicePaid == 0) {
            this.updateExchangeRatePaid();
        }
    };
    InvoiceSummaryComponent.prototype.updateExchangeRatePaid = function () {
        this.invoice.ExchangeRateInvoicePaid = (this.invoice.PaidTotal / this.invoice.TotalInvoiceAmountIncVat).toFixed(6);
    };
    //calculatePensionIfForeignCurrency() { debugger
    //  if (this.invoice.PensionSavingsFactor != 0) {
    //    this.invoice.PensionSavingsAmount = this.invoice.PaidTotal * this.invoice.PensionSavingsFactor;
    //  }
    //  else {}
    //}
    InvoiceSummaryComponent.prototype.setInvoiceFee = function () {
        if (this.invoice.FeePaidByReciever) {
            this.InvoiceFeeReceiver = this.invoice.InvoiceFee;
            this.InvoiceFeeMember = 0;
        }
        else {
            this.InvoiceFeeMember = this.invoice.InvoiceFee;
            this.InvoiceFeeReceiver = 0;
        }
    };
    InvoiceSummaryComponent.prototype.save = function (invoice, sendEmail) {
        var _this = this;
        this.isLoading = true;
        this.invoiceService.updateInvoiceSummary(invoice, sendEmail)
            .subscribe(function (response) { return _this.onSaved(response); }, function (error) { return console.log(error); });
    };
    InvoiceSummaryComponent.prototype.onSaved = function (response) {
        var _this = this;
        //Reload the view when page is saved
        this.route.params.subscribe(function (params) {
            _this.id = params['id'] ? params['id'] : 0;
            _this.loadInvoice(_this.id);
        });
    };
    InvoiceSummaryComponent.prototype.getCompanies = function () {
        var _this = this;
        this.companyService.getMyCompanies()
            .subscribe(function (value) { return _this.onGetCompaniesFinished(value); }, function (error) { return _this.onGetCompaniesError(error); });
    };
    InvoiceSummaryComponent.prototype.onGetCompaniesFinished = function (companies) {
        this.companies = companies;
    };
    InvoiceSummaryComponent.prototype.onGetCompaniesError = function (error) {
    };
    InvoiceSummaryComponent.prototype.getInvoiceNumberPart = function (invoiceNumber) {
        if (invoiceNumber && invoiceNumber.includes('-')) {
            return invoiceNumber.split('-')[0];
        }
        return invoiceNumber;
    };
    InvoiceSummaryComponent.prototype.getServiceFee = function () {
        this.loadGlobalServiceFee();
        if (!this.invoice.ServiceFeeUnlocked) {
            //If invoice is not paid, and member has custom service fee, use custom service fee, unless shared service fee
            if (this.invoice.ServiceFeeShare.Percentage != null && this.invoice.ServiceFeeShare.Percentage == 0) {
                if (this.invoice.UserSettings.HasCustomServiceFee && !this.invoice.ExpressPayOut && this.invoice.PayoutDate == null) {
                    this.serviceFeeFactor = this.invoice.UserSettings.CustomServiceFee;
                    this.invoice.ServiceFee = Math.round(this.serviceFeeFactor * this.invoice.TotalInvoiceAmount);
                    var customServicePercent = this.invoice.UserSettings.CustomServiceFee * 100;
                    this.ServiceFeeInfo = "Avtalad serviceavgift: " + customServicePercent + " %";
                }
            }
            else if (this.invoice.ServiceFeeShare.Percentage != null && this.invoice.ServiceFeeShare.IsActive == true) {
                var sharedServicePercent = this.invoice.ServiceFeeShare.Percentage;
                var parentServicePercent = this.invoice.ServiceFeeShare.InvoiceSenderServiceFeeFactor * 100;
                this.ServiceFeeInfo = "Andel: " + sharedServicePercent + " % av moderfakturans ordinarie serviceavgift: " + parentServicePercent + " %";
            }
            else {
                this.ServiceFeeInfo = "";
            }
        }
    };
    InvoiceSummaryComponent.prototype.loadGlobalServiceFee = function () {
        var _this = this;
        var express = this.invoice.ExpressPayOut;
        this.invoiceService.getGlobalServiceFee(express)
            .subscribe(function (value) { return _this.onGetGlobalServiceFeeFinished(value); }, function (error) { return _this.onGetGlobalServiceFeeFinished(error); });
    };
    InvoiceSummaryComponent.prototype.onGetGlobalServiceFeeError = function (error) {
    };
    InvoiceSummaryComponent.prototype.onGetGlobalServiceFeeFinished = function (serviceFeeFactor) {
        this.serviceFeeFactor = serviceFeeFactor;
    };
    InvoiceSummaryComponent.prototype.getCustomer = function (id) {
        for (var i = 0; i < this.companies.length; i++) {
            if (this.companies[i].Id == this.invoice.Customer.Id) {
                //console.log(this.companies[i]);
                return JSON.parse(JSON.stringify(this.companies[i]));
            }
        }
    };
    InvoiceSummaryComponent.prototype.openLink = function (link) {
        window.open(link, '_blank');
    };
    InvoiceSummaryComponent.prototype.employerTaxUpdated = function (taxId) {
        for (var i = 0; i < this.employerTaxes.length; i++) {
            if (this.employerTaxes[i].Id == taxId) {
                this.invoice.EmployerTaxType.Tax = this.employerTaxes[i].Tax;
            }
        }
        this.updateAmounts();
    };
    InvoiceSummaryComponent.prototype.toFormattedDate = function (iso) {
        var date = new Date(iso);
        var d = date.getFullYear() + "-" + ((date.getMonth() + 1).toString().length == 1 ? ('0' + (date.getMonth() + 1)) : date.getMonth() + 1) + "-" + (date.getDate().toString().length == 1 ? ("0" + date.getDate().toString()) : date.getDate().toString());
        if (d == '1970-01-01')
            return undefined;
        return d;
    };
    return InvoiceSummaryComponent;
}());
export { InvoiceSummaryComponent };
