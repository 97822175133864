import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { ICompany } from '../interfaces/ICompany';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError  } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment as env } from '@env/environment';
import { HttpHeaders } from '@angular/common/http';
import { HttpErrorHandler, HandleError } from './http-error-handler';
import { tap } from "rxjs/internal/operators/tap";
import { AuthGuardService } from '../auth/auth-guard.service';
import { Store } from '@ngrx/store';
import { IAccountSettings } from '../interfaces/user/IAccountSettings';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { AuthenticationService } from './AuthenticationService';
import { IUser } from '../interfaces/user/IUser';

@Injectable()
export class UserService {
  public apiAuthUrl: string;
  private apiBaseUrl: string;
  public token: string;
  private userString: string;
  private httpOptions: any;

  constructor(
      private http: HttpClient,
      private router: Router,
      private httpErrorHandler: HttpErrorHandler,
      private route: ActivatedRoute,
      public snackBar: MatSnackBar,
      public authService: AuthenticationService
  )
  {
      this.apiBaseUrl = env.hostApi;
      this.setHttpOptions();
  }

  showMessage(message: string) {
      let config = new MatSnackBarConfig();
      config.duration = 7000;
      this.snackBar.open(message, undefined, config);
  }

  getUsersUrl()
  {
      return this.apiBaseUrl + 'Users';
  }

  getUsers(): Observable<any> {

    var url = this.apiBaseUrl + 'Users';

    return this.http.get<any>(url, this.httpOptions)
      .pipe(
        tap((response: any) => console.log(response)),
        catchError(
          (err: any) => {
            return new Observable<any>();
          }
        ));
  }

  getOrganizationUsers(organizationId: number): Observable<any> {

    var url = this.apiBaseUrl + 'Organizations/' + organizationId + '/Users';

      return this.http.get<any>(url, this.httpOptions)
          .pipe(
          tap((response: any) => console.log(response)),
          catchError(
              (err: any) => {
                  return new Observable<any>();
              }
          ));
  }

  updatePensionSavingsCompany(userId: string, pensionSavingsCompanyId: number): Observable<any> {debugger
    var url = this.apiBaseUrl + 'Users/UpdatePensionSavingsCompany?userId=' + userId + '&pensionSavingsCompanyId=' + pensionSavingsCompanyId;
    
      return this.http.post<any>(url, this.httpOptions)
        .pipe(
          tap((response: any) => console.log(response)),
          catchError(
            (err: any) => {
              return new Observable<any>();
            }
          ));
  }

  updateUserBankInfo(userId: string, bankCompanyId: number, clearingNumber: string, accountNumber: string): Observable<any> {
    
    var url = this.apiBaseUrl + 'Users/UpdateUserBankInfo?userId=' + userId + '&bankCompanyId=' + bankCompanyId + '&clearingNumber=' + clearingNumber + '&accountNumber=' + accountNumber;

    return this.http.post<any>(url, this.httpOptions)
      .pipe(
        tap((response: any) => this.onFinishedUpdatingBank(response)),
        catchError((err: any) => {
          return new Observable<any>();
        })
      );
  }


  onFinishedUpdatingBank(response: any) {
    this.showMessage("Bankuppgifter sparades!");
  }

  getUserByPersonalOrOrgNumber(personalOrOrgNumber: string): Observable<any> {

  var url = this.apiBaseUrl + 'Users/GetByPersonalOrOrgNumber';
  var data = JSON.stringify(personalOrOrgNumber);

  return this.http.post<any>(url, data, this.httpOptions)
    .pipe(
      tap((response: any) => console.log(response)),
      catchError(
        (err: any) => {
          return new Observable<any>();
        }
      ));
}

  getUserBySearchTerm(searchTerm: string): Observable<any> {
      
    var url = this.apiBaseUrl + 'Users/GetBySearchTerm?term=' + searchTerm;
    
    return this.http.get<any>(url, this.httpOptions)
      .pipe(
        tap((response: any) => console.log(response)),
        catchError(
          (err: any) => {
            return new Observable<any>();
          }
        ));
  }

  getCompanyUserBySearchTerm(searchTerm: string): Observable<any> {
    
    var url = this.apiBaseUrl + 'Users/GetCompanyUserBySearchTerm?term=' + searchTerm;

    return this.http.get<any>(url, this.httpOptions)
      .pipe(
        tap((response: any) => console.log(response)),
        catchError(
          (err: any) => {
            return new Observable<any>();
          }
        ));
  }

  getUser(userId: string): Observable<any> {

    var url = this.apiBaseUrl + 'Users/' + userId;

    return this.http.get<any>(url, this.httpOptions)
        .pipe(
        tap((response: any) => console.log(response)),
        catchError(
            (err: any) => {
                return new Observable<any>();
            }
        ));
  }

  addUser(user: IUser): Observable<any> {
    
    var url = this.apiBaseUrl + 'Users';
    var data = JSON.stringify(user);

    return this.http.post<any>(url, data, this.httpOptions)
      .pipe(
        tap((response: any) => console.log(response)),
        catchError(
          (err: any) => {
            return throwError(err); // Rethrow the error
          }
        ));
  }

  addCompanyUser(user: IUser): Observable<any> {
    
    var url = this.apiBaseUrl + 'Companies/Users';
    var data = JSON.stringify(user);

    return this.http.post<any>(url, data, this.httpOptions)
      .pipe(
        tap((response: any) => console.log(response)),
        catchError(
          (err: any) => {
            return new Observable<any>();
          }
        ));
  }

  addFile(file: any): Observable<any> {

    var url = this.apiBaseUrl + 'Document';
    var data = JSON.stringify(file);

    return this.http.post<any>(url, data, this.httpOptions)
      .pipe(
        tap((response: any) => console.log(response)),
        catchError(
          (err: any) => {
            return new Observable<any>();
          }
      ));
  }

  //getUserEmployerTax(userId: number): Observable<any> {

  //  var url = this.apiBaseUrl + 'Users/' + userId + '/EmployerTax';

  //  return this.http.get<any>(url, this.httpOptions)
  //    .pipe(
  //      tap((response: any) => console.log(response)),
  //      catchError(
  //        (err: any) => {
  //          return new Observable<any>();
  //        }
  //      ));
  //}

  getUserEmployerTax(userId: number, checkZeroTax: boolean = false): Observable<any> {
    
    var url = this.apiBaseUrl + 'Users/' + userId + '/EmployerTax';
    if (checkZeroTax) {
      url += '?checkZeroTax=true';
    }

    return this.http.get<any>(url, this.httpOptions)
      .pipe(
        //tap((response: any) => console.log(response)),
        catchError(
          (err: any) => {
            return new Observable<any>();
          }
        ));
  }

  getUserNumberOfInvoices(userId: number): Observable<any> {

    var url = this.apiBaseUrl + 'Users/' + userId + '/NumberOfInvoices';
    
    return this.http.get<any>(url, this.httpOptions)
      .pipe(
        tap((response: any) => console.log(response)),
        catchError(
          (err: any) => {
            return new Observable<any>();
          }
        ));
  }

  getUserMunicipalityTax(payoutDate: Date, userId: number, checkZeroTax: boolean = false): Observable<any> {
       
    var url = this.apiBaseUrl + 'Users/' + userId + '/MunicipalityTax?payoutdate=' + payoutDate;

    if (checkZeroTax) {
      url += '&checkZeroTax=true';
    }

    return this.http.get<any>(url, this.httpOptions)
      .pipe(
        //tap((response: any) => console.log(response)),
        catchError(
          (err: any) => {
            return new Observable<any>();
          }
        ));
  }

  getTotalAmountOfHealthCareDeduction(userId: number, payoutYear: number = 0): Observable<any> {
    let url = this.apiBaseUrl + 'Users/' + userId + '/HealthCareAmount';

    if (payoutYear !== 0) {
      url += '?payoutyear=' + payoutYear;
    }

    return this.http.get<any>(url, this.httpOptions)
      .pipe(
        tap(response => console.log(response)),
        catchError(err => {
          console.log(err);
          return throwError(err);  // Re-throw the error to the subscriber
        })
      );
  }


  //getTotalAmountOfHealthCareDeduction(payoutYear: number, userId: number): Observable<any> {

  //  var url = this.apiBaseUrl + 'Users/' + userId + '/HealthCareAmount?payoutyear=' + payoutYear;


  //  return this.http.get<any>(url, this.httpOptions)
  //    .pipe(
  //      //tap((response: any) => console.log(response)),
  //      catchError(
  //        (err: any) => {
  //          return new Observable<any>();
  //        }
  //      ));
  //}

  //checkIfUserHasReceivedSalary(userId: number): Observable<any> {

  //  var url = this.apiBaseUrl + 'Users/' + userId + '/HasReceivedSalary';
    
  //  return this.http.get<any>(url, this.httpOptions)
  //    .pipe(
  //      tap((response: any) => console.log(response)),
  //      catchError(
  //        (err: any) => {
  //          return new Observable<any>();
  //        }
  //      ));
  //}

  deleteUser(userId: string): Observable<any> {

    var url = this.apiBaseUrl + 'Users/' + userId;

    return this.http.delete<any>(url, this.httpOptions)
        .pipe(
        tap((response: any) => console.log(response)),
        catchError(
            (err: any) => {
                return new Observable<any>();
            }
        ));
  }

  updatetUser(user: any): Observable<any> {
      var url = this.apiBaseUrl + 'Users';
      var payload = JSON.stringify(user);
        
    return this.http.put<any>(url, payload, this.httpOptions)
      .pipe(
        tap((response: any) => this.onFinishedUpdatingUser(response)),
        catchError(this.handleError<any>('updateUser'))
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // Log the error to the console or send it to a logging infrastructure
      console.error(`${operation} failed: ${error.message}`);

      // Re-throw the error
      return throwError(error);
    };
  }

  onUserUpdateError(error) {
      
      let errorMessage = "";

      if (error.error instanceof ErrorEvent) {
          // client-side error
      }
      else if (error.error == 'PersonalNumberExistsInDb') {
        errorMessage = 'En användare med detta personnummer existerar redan';

      }
      else if (error.error == 'EmailExistsInDb') {
        errorMessage = 'En användare med denna epostadress existerar redan';

      }
      else {
      // server-side error
      if (error.status === 409)
      {
        errorMessage = error.error;
      }
      else
      {
          errorMessage = ("Ett fel inträffade!");
        }
    }

    if (error.error != "")
        
        return throwError(errorMessage);
  }

  onFinishedUpdatingUser(response: any)
  {
      this.showMessage("Inställningarna sparades!");
  }

  getCurrentUser(): Observable<any> {
      var url = this.apiBaseUrl + 'User/My';

      return this.http.get<any>(url, this.httpOptions)
          .pipe(
          tap((response: any) => console.log(response)),
          catchError(
              (err: any) => {
                    
                  return new Observable<any>();

              }
          ));
  }
  
  getPersonFromSparByPersonalNumber(personalNumber: string): Observable<any> {
    var url = this.apiBaseUrl + 'Spar/' + personalNumber;

    return this.http.get<any>(url, this.httpOptions)
      .pipe(
        tap((response: any) => console.log(response)),
        catchError(
          (err: any) => {
            return new Observable<any>();
          }
        ));
  }
    
  userHasRole(roleName: string)
  {
      var e = this.authService.userData;

      if (e == null || e.User == null)
          return false;
        
      for (var i = 0; i < this.authService.userData.User.Roles.length; i++)
      {
          if (this.authService.userData.User.Roles[i].Name == roleName)
              return true;
      }

      return false;
  }

  userIsParentAccount() {
    var e = this.authService.userData;

    if (e == null || e.User == null)
      return false;
    
    return e.User.AccountSettings.IsParentAccount;
  }

  isOrganizationMasterAccount() {
    
    var e = this.authService.userData;

    if (e == null || e.User == null)
      return false;

    return e.User.AccountSettings.OrganizationMasterAccount;
  }

  userIsChildAccount() {
    var e = this.authService.userData;

    if (e == null || e.User == null)
      return false;

    return e.User.AccountSettings.ParentAccountId == '' ?  false : true;
  }
    
  setHttpOptions() {
      var bearerToken = 'bearer ' + this.authService.token;

      this.httpOptions = {
          headers: new HttpHeaders({
              'Content-Type': 'application/json',
              'Authorization': bearerToken
          })
      };
  }

  getCountries(): Observable<any> {
  var url = this.apiBaseUrl + 'Countries';

  return this.http.get<any>(url, this.httpOptions)
    .pipe(
      tap((response: any) => this.onCreated(response)),
      catchError(
        (err: any) => {

          return new Observable<any>();

        }
      ));
  }

  getPensionSavingsCompanies(): Observable<any> {
    var url = this.apiBaseUrl + 'PensionSavingsCompanies';

    return this.http.get<any>(url, this.httpOptions)
      .pipe(
        tap((response: any) => this.onCreated(response)),
        catchError(
          (err: any) => {

            return new Observable<any>();

          }
        ));
  }

  onCreated(response: any) {
  console.log(response);
  }

  getTaxAdjustmentTypes(): Observable<any> {

    var url = this.apiBaseUrl + 'TaxAdjustmentTypes';

    return this.http.get<any>(url, this.httpOptions)
        .pipe(
            tap((response: any) => console.log(response)),
            catchError(
                (err: any) => {
                    return new Observable<any>();
                }
            ));
  }

  getUserDocuments(userId: string): Observable<any> {

    var url = this.apiBaseUrl + 'Users/' + userId + '/Documents';

    return this.http.get<any>(url, this.httpOptions)
      .pipe(
        tap((response: any) => console.log(response)),
        catchError(
          (err: any) => {
            return new Observable<any>();
          }
        ));
  }
    
}

