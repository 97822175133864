import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '@app/core';
import { CanDeactivateGuard } from './core/guards/CanDeactivate.Component';
import { SettingsComponent } from './settings';
import { CompanyUserSettingsComponent } from './settings/settings/companyuser-settings.component';
import { InvoiceToolComponent } from './static/invoice-tool/invoice-tool.component';
import { CombinedInvoiceToolComponent } from './static/invoice-tool/combined-invoice-tool.component';
import { LoginComponent } from './static/login/login.component';
import { CreateAccountComponent } from './static/create-account/create-account.component';
import { ResetPasswordComponent } from './static/resetpassword/resetpassword.component';
import { InvoiceSummaryComponent } from './static/invoice-summary/invoice-summary.component';
import { CombinedInvoiceSummaryComponent } from './static/combined-invoice-summary/combined-invoice-summary.component';
import { InvoicesComponent } from './mypages/invoices/invoices.component';
import { ContactComponent } from './static/contact/contact.component';
import { AboutComponent } from './static/about/about.component';
import { UsersComponent } from './static/users/users.component';
import { UserEditComponent } from './static/users/edit-user/user-edit.component';
import { EmailpassComponent } from './static/create-account/emailpass/emailpass.component';
import { LoginEmailpassComponent } from './static/login/login-emailpass/login-emailpass.component';
import { PayoutReportsComponent } from './admin/payout-reports/payout-reports.component';
//import { PayoutReportsComponent } from './admin/payout-reports/payout-reports.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'about',
        pathMatch: 'full'
    },
    {
        path: 'about',
        component: AboutComponent,
        data: {
            title: 'Start'
        },
        canActivate: [AuthGuardService]
    },
    {
        path: 'users',
        component: UsersComponent,
        data: {
            title: 'Medlemmar'
        },
        canActivate: [AuthGuardService]
    },
    {
        path: 'users/:id',
        component: UserEditComponent,
        data: {
            title: 'Redigera Medarbetare'
        },
        canActivate: [AuthGuardService]
    },
    {
        path: 'login',
        component: LoginEmailpassComponent,
        data: { title: 'Logga in' }
    },

    {
        path: 'resetpassword',
        component: ResetPasswordComponent,
        data: {
            title: 'Återställ lösenord'
        }
    },
    {
        path: 'create-account',
        component: EmailpassComponent,
        data: { title: 'Skapa konto' }
    },
    {
        path: 'invoice-tool',
        component: InvoiceToolComponent,
        data: {
            title: 'Fakturaverktyget'
        },
        canActivate: [AuthGuardService],
  },
    {
        path: 'combined-invoice-tool',
        component: CombinedInvoiceToolComponent,
        data: {
          title: 'Samlingsfaktura'
        },
        canActivate: [AuthGuardService],
  },
    {
      path: 'combined-invoice-tool/:id',
      component: CombinedInvoiceToolComponent,
      data: {
        title: 'Combined Invoice Tool'
      },
      canActivate: [AuthGuardService],
    },
    {
        path: 'invoice-tool/:id',
        component: InvoiceToolComponent,
        data: {
            title: 'Invoice Tool'
        },
        canActivate: [AuthGuardService],
    },
    {
        path: 'invoice-summary/:id',
        component: InvoiceSummaryComponent,
        data: {
            title: 'In- och utbetalning'
        },
        canActivate: [AuthGuardService],
  },
    {
      path: 'combined-invoice-summary/:id',
      component: CombinedInvoiceSummaryComponent,
      data: {
        title: 'In- och utbetalning samlingsfaktura'
      },
      canActivate: [AuthGuardService],
    },
    {
        path: 'settings',
        component: SettingsComponent,
        data: {
            title: 'Inställningar'
        },
        canActivate: [AuthGuardService],
        canDeactivate: [CanDeactivateGuard]
  },
    {
      path: 'companyuser-settings',
      component: CompanyUserSettingsComponent,
      data: {
        title: 'Inställningar företag'
      },
      canActivate: [AuthGuardService],
      canDeactivate: [CanDeactivateGuard]
    },
    //{
    //  path: 'admin/payout-reports',
    //  component: PayoutReportsComponent,
    //  data: {
    //    title: 'Återrapporteringar'
    //  },
    //  canActivate: [AuthGuardService]
    //},
    {
        path: 'contact',
        component: ContactComponent,
        data: {
            title: 'Kontakt'
        },
        canActivate: [AuthGuardService],
    },
    {
        path: 'mypages',
        loadChildren: 'app/mypages/mypages.module#MypagesModule',
        canActivate: [AuthGuardService]
  },
    {
      path: 'admin',
      loadChildren: 'app/admin/admin.module#AdminModule',
      canActivate: [AuthGuardService]
    },
    {
        path: '**',
        redirectTo: '/login/login' 
    }
];

@NgModule({
    // useHash supports github.io demo page, remove in your app
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
export const routingComponents = [ EmailpassComponent,  LoginEmailpassComponent, ]             
 
//MbankidComponent, BankidComponent, LoginBankidComponent, LoginMbankidComponent
