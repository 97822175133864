<style>
  .accent {
    color: #E91E63;
  }

  .invoicevalue {
    text-align: right;
  }

  .pinkborder{
    border-top: 1px solid pink;
    border-bottom: 1px solid pink;
    padding: 3px 0;
  }

  .pinkbordertop {
    border-top: 1px solid pink;
    padding: 3px 0;
  }

  .extrapadding
  {
    padding: 15px;
  }

  .marginbottom {
   margin-bottom: 20px;
  }

  .margintop {
   margin-top: 20px;
  }

  .bold
  {
    font-weight: bold;
  }

  .infotext {
    font-style: italic;
    font-size: 90%;
  }

  .iconPreli {
    font-size: 14px;
    margin-bottom: -14px;
  }

</style>
<div mat-dialog-content>
  <div class="container">

    <div class="row col-lg-12 col-sm-12 marginbottom">
      <div class="col-sm-12 col-lg-6">
        <h3 *ngIf="data?.IsPayoutTransLog == false">Lönegrundande belopp</h3>
        <h3 *ngIf="data?.IsPayoutTransLog == true">Total utbetalning</h3>
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12 marginbottom">
      <div class="col-sm-12 col-lg-6">
        <b>Fakturanummer:</b> {{ data?.Invoice.InvoiceNumber }}
      </div>
      <div class="col-sm-12 col-lg-6">
        <b>Kund:</b> {{ data?.Invoice.Customer.Name }}
      </div>
      <div *ngIf="data.FeeDescription != null" class="col-sm-12 col-lg-6">
        Meddelande från löneadministratör: {{ data?.FeeDescription }}
      </div>
    </div>

    <!--Information shown in incoming transaction log popup -->

    <div class="row col-lg-12 col-sm-12 marginbottom" *ngIf="data?.IsPreliminary == true">
      <strong>Detta är en preliminär beräkning.</strong>
    </div>

    <div class="row col-lg-12 col-sm-12 marginbottom margintop pinkborder" *ngIf="data?.IsPayoutTransLog == false">
      <div class="col-sm-12 col-lg-8">
        Summa vi beräknar din utbetalning från:
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent bold">{{ data?.PartialTotalAmount | number }}</span> SEK
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12 marginbottom" *ngIf="data?.TotalInvoiceAmount != 0">
      <div class="col-sm-12 col-lg-8 accent">
        <h4>Så här har vi räknat</h4>
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12" *ngIf="data?.TotalInvoiceAmount != 0">
      <div class="col-sm-12 col-lg-8">
        Ditt fakturerade belopp, exkl moms
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent">{{ data?.TotalInvoiceAmount  | number}}</span> {{ data?.Invoice?.Currency?.Name }}
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12" *ngIf="data?.ExchangeRateInvoiceSent != 0">
      <div class="col-sm-12 col-lg-8">
        Växlingskurs när du skickade din faktura
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent">{{ data?.ExchangeRateInvoiceSent | number}}</span> SEK = 1 {{ data.Invoice.Currency.Name }}
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12" *ngIf="data?.ExchangeRateInvoiceSent != 0">
      <div class="col-sm-12 col-lg-8">
        Fakturans värde när den skickades
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent">{{ data?.ExchangeRateInvoiceSent * data?.TotalInvoiceAmount  | number}}</span> SEK
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12 marginbottom" *ngIf="data?.TotalInvoiceAmountIncVat != 0">
      <div class="col-sm-12 col-lg-8">
        Totalt fakturerat, inkl moms:
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent">{{ data?.TotalInvoiceAmountIncVat | number}}</span> SEK
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12" *ngIf="data?.InvoiceFeeReceiver > 0">
      <div class="col-sm-12 col-lg-8">
        Fakturaavgift till kund, exkl moms:
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent">{{ data?.InvoiceFee | number }}</span> SEK
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12" *ngIf="data?.PaidTotal != 0">
      <div class="col-sm-12 col-lg-8">
        Totalt inbetalt från kund:
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent"> + {{ data?.PaidTotal  | number }}</span> SEK
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12" *ngIf="data?.TotalVatAmount > 0">
      <div class="col-sm-12 col-lg-8">
        Avdragen moms:
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent">- {{ data?.TotalVatAmount }}</span> SEK
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12" *ngIf="data?.InvoiceFee > 0 && data?.InvoiceFeePaidByCustomer == true">
      <div class="col-sm-12 col-lg-8">
        Fakturaavgift från kund:
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent">- {{ data?.InvoiceFee | number}}</span> SEK
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12 marginbottom margintop" *ngIf="data?.IsPayoutTransLog == false">
      <div class="col-sm-12 col-lg-8">
        <strong>Dina avgifter:</strong>
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
      </div>
    </div>


    <div class="row col-lg-12 col-sm-12" *ngIf="data?.InvoiceFeeMember > 0">
      <div class="col-sm-12 col-lg-8">
        Din fakturaavgift:
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent">- {{ data?.InvoiceFeeMember | number}}</span> SEK
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12" *ngIf="data?.InvoiceFee > 0 && data?.InvoiceFeePaidByCustomer == false && data?.IsPayoutTransLog == false">
      <div class="col-sm-12 col-lg-8">
        Obetald fakturaavgift, kund: <mat-icon class="iconPreli" color="accent" matTooltipPosition="after" area-label="Hover to display info" matTooltip="Din kund har inte betalt fakturaavgiften, och avgiften har därför dragits från totalbeloppet">info</mat-icon>

      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent">- {{ data?.InvoiceFee | number}}</span> SEK
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12" *ngIf="data?.ServiceFee > 0">
      <div class="col-sm-12 col-lg-8">
        Avgift till SAMpoolen:
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent">- {{ data?.ServiceFee }}</span> SEK
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12" *ngIf="data?.ExchangeFee > 0">
      <div class="col-sm-12 col-lg-8">
        Bankavgift:
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent">- {{ data?.ExchangeFee }}</span> SEK
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12" *ngIf="data?.ReminderFee > 0">
      <div class="col-sm-12 col-lg-8">
        Påminnelseavgift:
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent">- {{ data?.ReminderFee }}</span> SEK
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12" *ngIf="data?.InkassoFee > 0">
      <div class="col-sm-12 col-lg-8">
        Inkassoavgift:
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent">- {{ data?.InkassoFee }}</span> SEK
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12" *ngIf="data?.KronofogdenFee > 0">
      <div class="col-sm-12 col-lg-8">
        Kronofogdeavgift:
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent">- {{ data?.KronofogdenFee }}</span> SEK
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12" *ngIf="data?.CustomFee > 0">
      <div class="col-sm-12 col-lg-8 marginbottom">
        Annan avgift (se kommentar):
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent">- {{ data?.CustomFee }}</span> SEK
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12" *ngIf="data?.ManualDiscount > 0 && data?.IsPayoutLog == false">
      <div class="col-sm-12 col-lg-8">
        Rabatt / gåva / kompensation (se kommentar):
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent">+ {{ data?.ManualDiscount  | number }}</span> SEK
      </div>
    </div>

    <!--PartialTotalAmount is visible in both incoming and outgoing transaction log-->

    <div class="row col-lg-12 col-sm-12 marginbottom margintop pinkborder">
      <div class="col-sm-12 col-lg-8">
        Summa vi beräknar din utbetalning från:
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent bold">{{ data?.PartialTotalAmount | number }}</span> SEK
      </div>
    </div>

    <!--Information shown in outgoing transaction log popup -->

    <div class="row col-lg-12 col-sm-12 margintop marginbottom" *ngIf="data?.DeductionsTotalAmount > 0">
      <div *ngIf="data?.DeductionsTotalAmount >0 || data?.AllowancesTotalAmount >0 || data?.MileageTotalAmount >0">
        <div class="col-sm-12 col-lg-12">
          <i>Utlägg, reseersättningar och traktamenten får du tillbaka skattefritt. De dras bort från beloppet ovan för att få fram ditt lönegrundande belopp. </i>
        </div>
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12" *ngIf="data?.DeductionsTotalAmount > 0">
      <div class="col-sm-12 col-lg-8">
        Privata utlägg:
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent">{{ data?.DeductionsTotalAmount | number }}</span> SEK
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12" *ngIf="data?.AllowancesTotalAmount > 0">
      <div class="col-sm-12 col-lg-8">
        Traktamenten:
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent">{{ data?.AllowancesTotalAmount | number }}</span> SEK
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12" *ngIf="data?.MileageTotalAmount > 0">
      <div class="col-sm-12 col-lg-8">
        Milersättning:
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent">{{ data?.MileageTotalAmount | number }}</span> SEK
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12 margintop" *ngIf="data?.TotalPayout > 0">
      <div class="col-sm-12 col-lg-8">
        Totalt lönegrundande belopp:
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent">{{ data?.PartialTotalAmount - data?.DeductionsTotalAmount - data?.MileageTotalAmount - data?.AllowancesTotalAmount | number }}</span> SEK
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12" *ngIf="data?.EmployerTaxAmount > 0">
      <div class="col-sm-12 col-lg-8">
        Arbetsgivaravgift:
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent">- {{ data?.EmployerTaxAmount | number }}</span> SEK
      </div>
    </div>


    <div class="row col-lg-12 col-sm-12 margintop bold" *ngIf="data?.GrossSalary > 0">
      <div class="col-sm-12 col-lg-8">
        Bruttolön:
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent">{{ data?.GrossSalary | number }}</span> SEK
      </div>
    </div>


    <div class="row col-lg-12 col-sm-12 marginbottom" *ngIf="data?.MunicipalityTaxAmount > 0">
      <div class="col-sm-12 col-lg-8">
        Kommunalskatt:
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent">- {{ data?.MunicipalityTaxAmount | number: '1.0-2'}}</span> SEK
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12 bold" *ngIf="data?.NetSalary > 0">
      <div class="col-sm-12 col-lg-8">
        Nettolön:
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent">{{ data?.NetSalary | number: '1.0-2' }}</span> SEK
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12" *ngIf="data?.DeductionsTotalAmount >0 || data?.AllowancesTotalAmount >0 || data?.MileageTotalAmount >0">
      <div class="col-sm-12 col-lg-8">
        Summa utlägg och reseersättningar:
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent">{{ data?.DeductionsTotalAmount + data?.AllowancesTotalAmount + data?.MileageTotalAmount | number }}</span> SEK
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12 marginbottom margintop bold pinkborder" *ngIf="data?.TotalPayout > 0">
      <div class="col-sm-12 col-lg-8">
        Total utbetalning:
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent bold">{{ data?.TotalPayout | number: '1.0-2' }}</span> SEK
      </div>
    </div>
    </div>

  </div>
<div mat-dialog-actions class="d-flex justify-content-end">
  <button mat-button (click)="closeDialog()" mat-flat-button color="warn">Stäng</button>
</div>
