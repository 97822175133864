
<style>

mat-dialog-content {
  overflow: auto;
}
.nopadding{
  padding: 0px !important;
  cursor: pointer;
}

</style>
<div mat-dialog-title>
  <div class="col d-flex justify-content-end nopadding">
    <mat-icon (click)="onCloseClick()">clear</mat-icon>
  </div>
</div>
<div class="container">
  <mat-dialog-content>   
    <h2 [innerHTML]=data.Headline></h2>
    <div [innerHTML]=data.Content></div>
  </mat-dialog-content>
</div>




