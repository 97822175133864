import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { SharedModule } from '../shared';

import { settingsReducer } from './settings.reducer';
import { SettingsEffects } from './settings.effects';
import { SettingsComponent } from './settings/settings.component';

import { CompanyUserSettingsComponent } from './settings/companyuser-settings.component';


import { MatTableModule } from '@angular/material';
import { MatSortModule } from '@angular/material';
import { MatPaginatorModule } from '@angular/material';

@NgModule({
  imports: [
    SharedModule,
    StoreModule.forFeature('settings', settingsReducer),
        EffectsModule.forFeature([SettingsEffects]),
        MatTableModule,
        MatSortModule,
        MatPaginatorModule
  ],
  declarations: [SettingsComponent,
                 CompanyUserSettingsComponent]
})
export class SettingsModule {}
