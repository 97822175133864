var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Router } from '@angular/router';
import { Actions, Effect } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { AUTH_KEY, AuthActionTypes } from './auth.reducer';
var AuthEffects = /** @class */ (function () {
    function AuthEffects(actions$, localStorageService, router) {
        this.actions$ = actions$;
        this.localStorageService = localStorageService;
        this.router = router;
    }
    AuthEffects.prototype.login = function () {
        var _this = this;
        return this.actions$
            .ofType(AuthActionTypes.LOGIN)
            .pipe(tap(function (action) {
            return _this.localStorageService.setItem(AUTH_KEY, { isAuthenticated: true });
        }));
    };
    AuthEffects.prototype.logout = function () {
        var _this = this;
        return this.actions$.ofType(AuthActionTypes.LOGOUT).pipe(tap(function (action) {
            _this.router.navigate(['']);
            _this.localStorageService.setItem(AUTH_KEY, { isAuthenticated: false });
        }));
    };
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Observable)
    ], AuthEffects.prototype, "login", null);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Observable)
    ], AuthEffects.prototype, "logout", null);
    return AuthEffects;
}());
export { AuthEffects };
