/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "./messages-dialog-component";
var styles_MessagesDialogComponent = ["mat-dialog-content[_ngcontent-%COMP%] {\n  overflow: auto;\n}\n.nopadding[_ngcontent-%COMP%]{\n  padding: 0px !important;\n  cursor: pointer;\n}"];
var RenderType_MessagesDialogComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_MessagesDialogComponent, data: {} });
export { RenderType_MessagesDialogComponent as RenderType_MessagesDialogComponent };
export function View_MessagesDialogComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i0.ɵdid(1, 81920, null, 0, i1.MatDialogTitle, [[2, i1.MatDialogRef], i0.ElementRef, i1.MatDialog], null, null), (_l()(), i0.ɵeld(2, 0, null, null, 3, "div", [["class", "col d-flex justify-content-end nopadding"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCloseClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i0.ɵdid(4, 9158656, null, 0, i3.MatIcon, [i0.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION], [2, i0.ErrorHandler]], null, null), (_l()(), i0.ɵted(-1, 0, ["clear"])), (_l()(), i0.ɵeld(6, 0, null, null, 4, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 3, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i0.ɵdid(8, 16384, null, 0, i1.MatDialogContent, [], null, null), (_l()(), i0.ɵeld(9, 0, null, null, 0, "h2", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null))], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 4, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = i0.ɵnov(_v, 4).inline; var currVal_2 = (((i0.ɵnov(_v, 4).color !== "primary") && (i0.ɵnov(_v, 4).color !== "accent")) && (i0.ɵnov(_v, 4).color !== "warn")); _ck(_v, 3, 0, currVal_1, currVal_2); var currVal_3 = _co.data.Headline; _ck(_v, 9, 0, currVal_3); var currVal_4 = _co.data.Content; _ck(_v, 10, 0, currVal_4); }); }
export function View_MessagesDialogComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "messages-dialog-component", [], null, null, null, View_MessagesDialogComponent_0, RenderType_MessagesDialogComponent)), i0.ɵdid(1, 49152, null, 0, i4.MessagesDialogComponent, [i1.MatDialogRef, i1.MAT_DIALOG_DATA], null, null)], null, null); }
var MessagesDialogComponentNgFactory = i0.ɵccf("messages-dialog-component", i4.MessagesDialogComponent, View_MessagesDialogComponent_Host_0, {}, {}, []);
export { MessagesDialogComponentNgFactory as MessagesDialogComponentNgFactory };
